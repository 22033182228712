import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertCourseSnapToICourse } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getCourseQueryKey = (courseId: string) => [
  "getCourseQueryKey",
  courseId,
];

interface IGetCourseArgs {
  courseId: string;
}

const getCourse = async (vars: IGetCourseArgs) => {
  const { courseId } = vars;
  const db = getFirestore();
  const courseDocRef = doc(db, collectionPaths.courses, courseId);
  const snapshot = await getDoc(courseDocRef);
  if (snapshot.exists()) {
    return convertCourseSnapToICourse(courseId, snapshot.data());
  } else {
    return Promise.reject("getCourse, course not found");
  }
};

export const useGetCourseQuery = (
  vars: IGetCourseArgs,
  enabled: boolean = true
) => {
  const { courseId } = vars;
  return useQuery(getCourseQueryKey(courseId), () => getCourse(vars), {
    onSettled: settledLogger("useGetCourseQuery"),
    enabled,
  });
};

export const useGetCourseFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetCourseArgs) => {
    const { courseId } = vars;
    return queryClient.fetchQuery(getCourseQueryKey(courseId), () =>
      getCourse(vars)
    );
  };
};
