import React from "react";
import { Home } from "./Home";

interface IHomeContainerProps {}

export const HomeContainer: React.FunctionComponent<IHomeContainerProps> = (
  props
) => {
  return <Home />;
};
