import { doc, FieldValue, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { IUser } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { listUsersByIdsQueryKey } from "../queries/useListUsersByIds";
import { settledLogger } from "../settledLogger";

// Need to make avatarUrl a FieldValue to use "deleteField()"
interface UserUpdateVars extends Omit<IUser, "avatarUrl"> {
  avatarUrl?: FieldValue | string;
}

export const useUpdateUserMutation = (userId: string) => {
  const queryClient = useQueryClient();
  const updateUser = async (vars: Partial<UserUpdateVars>) => {
    const db = getFirestore();
    const userDocRef = doc(db, collectionPaths.users, userId);
    await updateDoc(userDocRef, { ...vars });
    return vars;
  };
  return useMutation(updateUser, {
    onSettled: settledLogger("useUpdateUserMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(listUsersByIdsQueryKey([userId]), {
        refetchInactive: true,
      });
    },
  });
};
