import { Button, Space } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { LessonSelect } from "../common/LessonSelect";

interface INewLinkAlternativeProps {
  courseId: string;
  lessonId: string;
  setLessonId: (newLessonId: string) => void;
  onSubmit: () => void;
}

export const NewLinkAlternative: React.FunctionComponent<
  INewLinkAlternativeProps
> = (props) => {
  const { courseId, lessonId, setLessonId, onSubmit } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <LessonSelect
        courseId={courseId}
        selectedLessonId={lessonId}
        onChange={setLessonId}
        placeholder={"Select an existing link for your alternative"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button onClick={onSubmit} type={"primary"} disabled={!lessonId}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
