import { notification } from "antd";
import React, { useState } from "react";
import LocalStorageBase from "../../local-storage/LocalStorageBase";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { firebaseAuthErrorWithNotify } from "../../utils/validInputUtils";
import { ResetPasswordEmailEntry } from "./ResetPasswordEmailEntry";

interface IResetPasswordEmailEntryContainerProps {}

export const ResetPasswordEmailEntryContainer: React.FunctionComponent<
  IResetPasswordEmailEntryContainerProps
> = (props) => {
  const { resetPassword } = useUserAuth();
  const emailLastUsed = LocalStorageBase.getEmailLastUsed();
  const [email, setEmail] = useState<string>(emailLastUsed || "");
  const onResetPassword = async () => {
    if (!email) {
      notification.error({
        message: "Enter your email",
      });
      return;
    }
    try {
      await resetPassword(email);
      notification.success({ message: "Password recovery sent to your email" });
    } catch (error: any) {
      firebaseAuthErrorWithNotify(error);
    }
  };

  return (
    <ResetPasswordEmailEntry
      email={email}
      setEmail={setEmail}
      onResetPassword={onResetPassword}
    />
  );
};
