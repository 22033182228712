import React from "react";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { firebaseAuthErrorWithNotify } from "../../utils/validInputUtils";
import { SignInOptions } from "./SignInOptions";

interface ISignInOptionsContainerProps {
    
}

export const SignInOptionsContainer: React.FunctionComponent<
  ISignInOptionsContainerProps
> = (props) => {
  const { closeSisuModal } = useSisuModal();
  const { signInWithGoogle } = useUserAuth();

  const onSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      closeSisuModal();
    } catch (error) {
      firebaseAuthErrorWithNotify(error);
    }
  };

  return <SignInOptions onSignInWithGoogle={onSignInWithGoogle} />;
};
