import {
  FilterOutlined,
  ClockCircleOutlined,
  CaretUpFilled,
} from "@ant-design/icons";
import React from "react";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { featureFlags } from "../../../utils/appConstants";
import {
  convertCourseMetadataTypeToIcon,
  convertCourseSuggestionStateToIcon,
  convertCourseSuggestionStateToString,
  convertCourseSuggestionTypeToString,
} from "../../../utils/iconAndStringUtils";
import { FilterDropdown } from "../../common/FilterDropdown";

interface ICourseSuggestionsFilterDropdownProps {
  isSortingByUpvotes: boolean;
  stateFilter: CourseSuggestionState | undefined;
  typeFilter: CourseSuggestionType | undefined;
  toggleIsSortingByUpvotes: () => void;
  setStateFilter: (state: CourseSuggestionState | undefined) => void;
  setTypeFilter: (type: CourseSuggestionType | undefined) => void;
  clearAllFilters: () => void;
}

export const CourseSuggestionsFilterDropdown: React.FunctionComponent<
  ICourseSuggestionsFilterDropdownProps
> = (props) => {
  const {
    isSortingByUpvotes,
    stateFilter,
    typeFilter,
    toggleIsSortingByUpvotes,
    setStateFilter,
    setTypeFilter,
    clearAllFilters,
  } = props;

  const filterSections = [
    {
      title: "Sort",
      filterOptions: [
        {
          title: "Recent",
          icon: <ClockCircleOutlined />,
          isSelected: !isSortingByUpvotes,
          isClearable: false,
          onClick: toggleIsSortingByUpvotes,
        },
        {
          title: "Upvotes",
          icon: <CaretUpFilled />,
          isSelected: isSortingByUpvotes,
          isClearable: false,
          onClick: toggleIsSortingByUpvotes,
        },
      ],
    },
    {
      title: "Status",
      filterOptions: [
        CourseSuggestionState.Open,
        CourseSuggestionState.Accepted,
        CourseSuggestionState.Rejected,
      ].map((state) => {
        return {
          title: convertCourseSuggestionStateToString(state),
          icon: convertCourseSuggestionStateToIcon(state),
          isSelected: state === stateFilter,
          isClearable: true,
          onClick: () => {
            if (state === stateFilter) {
              setStateFilter(undefined);
            } else {
              setStateFilter(state);
            }
          },
        };
      }),
    },
    {
      title: "Type",
      filterOptions: [
        CourseSuggestionType.NewLesson,
        CourseSuggestionType.NewModule,
        ...(featureFlags.enableGeneralFeedbackSuggestion
          ? [CourseSuggestionType.GeneralFeedback]
          : []),
      ].map((type) => {
        return {
          title: convertCourseSuggestionTypeToString(type),
          icon: convertCourseMetadataTypeToIcon({
            courseSuggestionType: type,
          }),
          isSelected: typeFilter === type,
          isClearable: true,
          onClick: () => {
            if (typeFilter === type) {
              setTypeFilter(undefined);
            } else {
              setTypeFilter(type);
            }
          },
        };
      }),
    },
  ];

  return (
    <FilterDropdown
      defaultTitle={"Sort/Filter"}
      defaultIcon={<FilterOutlined />}
      filterSections={filterSections}
      clearAllFilters={clearAllFilters}
    />
  );
};
