import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { themeColors } from "../../styling/styleConstants";

interface IFetchMoreButtonProps {
  text: string;
  isNoMoreToFetch: boolean;
  fetchMore: () => void;
}

export const FetchMoreButton: React.FunctionComponent<IFetchMoreButtonProps> = (
  props
) => {
  const { text, isNoMoreToFetch, fetchMore } = props;

  if (!isNoMoreToFetch) {
    return (
      <Button
        onClick={fetchMore}
        type={"text"}
        size={"small"}
        style={{ color: themeColors.secondaryFontColor }}
        icon={<DownOutlined />}
      >
        {text}
      </Button>
    );
  }

  return null;
};
