import { notification } from "antd";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { CourseSuggestionState } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { getCourseSuggestionQueryKey } from "../queries/useGetCourseSuggestionQuery";
import { listCourseSuggestionsQueryKey } from "../queries/useListCourseSuggestionsQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateCourseSuggestionMutation = (
  courseSuggestionId: string,
  courseId?: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateCourseSuggestion = async (vars: {
    state: CourseSuggestionState;
  }) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useUpdateCourseSuggestionMutation, error - no user found"
      );
    }
    const { state } = vars;
    const db = getFirestore();
    const courseSuggestionDocRef = doc(
      db,
      collectionPaths.courseSuggestions,
      courseSuggestionId
    );
    return updateDoc(courseSuggestionDocRef, {
      state,
    });
  };
  return useMutation(updateCourseSuggestion, {
    onSettled: settledLogger("useUpdateCourseSuggestionMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listCourseSuggestionsQueryKey({ courseId }),
        { refetchInactive: true }
      );
      queryClient.invalidateQueries(
        getCourseSuggestionQueryKey(courseSuggestionId),
        { refetchInactive: true }
      );
    },
  });
};
