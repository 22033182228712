import { Button, Input, notification, Space, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import React, { useCallback, useMemo, useState } from "react";
import { useCreateNewCourseCategoryRequestMutation } from "../../../firestore/mutations/useCreateNewCourseCategoryRequestMutation";
import { locStrings } from "../../../localization/locStrings";
import { themeColors, zIndexPriorities } from "../../../styling/styleConstants";
import { useSisuModal } from "../../../ui-hooks/useSisuModal";
import { isValidNewCourseCategoryRequestInputWithErrorNotify } from "../../../utils/validInputUtils";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { ModalWithTitle } from "../../common/ModalWithTitle";

interface INewCourseCategoryRequestModalProps {
  visible: boolean;
  onClose: () => void;
}

export const NewCourseCategoryRequestModal: React.FunctionComponent<
  INewCourseCategoryRequestModalProps
> = (props) => {
  const { visible, onClose } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const [isSuccessState, setIsSuccessState] = useState<boolean>(false);
  const [newCategoryInput, setNewCategoryInput] = useState<string>("");
  const {
    mutate: createNewCourseCategoryRequest,
    isLoading: isCreateNewCourseCategoryRequestLoading,
  } = useCreateNewCourseCategoryRequestMutation();
  const enforcedCreateNewCourseCategoryRequest = useMemo(
    () => forceSisuModalFxnWrapper(createNewCourseCategoryRequest),
    [createNewCourseCategoryRequest, forceSisuModalFxnWrapper]
  );

  const onCloseAndReset = () => {
    setIsSuccessState(false);
    setNewCategoryInput("");
    onClose();
  };

  const onSubmit = useCallback(() => {
    const isValid =
      isValidNewCourseCategoryRequestInputWithErrorNotify().forCreate({
        category: newCategoryInput,
      });

    if (isValid) {
      enforcedCreateNewCourseCategoryRequest({ category: newCategoryInput });
      setNewCategoryInput("");
      notification.success({
        message: "Your category request has been submitted",
      });
      setIsSuccessState(true);
    }
  }, [enforcedCreateNewCourseCategoryRequest, newCategoryInput]);

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onCloseAndReset}
      title={"Request a New Category"}
      zIndex={zIndexPriorities.newCourseCategoryRequestModal}
    >
      {isSuccessState ? (
        <SuccessState onCloseAndReset={onCloseAndReset} />
      ) : (
        <RequestState
          onSubmit={onSubmit}
          setNewCategoryInput={setNewCategoryInput}
          newCategoryInput={newCategoryInput}
          isCreateNewCourseCategoryRequestLoading={
            isCreateNewCourseCategoryRequestLoading
          }
        />
      )}
    </ModalWithTitle>
  );
};

const RequestState: React.FunctionComponent<{
  onSubmit: () => void;
  setNewCategoryInput: (s: string) => void;
  newCategoryInput: string;
  isCreateNewCourseCategoryRequestLoading: boolean;
}> = (props) => {
  const {
    onSubmit,
    setNewCategoryInput,
    newCategoryInput,
    isCreateNewCourseCategoryRequestLoading,
  } = props;
  return (
    <Space direction={"vertical"}>
      <PageTypography
        title={"Not seeing a category that fits your course?"}
        level={PageTypographyLevel.PageSection}
      />
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "No problem! Submit a new category below and LoA admins will review if the topic area is large enough to warrant a new category."
        }
      </Typography>
      <Input
        id={"NewCourseCategoryRequestModal-input"}
        type={"text"}
        placeholder={"Enter a new category..."}
        value={newCategoryInput}
        onChange={(e) => setNewCategoryInput(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Button
          type={"primary"}
          loading={isCreateNewCourseCategoryRequestLoading}
          onClick={onSubmit}
        >
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};

const SuccessState: React.FunctionComponent<{ onCloseAndReset: () => void }> = (
  props
) => {
  const { onCloseAndReset } = props;
  return (
    <Space direction={"vertical"}>
      <Space direction={"horizontal"}>
        <CheckCircleFilled
          style={{ color: themeColors.successGreen, fontSize: "1.5rem" }}
        />
        <PageTypography
          title={"Your request has been submitted."}
          level={PageTypographyLevel.PageSection}
        />
      </Space>
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "In the meantime, select an existing category that most closely fits your course."
        }
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Button type={"primary"} onClick={onCloseAndReset}>
          {"Done"}
        </Button>
      </div>
    </Space>
  );
};
