import React from "react";
import { useListNotificationsQuery } from "../../firestore/queries/useListNotificationsQuery";
import { NotificationType } from "../../types/helperTypes";
import { useNotificationsFilterState } from "../../ui-hooks/useNotificationsFilterState";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { Notifications } from "./Notifications";

interface INotificationsContainerProps {}

export const NotificationsContainer: React.FunctionComponent<
  INotificationsContainerProps
> = (props) => {
  const { getNotificationsFilterState, setNotificationsFilterState } =
    useNotificationsFilterState();
  const { isUnreadOnlyEnabled, currentFilterType } =
    getNotificationsFilterState();
  const { user } = useUserAuth();
  const {
    data: notifications,
    fetchNextPage: fetchMoreNotifications,
    isNoMoreToFetch: isNoMoreNotificationsToFetch,
    isLoading: isNotificationsLoading,
    isError: isNotificationsError,
  } = useListNotificationsQuery(
    {
      userId: user?.uid || "",
      isRead: isUnreadOnlyEnabled ? false : undefined,
      type: currentFilterType,
    },
    !!user?.uid
  );

  const isAnyFilterApplied =
    isUnreadOnlyEnabled || currentFilterType !== undefined;
  const toggleUnreadOnly = () => {
    setNotificationsFilterState({ isUnreadOnlyEnabled: !isUnreadOnlyEnabled });
  };
  const onTypeFilterChange = (newFilter: NotificationType | undefined) => {
    setNotificationsFilterState({ currentFilterType: newFilter });
  };
  const clearAllFilters = () => {
    setNotificationsFilterState({
      isUnreadOnlyEnabled: false,
      currentFilterType: undefined,
    });
  };

  if (isNotificationsError) {
    return <GenericError />;
  }

  if (isNotificationsLoading || !notifications) {
    return <GenericLoader />;
  }

  return (
    <Notifications
      notifications={notifications}
      isNoMoreNotificationsToFetch={isNoMoreNotificationsToFetch}
      fetchMoreNotifications={fetchMoreNotifications}
      isUnreadOnlyEnabled={isUnreadOnlyEnabled}
      toggleUnreadOnly={toggleUnreadOnly}
      currentTypeFilter={currentFilterType}
      onTypeFilterChange={onTypeFilterChange}
      isAnyFilterApplied={isAnyFilterApplied}
      clearAllFilters={clearAllFilters}
    />
  );
};
