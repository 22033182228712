import { Button, Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { homePageSampleCourseId } from "../../utils/appConstants";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import WorldsConnectionsBlackGif from "../../assets/home-page/WorldsConnectionsBlack.gif";

interface IHomeTitleSectionProps {}

export const HomeTitleSection: React.FunctionComponent<
  IHomeTitleSectionProps
> = (props) => {
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  if (isScreenLargerThanMd) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          height: "70vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TitleWithButtons />
        </div>
        <TitleImage rems={20} />
      </div>
    );
  }

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div style={{ marginBottom: PAGE_SECTION_SPACING, textAlign: "center" }}>
        <TitleWithButtons />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TitleImage rems={15} style={{ marginTop: "-2rem" }} />
      </div>
    </Space>
  );
};

const TitleWithButtons = () => {
  const nav = useNavigation();
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <PageTypography
        title={locStrings.homePageTitle.value()}
        level={PageTypographyLevel.PageTitle}
      />
      <Typography>
        Where people build courses out of the free knowledge available all over
        the web.
      </Typography>
      <div style={{ marginTop: "0.3rem", display: "flex" }}>
        <Button
          size={"large"}
          type={"primary"}
          block={true}
          onClick={() => nav.goToPaths.courseInfo(homePageSampleCourseId)}
        >
          {"Sample Course"}
        </Button>
        <Button
          size={"large"}
          block={true}
          style={{ marginLeft: "0.8rem" }}
          onClick={() => nav.goToPaths.newCourse()}
        >
          {"Create"}
        </Button>
      </div>
    </Space>
  );
};

const TitleImage: React.FunctionComponent<{
  rems: number;
  style?: React.CSSProperties;
}> = ({ rems, style }) => {
  return (
    <img
      src={WorldsConnectionsBlackGif}
      alt={"Collaborate on LoA"}
      style={{ height: `${rems}rem`, width: "auto", margin: "auto", ...style }}
    />
  );
};
