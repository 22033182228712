import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { ILessonAlternativeLink } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { getLessonAlternativeLinkQueryKey } from "../queries/useGetLessonAlternativeLinkQuery";
import { listLessonAlternativeLinksQueryKey } from "../queries/useListLessonAlternativeLinksQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateLessonAlternativeLinkMutation = (
  alternativeLinkId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const updateUser = async (vars: {
    weblink?: string;
    reason?: string;
    previewImageUrl?: string;
  }) => {
    const { weblink, reason, previewImageUrl } = vars;
    const db = getFirestore();
    const lessonAlternativeLinkDoc = doc(
      db,
      collectionPaths.lessonAlternativeLinks,
      alternativeLinkId
    );
    const updatedAlternativeLink: Partial<ILessonAlternativeLink> = {
      weblink,
      reason,
    };
    if (vars.previewImageUrl) {
      (updatedAlternativeLink as any).previewImageUrl = previewImageUrl;
    }
    await updateDoc(lessonAlternativeLinkDoc, updatedAlternativeLink);
    return vars;
  };
  return useMutation(updateUser, {
    onSettled: settledLogger("useUpdateLessonAlternativeLinkMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAlternativeLinksQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
        }
      );
      queryClient.invalidateQueries(
        getLessonAlternativeLinkQueryKey(alternativeLinkId),
        {
          refetchInactive: true,
        }
      );
    },
  });
};
