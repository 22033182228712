import { Button, Input, Space } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import React from "react";
import {
  UsernameUniqueCheckState,
  UsernameUniqueInput,
} from "../common/UsernameUniqueInput";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { SISU_INPUT_WIDTH } from "../../styling/styleConstants";
import { convertSisuInputTypeToIcon } from "../../utils/iconAndStringUtils";
import { SignInOptionsContainer } from "./SignInOptionsContainer";
import { RevealFormForEmailButton } from "./RevealFormForEmailButton";

interface ISignUpProps {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  usernameUniqueCheckState: UsernameUniqueCheckState;
  setUsername: (s: string) => void;
  setEmail: (s: string) => void;
  setPassword: (s: string) => void;
  setConfirmPassword: (s: string) => void;
  setUsernameUniqueCheckState: (uucs: UsernameUniqueCheckState) => void;
  onSignUp: () => void;
}

export const SignUp: React.FunctionComponent<ISignUpProps> = (props) => {
  const {
    username,
    email,
    password,
    confirmPassword,
    usernameUniqueCheckState,
    setUsername,
    setEmail,
    setPassword,
    setConfirmPassword,
    setUsernameUniqueCheckState,
    onSignUp,
  } = props;
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", alignItems: "center" }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={"Let's get started."}
          level={PageTypographyLevel.PageSection}
        />
      </div>
      <SignInOptionsContainer />
      <RevealFormForEmailButton>
        <Space
          direction={"vertical"}
          style={{ width: "100%", alignItems: "center" }}
        >
          <UsernameUniqueInput
            value={username}
            onValueChange={(s) => setUsername(s)}
            onCheckStateChange={setUsernameUniqueCheckState}
            style={{ width: SISU_INPUT_WIDTH }}
          />
          <Input
            id={"SignUp-Email"}
            placeholder={"Email"}
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            prefix={convertSisuInputTypeToIcon("email")}
            style={{ width: SISU_INPUT_WIDTH }}
          />
          <Input.Password
            id={"SignUp-Password"}
            placeholder={"Password"}
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            prefix={convertSisuInputTypeToIcon("password")}
            style={{ width: SISU_INPUT_WIDTH }}
            iconRender={(visible) =>
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            id={"SignUp-ConfirmPassword"}
            placeholder={"Confirm Password"}
            type={"password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            prefix={convertSisuInputTypeToIcon("password")}
            style={{ width: SISU_INPUT_WIDTH }}
            iconRender={(visible) =>
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
          />
          <Button
            type={"primary"}
            size={"large"}
            onClick={onSignUp}
            disabled={
              usernameUniqueCheckState === UsernameUniqueCheckState.Loading ||
              usernameUniqueCheckState === UsernameUniqueCheckState.Taken
            }
          >
            Sign Up
          </Button>
        </Space>
      </RevealFormForEmailButton>
    </Space>
  );
};
