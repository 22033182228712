import { Button, Typography, Popover, notification } from "antd";
import React, { useState } from "react";
import { ILessonQuestion } from "../../types/firestoreMappings";
import { DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { isValidLessonQuestionInputWithErrorNotify } from "../../utils/validInputUtils";
import { locStrings } from "../../localization/locStrings";
import { ConfirmButton } from "../common/ConfirmButton";
import { LessonAnswersContainer } from "./LessonAnswersContainer";
import { InputWithButton } from "../common/InputWithButton";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { CardWithUserHeader } from "../common/CardWithUserHeader";
import { UpvoteButtonContainer } from "../upvotes/UpvoteButtonContainer";
import { MetadataType } from "../../types/helperTypes";

interface ILessonQuestionProps {
  question: ILessonQuestion;
  deleteQuestion?: () => void;
  updateQuestion?: (newQuestion: string) => void;
}

export const LessonQuestion: React.FunctionComponent<ILessonQuestionProps> = (
  props
) => {
  const { question, deleteQuestion, updateQuestion } = props;
  const nav = useNavigation();
  const parsedLessonQuestionId = nav.urlSearchParams.getLessonQuestionId();
  const wasNavigatedToAndShouldHighlight =
    parsedLessonQuestionId === question.id;
  const [isEditQuestionVisible, setIsEditQuestionVisible] =
    useState<boolean>(false);
  const [newQuestionInput, setNewQuestionInput] = useState<string>(
    question.question
  );

  const onUpdateQuestion = () => {
    if (updateQuestion) {
      const isValid = isValidLessonQuestionInputWithErrorNotify().forUpdate({
        question: newQuestionInput,
      });

      if (isValid) {
        try {
          updateQuestion(newQuestionInput);
        } catch (error) {
          notification.error({
            message: locStrings.genericErrorMessage.value(),
          });
        }
      }
    }
  };

  const renderEditQuestion = (
    <InputWithButton
      inputValue={newQuestionInput}
      onInputChange={setNewQuestionInput}
      onSubmit={onUpdateQuestion}
      buttonChildren={<CheckOutlined />}
      buttonWidthInPx={50}
      inputPlaceholder={""}
    />
  );

  return (
    <CardWithUserHeader
      userId={question.owner}
      courseIdToCheckForOwnership={question.courseId}
      customElements={[
        updateQuestion && (
          <Popover
            content={renderEditQuestion}
            visible={isEditQuestionVisible}
            onVisibleChange={setIsEditQuestionVisible}
            trigger={"click"}
          >
            <Button icon={<EditOutlined />} type={"text"} />
          </Popover>
        ),
        deleteQuestion && (
          <ConfirmButton
            onConfirm={deleteQuestion}
            icon={<DeleteOutlined />}
            type={"text"}
          />
        ),
        <UpvoteButtonContainer
          upvoteCount={question.upvotes}
          metadataId={question.id}
          metadataType={MetadataType.LessonQuestion}
        />,
      ]}
      className={
        wasNavigatedToAndShouldHighlight
          ? "highlight-light-blue-and-fade"
          : undefined
      }
    >
      <div style={{ marginBottom: "1rem" }}>
        <Typography style={{ fontWeight: "bold", wordBreak: "break-word" }}>
          {question.question}
        </Typography>
      </div>
      <LessonAnswersContainer question={question} />
    </CardWithUserHeader>
  );
};
