import { Card, Space } from "antd";
import React, { useId } from "react";
import {
  IUserItemContainerProps,
  UserItemContainer,
} from "../common/UserItemContainer";
import { DateAgoText } from "./DateAgoText";

export interface ICardWithUserHeaderProps extends IUserItemContainerProps {
  children: React.ReactNode;
  date?: Date;
  customElements?: React.ReactNode[];
  style?: React.CSSProperties;
  className?: string;
  headerStyle?: React.CSSProperties;
}

export const CardWithUserHeader: React.FunctionComponent<
  ICardWithUserHeaderProps
> = (props) => {
  const { children, date, customElements, style, className, headerStyle } =
    props;
  const uniqueId = useId();

  return (
    <Card style={style} size={"small"} className={className}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          ...headerStyle,
        }}
      >
        <UserItemContainer {...props} />
        <Space direction={"horizontal"}>
          {customElements?.map((el, index) => (
            <div key={`CardWithUserHeader-CustomElement-${index}-${uniqueId}`}>
              {el}
            </div>
          ))}
          {date && <DateAgoText date={date} />}
        </Space>
      </div>
      {children}
    </Card>
  );
};
