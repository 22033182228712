import { ICourse } from "../../types/firestoreMappings";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useUpdateModuleMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  const createModule = async (vars: {
    course: ICourse;
    moduleId: string;
    title: string;
    description?: string;
  }) => {
    const { course, moduleId, title, description } = vars;
    const updatedModules = course.modules.map((module) => {
      if (module.id === moduleId) {
        const updatedModule = {
          ...module,
          title,
        };
        if (description) {
          updatedModule.description = description;
        }
        return updatedModule;
      }
      return module;
    });
    await updateCourseMutation.mutate({
      modules: updatedModules,
    });
  };
  return {
    ...updateCourseMutation,
    mutate: createModule,
  };
};
