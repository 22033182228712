import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listCoursesQueryKeySimple } from "../queries/useListCoursesQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteCourseMutation = (courseId: string) => {
  const queryClient = useQueryClient();
  const deleteCourse = () => {
    const db = getFirestore();
    const courseDocRef = doc(db, collectionPaths.courses, courseId);
    return deleteDoc(courseDocRef);
  };
  return useMutation(deleteCourse, {
    onSettled: settledLogger("useDeleteCourseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries([listCoursesQueryKeySimple], {
        refetchInactive: true,
      });
    },
  });
};
