import {
  collection,
  getFirestore,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { ListCourseCommentsQueryParams } from "./useListCourseCommentsQuery";

export const countCourseCommentsQueryKey = (args: ICountCourseCommentsArgs) => [
  "countCourseCommentsQueryKey",
  args,
];
interface ICountCourseCommentsArgs
  extends Omit<
    ListCourseCommentsQueryParams,
    "sortByUpvotes" | "topItemId" | "numItemsPerPage"
  > {}

const countCourseComments = async (vars: ICountCourseCommentsArgs) => {
  const { courseId, metadataType, metadataId, owner } = vars;
  const db = getFirestore();
  const courseCommentsCollection = collection(
    db,
    collectionPaths.courseComments
  );
  const queryConstraints = [];
  if (courseId) {
    queryConstraints.push(where("courseId", "==", courseId));
  }
  if (metadataType) {
    queryConstraints.push(where("metadataType", "==", metadataType));
  }
  if (metadataId) {
    queryConstraints.push(where("metadataId", "==", metadataId));
  }
  if (owner) {
    queryConstraints.push(where("owner", "==", owner));
  }
  const q = query(courseCommentsCollection, ...queryConstraints);
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const useCountCourseCommentsQuery = (
  vars: ICountCourseCommentsArgs,
  enabled: boolean = true
) => {
  return useQuery(
    countCourseCommentsQueryKey(vars),
    () => countCourseComments(vars),
    {
      onSettled: settledLogger("useCountCourseCommentsQuery"),
      enabled,
    }
  );
};
