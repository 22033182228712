import { notification } from "antd";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { listLessonAnswersQueryKey } from "../queries/useListLessonAnswersQuery";
import { getLessonAsnwerQueryKey } from "../queries/useGetLessonAnswerQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateLessonAnswerMutation = (
  lessonQuestionId: string,
  lessonAnswerId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateAnswer = async (answer: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useUpdateLessonAnswerMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonAnswerDocRef = doc(
      db,
      collectionPaths.lessonAnswers,
      lessonAnswerId
    );
    return updateDoc(lessonAnswerDocRef, {
      answer,
    });
  };
  return useMutation(updateAnswer, {
    onSettled: settledLogger("useUpdateLessonAnswerMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAnswersQueryKey({ lessonQuestionId }),
        {
          refetchInactive: true,
        }
      );
      queryClient.invalidateQueries(getLessonAsnwerQueryKey(lessonAnswerId), {
        refetchInactive: true,
      });
    },
  });
};
