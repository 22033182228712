import { notification } from "antd";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { MetadataType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateUpvoteId } from "../idHelpers";
import { getUpvoteQueryKey } from "../queries/useGetUpvoteQuery";
import { settledLogger } from "../settledLogger";

const supportedMetadataTypesForUpvotes = [
  MetadataType.LessonQuestion,
  MetadataType.LessonAnswer,
  MetadataType.LessonExercise,
  MetadataType.AlternativeLink,
  MetadataType.CourseSuggestion,
  MetadataType.CourseComment,
];

export const useCreateUpvoteMutation = (
  metadataId: string,
  metadataType: MetadataType
) => {
  const queryClient = useQueryClient();
  const { user, isSignedIn } = useUserAuth();
  const upvoteId = useGenerateUpvoteId(metadataId);
  const createUpvote = async (vars: { isDownvote?: boolean }) => {
    const { isDownvote } = vars;
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject("useCreateUpvoteMutation, error - no user found");
    }
    if (!upvoteId) {
      return Promise.reject(
        "useCreateUpvoteMutation, upvoteId not properly generated"
      );
    }
    if (!supportedMetadataTypesForUpvotes.includes(metadataType)) {
      return Promise.reject(
        `useCreateUpvoteMutation, metadataType not supported for upvotes: ${metadataType}`
      );
    }

    const db = getFirestore();
    return setDoc(doc(db, collectionPaths.upvotes, upvoteId), {
      metadataId,
      metadataType,
      isDownvote: !!isDownvote,
      owner: user.uid,
    });
  };
  return useMutation(createUpvote, {
    onSettled: settledLogger("useCreateUpvoteMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        getUpvoteQueryKey({ metadataId, isSignedIn }),
        {
          refetchInactive: true,
        }
      );
    },
  });
};
