import { Button, Input, Space } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { LessonSelect } from "../common/LessonSelect";

interface ILessonQuestionEditProps {
  courseId: string;
  onSubmit: () => void;
  lessonId: string;
  question: string;
  onQuestionChange: (newQuestion: string) => void;
  onLessonIdChange: (newLessonId: string) => void;
}

export const LessonQuestionEdit: React.FunctionComponent<
  ILessonQuestionEditProps
> = (props) => {
  const {
    courseId,
    onSubmit,
    lessonId,
    question,
    onLessonIdChange,
    onQuestionChange,
  } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <LessonSelect
        courseId={courseId}
        placeholder={"Select an existing link for this question"}
        selectedLessonId={lessonId}
        onChange={onLessonIdChange}
      />
      <Input
        id={"LessonQuestionEdit-Question-Input"}
        placeholder={locStrings.askAQuestionPlaceholder.value()}
        type={"text"}
        value={question}
        onChange={(e) => onQuestionChange(e.target.value)}
      />
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onSubmit} type={"primary"}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
