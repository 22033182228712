import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertCourseSuggestionSnapToICourseSuggestion,
  ICourseSuggestion,
} from "../../types/firestoreMappings";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../types/helperTypes";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { useGetCourseSuggestionFetch } from "./useGetCourseSuggestionQuery";

export interface ListCourseSuggestionsQueryParams {
  courseId?: string;
  moduleId?: string;
  type?: CourseSuggestionType;
  state?: CourseSuggestionState;
  owner?: string;
  sortByUpvotes?: boolean;
  topItemId?: string | null;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 5;

export const listCourseSuggestionsQueryKey = (
  params: ListCourseSuggestionsQueryParams
) => {
  return ["listCourseSuggestionsQueryKey", params];
};

export const useListCourseSuggestionsQuery = (
  params: ListCourseSuggestionsQueryParams,
  enabled: boolean = true
) => {
  const fetchCourseSuggestion = useGetCourseSuggestionFetch();
  const listCourseSuggestions = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ICourseSuggestion[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const {
      courseId,
      moduleId,
      type,
      state,
      owner,
      sortByUpvotes,
      topItemId,
      numItemsPerPage,
    } = params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const courseSuggestionsCollectionRef = collection(
      db,
      collectionPaths.courseSuggestions
    );
    const queryConstraints = [];
    if (sortByUpvotes) {
      queryConstraints.push(orderBy("upvotes", "desc"));
    }
    // Always sort by createdAt by default
    queryConstraints.push(orderBy("createdAt", "desc"));
    if (courseId !== undefined) {
      queryConstraints.push(where("courseId", "==", courseId));
    }
    if (moduleId !== undefined) {
      queryConstraints.push(where("moduleId", "==", moduleId));
    }
    if (type !== undefined) {
      queryConstraints.push(where("type", "==", type));
    }
    if (state !== undefined) {
      queryConstraints.push(where("state", "==", state));
    }
    if (owner !== undefined) {
      queryConstraints.push(where("owner", "==", owner));
    }

    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      courseSuggestionsCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    let courseSuggestions = qSnapshot.docs.map((snapshot) =>
      convertCourseSuggestionSnapToICourseSuggestion(
        snapshot.id,
        snapshot.data()
      )
    );

    if (courseSuggestions.length < numItemsPerPageToUse) {
      isLastPage = true;
    }

    if (topItemId) {
      // Filter out specified top item to avoid duplicates
      courseSuggestions = courseSuggestions.filter(
        (suggestion) => suggestion.id !== topItemId
      );
      const isFirstPage = !pageParam;
      if (isFirstPage) {
        // Only add specified top item if first page load, otherwise it duplicates
        const topSuggestion = await fetchCourseSuggestion({
          courseSuggestionId: topItemId,
        });
        courseSuggestions = [topSuggestion].concat(courseSuggestions);
      }
    }

    return {
      realData: courseSuggestions,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listCourseSuggestionsQueryKey(params),
    listCourseSuggestions,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListCourseSuggestionsQuery"),
      enabled,
    }
  );

  let realDataAllPages: ICourseSuggestion[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }
  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
