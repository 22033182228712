import { Button, Space, Switch, Typography } from "antd";
import React from "react";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";

interface ISettingsNotificationsEditProps {
  onSave: () => void;
  isLoadingSave: boolean;
  areAnySettingsDelta: boolean;
  enableNewNotificationEmails: boolean;
  setEnableNewNotificationEmails: (isEnabled: boolean) => void;
}

export const SettingsNotificationsEdit: React.FunctionComponent<
  ISettingsNotificationsEditProps
> = (props) => {
  const {
    onSave,
    isLoadingSave,
    areAnySettingsDelta,
    enableNewNotificationEmails,
    setEnableNewNotificationEmails,
  } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <PageTypography
          title={"Notifications"}
          level={PageTypographyLevel.PageSection}
        />
        {areAnySettingsDelta && (
          <Button onClick={onSave} type={"primary"} loading={isLoadingSave}>
            {"Save"}
          </Button>
        )}
      </div>
      <Space direction={"horizontal"}>
        <Switch
          checked={enableNewNotificationEmails}
          onChange={setEnableNewNotificationEmails}
          checkedChildren={"On"}
          unCheckedChildren={"Off"}
        />
        <Typography>{"Enable emails for new notifications"}</Typography>
      </Space>
    </Space>
  );
};
