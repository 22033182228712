import { useQuery, useQueryClient } from "react-query";
import LocalStorageBase from "../local-storage/LocalStorageBase";
import { useUserAuth } from "./useUserAuth";

export enum SisuState {
  Unknown,
  SignIn,
  SignUp,
  ResetPasswordEmailEntry,
  VerifyEmail,
}

interface SisuModalState {
  isSisuModalVisible: boolean;
  sisuState: SisuState;
}

const isSisuModalVisibleQueryKey = "isSisuModalVisibleQueryKey";
const emailLastUsed = LocalStorageBase.getEmailLastUsed();
const defaultSisuState = !!emailLastUsed ? SisuState.SignIn : SisuState.SignUp;

export const useSisuModal = () => {
  const queryClient = useQueryClient();
  const { isSignedIn, isEmailVerified } = useUserAuth();
  const { data } = useQuery<SisuModalState>(
    isSisuModalVisibleQueryKey,
    async () => Promise.reject("useSisuModal.no-op, should never be called"),
    {
      initialData: { isSisuModalVisible: false, sisuState: defaultSisuState },
      staleTime: Infinity, // this data/cache will be updated manually
      cacheTime: Infinity, // never expire this data for entirety of local session
    }
  );
  const isSisuModalVisible = data?.isSisuModalVisible;
  const sisuState = data?.sisuState || SisuState.Unknown;

  const openSisuModal = (newSisuState?: SisuState) => {
    queryClient.setQueryData<SisuModalState>(isSisuModalVisibleQueryKey, () => {
      return {
        isSisuModalVisible: true,
        sisuState: newSisuState !== undefined ? newSisuState : defaultSisuState,
      };
    });
  };

  const closeSisuModal = () => {
    queryClient.setQueryData<SisuModalState>(isSisuModalVisibleQueryKey, () => {
      return {
        isSisuModalVisible: false,
        sisuState: defaultSisuState,
      };
    });
  };

  const setSisuState = (newSisuState: SisuState) => {
    queryClient.setQueryData<SisuModalState>(isSisuModalVisibleQueryKey, () => {
      return {
        isSisuModalVisible: true,
        sisuState: newSisuState,
      };
    });
  };

  const forceSisuModalFxnWrapper = <T extends (...args: any[]) => any>(
    func: T
  ): T => {
    return ((...args: any[]) => {
      if (!isSignedIn) {
        return openSisuModal();
      }
      if (!isEmailVerified) {
        return openSisuModal(SisuState.VerifyEmail);
      }
      return func(...args);
    }) as T;
  };

  return {
    isSisuModalVisible,
    sisuState,
    openSisuModal,
    closeSisuModal,
    setSisuState,
    forceSisuModalFxnWrapper,
  };
};
