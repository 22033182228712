import { Button, Input, Space } from "antd";
import React from "react";
import { locStrings } from "../../../localization/locStrings";
import { RichTextEditor } from "../../common/RichTextEditor";

interface IModuleCreateModalProps {
  moduleTitle: string;
  moduleDescription: string;
  setModuleTitle: (newTitle: string) => void;
  setModuleDescription: (newDescription: string) => void;
  onSubmit: () => void;
}

export const ModuleCreateModal: React.FunctionComponent<
  IModuleCreateModalProps
> = (props) => {
  const {
    moduleTitle,
    moduleDescription,
    setModuleTitle,
    setModuleDescription,
    onSubmit,
  } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Input
        value={moduleTitle}
        onChange={(e) => setModuleTitle(e.target.value)}
        placeholder={locStrings.moduleNamePlaceholder.value()}
      />
      <RichTextEditor
        initialValue={moduleDescription}
        onHtmlStringChange={setModuleDescription}
        placeholder={"Add an optional description for this module..."}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button onClick={onSubmit} type={"primary"}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
