import React from "react";
import { useListUsersByIds } from "../../firestore/queries/useListUsersByIds";
import { IUser } from "../../types/firestoreMappings";
import { useIsUserOwnerOfCourse } from "../../ui-hooks/useIsUserOwnerOfCourse";
import { generateNotFoundUser } from "../../utils/utils";
import { GenericLoader } from "./GenericLoader";
import { UserItem } from "./UserItem";

export interface IUserItemContainerProps {
  userId: string;
  preloadedUser?: IUser; // If parent component has already loaded user, then use this
  courseIdToCheckForOwnership?: string; // Shows a course owner tag if this matches
  onClick?: () => void; // Overrides default behavior of navigating to profile
  disableOnClick?: boolean; // Overrides any onClick behavior to make not clickable
  hideAvatar?: boolean;
  hideUsername?: boolean;
  addonTextAfterUsername?: string;
  addonTextBeforeUsername?: string;
  usernameTextStyle?: React.CSSProperties;
}

export const UserItemContainer: React.FunctionComponent<
  IUserItemContainerProps
> = (props) => {
  const {
    userId,
    preloadedUser,
    courseIdToCheckForOwnership,
    onClick,
    disableOnClick,
    hideAvatar,
    hideUsername,
    addonTextAfterUsername,
    addonTextBeforeUsername,
    usernameTextStyle,
  } = props;
  const { data: userArray, isLoading: isUserLoading } = useListUsersByIds(
    [userId],
    !preloadedUser
  );
  const isCourseOwner = useIsUserOwnerOfCourse(
    userId,
    courseIdToCheckForOwnership || ""
  );

  let user = generateNotFoundUser(userId);

  if (preloadedUser) {
    user = preloadedUser;
  } else {
    if (isUserLoading || !userArray) {
      return <GenericLoader />;
    }
    user = userArray[0];
  }

  return (
    <UserItem
      user={user}
      isCourseOwner={isCourseOwner}
      onClick={onClick}
      disableOnClick={disableOnClick}
      hideAvatar={hideAvatar}
      hideUsername={hideUsername}
      addonTextAfterUsername={addonTextAfterUsername}
      addonTextBeforeUsername={addonTextBeforeUsername}
      usernameTextStyle={usernameTextStyle}
    />
  );
};
