import React from "react";
import { useDeleteLessonExerciseMutation } from "../../firestore/mutations/useDeleteLessonExerciseMutation";
import { useUpdateLessonExerciseMutation } from "../../firestore/mutations/useUpdateLessonExerciseMutation";
import { ILessonExercise } from "../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useIsMeUserOwnerOfLessonExercise } from "../../ui-hooks/useIsMeUserOwnerOfLessonExercise";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { GenericLoader } from "../common/GenericLoader";
import { LessonExercise } from "./LessonExercise";

interface ILessonExerciseContainerProps {
  exercise: ILessonExercise;
}

export const LessonExerciseContainer: React.FunctionComponent<
  ILessonExerciseContainerProps
> = (props) => {
  const { exercise } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: deleteExercise, isLoading: isDeleteExerciseLoading } =
    useDeleteLessonExerciseMutation(
      exercise.id,
      exercise.courseId,
      exercise.lessonId
    );
  const { mutate: updateExercise, isLoading: isUpdateExerciseLoading } =
    useUpdateLessonExerciseMutation(
      exercise.id,
      exercise.courseId,
      exercise.lessonId
    );

  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(exercise.courseId);
  const isMeUserOwnerOfExercise = useIsMeUserOwnerOfLessonExercise(exercise);
  const isEditable = isMeUserOwnerOfCourse || isMeUserOwnerOfExercise;

  if (isDeleteExerciseLoading || isUpdateExerciseLoading) {
    return <GenericLoader />;
  }

  return (
    <LessonExercise
      exercise={exercise}
      updateExercise={
        isEditable ? forceSisuModalFxnWrapper(updateExercise) : undefined
      }
      deleteExercise={
        isEditable ? forceSisuModalFxnWrapper(deleteExercise) : undefined
      }
    />
  );
};
