import { Card, Space, Typography } from "antd";
import React, { useMemo } from "react";
import { themeColors } from "../../styling/styleConstants";
import { getYoututubeVideoId } from "../../utils/utils";
import { WeblinkPreviewImage } from "./WeblinkPreviewImage";

interface IWeblinkRendererProps {
  weblink: string;
  previewImageUrl?: string;
  isCardDisplay?: boolean;
  forceTextOnlyDisplay?: boolean;
}

export const WeblinkRenderer: React.FunctionComponent<IWeblinkRendererProps> = (
  props
) => {
  const { weblink, previewImageUrl, isCardDisplay, forceTextOnlyDisplay } =
    props;
  const youtubeVideoId = useMemo(() => getYoututubeVideoId(weblink), [weblink]);

  if (!forceTextOnlyDisplay) {
    if (youtubeVideoId) {
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingBottom: "56.25%",
          }}
        >
          <iframe
            title={"LoA-WeblinkRenderer-YoutubeEmbed"}
            style={{
              border: 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`https://www.youtube.com/embed/${youtubeVideoId}?rel=0`}
            allowFullScreen={true}
          />
        </div>
      );
    }
  }

  if (isCardDisplay) {
    return (
      <a href={weblink} target={"_blank"} rel={"noreferrer"}>
        <Card
          style={{
            wordBreak: "break-all",
          }}
          className={"shadow-border"}
        >
          <Space direction={"horizontal"}>
            <WeblinkPreviewImage imageUrl={previewImageUrl} />
            <Typography.Paragraph
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: themeColors.primary,
                marginBottom: 0,
              }}
              ellipsis={{ rows: 2 }}
            >
              {weblink}
            </Typography.Paragraph>
          </Space>
        </Card>
      </a>
    );
  } else {
    return (
      <Typography.Link
        href={weblink}
        target={"_blank"}
        rel={"noreferrer"}
        style={{ wordBreak: "break-all" }}
      >
        {weblink}
      </Typography.Link>
    );
  }
};
