import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetLessonQuestionQuery } from "../../firestore/queries/useGetLessonQuestionQuery";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { getLessonFromCourse } from "../../utils/utils";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemLessonQuestionContainerProps {
  notification: INotification;
}

export const NotificationItemLessonQuestionContainer: React.FunctionComponent<
  INotificationItemLessonQuestionContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: lessonQuestion,
    isLoading: isLessonQuestionLoading,
    isError: isLessonQuestionError,
  } = useGetLessonQuestionQuery({
    lessonQuestionId: notification.lessonQuestionId || "",
  });
  const courseIdToQuery = lessonQuestion?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isLessonQuestionError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.LessonQuestion}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (
    isLessonQuestionLoading ||
    isCourseLoading ||
    !lessonQuestion ||
    !course
  ) {
    return <GenericLoader />;
  }

  const title = `Question`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  const lessonWithQuestion = getLessonFromCourse(
    lessonQuestion.lessonId,
    course
  );
  infoPoints.push({
    boldedPrefix: "For this Link:",
    text: lessonWithQuestion?.title || "Unknown",
  });
  infoPoints.push({
    boldedPrefix: "Question:",
    text: lessonQuestion.question,
  });
  const onNavToContent = async () => {
    nav.goToPaths.inLesson(lessonQuestion.courseId, lessonQuestion.lessonId, {
      lessonQuestionId: lessonQuestion.id,
    });
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };
  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={lessonQuestion.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
