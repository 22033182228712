import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertCourseCommentSnapToICourseComment,
  ICourseComment,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { useGetCourseCommentFetch } from "./useGetCourseCommentQuery";

export interface ListCourseCommentsQueryParams {
  courseId?: string;
  metadataType?: string;
  metadataId?: string;
  owner?: string;
  sortByUpvotes?: boolean;
  topItemId?: string | null;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 3;

export const listCourseCommentsQueryKey = (
  params: ListCourseCommentsQueryParams
) => {
  return ["listCourseCommentsQueryKey", params];
};

export const useListCourseCommentsQuery = (
  params: ListCourseCommentsQueryParams,
  enabled: boolean = true
) => {
  const fetchCourseComment = useGetCourseCommentFetch();
  const listCourseComments = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ICourseComment[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const {
      courseId,
      metadataType,
      metadataId,
      owner,
      sortByUpvotes,
      topItemId,
      numItemsPerPage,
    } = params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const courseCommentsCollectionRef = collection(
      db,
      collectionPaths.courseComments
    );
    const queryConstraints = [];
    if (sortByUpvotes) {
      queryConstraints.push(orderBy("upvotes", "desc"));
    }
    // Always sort by createdAt by default
    queryConstraints.push(orderBy("createdAt", "desc"));
    if (courseId) {
      queryConstraints.push(where("courseId", "==", courseId));
    }
    if (metadataType) {
      queryConstraints.push(where("metadataType", "==", metadataType));
    }
    if (metadataId) {
      queryConstraints.push(where("metadataId", "==", metadataId));
    }
    if (owner) {
      queryConstraints.push(where("owner", "==", owner));
    }
    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      courseCommentsCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    let courseComments = qSnapshot.docs.map((snapshot) =>
      convertCourseCommentSnapToICourseComment(snapshot.id, snapshot.data())
    );

    if (courseComments.length < numItemsPerPageToUse) {
      isLastPage = true;
    }

    if (topItemId) {
      // Filter out specified top item to avoid duplicates
      courseComments = courseComments.filter(
        (courseComment) => courseComment.id !== topItemId
      );
      const isFirstPage = !pageParam;
      if (isFirstPage) {
        // Only add specified top item if first page load, otherwise it duplicates
        const topCourseComment = await fetchCourseComment({
          courseCommentId: topItemId,
        });
        courseComments = [topCourseComment].concat(courseComments);
      }
    }

    return {
      realData: courseComments,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listCourseCommentsQueryKey(params),
    listCourseComments,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListCourseCommentsQuery"),
      enabled,
    }
  );

  let realDataAllPages: ICourseComment[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }

  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
