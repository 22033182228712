import { Button, Popover, Space, Typography } from "antd";
import React, { useState } from "react";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { ICourseSuggestion } from "../../../types/firestoreMappings";
import { convertCourseSuggestionStateToIcon } from "../../../utils/iconAndStringUtils";
import { CourseSuggestionStateDisplayRead } from "./CourseSuggestionStateDisplayRead";
import { NewLinkModalContainer } from "../../new-link-modal/NewLinkModalContainer";
import { zIndexPriorities } from "../../../styling/styleConstants";
import { ModuleCreateModalContainer } from "../../course/module-write/ModuleCreateModalContainer";

interface ICourseSuggestionStateDisplayEditProps {
  courseSuggestion: ICourseSuggestion;
  updateSuggestion: (vars: { state: CourseSuggestionState }) => void;
  buttonContent: React.ReactNode;
}

export const CourseSuggestionStateDisplayEdit: React.FunctionComponent<
  ICourseSuggestionStateDisplayEditProps
> = (props) => {
  const { courseSuggestion, updateSuggestion, buttonContent } = props;
  const [isCreateLinkModalVisible, setIsCreateLinkModalVisible] =
    useState<boolean>(false);
  const [isCreateModuleModalVisible, setIsCreateModuleModalVisible] =
    useState<boolean>(false);

  const onAccept = () => {
    if (courseSuggestion.type === CourseSuggestionType.NewLesson) {
      setIsCreateLinkModalVisible(true);
    } else if (courseSuggestion.type === CourseSuggestionType.NewModule) {
      setIsCreateModuleModalVisible(true);
    } else {
      updateSuggestion({ state: CourseSuggestionState.Accepted });
    }
  };

  const onReject = () => {
    updateSuggestion({ state: CourseSuggestionState.Rejected });
  };

  if (courseSuggestion.state === CourseSuggestionState.Open) {
    return (
      <>
        <Popover
          trigger={"click"}
          zIndex={zIndexPriorities.tooltipOutsideModal}
          content={
            <Space direction={"vertical"}>
              <Button
                icon={convertCourseSuggestionStateToIcon(
                  CourseSuggestionState.Accepted,
                  true
                )}
                onClick={onAccept}
                style={{ width: "100%" }}
              >
                {"Accept"}
              </Button>
              <Button
                icon={convertCourseSuggestionStateToIcon(
                  CourseSuggestionState.Rejected,
                  true
                )}
                onClick={onReject}
                style={{ width: "100%" }}
              >
                {"Reject"}
              </Button>
            </Space>
          }
        >
          <Button type={"primary"}>{buttonContent}</Button>
        </Popover>
        <NewLinkModalContainer
          visible={isCreateLinkModalVisible}
          onClose={() => setIsCreateLinkModalVisible(false)}
          courseId={courseSuggestion.courseId}
          courseSuggestionId={courseSuggestion.id}
        />
        <ModuleCreateModalContainer
          visible={isCreateModuleModalVisible}
          onClose={() => setIsCreateModuleModalVisible(false)}
          courseId={courseSuggestion.courseId}
          courseSuggestionId={courseSuggestion.id}
        />
      </>
    );
  } else if (courseSuggestion.state === CourseSuggestionState.Accepted) {
    // Do not allow re-opening if Accepted because then it will be duplicated
    return (
      <CourseSuggestionStateDisplayRead courseSuggestion={courseSuggestion} />
    );
  } else {
    // Allow re-opening only if Rejected
    return (
      <Popover
        trigger={"click"}
        content={
          <Space direction={"vertical"}>
            <Typography>{"Re-open this suggestion?"}</Typography>
            <Button
              onClick={() =>
                updateSuggestion({ state: CourseSuggestionState.Open })
              }
            >
              Re-open
            </Button>
          </Space>
        }
      >
        <Button>
          <CourseSuggestionStateDisplayRead
            courseSuggestion={courseSuggestion}
          />
        </Button>
      </Popover>
    );
  }
};
