import React from "react";
import { Select } from "antd";
import { IModule } from "../../types/firestoreMappings";
import { locStrings } from "../../localization/locStrings";

interface IModuleSelectProps {
  modules: IModule[];
  onChange: (moduleId: string) => void;
  selectedModuleId?: string;
  style?: React.CSSProperties;
}

export const ModuleSelect: React.FunctionComponent<IModuleSelectProps> = (
  props
) => {
  const { modules, onChange, selectedModuleId, style } = props;
  return (
    <Select
      value={selectedModuleId || null}
      onChange={onChange}
      placeholder={locStrings.lessonModuleSelectPlacerholder.value()}
      style={{ display: "flex", ...style }}
    >
      {modules.map((module, index) => {
        return (
          <Select.Option
            key={`ModuleSelect-ModuleSelectOption-${module.id}`}
            value={module.id}
          >
            {`${index + 1}. ${module.title}`}
          </Select.Option>
        );
      })}
    </Select>
  );
};
