import { Button, Card, Space, Typography } from "antd";
import React from "react";
import { themeColors } from "../../../styling/styleConstants";
import { ICourse } from "../../../types/firestoreMappings";
import { CoursePublishState } from "../../../types/helperTypes";
import { useHasAnyCourseReviewRejections } from "../../../ui-hooks/useHasAnyCourseReviewRejections";
import { useIsScreenLargerThanSm } from "../../../ui-hooks/useIsScreenLargerThanSm";
import { calculateModuleAndLessonCountsInCourse } from "../../../utils/utils";
import { ConfirmButton } from "../../common/ConfirmButton";

interface ICourseInfoPublishStateRequestProps {
  course: ICourse;
  publishState: CoursePublishState;
  onSetToInReview: () => void;
  onSetToDraft: () => void;
  onGoToReviewerRejectionFeedback: () => void;
}

export const CourseInfoPublishStateRequest: React.FunctionComponent<
  ICourseInfoPublishStateRequestProps
> = (props) => {
  const {
    course,
    publishState,
    onSetToInReview,
    onSetToDraft,
    onGoToReviewerRejectionFeedback,
  } = props;
  const hasAnyCourseReviewRejections = useHasAnyCourseReviewRejections(
    course.id
  );
  const isScreenLargerThanSm = useIsScreenLargerThanSm();
  const moduleAndLessonCounts = calculateModuleAndLessonCountsInCourse(course);
  const shouldShowRequestReviewButton =
    moduleAndLessonCounts.modulesCount > 0 &&
    moduleAndLessonCounts.lessonsCount >= 5;

  let publishStateText = "UNKNOWN";
  let publishStateButtonText = "UNKNOWN";
  let publishStateButtonOnClick = () => {};
  let cardBackground = "transparent";
  switch (publishState) {
    case CoursePublishState.Draft:
      publishStateText = shouldShowRequestReviewButton
        ? "This course is under construction. Request a review to get it marked as a trustworthy course."
        : "This course is under construction. Add more content to get it reviewed.";
      publishStateButtonText = "Request Review";
      publishStateButtonOnClick = onSetToInReview;
      cardBackground = themeColors.publishStateDraftYellow;
      break;
    case CoursePublishState.InReview:
      publishStateText =
        "This course is in review. Once approved, it will be marked as a reviewed course.";
      publishStateButtonText = "Revert to Under Construction";
      publishStateButtonOnClick = onSetToDraft;
      cardBackground = themeColors.publishStateInReviewOrange;
      break;
    case CoursePublishState.Published:
      publishStateText = "This course has been reviewed.";
      publishStateButtonText = "Revert to Under Construction";
      publishStateButtonOnClick = onSetToDraft;
      cardBackground = themeColors.publishStatePublishedGreen;
      break;
  }

  return (
    <Card style={{ backgroundColor: cardBackground }} size={"small"}>
      <Space direction={"vertical"}>
        <Typography>{publishStateText}</Typography>
        {(shouldShowRequestReviewButton || hasAnyCourseReviewRejections) && (
          <Space
            direction={isScreenLargerThanSm ? "horizontal" : "vertical"}
            style={{ marginTop: "1rem" }}
          >
            {shouldShowRequestReviewButton && (
              <ConfirmButton
                onConfirm={publishStateButtonOnClick}
                text={publishStateButtonText}
                type={"default"}
              />
            )}
            {hasAnyCourseReviewRejections && (
              <Button
                onClick={onGoToReviewerRejectionFeedback}
                type={"default"}
              >
                {"See reviewer feedback"}
              </Button>
            )}
          </Space>
        )}
      </Space>
    </Card>
  );
};
