import { Modal, Space, Typography } from "antd";
import React from "react";
import { SisuState, useSisuModal } from "../../ui-hooks/useSisuModal";
import { zIndexPriorities } from "../../styling/styleConstants";
import { SignUpContainer } from "./SignUpContainer";
import { SignInContainer } from "./SignInContainer";
import { ResetPasswordEmailEntryContainer } from "./ResetPasswordEmailEntryContainer";
import { VerifyEmailContainer } from "./VerifyEmailContainer";

interface ISisuModalProps {}

export const SisuModal: React.FunctionComponent<ISisuModalProps> = (props) => {
  const { isSisuModalVisible, sisuState, closeSisuModal, setSisuState } =
    useSisuModal();

  return (
    // @ts-ignore
    <Modal
      visible={isSisuModalVisible}
      onCancel={closeSisuModal}
      footer={null}
      zIndex={zIndexPriorities.sisuModal}
    >
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ width: "100%", marginBottom: "1rem" }}>
          {sisuState === SisuState.SignUp && <SignUpContainer />}
          {sisuState === SisuState.SignIn && <SignInContainer />}
          {sisuState === SisuState.ResetPasswordEmailEntry && (
            <ResetPasswordEmailEntryContainer />
          )}
          {sisuState === SisuState.VerifyEmail && <VerifyEmailContainer />}
        </div>
        {(sisuState === SisuState.SignUp ||
          sisuState === SisuState.ResetPasswordEmailEntry) && (
          <Typography.Link onClick={() => setSisuState(SisuState.SignIn)}>
            Already have an account? Sign in
          </Typography.Link>
        )}
        {(sisuState === SisuState.SignIn ||
          sisuState === SisuState.ResetPasswordEmailEntry) && (
          <Typography.Link onClick={() => setSisuState(SisuState.SignUp)}>
            Don't have an account? Sign up
          </Typography.Link>
        )}
        {(sisuState === SisuState.SignUp || sisuState === SisuState.SignIn) && (
          <Typography.Link
            onClick={() => setSisuState(SisuState.ResetPasswordEmailEntry)}
          >
            Forgot my password
          </Typography.Link>
        )}
      </Space>
    </Modal>
  );
};
