import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { convertCourseMetadataTypeToIcon } from "../../utils/iconAndStringUtils";
import { BadgeWrapper } from "../common/BadgeWrapper";
import { CardWithUserHeader } from "../common/CardWithUserHeader";
import { ClickableWrapper } from "../common/ClickableWrapper";
import { TypographyBold } from "../common/TypographyBold";

interface INotificationItemProps {
  notification: INotification;
  title: string;
  owner: string;
  infoPoints: { boldedPrefix: string; text: string }[];
  type: NotificationType;
  isRead: boolean;
  onClick: () => void;
}

export const NotificationItem: React.FunctionComponent<
  INotificationItemProps
> = (props) => {
  const { notification, title, owner, infoPoints, type, isRead, onClick } =
    props;

  return (
    <BadgeWrapper
      isBadgeVisible={!isRead}
      ribbonConfig={{ text: locStrings.new.value() }}
    >
      <ClickableWrapper onClick={onClick}>
        <CardWithUserHeader userId={owner} date={notification.createdAt}>
          <Space
            direction={"horizontal"}
            style={{
              alignItems: "flex-start",
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "1rem",
            }}
          >
            {convertCourseMetadataTypeToIcon({ notificationType: type })}
            <Typography.Text strong={true}>{title}</Typography.Text>
          </Space>
          {infoPoints.map((infoPoint) => {
            return (
              <TypographyBold
                key={`NotificationItem-${infoPoint.boldedPrefix}-${infoPoint.text}-${notification.id}`}
                boldedPrefix={infoPoint.boldedPrefix}
                children={infoPoint.text}
              />
            );
          })}
        </CardWithUserHeader>
      </ClickableWrapper>
    </BadgeWrapper>
  );
};
