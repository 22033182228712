import { Layout } from "antd";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { PageNotFound } from "./PageNotFound";
import { HomeContainer } from "../home/HomeContainer";
import { CourseInfoContainer } from "../course/CourseInfoContainer";
import { LessonCreateContainer } from "../course/lesson-write/LessonCreateContainer";
import { CourseCreateContainer } from "../course/course-write/CourseCreateContainer";
import { UserProfileContainer } from "../user-profile/UserProfileContainer";
import { InLessonContainer } from "../in-lesson/InLessonContainer";
import { Footer } from "./Footer";
import { CourseUpdateContainer } from "../course/course-write/CourseUpdateContainer";
import { ModuleUpdateContainer } from "../course/module-write/ModuleUpdateContainer";
import { LessonUpdateContainer } from "../course/lesson-write/LessonUpdateContainer";
import { CourseReorderContainer } from "../course/course-write/CourseReorderContainer";
import { NotificationsContainer } from "../notifications/NotificationsContainer";
import { HeaderNavBarContainer } from "../header-nav-bar/HeaderNavBarContainer";
import { CourseReviewRejectionsContainer } from "../course-review/CourseReviewRejectionsContainer";
import { ReviewQueueContainer } from "../course-review/ReviewQueueContainer";
import { CourseSuggestionsOverviewContainer } from "../course-suggestions/overview/CourseSuggestionsOverviewContainer";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import {
  PAGE_HORIZONTAL_SPACING_LARGER_THAN_MD,
  PAGE_HORIZONTAL_SPACING_SMALLER_THAN_MD,
} from "../../styling/styleConstants";
import { ExploreContainer } from "../explore/ExploreContainer";
import { ExploreCourseCategoryContainer } from "../explore/course-category/ExploreCourseCategoryContainer";
import { SettingsContainer } from "../settings/SettingsContainer";

interface IBaseNavigationProps {}

export const BaseNavigation: React.FunctionComponent<IBaseNavigationProps> = (
  props
) => {
  const nav = useNavigation();
  const location = useLocation();
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  const pageHorizontalPadding = isScreenLargerThanMd
    ? PAGE_HORIZONTAL_SPACING_LARGER_THAN_MD
    : PAGE_HORIZONTAL_SPACING_SMALLER_THAN_MD;

  // Scroll to top of page on every navigate
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header
        style={{
          paddingLeft: pageHorizontalPadding,
          paddingRight: pageHorizontalPadding,
        }}
      >
        <HeaderNavBarContainer />
      </Layout.Header>
      <Routes>
        <Route path={nav.paths.home} element={<HomeContainer />} />
        <Route path={nav.paths.explore} element={<ExploreContainer />} />
        <Route
          path={nav.paths.courseCategory}
          element={<ExploreCourseCategoryContainer />}
        />
        <Route path={nav.paths.settings} element={<SettingsContainer />} />
        <Route
          path={nav.paths.userProfile}
          element={<UserProfileContainer />}
        />
        <Route
          path={nav.paths.reviewQueue}
          element={<ReviewQueueContainer />}
        />
        <Route
          path={nav.paths.notifications}
          element={<NotificationsContainer />}
        />
        <Route path={nav.paths.courseInfo} element={<CourseInfoContainer />} />
        <Route
          path={nav.paths.courseReviewRejections}
          element={<CourseReviewRejectionsContainer />}
        />
        <Route path={nav.paths.newCourse} element={<CourseCreateContainer />} />
        <Route path={nav.paths.newLesson} element={<LessonCreateContainer />} />
        <Route
          path={nav.paths.editCourse}
          element={<CourseUpdateContainer />}
        />
        <Route
          path={nav.paths.editModule}
          element={<ModuleUpdateContainer />}
        />
        <Route
          path={nav.paths.editLesson}
          element={<LessonUpdateContainer />}
        />
        <Route
          path={nav.paths.reorderCourse}
          element={<CourseReorderContainer />}
        />
        <Route path={nav.paths.inLesson} element={<InLessonContainer />} />
        <Route
          path={nav.paths.courseSuggestionsOverview}
          element={<CourseSuggestionsOverviewContainer />}
        />
        <Route path={"*"} element={<PageNotFound />} />
      </Routes>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};
