import { Card, Space, Typography } from "antd";
import React from "react";
import { themeColors } from "../../../styling/styleConstants";
import { isEmptyHtmlString } from "../../../utils/utils";
import {
  CardWithUserHeader,
  ICardWithUserHeaderProps,
} from "../../common/CardWithUserHeader";
import { ClickableWrapper } from "../../common/ClickableWrapper";
import { RichTextRenderer } from "../../common/RichTextRenderer";
import { WeblinkPreviewImage } from "../../common/WeblinkPreviewImage";
import {
  CourseStructureLessonItemBottomTabs,
  ICourseStructureLessonItemBottomTabsProps,
} from "./CourseStructureLessonItemBottomTabs";

const indentationWidthInRem = 1.5;

interface ICourseStructureLessonItemProps
  extends ICourseStructureLessonItemBottomTabsProps {
  lessonTitle: string;
  lessonWeblink: string;
  lessonDescription?: string;
  onClick?: () => void;
  href?: string;
  previewImageUrl?: string;
  leftEl?: JSX.Element;
  rightEl?: JSX.Element;
  cardWithUserHeaderProps?: Omit<ICardWithUserHeaderProps, "children">;
  indentationLevel?: number;
  cardStyle?: React.CSSProperties;
}

export const CourseStructureLessonItem: React.FunctionComponent<
  ICourseStructureLessonItemProps
> = (props) => {
  const {
    lessonTitle,
    lessonWeblink,
    lessonDescription,
    onClick,
    href,
    previewImageUrl,
    leftEl,
    rightEl,
    tabs,
    cardWithUserHeaderProps,
    indentationLevel,
    cardStyle,
  } = props;

  const marginLeftIndentation = !!indentationLevel
    ? `${indentationLevel * indentationWidthInRem}rem`
    : undefined;
  const commonCardStyles = {
    width: "100%",
    marginLeft: marginLeftIndentation,
    ...cardStyle,
  };

  const content = (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space direction={"horizontal"} style={{ width: "100%" }}>
          {leftEl}
          <ClickableWrapper onClick={onClick} href={href}>
            <Space direction={"horizontal"} style={{ alignItems: "center" }}>
              <WeblinkPreviewImage imageUrl={previewImageUrl} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordBreak: "break-word",
                }}
              >
                <Typography.Paragraph
                  style={{ fontWeight: 450, margin: 0 }}
                  ellipsis={{ rows: 2 }}
                >
                  {lessonTitle}
                </Typography.Paragraph>
                <Typography.Paragraph
                  style={{
                    fontWeight: 200,
                    margin: 0,
                    color: themeColors.primary,
                  }}
                  ellipsis={{ rows: 2 }}
                >
                  {lessonWeblink}
                </Typography.Paragraph>
              </div>
            </Space>
          </ClickableWrapper>
        </Space>
        {rightEl}
      </div>
      {lessonDescription && !isEmptyHtmlString(lessonDescription) && (
        <RichTextRenderer
          htmlString={lessonDescription}
          maxHeightBeforeReveal={55}
        />
      )}
      <CourseStructureLessonItemBottomTabs tabs={tabs} />
    </Space>
  );

  return cardWithUserHeaderProps ? (
    <CardWithUserHeader
      {...cardWithUserHeaderProps}
      style={{ ...commonCardStyles }}
    >
      {content}
    </CardWithUserHeader>
  ) : (
    <Card size={"small"} style={{ ...commonCardStyles }}>
      {content}
    </Card>
  );
};
