import React, { useState } from "react";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import {
  ListCourseSuggestionsQueryParams,
  useListCourseSuggestionsQuery,
} from "../../../firestore/queries/useListCourseSuggestionsQuery";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseSuggestions } from "./CourseSuggestions";

interface ICourseSuggestionsContainerProps {
  courseId: string;
  queryParams?: ListCourseSuggestionsQueryParams;
  hideFilters?: boolean;
  hideNewButtonOnEmptyState?: boolean;
}

export const CourseSuggestionsContainer: React.FunctionComponent<
  ICourseSuggestionsContainerProps
> = (props) => {
  const { courseId, queryParams, hideFilters, hideNewButtonOnEmptyState } =
    props;
  const [isSortingByUpvotes, setIsSortingByUpvotes] = useState<boolean>(
    queryParams?.sortByUpvotes || false
  );
  const [typeFilter, setTypeFilter] = useState<
    CourseSuggestionType | undefined
  >(queryParams?.type);
  const [stateFilter, setStateFilter] = useState<
    CourseSuggestionState | undefined
  >(queryParams?.state);
  const {
    data: course,
    isLoading: isCourseLoading,
    error: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const {
    data: courseSuggestions,
    fetchNextPage: fetchMoreCourseSuggestions,
    isNoMoreToFetch: isNoMoreCourseSuggestionsToFetch,
    isLoading: isCourseSuggestionsLoading,
    isError: isCourseSuggestionsError,
  } = useListCourseSuggestionsQuery({
    courseId,
    state: stateFilter,
    type: typeFilter,
    sortByUpvotes: isSortingByUpvotes,
    topItemId: queryParams?.topItemId,
    numItemsPerPage: queryParams?.numItemsPerPage,
  });

  const toggleIsSortingByUpvotes = () => {
    setIsSortingByUpvotes(!isSortingByUpvotes);
  };
  const clearAllFilters = () => {
    setStateFilter(undefined);
    setTypeFilter(undefined);
  };

  if (isCourseError || isCourseSuggestionsError) {
    return <GenericError />;
  }
  if (isCourseLoading || !course || isCourseSuggestionsLoading) {
    return <GenericLoader />;
  }

  return (
    <CourseSuggestions
      course={course}
      courseSuggestions={courseSuggestions}
      fetchMoreCourseSuggestions={fetchMoreCourseSuggestions}
      isNoMoreCourseSuggestionsToFetch={isNoMoreCourseSuggestionsToFetch}
      isSortingByUpvotes={isSortingByUpvotes}
      stateFilter={stateFilter}
      typeFilter={typeFilter}
      toggleIsSortingByUpvotes={toggleIsSortingByUpvotes}
      setStateFilter={setStateFilter}
      setTypeFilter={setTypeFilter}
      clearAllFilters={clearAllFilters}
      hideFilters={hideFilters}
      hideNewButtonOnEmptyState={hideNewButtonOnEmptyState}
    />
  );
};
