import { ILessonAnswer } from "../types/firestoreMappings";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserOwnerOfLessonAnswer = (answer: ILessonAnswer) => {
  const { user } = useUserAuth();

  if (!user) {
    return false;
  }

  return answer.owner === user.uid;
};
