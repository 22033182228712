import { Button, Input, Space } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import React from "react";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { convertSisuInputTypeToIcon } from "../../utils/iconAndStringUtils";
import { SISU_INPUT_WIDTH } from "../../styling/styleConstants";
import { SignInOptionsContainer } from "./SignInOptionsContainer";
import { RevealFormForEmailButton } from "./RevealFormForEmailButton";

interface ISignInProps {
  email: string;
  password: string;
  setEmail: (s: string) => void;
  setPassword: (s: string) => void;
  onSignIn: () => void;
}

export const SignIn: React.FunctionComponent<ISignInProps> = (props) => {
  const { email, password, setEmail, setPassword, onSignIn } = props;
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", alignItems: "center" }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={"Welcome back."}
          level={PageTypographyLevel.PageSection}
        />
      </div>
      <SignInOptionsContainer />
      <RevealFormForEmailButton>
        <Space
          direction={"vertical"}
          style={{ width: "100%", alignItems: "center" }}
        >
          <Input
            id={"SignIn-Email"}
            placeholder={"Email"}
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            prefix={convertSisuInputTypeToIcon("email")}
            style={{ width: SISU_INPUT_WIDTH }}
          />
          <Input.Password
            id={"SignIn-Password"}
            placeholder={"Password"}
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            prefix={convertSisuInputTypeToIcon("password")}
            style={{ width: SISU_INPUT_WIDTH }}
            iconRender={(visible) =>
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
          />
          <Button type={"primary"} onClick={onSignIn} size={"large"}>
            Sign In
          </Button>
        </Space>
      </RevealFormForEmailButton>
    </Space>
  );
};
