import { Card, Space } from "antd";
import React from "react";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { IUser } from "../../types/firestoreMappings";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { SettingsAvatarEditContainer } from "./SettingsAvatarEditContainer";
import { SettingsNotificationsEditContainer } from "./SettingsNotificationsEditContainer";
import { SettingsUsernameEditContainer } from "./SettingsUsernameEditContainer";

interface ISettingsProps {
  meUser: IUser;
}

export const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
  const { meUser } = props;
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={"Settings"}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
          />
        </div>
        <Space
          style={{
            marginBottom: PAGE_SECTION_SPACING,
            alignItems: "center",
            width: "100%",
          }}
          direction={"vertical"}
        >
          <SettingsAvatarEditContainer meUser={meUser} />
          <SettingsUsernameEditContainer meUser={meUser} />
        </Space>
        <Card size={"small"}>
          <SettingsNotificationsEditContainer meUser={meUser} />
        </Card>
      </Space>
    </LayoutContent>
  );
};
