import { Button, Layout, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import React from "react";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { ICourse, ILesson } from "../../types/firestoreMappings";
import { LayoutContent } from "../common/LayoutContent";
import { NewCourseSuggestionButton } from "../common/NewCourseSuggestionButton";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { WeblinkRenderer } from "../common/WeblinkRenderer";
import { InLessonNavButtons } from "./InLessonNavButtons";
import { InLessonSidebar } from "./InLessonSidebar";
import { InLessonDetailsSectionAlternativeLinksContainer } from "./details/InLessonDetailsSectionAlternativeLinksContainer";
import { InLessonDetailsSectionQuestionsContainer } from "./details/InLessonDetailsSectionQuestionsContainer";
import { InLessonDetailsSectionExercisesContainer } from "./details/InLessonDetailsSectionExercisesContainer";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useScrollToElementRef } from "../../ui-hooks/useScrollToElementRef";
import { featureFlags } from "../../utils/appConstants";
import { RichTextRenderer } from "../common/RichTextRenderer";
import { InLessonDetailsSectionCourseCommentsContainer } from "./details/InLessonDetailsSectionCommentsContainer";

interface IInLessonProps {
  course: ICourse;
  lesson: ILesson;
}

export const InLesson: React.FunctionComponent<IInLessonProps> = (props) => {
  const { course, lesson } = props;
  const nav = useNavigation();
  const parsedLessonAlternativeLinkId =
    nav.urlSearchParams.getLessonAlternativeLinkId();
  const parsedLessonExerciseId = nav.urlSearchParams.getLessonExerciseId();
  const parsedLessonQuestionId = nav.urlSearchParams.getLessonQuestionId();
  const parsedLessonAnswerId = nav.urlSearchParams.getLessonAnswerId();
  const parsedCourseCommentIdForLesson =
    nav.urlSearchParams.getCourseCommentIdForLesson();
  const { elRef: alternativeLinksSectionRef } = useScrollToElementRef(
    !!parsedLessonAlternativeLinkId
  );
  const { elRef: exercisesSectionRef } = useScrollToElementRef(
    !!parsedLessonExerciseId
  );
  const { elRef: questionsSectionRef } = useScrollToElementRef(
    !!parsedLessonQuestionId || !!parsedLessonAnswerId
  );
  const { elRef: lessonCourseCommentsSectionRef } = useScrollToElementRef(
    !!parsedCourseCommentIdForLesson
  );
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(course.id);

  return (
    <Layout hasSider={true}>
      <InLessonSidebar course={course} currentLesson={lesson} />
      <LayoutContent>
        <Space
          direction={"vertical"}
          style={{
            display: "flex",
            flex: 1,
            marginTop: isScreenLargerThanMd ? 0 : "5px",
          }} // Extra 5px margin to stop overlap of sider on mobile
        >
          <PageTypography
            title={lesson.title}
            level={PageTypographyLevel.PageTitle}
          />
          <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
            <WeblinkRenderer
              weblink={lesson.weblink}
              previewImageUrl={lesson.previewImageUrl}
              isCardDisplay={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {isMeUserOwnerOfCourse ? (
              <Button
                icon={<EditOutlined />}
                type={"dashed"}
                onClick={() => nav.goToPaths.editLesson(course.id, lesson.id)}
              >
                {"Edit"}
              </Button>
            ) : (
              <NewCourseSuggestionButton
                course={course}
                currentLessonId={lesson.id}
              />
            )}
            <InLessonNavButtons currentLessonId={lesson.id} course={course} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "1rem",
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <RichTextRenderer htmlString={lesson.description} />
            </div>
            <div ref={alternativeLinksSectionRef}>
              <InLessonDetailsSectionAlternativeLinksContainer
                courseId={course.id}
                lessonId={lesson.id}
              />
            </div>
            <div ref={lessonCourseCommentsSectionRef}>
              <InLessonDetailsSectionCourseCommentsContainer
                courseId={course.id}
                lessonId={lesson.id}
              />
            </div>
            {featureFlags.enableLessonExercises && (
              <div ref={exercisesSectionRef}>
                <InLessonDetailsSectionExercisesContainer
                  courseId={course.id}
                  lessonId={lesson.id}
                />
              </div>
            )}
            {featureFlags.enableLessonQuestionsAndAnswers && (
              <div ref={questionsSectionRef}>
                <InLessonDetailsSectionQuestionsContainer
                  courseId={course.id}
                  lessonId={lesson.id}
                />
              </div>
            )}
          </div>
        </Space>
      </LayoutContent>
    </Layout>
  );
};
