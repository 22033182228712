import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { listLessonAlternativeLinksQueryKey } from "../queries/useListLessonAlternativeLinksQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateLessonAlternativeLinkMutation = (
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const getCourse = useGetCourseFetch();
  const createLessonAlternativeLink = async (vars: {
    weblink: string;
    reason: string;
    previewImageUrl?: string;
    createdFromSuggestionId?: string;
  }) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateLessonAlternativeLinkMutation, error - no user found"
      );
    }
    const { weblink, reason, previewImageUrl, createdFromSuggestionId } = vars;
    const db = getFirestore();
    const lessonAlternativeLinksCollectionRef = collection(
      db,
      collectionPaths.lessonAlternativeLinks
    );
    const newAlternativeLink = {
      weblink,
      reason,
      upvotes: 0,
      courseId,
      lessonId,
      owner: user.uid,
      createdAt: serverTimestamp(),
    };
    if (previewImageUrl) {
      (newAlternativeLink as any).previewImageUrl = previewImageUrl;
    }
    if (createdFromSuggestionId) {
      (newAlternativeLink as any).createdFromSuggestionId =
        createdFromSuggestionId;
    }
    const newLessonAlternativeLinkDoc = await addDoc(
      lessonAlternativeLinksCollectionRef,
      newAlternativeLink
    );
    const courseData = await getCourse({ courseId });
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid) {
          createNotification({
            type: NotificationType.LessonAlternativeLink,
            userId: owner,
            notificationData: {
              lessonAlternativeLinkId: newLessonAlternativeLinkDoc.id,
            },
          });
        }
      });
    }
    return newLessonAlternativeLinkDoc;
  };
  return useMutation(createLessonAlternativeLink, {
    onSettled: settledLogger("useCreateLessonAlternativeLinkMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAlternativeLinksQueryKey({
          courseId,
          lessonId,
        }),
        { refetchInactive: true }
      );
    },
  });
};
