import { Button, Tooltip, Typography } from "antd";
import React from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import {
  fontSizes,
  themeColors,
  zIndexPriorities,
} from "../../styling/styleConstants";

interface IUpvoteButtonProps {
  upvoteCount: number;
  doesUpvoteDocExist: boolean;
  isExistingVoteDown: boolean;
  onUpvoteClick: () => void;
  onDownvoteClick: () => void;
}

export const UpvoteButton: React.FunctionComponent<IUpvoteButtonProps> = (
  props
) => {
  const {
    upvoteCount,
    doesUpvoteDocExist,
    isExistingVoteDown,
    onUpvoteClick,
    onDownvoteClick,
  } = props;

  const isUpvoted = doesUpvoteDocExist && !isExistingVoteDown;
  const isDownvoted = doesUpvoteDocExist && isExistingVoteDown;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Tooltip title={"Upvote"} zIndex={zIndexPriorities.tooltipOutsideModal}>
        <Button
          onClick={onUpvoteClick}
          type={"text"}
          size={"small"}
          style={{ padding: 0 }}
        >
          <CaretUpOutlined
            style={{
              color: isUpvoted ? themeColors.primary : themeColors.upvoteButton,
              fontSize: "2rem",
            }}
          />
        </Button>
      </Tooltip>
      <Typography style={{ fontSize: fontSizes.small, fontWeight: 900 }}>
        {upvoteCount}
      </Typography>
      <Tooltip title={"Downvote"} zIndex={zIndexPriorities.tooltipOutsideModal}>
        <Button
          onClick={onDownvoteClick}
          type={"text"}
          size={"small"}
          style={{ padding: 0, bottom: "9px" }}
        >
          <CaretDownOutlined
            style={{
              color: isDownvoted
                ? themeColors.primary
                : themeColors.upvoteButton,
              fontSize: "2rem",
            }}
          />
        </Button>
      </Tooltip>
    </div>
  );
};
