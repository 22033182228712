import {
  Button,
  Collapse,
  Dropdown,
  Menu,
  Space,
  //   Tooltip,
  Typography,
} from "antd";
import React from "react";
import {
  ICourse,
  ICourseSuggestion,
  ILesson,
} from "../../../types/firestoreMappings";
import {
  PlusOutlined,
  EllipsisOutlined,
  //   CheckCircleOutlined,
  EditOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { locStrings } from "../../../localization/locStrings";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { useIsMeUserOwnerOfCourse } from "../../../ui-hooks/useIsMeUserOwnerOfCourse";
import {
  isCourseEmptyOfModulesAndLessons,
  isCourseReorderable,
  isEmptyHtmlString,
} from "../../../utils/utils";
import { CourseStructureLessonItem } from "./CourseStructureLessonItem";
import { fontSizes } from "../../../styling/styleConstants";
import { RichTextRenderer } from "../../common/RichTextRenderer";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { convertCourseMetadataTypeToIcon } from "../../../utils/iconAndStringUtils";
import {
  LessonsAndSuggestionsMap,
  MetadataType,
} from "../../../types/helperTypes";
import { useCountLessonAlternativeLinksQuery } from "../../../firestore/queries/useCountLessonAlternativeLinksQuery";
import { useCountCourseCommentsQuery } from "../../../firestore/queries/useCountCourseCommentsQuery";
import { UpvoteButtonContainer } from "../../upvotes/UpvoteButtonContainer";
import { CourseSuggestionStateDisplayContainer } from "../../course-suggestions/read/CourseSuggestionStateDisplayContainer";
import { isICourseSuggestion } from "../../../types/typeGuards";
import { CourseToolBar } from "../course-tool-bar/CourseToolBar";

const generateModuleCollapseKey = (moduleId: string) =>
  `CourseStructure-Module-${moduleId}`;

interface ICourseStructureProps {
  course: ICourse;
  lessonsAndSuggestionsMap: LessonsAndSuggestionsMap;
  isNoMoreNewLessonSuggestionsToFetch: boolean;
  fetchMoreNewLessonSuggestions: () => void;
  isSuggestionsVisible: boolean;
  toggleSuggestionsVisibility: () => void;
}

export const CourseStructure: React.FunctionComponent<ICourseStructureProps> = (
  props
) => {
  const {
    course,
    lessonsAndSuggestionsMap,
    isNoMoreNewLessonSuggestionsToFetch,
    fetchMoreNewLessonSuggestions,
    isSuggestionsVisible,
    toggleSuggestionsVisibility,
  } = props;
  const isCourseEmpty = isCourseEmptyOfModulesAndLessons(course);
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(course.id);
  const isShowingAddEditButtons = isMeUserOwnerOfCourse;
  const allModuleKeys = course.modules.map((module) =>
    generateModuleCollapseKey(module.id)
  );

  return (
    <div style={{ width: "100%" }}>
      <CourseToolBar
        course={course}
        isNoMoreNewLessonSuggestionsToFetch={
          isNoMoreNewLessonSuggestionsToFetch
        }
        fetchMoreNewLessonSuggestions={fetchMoreNewLessonSuggestions}
        isSuggestionsVisible={isSuggestionsVisible}
        toggleSuggestionsVisibility={toggleSuggestionsVisibility}
      />
      {isCourseEmpty && (
        <Typography.Text italic={true}>
          {locStrings.noContentHasBeenAddedYet.value()}
        </Typography.Text>
      )}
      {
        // @ts-ignore
        <Collapse activeKey={allModuleKeys} collapsible={"header"}>
          {course.modules.map((module, index) => {
            const moduleHasDescription =
              !!module.description && !isEmptyHtmlString(module.description);
            return (
              // @ts-ignore
              <Collapse.Panel
                key={generateModuleCollapseKey(module.id)}
                header={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "default",
                    }}
                  >
                    <PageTypography
                      title={`${index + 1}. ${module.title}`}
                      level={PageTypographyLevel.PageSection}
                    />
                    {moduleHasDescription && (
                      <RichTextRenderer
                        htmlString={module.description}
                        maxHeightBeforeReveal={60}
                      />
                    )}
                  </div>
                }
                showArrow={false}
                extra={
                  isShowingAddEditButtons ? (
                    <EditModuleButton course={course} moduleId={module.id} />
                  ) : null
                }
              >
                <Space direction={"vertical"} style={{ width: "100%" }}>
                  {lessonsAndSuggestionsMap[module.id].length > 0 ? (
                    lessonsAndSuggestionsMap[module.id].map(
                      (lessonOrSuggestionItem) => {
                        if (isICourseSuggestion(lessonOrSuggestionItem)) {
                          return (
                            <NewLessonSuggestionItem
                              key={`CourseStructure-SuggestionItem-${lessonOrSuggestionItem.id}`}
                              course={course}
                              suggestion={lessonOrSuggestionItem}
                            />
                          );
                        } else {
                          return (
                            <LessonItem
                              key={`CourseStructure-LessonItem-${lessonOrSuggestionItem.id}`}
                              courseId={course.id}
                              lesson={lessonOrSuggestionItem}
                              isShowingAddEditButtons={isShowingAddEditButtons}
                            />
                          );
                        }
                      }
                    )
                  ) : (
                    <Typography.Link
                      type={"secondary"}
                      disabled={true}
                      italic={true}
                    >
                      {locStrings.noLessonsAddedYet.value()}
                    </Typography.Link>
                  )}
                </Space>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      }
    </div>
  );
};

const LessonItem: React.FunctionComponent<{
  courseId: string;
  lesson: ILesson;
  isShowingAddEditButtons: boolean;
}> = (props) => {
  const { courseId, lesson, isShowingAddEditButtons } = props;
  const nav = useNavigation();
  const { data: alternativeLinksCount } = useCountLessonAlternativeLinksQuery({
    lessonId: lesson.id,
  });
  const { data: commentsCount } = useCountCourseCommentsQuery({
    metadataId: lesson.id,
  });
  return (
    <CourseStructureLessonItem
      lessonTitle={lesson.title}
      lessonWeblink={lesson.weblink}
      lessonDescription={lesson.description}
      previewImageUrl={lesson.previewImageUrl}
      onClick={() => nav.goToPaths.inLesson(courseId, lesson.id)}
      //   leftEl={
      //     <Tooltip
      //       title={"Approved by course owner"}
      //       zIndex={zIndexPriorities.tooltipOutsideModal}
      //     >
      //       <CheckCircleOutlined />
      //     </Tooltip>
      //   }
      rightEl={
        isShowingAddEditButtons ? (
          <EditLessonButton courseId={courseId} lessonId={lesson.id} />
        ) : undefined
      }
      tabs={[
        {
          id: `CourseStructure-AlternativeLinks-${lesson.id}`,
          tabTitle: {
            icon: convertCourseMetadataTypeToIcon({
              metadataType: MetadataType.AlternativeLink,
            }),
            title: alternativeLinksCount ? `${alternativeLinksCount}` : "0",
            tooltip: "Alternative links",
          },
          tabFunctionality: {
            onClick: () => nav.goToPaths.inLesson(courseId, lesson.id),
          },
        },
        {
          id: `CourseStructure-Comments-${lesson.id}`,
          tabTitle: {
            icon: convertCourseMetadataTypeToIcon({
              metadataType: MetadataType.CourseComment,
            }),
            title: commentsCount ? `${commentsCount}` : "0",
            tooltip: "Comments",
          },
          tabFunctionality: {
            onClick: () => nav.goToPaths.inLesson(courseId, lesson.id),
          },
        },
      ]}
    />
  );
};

const NewLessonSuggestionItem: React.FunctionComponent<{
  course: ICourse;
  suggestion: ICourseSuggestion;
}> = (props) => {
  const { course, suggestion } = props;
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(course.id);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <UpvoteButtonContainer
        upvoteCount={suggestion.upvotes}
        metadataId={suggestion.id}
        metadataType={MetadataType.CourseSuggestion}
      />
      <CourseStructureLessonItem
        cardStyle={{ marginLeft: "0.5rem" }}
        lessonTitle={suggestion.lessonTitle || "Invalid suggestion title"}
        lessonWeblink={suggestion.lessonWeblink || "Invalid suggestion weblink"}
        lessonDescription={suggestion.reason}
        previewImageUrl={suggestion.lessonPreviewImageUrl}
        href={suggestion.lessonWeblink}
        // leftEl={
        //   <Tooltip
        //     title={"Suggested by the community"}
        //     zIndex={zIndexPriorities.tooltipOutsideModal}
        //   >
        //     {convertCourseMetadataTypeToIcon({
        //       metadataType: MetadataType.CourseSuggestion,
        //     })}
        //   </Tooltip>
        // }
        rightEl={
          isMeUserOwnerOfCourse ? (
            <CourseSuggestionStateDisplayContainer
              courseSuggestion={suggestion}
              buttonContent={<PlusOutlined />}
            />
          ) : undefined
        }
        tabs={[]}
        cardWithUserHeaderProps={{
          userId: suggestion.owner,
          hideAvatar: true,
          addonTextBeforeUsername: "Suggested by ",
          usernameTextStyle: {
            fontSize: fontSizes.small,
          },
          headerStyle: { marginBottom: "0.5rem" },
        }}
      />
    </div>
  );
};

const EditLessonButton: React.FunctionComponent<{
  courseId: string;
  lessonId: string;
}> = (props) => {
  const { courseId, lessonId } = props;
  const nav = useNavigation();
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => nav.goToPaths.editLesson(courseId, lessonId)}
        icon={<EditOutlined />}
      >
        {"Edit Link"}
      </Menu.Item>
    </Menu>
  );

  return (
    //@ts-ignore
    <Dropdown overlay={menu}>
      <Button type={"text"}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

const EditModuleButton: React.FunctionComponent<{
  course: ICourse;
  moduleId: string;
}> = (props) => {
  const { course, moduleId } = props;
  const isReorderable = isCourseReorderable(course);
  const nav = useNavigation();
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => nav.goToPaths.editModule(course.id, moduleId)}
        icon={<EditOutlined />}
      >
        {"Edit Module"}
      </Menu.Item>
      {isReorderable && (
        <Menu.Item
          onClick={() => nav.goToPaths.reorderCourse(course.id)}
          icon={<SwapOutlined />}
        >
          {"Reorder Links"}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    //@ts-ignore
    <Dropdown overlay={menu}>
      <Button type={"text"}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};
