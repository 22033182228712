import React from "react";
import { SisuModal } from "./components/auth/SisuModal";
import { BaseNavigation } from "./components/nav/BaseNavigation";

function App() {
  return (
    <>
      <BaseNavigation />
      <SisuModal />
    </>
  );
}

export default App;
