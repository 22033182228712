export const locStrings = {
  topNavBarHome: {
    value: () => "Home",
  },
  topNavBarNewCourse: {
    value: () => "New Course",
  },
  topNavBarReviewQueue: {
    value: () => "Review Queue",
  },
  topNavBarExploer: {
    value: () => "Explore",
  },
  topNavBarNotifications: {
    value: () => "Notifications",
  },
  genericErrorMessage: {
    value: () => "Oops. Something went wrong",
  },
  genericErrorBackToHome: {
    value: () => "Go back to main page",
  },
  homePageTitle: {
    value: () => "Welcome to the Library of Alexandria",
  },
  sisuSignOutMessage: {
    value: () => "Sign out",
  },
  sisuSignUpMessage: {
    value: (username: string) => `Welcome ${username}`,
  },
  sisuSignInMessage: {
    value: () => "Welcome back",
  },
  sisuSignIn: {
    value: () => "Sign in",
  },
  notSignedInRestriction: {
    value: () => "You must be signed in to do that",
  },
  favorite: {
    value: () => "Favorite",
  },
  suggestions: {
    value: () => "Suggestions",
  },
  favorites: {
    value: () => "Favorites",
  },
  edit: {
    value: () => "Edit",
  },
  addModule: {
    value: () => "Add module",
  },
  addLesson: {
    value: () => "Add link",
  },
  editCourse: {
    value: () => "Edit course",
  },
  editModule: {
    value: () => "Edit module",
  },
  editLesson: {
    value: () => "Edit link",
  },
  reorder: {
    value: () => "Reorder",
  },
  reorderCoursePageTitle: {
    value: () => "Reorder Modules & Links",
  },
  noLessonsAddedYet: {
    value: () => "No links added yet",
  },
  summary: {
    value: () => "Summary",
  },
  questions: {
    value: () => "Questions",
  },
  exercises: {
    value: () => "Exercises",
  },
  alternativeLinksTab: {
    value: () => "Alternative Links",
  },
  courseCommentsTab: {
    value: () => "Comments",
  },
  createANewCourse: {
    value: () => "Create a New Course",
  },
  editCoursePageTitle: {
    value: () => "Edit Course",
  },
  addANewModule: {
    value: () => "Add a New Module",
  },
  editModulePageTitle: {
    value: () => "Edit Module",
  },
  addANewLesson: {
    value: () => "Add a New Link",
  },
  editLessonPageTitle: {
    value: () => "Edit Link",
  },
  submit: {
    value: () => "Submit",
  },
  myCourses: {
    value: () => "My Courses",
  },
  startCourse: {
    value: () => "Start Course",
  },
  noContentHasBeenAddedYet: {
    value: () => "No content has been added yet",
  },
  profile: {
    value: () => "Profile",
  },
  coursesByUser: {
    value: (username: string) => `${username}'s courses`,
  },
  courseTitle: {
    value: () => "Title",
  },
  courseTitlePlaceholder: {
    value: () => "Enter a title...",
  },
  courseDescription: {
    value: () => "Description",
  },
  courseDescriptionPlaceholder: {
    value: () =>
      "Describe the course, what others will learn, who should take it, etc...",
  },
  courseOwners: {
    value: () => "Course Owners",
  },
  courseCategories: {
    value: () => "Categories",
  },
  courseCategoriesPlaceholder: {
    value: () => "Choose at least one category for this course",
  },
  moduleName: {
    value: () => "Module Name",
  },
  moduleNamePlaceholder: {
    value: () => "Enter a name...",
  },
  lessonModuleSelect: {
    value: () => "Module for Link",
  },
  lessonModuleSelectPlacerholder: {
    value: () => "Select a module for this link...",
  },
  lessonTitle: {
    value: () => "Title",
  },
  lessonTitlePlacerholder: {
    value: () => "Enter a title...",
  },
  lessonWeblink: {
    value: () => "Weblink",
  },
  lessonWeblinkPlacerholder: {
    value: () => "Paste a weblink...",
  },
  lessonDescription: {
    value: () => "Description",
  },
  lessonDescriptionPlacerholder: {
    value: () =>
      "Why is this link useful, what are some key takeaways, how does it fit into this course, etc...",
  },
  delete: {
    value: () => "Delete",
  },
  areYouSure: {
    value: () => "Are you sure?",
  },
  yes: {
    value: () => "Yes",
  },
  no: {
    value: () => "No",
  },
  typeAUsernameCaseSensitive: {
    value: () => "Type a username (Case-sensitive)",
  },
  noUsersFound: {
    value: () => "No users found (Case-sensitive)",
  },
  next: {
    value: () => "Next",
  },
  back: {
    value: () => "Back",
  },
  uploadAvatar: {
    value: () => "Upload avatar",
  },
  removeAvatar: {
    value: () => "Remove avatar",
  },
  askAQuestionPlaceholder: {
    value: () => "Type a question...",
  },
  createAnExercisePlaceholder: {
    value: () => "Suggest an exercise to reinforce the learnings of this link",
  },
  writeAnAnswerPlaceholder: {
    value: () => "Write an answer...",
  },
  loadMoreQuestions: {
    value: () => "Load more questions",
  },
  loadMoreAnswers: {
    value: () => "Load more answers",
  },
  loadMoreAlternativeLinks: {
    value: () => "Load more links",
  },
  loadMoreComments: {
    value: () => "Load more comments",
  },
  loadMoreExercises: {
    value: () => "Load more exercises",
  },
  loadMoreCourses: {
    value: () => "Load more courses",
  },
  loadMoreSuggestions: {
    value: () => "Load more suggestions",
  },
  loadMore: {
    value: () => "Load more",
  },
  yourNotifications: {
    value: () => "Your Notifications",
  },
  new: {
    value: () => "New",
  },
  courseOwner: {
    value: () => "Course Owner",
  },
  newCourseSuggestionButtonText: {
    value: () => "Contribute",
  },
  suggestionNewLessonReasonInputPlaceholder: {
    value: () => "Give a reason why this link should be added",
  },
  suggestionNewModuleTitleInputPlaceholder: {
    value: () => "Enter a module name",
  },
  suggestionNewModuleReasonInputPlaceholder: {
    value: () => "Give a reason why this module should be added",
  },
  suggestionGeneralFeedbackReasonInputPlaceholder: {
    value: () => "Provide general feedback about this course",
  },
  courseSuggestionCreatedSuccessNotification: {
    value: () => "Your suggestion has been submitted",
  },
  courseSuggestionNewLesson: {
    value: () => "New Link",
  },
  courseSuggestionNewModule: {
    value: () => "New Module",
  },
  courseSuggestionGeneralFeedback: {
    value: () => "General Feedback",
  },
};
