import { Spin } from "antd";
import React from "react";

interface IGenericLoaderProps {}

export const GenericLoader: React.FunctionComponent<IGenericLoaderProps> = (
  props
) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  );
};
