import { Button, Space, Typography } from "antd";
import React from "react";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";

interface IVerifyEmailProps {
  onVerifyEmail: () => void;
  userEmail: string;
}

export const VerifyEmail: React.FunctionComponent<IVerifyEmailProps> = (
  props
) => {
  const { onVerifyEmail, userEmail } = props;
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", alignItems: "center" }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={"Verify your email."}
          level={PageTypographyLevel.PageSection}
        />
      </div>
      <Typography style={{ marginBottom: "1rem" }}>
        {`You need to verify your email address: ${userEmail}`}
      </Typography>
      <Typography style={{ marginBottom: "1rem" }}>
        {"Please press the button below and go to your email to verify."}
      </Typography>

      <Button
        type={"primary"}
        onClick={onVerifyEmail}
        size={"large"}
        className={"pulsing-animation"}
      >
        {"Send email"}
      </Button>
    </Space>
  );
};
