import { Space, Typography } from "antd";
import React from "react";
import { IUser } from "../../types/firestoreMappings";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { ClickableWrapper } from "./ClickableWrapper";
import { CourseOwnerTag } from "./CourseOwnerTag";
import { UserAvatar } from "./UserAvatar";

interface IUserItemProps {
  user: IUser;
  isCourseOwner?: boolean;
  onClick?: () => void;
  disableOnClick?: boolean;
  hideAvatar?: boolean;
  hideUsername?: boolean;
  addonTextAfterUsername?: string;
  addonTextBeforeUsername?: string;
  usernameTextStyle?: React.CSSProperties;
}

export const UserItem: React.FunctionComponent<IUserItemProps> = (props) => {
  const {
    user,
    isCourseOwner,
    onClick,
    disableOnClick,
    hideAvatar,
    hideUsername,
    addonTextAfterUsername,
    addonTextBeforeUsername,
    usernameTextStyle,
  } = props;
  const nav = useNavigation();

  let usernameText = user.username;
  if (addonTextAfterUsername) {
    usernameText += addonTextAfterUsername;
  }
  if (addonTextBeforeUsername) {
    usernameText = addonTextBeforeUsername + usernameText;
  }

  const goToProfile = () => {
    nav.goToPaths.userProfile(user.id);
  };

  const onClickFxn = onClick || goToProfile;

  return (
    <ClickableWrapper onClick={disableOnClick ? undefined : onClickFxn}>
      <Space direction={"horizontal"} style={{ alignItems: "center" }}>
        {!hideAvatar && (
          <UserAvatar
            uid={user.id}
            username={user.username}
            avatarUrl={user.avatarUrl}
          />
        )}
        {isCourseOwner && <CourseOwnerTag />}
        {!hideUsername && (
          <Typography.Text type={"secondary"} style={usernameTextStyle}>
            {usernameText}
          </Typography.Text>
        )}
      </Space>
    </ClickableWrapper>
  );
};
