import { Space } from "antd";
import React from "react";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { ICourse, ICourseReviewRejection } from "../../types/firestoreMappings";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { CourseReviewRejectionItemContainer } from "./CourseReviewRejectionItemContainer";
import { CourseReviewRejectionsEmptyState } from "./CourseReviewRejectionsEmptyState";

interface ICourseReviewRejectionsProps {
  course: ICourse;
  reviewRejections: ICourseReviewRejection[];
  isNoMoreReviewRejectionsToFetch: boolean;
  fetchMoreReviewRejections: () => void;
  navToCourse: () => void;
}

export const CourseReviewRejections: React.FunctionComponent<
  ICourseReviewRejectionsProps
> = (props) => {
  const {
    course,
    reviewRejections,
    isNoMoreReviewRejectionsToFetch,
    fetchMoreReviewRejections,
  } = props;

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={"Reviewer Feedback"}
            subTitle={course.title}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
          />
        </div>
        {reviewRejections.length === 0 && <CourseReviewRejectionsEmptyState />}
        {reviewRejections.map((reviewRejection) => {
          return (
            <CourseReviewRejectionItemContainer
              key={`CourseReviewRejections-Item-${reviewRejection.id}`}
              reviewRejection={reviewRejection}
            />
          );
        })}
        <FetchMoreButton
          text={"Load more reviews"}
          isNoMoreToFetch={isNoMoreReviewRejectionsToFetch}
          fetchMore={fetchMoreReviewRejections}
        />
      </Space>
    </LayoutContent>
  );
};
