import { Avatar } from "antd";
import React from "react";
import { ClickableWrapper } from "./ClickableWrapper";

interface IUserAvatarProps {
  uid: string;
  username: string;
  avatarUrl?: string;
  onClick?: () => void;
  overrideSize?: number;
}

export const UserAvatar: React.FunctionComponent<IUserAvatarProps> = (
  props
) => {
  const { uid, username, avatarUrl, onClick, overrideSize } = props;

  const renderAvatar = (
    <Avatar
      src={avatarUrl}
      size={overrideSize || "default"}
      style={{ backgroundColor: `${randomColorFromString(uid)}` }}
    >
      {generateInitialsForDefaultAvatar(username)}
    </Avatar>
  );

  if (onClick) {
    return (
      <ClickableWrapper onClick={onClick}>{renderAvatar}</ClickableWrapper>
    );
  } else {
    return renderAvatar;
  }
};

const generateInitialsForDefaultAvatar = (username: string) => {
  let initials = "";
  username.split(" ").forEach((wordInUsername) => {
    initials += wordInUsername[0].toLocaleUpperCase();
  });
  return initials.slice(0, 2);
};

const randomColorFromString = (username: string) => {
  let hash = 0;
  if (username.length === 0) return hash;
  for (let i = 0; i < username.length; i++) {
    hash = username.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};
