import React, { useEffect, useRef, useState } from "react";
import { FetchMoreButton } from "./FetchMoreButton";

interface IRichTextRendererProps {
  htmlString: string;
  maxHeightBeforeReveal?: number;
  style?: React.CSSProperties;
  className?: string;
}

const defaultMaxHeightInPx = 100;

export const RichTextRenderer: React.FunctionComponent<
  IRichTextRendererProps
> = (props) => {
  const { htmlString, maxHeightBeforeReveal, style, className } = props;
  const maxHeightToUse = maxHeightBeforeReveal || defaultMaxHeightInPx;
  const richTextRef = useRef<HTMLDivElement | null>(null);
  const [isRevealingAll, setIsRevealingAll] = useState<boolean>(false);
  const [isRevealButtonVisible, setIsRevealButtonVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      richTextRef.current?.offsetHeight &&
      !!maxHeightToUse &&
      richTextRef.current.offsetHeight >= maxHeightToUse
    ) {
      setIsRevealButtonVisible(true);
    }
  }, [maxHeightToUse, richTextRef.current?.offsetHeight]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        ref={richTextRef}
        dangerouslySetInnerHTML={{ __html: htmlString }}
        style={{
          wordBreak: "break-word",
          maxHeight: isRevealingAll ? undefined : maxHeightToUse,
          overflowY: "hidden",
          ...style,
        }}
        className={className}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <FetchMoreButton
          text={"Read more"}
          isNoMoreToFetch={!isRevealButtonVisible || isRevealingAll}
          fetchMore={() => setIsRevealingAll(true)}
        />
      </div>
    </div>
  );
};
