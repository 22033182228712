import _ from "lodash";
import {
  ICourse,
  ICourseSuggestion,
  ILesson,
  IModule,
  IUser,
} from "../types/firestoreMappings";
import {
  CourseCategory,
  CourseReorderScaffold,
  LessonPlacementInCourse,
  LessonsAndSuggestionsMap,
} from "../types/helperTypes";
import { courseCategoryMap } from "./courseCategoryMap";

export const isAlphaNumeric = (text: string) => {
  return !/\W|_/.exec(text);
};

export const isValidUrl = (maybeUrl: string) => {
  try {
    new URL(maybeUrl);
  } catch (e) {
    return false;
  }
  return true;
};

export const getYoututubeVideoId = (url: string) => {
  const p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const thingy = url.match(p);
  if (thingy) {
    return thingy[1];
  }
  return false;
};

export const isEmptyHtmlString = (htmlString: string) => {
  const strippedHtmlString = htmlString
    .replace(/[\r\n]/gm, "") // Remove new lines from string
    .replaceAll("<p>", "") // Remove paragraph tags
    .replaceAll("</p>", "")
    .replaceAll("<br>", "") // Remove line breaks
    .replaceAll("&nbsp;", ""); // Remove spaces

  return !strippedHtmlString;
};

// Used to fill in default lesson description
export const generateEmptyHtmlString = () => {
  return "<p></p>";
};

export const generateNotFoundUser = (userId: string): IUser => {
  return {
    id: userId,
    username: "Not Found",
  };
};

export const calculateModuleAndLessonCountsInCourse = (course: ICourse) => {
  const moduleAndLessonCounts = {
    modulesCount: 0,
    lessonsCount: 0,
  };

  course.modules.forEach((module) => {
    moduleAndLessonCounts.modulesCount += 1;
    moduleAndLessonCounts.lessonsCount += module.lessons.length;
  });

  return moduleAndLessonCounts;
};

export const getLessonFromCourse = (
  lessonId: string,
  course: ICourse
): ILesson | undefined => {
  let targetLesson: ILesson | undefined;
  course.modules.forEach((module) => {
    module.lessons.forEach((lesson) => {
      if (lesson.id === lessonId) {
        targetLesson = _.cloneDeep(lesson);
      }
    });
  });
  return targetLesson;
};

export const getModuleFromCourse = (
  moduleId: string,
  course: ICourse
): IModule | undefined => {
  let targetModule: IModule | undefined;
  course.modules.forEach((module) => {
    if (module.id === moduleId) {
      targetModule = _.cloneDeep(module);
    }
  });
  return targetModule;
};

export const getModuleIdViaLessonIdFromCourse = (
  course: ICourse,
  lessonId: string
): string | undefined => {
  let targetModuleId: string | undefined;
  course.modules.forEach((module) => {
    module.lessons.forEach((lesson) => {
      if (lesson.id === lessonId) {
        targetModuleId = module.id;
      }
    });
  });
  return targetModuleId;
};

export const isCourseEmptyOfModulesAndLessons = (course: ICourse) => {
  return course.modules.length === 0;
};

export const isModuleEmptyOfLessons = (module: IModule) => {
  return module.lessons.length === 0;
};

export const isCourseReorderable = (course: ICourse) => {
  let moduleCount = 0;
  let lessonCount = 0;

  course.modules.forEach((module) => {
    moduleCount += 1;
    module.lessons.forEach((lesson) => {
      lessonCount += 1;
    });
  });

  if (moduleCount >= 2 || lessonCount >= 2) {
    return true;
  } else {
    return false;
  }
};

export const generateReorderScaffoldFromCourse = (course: ICourse) => {
  const scaffold: CourseReorderScaffold = { modules: [] };
  course.modules.forEach((module) => {
    const lessonIds = module.lessons.map((lesson) => lesson.id);
    scaffold.modules.push({ moduleId: module.id, lessonIds });
  });
  return scaffold;
};

export const generateCourseFromReorderScaffold = (
  course: ICourse,
  scaffold: CourseReorderScaffold
): ICourse => {
  const reorderedModules = scaffold.modules.map((moduleScaffold) => {
    const module = getModuleFromCourse(
      moduleScaffold.moduleId,
      course
    ) as IModule;
    if (!module) {
      throw new Error(
        `generateCourseFromReorderScaffold, unknown module: ${moduleScaffold.moduleId}`
      );
    }
    const reorderedLessons = moduleScaffold.lessonIds.map((lessonId) => {
      const lesson = getLessonFromCourse(lessonId, course) as ILesson;
      if (!lesson) {
        throw new Error(
          `generateCourseFromReorderScaffold, unknown lesson: ${lessonId}`
        );
      }
      return lesson;
    });
    return {
      ...module,
      lessons: reorderedLessons,
    };
  });
  return {
    ...course,
    modules: reorderedModules,
  };
};

/**
 *
 * @param reorderScaffold
 * @param lessonId
 * @returns Either a CourseReorderScaffold object, or undefined if the lesson cannot be moved
 */
export const moveLessonUpInScaffold = (
  reorderScaffold: CourseReorderScaffold,
  lessonId: string
) => {
  let isValidMove = true;
  let newScaffold: CourseReorderScaffold = _.cloneDeep(reorderScaffold);
  reorderScaffold.modules.forEach((moduleScaffold, moduleIndex) => {
    const indexOfLessonId = moduleScaffold.lessonIds.indexOf(lessonId);
    if (indexOfLessonId === -1) {
      // lessonId not found in this module, check next
      _.noop();
    } else if (indexOfLessonId === 0) {
      // First lesson, check prev module
      const prevModule = reorderScaffold.modules[moduleIndex - 1];
      if (prevModule) {
        // Insert lesson at end of prev module, remove it from beginning of current module
        newScaffold.modules[moduleIndex - 1].lessonIds.push(lessonId);
        newScaffold.modules[moduleIndex].lessonIds.shift();
      } else {
        // Cannot move first less of first module
        isValidMove = false;
      }
    } else if (indexOfLessonId > 0) {
      // Not first lesson, move up one position
      const indexBeforeLessonId = indexOfLessonId - 1;
      const selectedLessonId = moduleScaffold.lessonIds[indexOfLessonId];
      newScaffold.modules[moduleIndex].lessonIds[indexOfLessonId] =
        moduleScaffold.lessonIds[indexBeforeLessonId];
      newScaffold.modules[moduleIndex].lessonIds[indexBeforeLessonId] =
        selectedLessonId;
    }
  });

  if (isValidMove) {
    return newScaffold;
  } else {
    return undefined;
  }
};

/**
 *
 * @param reorderScaffold
 * @param lessonId
 * @returns Either a CourseReorderScaffold object, or undefined if the lesson cannot be moved
 */
export const moveLessonDownInScaffold = (
  reorderScaffold: CourseReorderScaffold,
  lessonId: string
) => {
  let isValidMove = true;
  let newScaffold: CourseReorderScaffold = _.cloneDeep(reorderScaffold);
  reorderScaffold.modules.forEach((moduleScaffold, moduleIndex) => {
    const indexOfLessonId = moduleScaffold.lessonIds.indexOf(lessonId);
    if (indexOfLessonId === -1) {
      // lessonId not found in this module, check next
      _.noop();
    } else if (indexOfLessonId === moduleScaffold.lessonIds.length - 1) {
      // Last lesson, check next module
      const nextModule = reorderScaffold.modules[moduleIndex + 1];
      if (nextModule) {
        // Insert lesson at beginning of next module, remove it from end of current module
        newScaffold.modules[moduleIndex + 1].lessonIds.unshift(lessonId);
        newScaffold.modules[moduleIndex].lessonIds.pop();
      } else {
        // Cannot move first last of last module
        isValidMove = false;
      }
    } else if (indexOfLessonId >= 0) {
      // Not last lesson, move down one position
      const indexAfterLessonId = indexOfLessonId + 1;
      const selectedLessonId = moduleScaffold.lessonIds[indexOfLessonId];
      newScaffold.modules[moduleIndex].lessonIds[indexOfLessonId] =
        moduleScaffold.lessonIds[indexAfterLessonId];
      newScaffold.modules[moduleIndex].lessonIds[indexAfterLessonId] =
        selectedLessonId;
    }
  });

  if (isValidMove) {
    return newScaffold;
  } else {
    return undefined;
  }
};

/**
 *
 * @param reorderScaffold
 * @param moduleId
 * @returns Either a CourseReorderScaffold object, or undefined if the module cannot be moved
 */
export const moveModuleUpInScaffold = (
  reorderScaffold: CourseReorderScaffold,
  moduleId: string
) => {
  let isValidMove = true;
  let newScaffold: CourseReorderScaffold = _.cloneDeep(reorderScaffold);
  let indexOfModuleId = -1;
  reorderScaffold.modules.forEach((moduleScaffold, i) => {
    if (moduleScaffold.moduleId === moduleId) {
      indexOfModuleId = i;
    }
  });
  if (indexOfModuleId === -1) {
    // moduleId not found, check next
    throw new Error("moveModuleUpInScaffold, module not found");
  } else if (indexOfModuleId === 0) {
    // First module, cannot move up
    isValidMove = false;
  } else {
    // Move up the module
    const indexBeforeModuleId = indexOfModuleId - 1;
    const selectedModuleScaffold = reorderScaffold.modules[indexOfModuleId];
    newScaffold.modules[indexOfModuleId] =
      reorderScaffold.modules[indexBeforeModuleId];
    newScaffold.modules[indexBeforeModuleId] = selectedModuleScaffold;
  }

  if (isValidMove) {
    return newScaffold;
  } else {
    return undefined;
  }
};

/**
 *
 * @param reorderScaffold
 * @param moduleId
 * @returns Either a CourseReorderScaffold object, or undefined if the module cannot be moved
 */
export const moveModuleDownInScaffold = (
  reorderScaffold: CourseReorderScaffold,
  moduleId: string
) => {
  let isValidMove = true;
  let newScaffold: CourseReorderScaffold = _.cloneDeep(reorderScaffold);
  let indexOfModuleId = -1;
  reorderScaffold.modules.forEach((moduleScaffold, i) => {
    if (moduleScaffold.moduleId === moduleId) {
      indexOfModuleId = i;
    }
  });
  if (indexOfModuleId === -1) {
    // moduleId not found, check next
    throw new Error("moveModuleDownInScaffold, module not found");
  } else if (indexOfModuleId === reorderScaffold.modules.length - 1) {
    // Last module, cannot move down
    isValidMove = false;
  } else {
    // Move up the module
    const indexAfterModuleId = indexOfModuleId + 1;
    const selectedModuleScaffold = reorderScaffold.modules[indexOfModuleId];
    newScaffold.modules[indexOfModuleId] =
      reorderScaffold.modules[indexAfterModuleId];
    newScaffold.modules[indexAfterModuleId] = selectedModuleScaffold;
  }

  if (isValidMove) {
    return newScaffold;
  } else {
    return undefined;
  }
};

/**
 *
 * @param course
 * @param moduleId
 * @param indexBetweenLessonsInModule the index of the position between links in a module, where `0` is before first lesson and `lessons.length` is after last lesson
 * @returns
 */
export const calculateSurroundingLessonIdsOfIndex = (
  course: ICourse,
  moduleId: string,
  indexBetweenLessonsInModule: number
): LessonPlacementInCourse => {
  const placement: LessonPlacementInCourse = {
    moduleId,
    prevLessonId: undefined,
    nextLessonId: undefined,
  };
  const module = getModuleFromCourse(moduleId, course);
  if (!module) {
    return placement;
  }
  if (module.lessons.length === 0) {
    return placement;
  }

  const prevLesson = module.lessons[indexBetweenLessonsInModule - 1];
  const nextLesson = module.lessons[indexBetweenLessonsInModule];

  if (prevLesson) {
    placement.prevLessonId = prevLesson.id;
  }
  if (nextLesson) {
    placement.nextLessonId = nextLesson.id;
  }

  return placement;
};

/**
 *
 * @param next If true, get next lesson, if false get previous lesson
 * @param currentLessonId
 * @param course
 * @returns returns lessonId if found, otherwise returns undefined
 */
export const calculateFirstLessonId = (course: ICourse): string | undefined => {
  const allLessonIdsInAllModulesInOrder = allLessonIdsInOrder(course);
  const firstLesson = allLessonIdsInAllModulesInOrder[0];
  return firstLesson;
};

/**
 *
 * @param next If true, get next lesson, if false get previous lesson
 * @param currentLessonId
 * @param course
 * @returns returns lessonId if found, otherwise returns undefined
 */
export const calculateAdjacentLessonId = (
  next: boolean,
  currentLessonId: string,
  course: ICourse
): string | undefined => {
  const allLessonIdsInAllModulesInOrder = allLessonIdsInOrder(course);
  const indexOfCurrentLesson =
    allLessonIdsInAllModulesInOrder.indexOf(currentLessonId);
  return allLessonIdsInAllModulesInOrder[
    indexOfCurrentLesson + (next ? 1 : -1)
  ];
};

const allLessonIdsInOrder = (course: ICourse): string[] => {
  const allLessonIdsInAllModulesInOrder: string[] = [];

  course.modules.forEach((module) => {
    module.lessons.forEach((lesson) => {
      allLessonIdsInAllModulesInOrder.push(lesson.id);
    });
  });

  return allLessonIdsInAllModulesInOrder;
};

export const iterateOverCourseCategories = (
  iterator: (category: CourseCategory, index: number) => void
) => {
  let i = 0;
  Object.keys(courseCategoryMap).forEach((topLevelCategory) => {
    return courseCategoryMap[
      topLevelCategory as keyof typeof courseCategoryMap
    ].forEach((category: CourseCategory) => {
      iterator(category, i);
      i += 1;
    });
  });
};

export const placeLessonSuggestionsAmongstLessons = (
  course: ICourse,
  newLessonSuggestions: ICourseSuggestion[],
  skipPlacingSuggestions: boolean
): LessonsAndSuggestionsMap => {
  const result: LessonsAndSuggestionsMap = {};
  const alreadyPlacedSuggestions: { [suggestionId: string]: boolean } = {};

  course.modules.forEach((module) => {
    result[module.id] = [];

    module.lessons.forEach((lesson) => {
      // Pace suggestions surrounding their corresponding lessonId
      const beforeLessonSuggestions: ICourseSuggestion[] = [];
      const afterLessonSuggestions: ICourseSuggestion[] = [];

      newLessonSuggestions.forEach((sugg) => {
        if (alreadyPlacedSuggestions[sugg.id] || skipPlacingSuggestions) {
          return;
        }

        // prevLessonId takes precedence of nextLessonId
        if (sugg.prevLessonId === lesson.id) {
          afterLessonSuggestions.push(sugg);
          alreadyPlacedSuggestions[sugg.id] = true;
        }

        if (sugg.nextLessonId === lesson.id) {
          beforeLessonSuggestions.push(sugg);
          alreadyPlacedSuggestions[sugg.id] = true;
        }
      });

      result[module.id] = result[module.id].concat([
        ...beforeLessonSuggestions,
        lesson,
        ...afterLessonSuggestions,
      ]);
    });

    // Any leftover suggestions for this module are added to the end
    const endOfModuleSuggestions: ICourseSuggestion[] = [];
    newLessonSuggestions.forEach((sugg) => {
      if (alreadyPlacedSuggestions[sugg.id] || skipPlacingSuggestions) {
        return;
      }

      if (sugg.moduleId === module.id) {
        endOfModuleSuggestions.push(sugg);
        alreadyPlacedSuggestions[sugg.id] = true;
      }
    });
    result[module.id] = result[module.id].concat(endOfModuleSuggestions);
  });

  return result;
};

export const placeLessonInModule = (
  newLesson: ILesson,
  currentModule: IModule,
  prevLessonId?: string,
  nextLessonId?: string
): IModule => {
  let hasPlacedNewLesson = false;
  const updatedModule = { ...currentModule };

  currentModule.lessons.forEach((existingLesson, existingLessonId) => {
    if (hasPlacedNewLesson) {
      return;
    }

    if (existingLesson.id === prevLessonId) {
      hasPlacedNewLesson = true;
      const lessonsBefore = currentModule.lessons.slice(
        0,
        existingLessonId + 1
      );
      const lessonsAfter = currentModule.lessons.slice(
        existingLessonId + 1,
        currentModule.lessons.length
      );
      updatedModule.lessons = lessonsBefore
        .concat([newLesson])
        .concat(lessonsAfter);
    }

    if (existingLesson.id === nextLessonId) {
      hasPlacedNewLesson = true;
      const lessonsBefore = currentModule.lessons.slice(0, existingLessonId);
      const lessonsAfter = currentModule.lessons.slice(
        existingLessonId,
        currentModule.lessons.length
      );
      updatedModule.lessons = lessonsBefore
        .concat([newLesson])
        .concat(lessonsAfter);
    }
  });

  if (!hasPlacedNewLesson) {
    updatedModule.lessons.push(newLesson);
  }

  return updatedModule;
};
