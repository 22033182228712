import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { MetadataType, NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { listCourseCommentsQueryKey } from "../queries/useListCourseCommentsQuery";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";
import { useGetCourseCommentFetch } from "../queries/useGetCourseCommentQuery";

export const useCreateCourseCommentMutation = (courseId: string) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const fetchCourse = useGetCourseFetch();
  const fetchCourseComment = useGetCourseCommentFetch();
  const createCourseComment = async (vars: {
    comment: string;
    metadataType: MetadataType;
    metadataId: string;
    replyToId?: string;
  }) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateCourseCommentMutation, error - no user found"
      );
    }
    const { comment, metadataType, metadataId, replyToId } = vars;
    const newComment = {
      comment,
      courseId,
      metadataType,
      metadataId,
      upvotes: 0,
      owner: user.uid,
      createdAt: serverTimestamp(),
    };
    if (replyToId) {
      (newComment as any).replyToId = replyToId;
    }
    const db = getFirestore();
    const courseCommentsCollectionRef = collection(
      db,
      collectionPaths.courseComments
    );
    const newCourseCommentDoc = await addDoc(
      courseCommentsCollectionRef,
      newComment
    );
    const courseData = await fetchCourse({ courseId });
    let alreadyNotifiedRepliedToUser: string | undefined;
    if (replyToId) {
      const repliedToComment = await fetchCourseComment({
        courseCommentId: replyToId,
      });
      if (repliedToComment && repliedToComment.owner !== user.uid) {
        alreadyNotifiedRepliedToUser = repliedToComment.owner;
        createNotification({
          type: NotificationType.CourseCommentReply,
          userId: repliedToComment.owner,
          notificationData: {
            courseCommentId: newCourseCommentDoc.id,
          },
        });
      }
    }
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid && owner !== alreadyNotifiedRepliedToUser) {
          createNotification({
            type: NotificationType.CourseComment,
            userId: owner,
            notificationData: {
              courseCommentId: newCourseCommentDoc.id,
            },
          });
        }
      });
    }
  };
  return useMutation(createCourseComment, {
    onSettled: settledLogger("useCreateCourseCommentMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listCourseCommentsQueryKey({
          courseId,
        }),
        { refetchInactive: true }
      );
    },
  });
};
