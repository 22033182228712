import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetLessonExerciseQuery } from "../../firestore/queries/useGetLessonExerciseQuery";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { getLessonFromCourse } from "../../utils/utils";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemLessonExerciseContainerProps {
  notification: INotification;
}

export const NotificationItemLessonExerciseContainer: React.FunctionComponent<
  INotificationItemLessonExerciseContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: lessonExercise,
    isLoading: isLessonExerciseLoading,
    isError: isLessonExerciseError,
  } = useGetLessonExerciseQuery({
    lessonExerciseId: notification.lessonExerciseId || "",
  });
  const courseIdToQuery = lessonExercise?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isLessonExerciseError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.LessonExercise}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (
    isLessonExerciseLoading ||
    isCourseLoading ||
    !lessonExercise ||
    !course
  ) {
    return <GenericLoader />;
  }

  const title = `Exercise`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  const lessonWithExercise = getLessonFromCourse(
    lessonExercise.lessonId,
    course
  );
  infoPoints.push({
    boldedPrefix: "For this Link:",
    text: lessonWithExercise?.title || "Unknown",
  });
  const onNavToContent = async () => {
    nav.goToPaths.inLesson(lessonExercise.courseId, lessonExercise.lessonId, {
      lessonExerciseId: lessonExercise.id,
    });
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };
  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={lessonExercise.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
