import { notification } from "antd";
import React, { useState } from "react";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { firebaseAuthErrorWithNotify } from "../../utils/validInputUtils";
import { GenericLoader } from "../common/GenericLoader";
import { VerifyEmail } from "./VerifyEmail";
import { VerifyEmailSuccess } from "./VerifyEmailSuccess";

interface IVerifyEmailContainerProps {}

export const VerifyEmailContainer: React.FunctionComponent<
  IVerifyEmailContainerProps
> = (props) => {
  const { verifyEmail, user } = useUserAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessState, setIsSuccessState] = useState<boolean>(false);
  const onVerifyEmail = async () => {
    setIsLoading(true);
    try {
      await verifyEmail();
      notification.success({ message: "Verification email sent" });
      setIsSuccessState(true);
      setIsLoading(false);
    } catch (error: any) {
      firebaseAuthErrorWithNotify(error);
      setIsLoading(false);
    }
  };

  const userEmail = user?.email;
  if (!userEmail || isLoading) {
    return <GenericLoader />;
  }

  return isSuccessState ? (
    <VerifyEmailSuccess userEmail={userEmail} />
  ) : (
    <VerifyEmail onVerifyEmail={onVerifyEmail} userEmail={userEmail} />
  );
};
