import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertLessonExerciseSnapToILessonExercise } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getLessonExerciseQueryKey = (lessonExerciseId: string) => [
  "getLessonExerciseQueryKey",
  lessonExerciseId,
];

interface IGetLessonExerciseArgs {
  lessonExerciseId: string;
}

const getLessonExercise = async (vars: IGetLessonExerciseArgs) => {
  const { lessonExerciseId } = vars;
  const db = getFirestore();
  const lessonExerciseDocRef = doc(
    db,
    collectionPaths.lessonExercises,
    lessonExerciseId
  );
  const snapshot = await getDoc(lessonExerciseDocRef);
  if (snapshot.exists()) {
    return convertLessonExerciseSnapToILessonExercise(
      lessonExerciseId,
      snapshot.data()
    );
  } else {
    return Promise.reject("getLessonExercise, lessonExerciseId not found");
  }
};

export const useGetLessonExerciseQuery = (
  vars: IGetLessonExerciseArgs,
  enabled: boolean = true
) => {
  const { lessonExerciseId } = vars;
  return useQuery(
    getLessonExerciseQueryKey(lessonExerciseId),
    () => getLessonExercise(vars),
    {
      onSettled: settledLogger("useGetLessonExerciseQuery"),
      enabled,
    }
  );
};

export const useGetLessonExerciseFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetLessonExerciseArgs) => {
    const { lessonExerciseId } = vars;
    return queryClient.fetchQuery(
      getLessonExerciseQueryKey(lessonExerciseId),
      () => getLessonExercise(vars)
    );
  };
};
