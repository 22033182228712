import { Space } from "antd";
import React from "react";
import { RichTextRenderer } from "../../../common/RichTextRenderer";

interface ICourseSuggestionGeneralFeedbackProps {
  reason: string;
}

export const CourseSuggestionGeneralFeedback: React.FunctionComponent<
  ICourseSuggestionGeneralFeedbackProps
> = (props) => {
  const { reason } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <RichTextRenderer htmlString={reason} />
    </Space>
  );
};
