import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery } from "react-query";
import {
  convertCourseFavoriteSnapToICourseFavorite,
  ICourseFavorite,
} from "../../types/firestoreMappings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateCourseFavoriteId } from "../idHelpers";
import { settledLogger } from "../settledLogger";

interface IGetCourseFavoriteArgs {
  courseId: string;
}

interface IGetCourseFavoriteKeyArgs extends IGetCourseFavoriteArgs {
  isSignedIn: boolean;
}

export const getCourseFavoriteQueryKey = (
  params: IGetCourseFavoriteKeyArgs
) => ["getCourseFavoriteQueryKey", params];

const generateNonExistentCourseFavorite = (): ICourseFavorite => {
  return {
    id: "id",
    courseId: "courseId",
    owner: "owner",
    createdAt: new Date(),
    doesNotExist: true,
  };
};

export const useGetCourseFavoriteQuery = (
  vars: IGetCourseFavoriteArgs,
  enabled: boolean = true
) => {
  const { courseId } = vars;
  const { isSignedIn } = useUserAuth();
  const courseFavoriteId = useGenerateCourseFavoriteId(courseId);
  const getCourseFavorite = async () => {
    if (!isSignedIn) {
      return Promise.resolve(generateNonExistentCourseFavorite());
    }
    if (!courseFavoriteId) {
      return Promise.reject(
        `getCourseFavorite, courseFavoriteId not properly generated`
      );
    }
    const db = getFirestore();
    const upvoteDocRef = doc(
      db,
      collectionPaths.courseFavorites,
      courseFavoriteId
    );
    const snapshot = await getDoc(upvoteDocRef);
    if (snapshot.exists()) {
      return convertCourseFavoriteSnapToICourseFavorite(
        courseFavoriteId,
        snapshot.data()
      );
    } else {
      return Promise.resolve(generateNonExistentCourseFavorite());
    }
  };
  return useQuery(
    getCourseFavoriteQueryKey({ courseId, isSignedIn }),
    () => getCourseFavorite(),
    {
      onSettled: settledLogger("useGetCourseFavoriteQuery"),
      enabled,
    }
  );
};
