import { Card, Space, Typography } from "antd";
import React from "react";
import { fontSizes, themeColors } from "../../../../styling/styleConstants";
import { ICourseSuggestion } from "../../../../types/firestoreMappings";
import { MetadataType } from "../../../../types/helperTypes";
import { RichTextRenderer } from "../../../common/RichTextRenderer";
import { UpvoteButtonContainer } from "../../../upvotes/UpvoteButtonContainer";

interface ICourseSuggestionNewModuleProps {
  suggestion: ICourseSuggestion;
  moduleTitle: string;
  reason: string;
}

export const CourseSuggestionNewModule: React.FunctionComponent<
  ICourseSuggestionNewModuleProps
> = (props) => {
  const { suggestion, moduleTitle, reason } = props;

  return (
    <Space direction={"vertical"}>
      <Space direction={"horizontal"}>
        <UpvoteButtonContainer
          upvoteCount={suggestion.upvotes}
          metadataId={suggestion.id}
          metadataType={MetadataType.CourseSuggestion}
        />
        <Card
          style={{ backgroundColor: themeColors.previewImageUrlBackground }}
          size={"small"}
        >
          <Typography style={{ fontSize: fontSizes.small }}>
            {"Suggested module's title:"}
          </Typography>
          <Typography.Text strong={true}>{moduleTitle}</Typography.Text>
        </Card>
      </Space>
      <RichTextRenderer htmlString={reason} />
    </Space>
  );
};
