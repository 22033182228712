import React from "react";
import {
  ListCoursesQueryParams,
  useListCoursesQuery,
} from "../../../firestore/queries/useListCoursesQuery";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { Courses } from "./Courses";

interface ICoursesContainerProps {
  listCoursesQueryParams: ListCoursesQueryParams;
  shouldNavWithReviewMode?: boolean;
  shouldNavWithHighlightNewSuggestion?: boolean;
}

export const CoursesContainer: React.FunctionComponent<
  ICoursesContainerProps
> = (props) => {
  const {
    listCoursesQueryParams,
    shouldNavWithReviewMode,
    shouldNavWithHighlightNewSuggestion,
  } = props;
  const {
    data: courses,
    isLoading: isCoursesLoading,
    isError: isCoursesError,
    fetchNextPage: fetchMoreCourses,
    isNoMoreToFetch: isNoMoreCoursesToFetch,
  } = useListCoursesQuery(listCoursesQueryParams);

  if (isCoursesError) {
    return <GenericError />;
  }

  if (isCoursesLoading || !courses) {
    return <GenericLoader />;
  }

  return (
    <Courses
      courses={courses}
      fetchMoreCourses={fetchMoreCourses}
      isNoMoreCoursesToFetch={isNoMoreCoursesToFetch}
      shouldNavWithReviewMode={shouldNavWithReviewMode}
      shouldNavWithHighlightNewSuggestion={shouldNavWithHighlightNewSuggestion}
    />
  );
};
