import { notification } from "antd";
import React from "react";
import { useCreateCourseReviewRejectionMutation } from "../../firestore/mutations/useCreateCourseReviewRejectionMutation";
import { useUpdateCoursePublishStateMutation } from "../../firestore/mutations/useUpdateCoursePublishState";
import { locStrings } from "../../localization/locStrings";
import { CoursePublishState } from "../../types/helperTypes";
import { isValidCourseReviewRejectionInputWithErrorNotify } from "../../utils/validInputUtils";
import { GenericLoader } from "../common/GenericLoader";
import { CourseInfoReview } from "./CourseInfoReview";

interface ICourseInfoReviewContainerProps {
  courseId: string;
  publishState: CoursePublishState;
}

export const CourseInfoReviewContainer: React.FunctionComponent<
  ICourseInfoReviewContainerProps
> = (props) => {
  const { courseId, publishState } = props;
  const { mutate: updatePublishState, isLoading: isUpdatePublishStateLoading } =
    useUpdateCoursePublishStateMutation(courseId);
  const {
    mutate: createReviewRejection,
    isLoading: isCreateReviewRejectionLoading,
  } = useCreateCourseReviewRejectionMutation(courseId);
  const onReject = async (rejectionReason: string) => {
    const isValid =
      isValidCourseReviewRejectionInputWithErrorNotify().forCreate({
        rejectionReason,
      });

    if (isValid) {
      try {
        await updatePublishState({ publishState: CoursePublishState.Draft });
        await createReviewRejection(rejectionReason);
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
  };
  const onPublish = () => {
    try {
      updatePublishState({ publishState: CoursePublishState.Published });
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };

  if (isUpdatePublishStateLoading || isCreateReviewRejectionLoading) {
    return <GenericLoader />;
  }

  return (
    <CourseInfoReview
      publishState={publishState}
      onReject={onReject}
      onPublish={onPublish}
    />
  );
};
