import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { listLessonExercisesQueryKey } from "../queries/useListLessonExercisesQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateLessonExerciseMutation = (
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const getCourse = useGetCourseFetch();
  const createLessonExercise = async (exercise: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateLessonExerciseMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonExercisesCollectionRef = collection(
      db,
      collectionPaths.lessonExercises
    );
    const newLessonExerciseDoc = await addDoc(lessonExercisesCollectionRef, {
      exercise,
      courseId,
      lessonId,
      upvotes: 0,
      owner: user.uid,
      createdAt: serverTimestamp(),
    });
    const courseData = await getCourse({ courseId });
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid) {
          createNotification({
            type: NotificationType.LessonExercise,
            userId: owner,
            notificationData: {
              lessonExerciseId: newLessonExerciseDoc.id,
            },
          });
        }
      });
    }
  };
  return useMutation(createLessonExercise, {
    onSettled: settledLogger("useCreateLessonExerciseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonExercisesQueryKey({
          courseId,
          lessonId,
        }),
        { refetchInactive: true }
      );
    },
  });
};
