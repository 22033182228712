import React from "react";
import { useIsMeUserReviewer } from "../../ui-hooks/useIsMeUserReviewer";
import { ReviewQueue } from "./ReviewQueue";
import { ReviewQueueForbidden } from "./ReviewQueueForbidden";

interface IReviewQueueContainerProps {}

export const ReviewQueueContainer: React.FunctionComponent<
  IReviewQueueContainerProps
> = (props) => {
  const isMeUserReviewer = useIsMeUserReviewer();

  if (!isMeUserReviewer) {
    return <ReviewQueueForbidden />;
  }

  return <ReviewQueue />;
};
