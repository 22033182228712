import React from "react";
import { locStrings } from "../../../localization/locStrings";
import { MetadataType } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { LessonExercisesContainer } from "../../exercises/LessonExercisesContainer";
import { InLessonDetailsSection } from "./InLessonDetailsSection";

interface IInLessonDetailsSectionExercisesContainerProps {
  courseId: string;
  lessonId: string;
}

export const InLessonDetailsSectionExercisesContainer: React.FunctionComponent<
  IInLessonDetailsSectionExercisesContainerProps
> = (props) => {
  const { courseId, lessonId } = props;
  const nav = useNavigation();
  const parsedLessonExerciseId = nav.urlSearchParams.getLessonExerciseId();

  return (
    <InLessonDetailsSection
      title={locStrings.exercises.value()}
      metadataType={MetadataType.LessonExercise}
    >
      <LessonExercisesContainer
        params={{
          courseId,
          lessonId,
          sortByUpvotes: true,
          topItemId: parsedLessonExerciseId,
        }}
        shouldSkipFetch={false}
      />
    </InLessonDetailsSection>
  );
};
