import { Typography } from "antd";
import moment from "moment";
import React from "react";
import { fontSizes } from "../../styling/styleConstants";

interface IDateAgoTextProps {
  date: Date;
  prefix?: string;
  suffix?: string;
}

export const DateAgoText: React.FunctionComponent<IDateAgoTextProps> = (
  props
) => {
  const { date, prefix, suffix } = props;

  let stringToRender = moment(date).fromNow();
  if (prefix) {
    stringToRender = `${prefix}${stringToRender}`;
  }
  if (suffix) {
    stringToRender = `${stringToRender}${suffix}`;
  }

  return (
    <Typography.Text
      style={{ fontSize: fontSizes.small, wordBreak: "break-all" }}
    >
      {stringToRender}
    </Typography.Text>
  );
};
