import { Alert, Space, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { ShareLinkButton } from "../common/ShareLinkButton";
import { baseUrlWithPath } from "../../ui-hooks/useNavigation";

interface ICourseCollaborateBoxProps {
  courseId: string;
}

export const CourseCollaborateBox: React.FunctionComponent<
  ICourseCollaborateBoxProps
> = (props) => {
  const { courseId } = props;

  return (
    <Alert
      type={"info"}
      message={"You don't have to build this course all by yourself!"}
      icon={<InfoCircleOutlined />}
      showIcon={true}
      closable={true}
      className={"fade-in"}
      description={
        <Space direction={"vertical"}>
          <Typography>
            {
              "Others can suggest new links and modules too, just share this course with them:"
            }
          </Typography>
          <ShareLinkButton
            modalTitle={"Share this course"}
            link={baseUrlWithPath.courseInfo(courseId)}
            buttonText={"Share this course"}
          />
        </Space>
      }
    />
  );
};
