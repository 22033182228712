import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listLessonAnswersQueryKey } from "../queries/useListLessonAnswersQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteLessonAnswerMutation = (
  lessonQuestionId: string,
  lessonAnswerId: string
) => {
  const queryClient = useQueryClient();
  const deleteLessonAnswer = () => {
    const db = getFirestore();
    const lessonAnswerDocRef = doc(
      db,
      collectionPaths.lessonAnswers,
      lessonAnswerId
    );
    return deleteDoc(lessonAnswerDocRef);
  };
  return useMutation(deleteLessonAnswer, {
    onSettled: settledLogger("useDeleteLessonAnswerMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAnswersQueryKey({ lessonQuestionId }),
        {
          refetchInactive: true,
        }
      );
    },
  });
};
