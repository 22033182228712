import { useGetCourseQuery } from "../firestore/queries/useGetCourseQuery";

export const useIsUserOwnerOfCourse = (userId: string, courseId: string) => {
  const { data: course } = useGetCourseQuery({ courseId }, !!courseId);

  if (!course) {
    return false;
  }

  return course.owners.includes(userId);
};
