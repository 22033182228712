import { useUserAuth } from "../ui-hooks/useUserAuth";

export const generateUniqueId = (): string => {
  const dateStr = Date.now().toString(36); // convert num to base 36 and stringify
  const randomStr = Math.random().toString(36).substring(2, 8); // start at index 2 to skip decimal point
  return `${dateStr}-${randomStr}`;
};

// id is a concatenation of metadataId + userId
export const useGenerateUpvoteId = (metadataId: string) => {
  const { user } = useUserAuth();
  if (user) {
    return metadataId + user.uid;
  } else {
    return undefined;
  }
};

// id is a concatenation of courseId + userId
export const useGenerateCourseFavoriteId = (courseId: string) => {
  const { user } = useUserAuth();
  if (user) {
    return courseId + user.uid;
  } else {
    return undefined;
  }
};
