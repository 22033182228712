import React from "react";
import { ICourseReviewRejection } from "../../types/firestoreMappings";
import { RichTextRenderer } from "../common/RichTextRenderer";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { themeColors } from "../../styling/styleConstants";
import { CardWithUserHeader } from "../common/CardWithUserHeader";

interface ICourseReviewRejectionItemProps {
  reviewRejection: ICourseReviewRejection;
}

export const CourseReviewRejectionItem: React.FunctionComponent<
  ICourseReviewRejectionItemProps
> = (props) => {
  const { reviewRejection } = props;
  const nav = useNavigation();
  const isHighlighted =
    nav.urlSearchParams.courseReviewRejectionId() === reviewRejection.id;

  return (
    <CardWithUserHeader
      userId={reviewRejection.owner}
      date={reviewRejection.createdAt}
      style={{
        backgroundColor: isHighlighted
          ? themeColors.highlightedNavCardBackgroundColor
          : "transparent",
      }}
    >
      <RichTextRenderer htmlString={reviewRejection.rejectionReason} />
    </CardWithUserHeader>
  );
};
