import { CourseCategory, CourseTopLevelCategory } from "../types/helperTypes";

export const courseCategoryMap = {
  [CourseTopLevelCategory.ArtsAndHumanities]: [
    CourseCategory.Music,
    CourseCategory.Painting,
    CourseCategory.Drawing,
    CourseCategory.Writing,
    CourseCategory.Philosophy,
    CourseCategory.GraphicDesign,
    CourseCategory.FilmAndTheatre,
    CourseCategory.LiteratureAndPoetry,
  ],
  [CourseTopLevelCategory.PhysicalScience]: [
    CourseCategory.Mathematics,
    CourseCategory.Chemistry,
    CourseCategory.Biology,
    CourseCategory.Physics,
    CourseCategory.MechanicalEngineering,
  ],
  [CourseTopLevelCategory.Culture]: [
    CourseCategory.PersonalDevelopment,
    CourseCategory.History,
    CourseCategory.Games,
    CourseCategory.Sports,
    CourseCategory.Religion,
    CourseCategory.FoodAndDrink,
  ],
  [CourseTopLevelCategory.Business]: [
    CourseCategory.Finance,
    CourseCategory.ProjectManagement,
    CourseCategory.Marketing,
    CourseCategory.RealEstate,
  ],
  [CourseTopLevelCategory.ComputerScience]: [
    CourseCategory.Coding,
    CourseCategory.Cybersecurity,
    CourseCategory.DataScience,
  ],
};
