import { Button, Dropdown, Menu } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import React from "react";
import { MetadataType } from "../../../types/helperTypes";
import { convertCourseMetadataTypeToIcon } from "../../../utils/iconAndStringUtils";
import { useIsScreenLargerThanSm } from "../../../ui-hooks/useIsScreenLargerThanSm";

interface ICourseToolBarSuggestionsButtonProps {
  isNoMoreNewLessonSuggestionsToFetch: boolean;
  fetchMoreNewLessonSuggestions: () => void;
  isSuggestionsVisible: boolean;
  toggleSuggestionsVisibility: () => void;
}

export const CourseToolBarSuggestionsButton: React.FunctionComponent<
  ICourseToolBarSuggestionsButtonProps
> = (props) => {
  const {
    isNoMoreNewLessonSuggestionsToFetch,
    fetchMoreNewLessonSuggestions,
    isSuggestionsVisible,
    toggleSuggestionsVisibility,
  } = props;
  const isScreenLargerThanSm = useIsScreenLargerThanSm();

  const toggleSuggestionsVisibilityOption = (
    <Menu.Item
      key={`CourseToolBarSuggestionsButton-ToggleVisibility`}
      onClick={toggleSuggestionsVisibility}
      icon={isSuggestionsVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
    >
      {isSuggestionsVisible ? "Hide suggestions" : "Show suggestions"}
    </Menu.Item>
  );

  const fetchMoreSuggestionsOption = (
    <Menu.Item
      key={`CourseToolBarSuggestionsButton-FetchMore`}
      onClick={fetchMoreNewLessonSuggestions}
      icon={<DownOutlined />}
    >
      {"Load more suggestions"}
    </Menu.Item>
  );

  const menuItems = [toggleSuggestionsVisibilityOption];
  if (!isNoMoreNewLessonSuggestionsToFetch) {
    menuItems.push(fetchMoreSuggestionsOption);
  }

  return (
    /* @ts-ignore */
    <Dropdown overlay={<Menu>{menuItems}</Menu>}>
      <Button
        icon={convertCourseMetadataTypeToIcon({
          metadataType: MetadataType.CourseSuggestion,
        })}
      >
        {isScreenLargerThanSm && "Suggestions"}
      </Button>
    </Dropdown>
  );
};
