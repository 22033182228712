import React from "react";
import { useDeleteLessonAnswerMutation } from "../../firestore/mutations/useDeleteLessonAnswerMutation";
import { useUpdateLessonAnswerMutation } from "../../firestore/mutations/useUpdateLessonAnswerMutation";
import { ILessonAnswer } from "../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useIsMeUserOwnerOfLessonAnswer } from "../../ui-hooks/useIsMeUserOwnerOfLessonAnswer";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { LessonAnswer } from "./LessonAnswer";

interface ILessonAnswerContainerProps {
  answer: ILessonAnswer;
}

export const LessonAnswerContainer: React.FunctionComponent<
  ILessonAnswerContainerProps
> = (props) => {
  const { answer } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const {
    mutate: updateAnswer,
    isLoading: isUpdateAnswerLoading,
    isError: isUpdateAnswerError,
  } = useUpdateLessonAnswerMutation(answer.lessonQuestionId, answer.id);
  const {
    mutate: deleteAnswer,
    isLoading: isDeleteAnswerLoading,
    isError: isDeleteAnswerError,
  } = useDeleteLessonAnswerMutation(answer.lessonQuestionId, answer.id);
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(answer.courseId);
  const isMeUserOwnerOfAnswer = useIsMeUserOwnerOfLessonAnswer(answer);
  const isEditable = isMeUserOwnerOfCourse || isMeUserOwnerOfAnswer;

  if (isUpdateAnswerError || isDeleteAnswerError) {
    return <GenericError />;
  }

  if (isUpdateAnswerLoading || isDeleteAnswerLoading) {
    return <GenericLoader />;
  }

  return (
    <LessonAnswer
      answer={answer}
      updateAnswer={
        isEditable ? forceSisuModalFxnWrapper(updateAnswer) : undefined
      }
      deleteAnswer={
        isEditable ? forceSisuModalFxnWrapper(deleteAnswer) : undefined
      }
    />
  );
};
