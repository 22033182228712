import React from "react";
import { useCountCourseCommentsQuery } from "../../../firestore/queries/useCountCourseCommentsQuery";
import { locStrings } from "../../../localization/locStrings";
import { MetadataType } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { CourseCommentsContainer } from "../../comments/CourseCommentsContainer";
import { CreateCourseCommentInputContainer } from "../../comments/write/CreateCourseCommentInputContainer";
import { InLessonDetailsSection } from "./InLessonDetailsSection";

interface IInLessonDetailsSectionCourseCommentsContainerProps {
  courseId: string;
  lessonId: string;
}

export const InLessonDetailsSectionCourseCommentsContainer: React.FunctionComponent<
  IInLessonDetailsSectionCourseCommentsContainerProps
> = (props) => {
  const { courseId, lessonId } = props;
  const nav = useNavigation();
  const parsedCourseCommentIdForLesson =
    nav.urlSearchParams.getCourseCommentIdForLesson();
  const { data: courseCommentsCount } = useCountCourseCommentsQuery({
    metadataId: lessonId,
  });

  return (
    <InLessonDetailsSection
      title={locStrings.courseCommentsTab.value()}
      metadataType={MetadataType.CourseComment}
      count={courseCommentsCount}
    >
      <>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <CreateCourseCommentInputContainer
            courseId={courseId}
            metadataType={MetadataType.Lesson}
            metadataId={lessonId}
          />
        </div>
        <CourseCommentsContainer
          params={{
            courseId,
            metadataType: MetadataType.Lesson,
            metadataId: lessonId,
            numItemsPerPage: 3,
            topItemId: parsedCourseCommentIdForLesson,
          }}
          shouldSkipFetch={false}
        />
      </>
    </InLessonDetailsSection>
  );
};
