import { Button, Input } from "antd";
import React, { useId } from "react";

interface IInputWithButtonProps {
  inputValue: string;
  inputPlaceholder: string;
  onInputChange: (s: string) => void;
  buttonChildren: string | JSX.Element;
  buttonWidthInPx: number;
  onSubmit: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const InputWithButton: React.FunctionComponent<IInputWithButtonProps> = (
  props
) => {
  const uniqueId = useId();
  const {
    inputValue,
    inputPlaceholder,
    onInputChange,
    buttonChildren,
    buttonWidthInPx,
    onSubmit,
    disabled,
    isLoading,
  } = props;
  return (
    <Input.Group compact={true}>
      <Input
        id={`InputWithButton-${uniqueId}`}
        type={"text"}
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        placeholder={inputPlaceholder}
        style={{ width: `calc(100% - ${buttonWidthInPx}px)` }}
      />
      <Button
        onClick={onSubmit}
        style={{ width: `${buttonWidthInPx}px` }}
        disabled={disabled}
        loading={isLoading}
      >
        {buttonChildren}
      </Button>
    </Input.Group>
  );
};
