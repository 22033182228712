import { debounce } from "lodash";
import { Input, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  WarningOutlined,
  CheckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetUserByUsernameQuery } from "../../firestore/queries/useGetUserByUsernameQuery";
import { themeColors } from "../../styling/styleConstants";
import { convertSisuInputTypeToIcon } from "../../utils/iconAndStringUtils";

export enum UsernameUniqueCheckState {
  Taken,
  Available,
  Loading,
}

interface IUsernameUniqueInputProps {
  value: string;
  onValueChange: (s: string) => void;
  onCheckStateChange: (checkState: UsernameUniqueCheckState) => void;
  hideCheckStateStatus?: boolean;
  style?: React.CSSProperties;
}

export const UsernameUniqueInput: React.FunctionComponent<
  IUsernameUniqueInputProps
> = (props) => {
  const {
    value,
    onValueChange,
    onCheckStateChange,
    hideCheckStateStatus,
    style,
  } = props;
  const [isDebounced, setIsDebounced] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<string>(value);
  const { data: existingUser, isLoading: isExistingUserLoading } =
    useGetUserByUsernameQuery({ username: internalValue }, !!internalValue);

  const isUsernameTaken = !!existingUser && existingUser.length > 0;
  const isCalculating = isDebounced || isExistingUserLoading;

  useEffect(() => {
    if (isCalculating) {
      onCheckStateChange(UsernameUniqueCheckState.Loading);
    } else {
      if (isUsernameTaken) {
        onCheckStateChange(UsernameUniqueCheckState.Taken);
      } else {
        onCheckStateChange(UsernameUniqueCheckState.Available);
      }
    }
  }, [isUsernameTaken, isCalculating, onCheckStateChange]);

  const onInternalValueChange = (s: string) => {
    setInternalValue(s);
    setIsDebounced(false);
  };
  const onInternalValueChangeDebounced = debounce(onInternalValueChange, 1000);
  const onInternalValueChangeDebouncedWithLoading = (s: string) => {
    setIsDebounced(true);
    onInternalValueChangeDebounced(s);
  };
  const onExternalValueChange = (s: string) => {
    onValueChange(s);
    onInternalValueChangeDebouncedWithLoading(s);
  };
  return (
    <Space direction={"vertical"}>
      <Input
        id={"UsernameUniqueInput"}
        type={"text"}
        value={value}
        onChange={(e) => onExternalValueChange(e.target.value)}
        placeholder={"Username"}
        prefix={convertSisuInputTypeToIcon("username")}
        style={style}
      />
      {!value || hideCheckStateStatus ? null : isCalculating ? (
        <LoadingOutlined rotate={1} />
      ) : isUsernameTaken ? (
        <Space direction={"horizontal"}>
          <WarningOutlined style={{ color: themeColors.errorRed }} />
          <Typography style={{ color: themeColors.errorRed }}>
            Username is taken
          </Typography>
        </Space>
      ) : (
        <Space direction={"horizontal"}>
          <CheckOutlined style={{ color: themeColors.successGreen }} />
          <Typography style={{ color: themeColors.successGreen }}>
            Username is available
          </Typography>
        </Space>
      )}
    </Space>
  );
};
