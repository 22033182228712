import { Space, Typography } from "antd";
import React from "react";
import { BackButton } from "./BackButton";

interface IPageTypographyProps {
  title: string | JSX.Element;
  level: PageTypographyLevel;
  isGoBackEnabled?: boolean;
  subTitle?: string | JSX.Element;
  style?: React.CSSProperties;
}

export enum PageTypographyLevel {
  PageTitle,
  PageSection,
}

export const PageTypography: React.FunctionComponent<IPageTypographyProps> = (
  props
) => {
  const { title, level, isGoBackEnabled, subTitle, style } = props;
  let typographyLevel: 1 | 2 | 3 | 4 | 5 = 1;
  switch (level) {
    case PageTypographyLevel.PageTitle:
      typographyLevel = 2;
      break;
    case PageTypographyLevel.PageSection:
      typographyLevel = 4;
      break;
  }
  return (
    <Space direction={"horizontal"} style={{ alignItems: "flex-start" }}>
      {isGoBackEnabled && (
        <BackButton style={{ paddingTop: "2px", paddingRight: "10px" }} />
      )}
      <div>
        <Typography.Title
          level={typographyLevel}
          style={{ margin: 0, wordBreak: "break-word", ...style }}
        >
          {title}
        </Typography.Title>
        {subTitle && (
          <Typography.Text
            type={"secondary"}
            style={{ wordBreak: "break-word", margin: 0 }}
          >
            {subTitle}
          </Typography.Text>
        )}
      </div>
    </Space>
  );
};
