import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetLessonAlternativeLinkQuery } from "../../firestore/queries/useGetLessonAlternativeLinkQuery";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { getLessonFromCourse } from "../../utils/utils";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemLessonAlternativeLinkContainerProps {
  notification: INotification;
}

export const NotificationItemLessonAlternativeLinkContainer: React.FunctionComponent<
  INotificationItemLessonAlternativeLinkContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: lessonAlternativeLink,
    isLoading: isLessonAlternativeLinkLoading,
    isError: isLessonAlternativeLinkError,
  } = useGetLessonAlternativeLinkQuery({
    lessonAlternativeLinkId: notification.lessonAlternativeLinkId || "",
  });
  const courseIdToQuery = lessonAlternativeLink?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isLessonAlternativeLinkError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.LessonAlternativeLink}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (
    isLessonAlternativeLinkLoading ||
    isCourseLoading ||
    !lessonAlternativeLink ||
    !course
  ) {
    return <GenericLoader />;
  }

  const title = `Alternative Link`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  const lessonWithAlternativeLink = getLessonFromCourse(
    lessonAlternativeLink.lessonId,
    course
  );
  infoPoints.push({
    boldedPrefix: "For this Link:",
    text: lessonWithAlternativeLink?.title || "Unknown",
  });
  const onNavToContent = async () => {
    nav.goToPaths.inLesson(
      lessonAlternativeLink.courseId,
      lessonAlternativeLink.lessonId,
      {
        lessonAlternativeLinkId: lessonAlternativeLink.id,
      }
    );
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };
  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={lessonAlternativeLink.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
