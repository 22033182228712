import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetCourseSuggestionQuery } from "../../firestore/queries/useGetCourseSuggestionQuery";
import { INotification } from "../../types/firestoreMappings";
import {
  CourseSuggestionType,
  NotificationType,
} from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemCourseSuggestionContainerProps {
  notification: INotification;
}

export const NotificationItemCourseSuggestionContainer: React.FunctionComponent<
  INotificationItemCourseSuggestionContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: courseSuggestion,
    isLoading: isCourseSuggestionLoading,
    isError: isCourseSuggestionError,
  } = useGetCourseSuggestionQuery({
    courseSuggestionId: notification.courseSuggestionId || "",
  });
  const courseIdToQuery = courseSuggestion?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isCourseSuggestionError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.CourseSuggestion}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (
    isCourseSuggestionLoading ||
    isCourseLoading ||
    !courseSuggestion ||
    !course
  ) {
    return <GenericLoader />;
  }

  let title = `Suggestion`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  switch (courseSuggestion.type) {
    case CourseSuggestionType.NewLesson:
      title += ": New Link";
      infoPoints.push({
        boldedPrefix: "Title:",
        text: courseSuggestion.lessonTitle || "Unknown",
      });
      break;
    case CourseSuggestionType.NewModule:
      title += ": New Module";
      infoPoints.push({
        boldedPrefix: "Title:",
        text: courseSuggestion.moduleTitle || "Unknown",
      });
      break;
    case CourseSuggestionType.GeneralFeedback:
      title += ": General Feedback";
      infoPoints.push({
        boldedPrefix: "Feedback:",
        text: courseSuggestion.reason,
      });
      break;
  }
  const onNavToContent = () => {
    nav.goToPaths.courseInfo(courseSuggestion.courseId, {
      courseSuggestionId: courseSuggestion.id,
    });
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };

  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={courseSuggestion.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
