import { Button, Tooltip } from "antd";
import React from "react";
import { zIndexPriorities } from "../../../styling/styleConstants";
import { ICourseToolBarItem } from "./CourseToolBarItems";

interface ICourseToolBarButtonProps {
  item: ICourseToolBarItem;
}

export const CourseToolBarButton: React.FunctionComponent<
  ICourseToolBarButtonProps
> = (props) => {
  const { item } = props;
  return (
    <Tooltip
      title={item.tooltipTitle}
      visible={!!item.tooltipTitle ? true : false}
      zIndex={zIndexPriorities.tooltipOutsideModal}
    >
      <Button
        onClick={item.onClick}
        icon={item.icon}
        className={item.className}
      >
        {item.title}
      </Button>
    </Tooltip>
  );
};
