import { ILessonAlternativeLink } from "../types/firestoreMappings";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserOwnerOfLessonAlternativeLink = (
  alternativeLink: ILessonAlternativeLink
) => {
  const { user } = useUserAuth();

  if (!user) {
    return false;
  }

  return alternativeLink.owner === user.uid;
};
