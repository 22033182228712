import { notification } from "antd";
import React from "react";
import { useUpdateCoursePublishStateMutation } from "../../../firestore/mutations/useUpdateCoursePublishState";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { locStrings } from "../../../localization/locStrings";
import { CoursePublishState } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { useSisuModal } from "../../../ui-hooks/useSisuModal";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseInfoPublishStateRequest } from "./CourseInfoPublishStateRequest";

interface ICourseInfoPublishStateRequestContainerProps {
  courseId: string;
}

export const CourseInfoPublishStateRequestContainer: React.FunctionComponent<
  ICourseInfoPublishStateRequestContainerProps
> = (props) => {
  const { courseId } = props;
  const nav = useNavigation();
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId });
  const { mutate: updatePublishState, isLoading: isUpdatePublishStateLoading } =
    useUpdateCoursePublishStateMutation(courseId);
  const onSetToInReview = () => {
    try {
      updatePublishState({ publishState: CoursePublishState.InReview });
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };
  const onSetToDraft = () => {
    try {
      updatePublishState({ publishState: CoursePublishState.Draft });
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };
  const onGoToReviewerRejectionFeedback = () => {
    nav.goToPaths.courseReviewRejections(courseId);
  };

  if (isCourseError) {
    return <GenericError />;
  }

  if (isCourseLoading || isUpdatePublishStateLoading || !course) {
    return <GenericLoader />;
  }

  return (
    <CourseInfoPublishStateRequest
      course={course}
      publishState={course.publishState}
      onSetToInReview={forceSisuModalFxnWrapper(onSetToInReview)}
      onSetToDraft={forceSisuModalFxnWrapper(onSetToDraft)}
      onGoToReviewerRejectionFeedback={onGoToReviewerRejectionFeedback}
    />
  );
};
