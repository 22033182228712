import { Button, Space } from "antd";
import Marquee from "react-fast-marquee";
import React from "react";
import { convertCourseCategoryToStringAndIcon } from "../../utils/iconAndStringUtils";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { iterateOverCourseCategories } from "../../utils/utils";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { CourseCategory } from "../../types/helperTypes";

interface IHHomeExploreSectionProps {}

export const HomeExploreSection: React.FunctionComponent<
  IHHomeExploreSectionProps
> = (props) => {
  const nav = useNavigation();
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <PageTypography
        title={"Explore by category"}
        level={PageTypographyLevel.PageSection}
      />
      <CategoriesMarquee />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={nav.goToPaths.explore}>{"See all categories"}</Button>
      </div>
    </Space>
  );
};

const CategoriesMarquee = () => {
  const nav = useNavigation();
  const firstRowCategoryItems: JSX.Element[] = [];
  const secondRowCategoryItems: JSX.Element[] = [];
  const numOfCategories = Object.keys(CourseCategory).length;
  const halfwayIndex = Math.round(numOfCategories / 2);
  iterateOverCourseCategories((category, index) => {
    const categoryStringAndIcon =
      convertCourseCategoryToStringAndIcon(category);
    const item = (
      <Button
        key={`HomeExploreSection-CategoryButton-${category}`}
        onClick={() => nav.goToPaths.courseCategory(category)}
        style={{ margin: "0.5rem" }}
      >
        <Space direction={"horizontal"}>
          <img
            alt={categoryStringAndIcon.string}
            src={categoryStringAndIcon.iconSrcString}
            style={{ height: "1.2rem", width: "auto" }}
          />
          {categoryStringAndIcon.string}
        </Space>
      </Button>
    );
    if (index < halfwayIndex) {
      firstRowCategoryItems.push(item);
    } else {
      secondRowCategoryItems.push(item);
    }
  });

  return (
    <>
      <Marquee
        style={{ maxWidth: "85vw" }}
        gradientWidth={25}
        direction={"right"}
        speed={30}
      >
        {firstRowCategoryItems}
      </Marquee>
      <Marquee
        style={{ maxWidth: "85vw" }}
        gradientWidth={25}
        direction={"left"}
        speed={20}
      >
        {secondRowCategoryItems}
      </Marquee>
    </>
  );
};
