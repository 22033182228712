import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listLessonExercisesQueryKey } from "../queries/useListLessonExercisesQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteLessonExerciseMutation = (
  exerciseId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const deleteLessonExercise = () => {
    const db = getFirestore();
    const exerciseDocRef = doc(db, collectionPaths.lessonExercises, exerciseId);
    return deleteDoc(exerciseDocRef);
  };
  return useMutation(deleteLessonExercise, {
    onSettled: settledLogger("useDeleteLessonExerciseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonExercisesQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
          refetchPage: (page, index) => true,
        }
      );
    },
  });
};
