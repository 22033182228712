import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetLessonQuestionFetch } from "../queries/useGetLessonQuestionQuery";
import { listLessonAnswersQueryKey } from "../queries/useListLessonAnswersQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateLessonAnswerMutation = (
  lessonQuestionId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const getLessonQuestion = useGetLessonQuestionFetch();
  const createLessonAnswer = async (answer: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateLessonAnswerMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonAnswersCollectionRef = collection(
      db,
      collectionPaths.lessonAnswers
    );
    const newLessonAnswerDoc = await addDoc(lessonAnswersCollectionRef, {
      answer,
      lessonQuestionId,
      courseId,
      lessonId,
      upvotes: 0,
      owner: user.uid,
      createdAt: serverTimestamp(),
    });
    const lessonQuestion = await getLessonQuestion({ lessonQuestionId });
    if (lessonQuestion) {
      if (lessonQuestion.owner !== user.uid) {
        createNotification({
          type: NotificationType.LessonAnswer,
          userId: lessonQuestion.owner,
          notificationData: {
            lessonAnswerId: newLessonAnswerDoc.id,
          },
        });
      }
    }
  };
  return useMutation(createLessonAnswer, {
    onSettled: settledLogger("useCreateLessonAnswerMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAnswersQueryKey({
          lessonQuestionId,
          courseId,
          lessonId,
        }),
        { refetchInactive: true }
      );
    },
  });
};
