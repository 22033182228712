import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { ILessonQuestion } from "../../types/firestoreMappings";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { LessonQuestionContainer } from "../questions-answers/LessonQuestionContainer";

interface ILessonQuestionsProps {
  questions: ILessonQuestion[];
  isNoMoreQuestionsToFetch: boolean;
  fetchMoreQuestions: () => void;
}

export const LessonQuestions: React.FunctionComponent<ILessonQuestionsProps> = (
  props
) => {
  const { questions, isNoMoreQuestionsToFetch, fetchMoreQuestions } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {questions.length === 0 && (
        <Typography.Text italic={true}>
          {"No questions have been asked yet"}
        </Typography.Text>
      )}
      {questions.map((question) => {
        return (
          <LessonQuestionContainer
            question={question}
            key={`LessonQuestions-QuestionCard-${question.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreQuestions.value()}
        isNoMoreToFetch={isNoMoreQuestionsToFetch}
        fetchMore={fetchMoreQuestions}
      />
    </Space>
  );
};
