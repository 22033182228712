import { notification } from "antd";
import React, { useState } from "react";
import { useUpdateUserMutation } from "../../firestore/mutations/useUpdateUserMutation";
import { locStrings } from "../../localization/locStrings";
import { IUser } from "../../types/firestoreMappings";
import { isValidUserInputWithErrorNotify } from "../../utils/validInputUtils";
import { GenericLoader } from "../common/GenericLoader";
import { UsernameUniqueCheckState } from "../common/UsernameUniqueInput";
import { SettingsUsernameEdit } from "./SettingsUsernameEdit";

interface ISettingsUsernameEditContainerProps {
  meUser: IUser;
}

export const SettingsUsernameEditContainer: React.FunctionComponent<
  ISettingsUsernameEditContainerProps
> = (props) => {
  const { meUser } = props;
  const [username, setUsername] = useState<string>(meUser.username);
  const [usernameUniqueCheckState, setUsernameUniqueCheckState] =
    useState<UsernameUniqueCheckState>(UsernameUniqueCheckState.Available);
  const { mutate: updateUser, isLoading: isUpdateUserLoading } =
    useUpdateUserMutation(meUser.id);

  const onUpdateUsername = () => {
    try {
      const isValid = isValidUserInputWithErrorNotify().forUpdate({ username });
      if (isValid) {
        updateUser({ username });
      } else {
        console.error(
          "SettingsUsernameEditContainer.onUpdateUsername, not valid"
        );
      }
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };

  if (isUpdateUserLoading) {
    return <GenericLoader />;
  }

  return (
    <SettingsUsernameEdit
      meUser={meUser}
      username={username}
      setUsername={setUsername}
      usernameUniqueCheckState={usernameUniqueCheckState}
      setUsernameUniqueCheckState={setUsernameUniqueCheckState}
      onSubmit={onUpdateUsername}
    />
  );
};
