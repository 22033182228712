import { arrayUnion } from "firebase/firestore";
import { IModule } from "../../types/firestoreMappings";
import { generateUniqueId } from "../idHelpers";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useCreateModuleMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  let newModuleId = "";
  const createModule = async (vars: {
    title: string;
    description?: string;
    onSuccess?: () => void;
    createdFromSuggestionId?: string;
  }) => {
    const { title, description, onSuccess, createdFromSuggestionId } = vars;
    const moduleId = generateUniqueId();
    const newModule: IModule = {
      id: moduleId,
      title,
      lessons: [],
    };
    if (description) {
      (newModule as any).description = description;
    }
    if (createdFromSuggestionId) {
      (newModule as any).createdFromSuggestionId = createdFromSuggestionId;
    }
    await updateCourseMutation.mutate(
      {
        modules: arrayUnion(newModule) as unknown as IModule[],
      },
      { onSuccess }
    );
    newModuleId = moduleId;
  };
  return {
    ...updateCourseMutation,
    mutate: createModule,
    data: { newModuleId },
  };
};
