import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { convertUserSnapToIUser } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const listUsersBySearchTermQueryKey = (searchTerm: string) => {
  return ["listUsersBySearchTermQueryKey", searchTerm];
};

export const useListUsersBySearchTermQuery = (
  searchTerm: string,
  enabled: boolean = true
) => {
  const listUsersBySearchTerm = async () => {
    const db = getFirestore();
    const coursesCollectionRef = collection(db, collectionPaths.users);
    const q = query(
      coursesCollectionRef,
      where("username", ">=", searchTerm),
      where("username", "<=", searchTerm + "\uf8ff"),
      limit(5)
    );
    const qSnapshot = await getDocs(q);
    return qSnapshot.docs.map((snapshot) =>
      convertUserSnapToIUser(snapshot.id, snapshot.data())
    );
  };
  return useQuery(
    listUsersBySearchTermQueryKey(searchTerm),
    listUsersBySearchTerm,
    {
      onSettled: settledLogger("useListUsersBySearchTermQuery"),
      enabled,
    }
  );
};
