import { Card, Space, Typography } from "antd";
import React, { useState } from "react";
import { LinkOutlined, FolderOutlined } from "@ant-design/icons";
import { ICourse } from "../../../types/firestoreMappings";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { ClickableWrapper } from "../../common/ClickableWrapper";
import { PublishStateTag } from "../../common/PublishStateTag";
import { calculateModuleAndLessonCountsInCourse } from "../../../utils/utils";
import { DateAgoText } from "../../common/DateAgoText";
import { CourseCategoryTag } from "../../common/CourseCategoryTag";
import { useIsScreenLargerThanMd } from "../../../ui-hooks/useIsScreenLargerThanMd";
import { RichTextRenderer } from "../../common/RichTextRenderer";

interface ICourseProps {
  course: ICourse;
  shouldNavWithReviewMode?: boolean;
  shouldNavWithHighlightNewSuggestion?: boolean;
}

// rem
const cardHeight = 15;

export const Course: React.FunctionComponent<ICourseProps> = (props) => {
  const {
    course,
    shouldNavWithReviewMode,
    shouldNavWithHighlightNewSuggestion,
  } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const nav = useNavigation();
  const isScreenLargerThanMd = useIsScreenLargerThanMd();

  const modulesAndLessonsCounts =
    calculateModuleAndLessonCountsInCourse(course);

  const onCourseClick = () => {
    nav.goToPaths.courseInfo(course.id, {
      isReviewMode: shouldNavWithReviewMode ? "true" : undefined,
      highlightNewSuggestion: shouldNavWithHighlightNewSuggestion
        ? "true"
        : undefined,
    });
  };

  const renderUpdatedAgo = (
    <DateAgoText date={course.updatedAt} prefix={"Last updated "} />
  );

  return (
    <ClickableWrapper onClick={onCourseClick} style={{ height: "100%" }}>
      <Card
        size={"small"}
        style={{
          height: "100%",
        }}
        className={"shadow-border"}
        onMouseEnter={
          isScreenLargerThanMd ? () => setIsHovered(true) : undefined
        }
        onMouseLeave={() => setIsHovered(false)}
      >
        <Space
          direction={"vertical"}
          style={{
            width: "100%",
            height: isScreenLargerThanMd ? `${cardHeight}rem` : undefined,
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography.Paragraph
              ellipsis={{ rows: 2 }}
              style={{ fontSize: "1.2rem" }}
            >
              {course.title}
            </Typography.Paragraph>
            {isHovered && (
              <RichTextRenderer
                htmlString={course.description}
                style={{
                  maxHeight: `${cardHeight - 4.5}rem`,
                  overflowY: "clip",
                }}
                className={"fade-in fade-to-white-at-bottom"}
              />
            )}
          </div>
          {!isHovered && (
            <Space direction={"vertical"} className={"fade-in"}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Space direction={"horizontal"}>
                  <FolderOutlined />
                  {`${modulesAndLessonsCounts.modulesCount} Modules`}
                </Space>
                <Space direction={"horizontal"}>
                  <LinkOutlined />
                  {`${modulesAndLessonsCounts.lessonsCount} Links`}
                </Space>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <PublishStateTag publishState={course.publishState} />
                {course.categories.map((category) => (
                  <CourseCategoryTag
                    key={`CourseItem-${course.id}-${category}`}
                    category={category}
                  />
                ))}
              </div>
              {renderUpdatedAgo}
            </Space>
          )}
        </Space>
      </Card>
    </ClickableWrapper>
  );
};
