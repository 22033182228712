import { Button, Input, Space } from "antd";
import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { locStrings } from "../../../localization/locStrings";
import {
  PAGE_SECTION_SPACING,
  themeColors,
} from "../../../styling/styleConstants";
import { ICourse } from "../../../types/firestoreMappings";
import { ConfirmButton } from "../../common/ConfirmButton";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { ModuleSelect } from "../../common/ModuleSelect";
import { WeblinkInput } from "../../common/WeblinkInput";
import { RichTextEditor } from "../../common/RichTextEditor";

interface ILessonEditProps {
  pageTitle: string;
  course: ICourse;
  selectedModuleId: string;
  lessonTitle: string;
  lessonWeblink: string;
  lessonDescription: string;
  onSelectedModuleIdChange: (moduleId: string) => void;
  onLessonTitleChange: (s: string) => void;
  onLessonWeblinkChange: (s: string) => void;
  onLessonDescriptionChange: (s: string) => void;
  onSubmit: () => Promise<void>;
  hideModuleSelection?: boolean;
  onDeleteLesson?: () => void;
  isLoading?: boolean;
}

export const LessonEdit: React.FunctionComponent<ILessonEditProps> = (
  props
) => {
  const {
    pageTitle,
    course,
    selectedModuleId,
    lessonTitle,
    lessonWeblink,
    lessonDescription,
    onSelectedModuleIdChange,
    onLessonTitleChange,
    onLessonWeblinkChange,
    onLessonDescriptionChange,
    onSubmit,
    hideModuleSelection,
    onDeleteLesson,
    isLoading,
  } = props;
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <PageTypography
            title={pageTitle}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
            style={{ marginBottom: PAGE_SECTION_SPACING }}
          />
          {!!onDeleteLesson && (
            <ConfirmButton
              onConfirm={onDeleteLesson}
              text={locStrings.delete.value()}
              type={"default"}
              icon={<DeleteOutlined />}
              style={{ color: themeColors.errorRed }}
            />
          )}
        </div>
        {!hideModuleSelection && (
          <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
            <PageTypography
              title={locStrings.lessonModuleSelect.value()}
              level={PageTypographyLevel.PageSection}
            />
            <ModuleSelect
              modules={course.modules}
              selectedModuleId={selectedModuleId}
              onChange={onSelectedModuleIdChange}
            />
          </div>
        )}
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={locStrings.lessonTitle.value()}
            level={PageTypographyLevel.PageSection}
          />
          <Input
            id={"LessonEdit-title"}
            type={"text"}
            placeholder={locStrings.lessonTitlePlacerholder.value()}
            value={lessonTitle}
            onChange={(e) => onLessonTitleChange(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={locStrings.lessonWeblink.value()}
            level={PageTypographyLevel.PageSection}
          />
          <WeblinkInput
            weblink={lessonWeblink}
            onChange={onLessonWeblinkChange}
          />
        </div>
        <div>
          <PageTypography
            title={locStrings.lessonDescription.value()}
            level={PageTypographyLevel.PageSection}
          />
          <RichTextEditor
            initialValue={lessonDescription}
            onHtmlStringChange={onLessonDescriptionChange}
            placeholder={locStrings.lessonDescriptionPlacerholder.value()}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onSubmit} type={"primary"} loading={isLoading}>
            {locStrings.submit.value()}
          </Button>
        </div>
      </Space>
    </LayoutContent>
  );
};
