import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertLessonQuestionSnapToILessonQuestion,
  ILessonQuestion,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { useGetLessonQuestionFetch } from "./useGetLessonQuestionQuery";

export interface ListLessonQuestionsQueryParams {
  courseId?: string;
  lessonId?: string;
  sortByUpvotes?: boolean;
  topItemId?: string | null;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 3;

export const listLessonQuestionsQueryKey = (
  params: ListLessonQuestionsQueryParams
) => {
  return ["listLessonQuestionsQueryKey", params];
};

export const useListLessonQuestionsQuery = (
  params: ListLessonQuestionsQueryParams,
  enabled: boolean = true
) => {
  const fetchLessonQuestion = useGetLessonQuestionFetch();
  const listLessonQuestions = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ILessonQuestion[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const { courseId, lessonId, sortByUpvotes, topItemId, numItemsPerPage } =
      params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const lessonQuestionsCollectionRef = collection(
      db,
      collectionPaths.lessonQuestions
    );
    const queryConstraints = [];
    if (sortByUpvotes) {
      queryConstraints.push(orderBy("upvotes", "desc"));
    }
    // Always sort by createdAt by default
    queryConstraints.push(orderBy("createdAt", "desc"));
    if (courseId) {
      queryConstraints.push(where("courseId", "==", courseId));
    }
    if (lessonId) {
      queryConstraints.push(where("lessonId", "==", lessonId));
    }
    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      lessonQuestionsCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    let lessonQuestions = qSnapshot.docs.map((snapshot) =>
      convertLessonQuestionSnapToILessonQuestion(snapshot.id, snapshot.data())
    );

    if (lessonQuestions.length < numItemsPerPageToUse) {
      isLastPage = true;
    }

    if (topItemId) {
      // Filter out specified top item to avoid duplicates
      lessonQuestions = lessonQuestions.filter(
        (question) => question.id !== topItemId
      );
      const isFirstPage = !pageParam;
      if (isFirstPage) {
        // Only add specified top item if first page load, otherwise it duplicates
        const topQuestion = await fetchLessonQuestion({
          lessonQuestionId: topItemId,
        });
        lessonQuestions = [topQuestion].concat(lessonQuestions);
      }
    }

    return {
      realData: lessonQuestions,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listLessonQuestionsQueryKey(params),
    listLessonQuestions,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListLessonQuestionsQuery"),
      enabled,
    }
  );

  let realDataAllPages: ILessonQuestion[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }

  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
