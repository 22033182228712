import { Space } from "antd";
import React from "react";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { PAGE_SECTION_SPACING } from "../../../styling/styleConstants";
import { ICourse } from "../../../types/firestoreMappings";
import { CourseSuggestionsContainer } from "../read/CourseSuggestionsContainer";
import { useNavigation } from "../../../ui-hooks/useNavigation";

interface ICourseSuggestionsOverviewProps {
  course: ICourse;
}

export const CourseSuggestionsOverview: React.FunctionComponent<
  ICourseSuggestionsOverviewProps
> = (props) => {
  const { course } = props;
  const nav = useNavigation();
  const parsedCourseSuggestionId = nav.urlSearchParams.courseSuggestionId();

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={`All Suggestions`}
            subTitle={course.title}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
          />
        </div>
        <CourseSuggestionsContainer
          courseId={course.id}
          queryParams={{ topItemId: parsedCourseSuggestionId }}
        />
      </Space>
    </LayoutContent>
  );
};
