import React from "react";
import { useCountLessonAlternativeLinksQuery } from "../../../firestore/queries/useCountLessonAlternativeLinksQuery";
import { locStrings } from "../../../localization/locStrings";
import { MetadataType } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { LessonAlternativeLinksContainer } from "../../alternative-links/LessonAlternativeLinksContainer";
import { InLessonDetailsSection } from "./InLessonDetailsSection";

interface IInLessonDetailsSectionAlternativeLinksContainerProps {
  courseId: string;
  lessonId: string;
}

export const InLessonDetailsSectionAlternativeLinksContainer: React.FunctionComponent<
  IInLessonDetailsSectionAlternativeLinksContainerProps
> = (props) => {
  const { courseId, lessonId } = props;
  const nav = useNavigation();
  const parsedLessonAlternativeLinkId =
    nav.urlSearchParams.getLessonAlternativeLinkId();
  const { data: lessonAlternativeLinksCount } =
    useCountLessonAlternativeLinksQuery({ lessonId });

  return (
    <InLessonDetailsSection
      title={locStrings.alternativeLinksTab.value()}
      metadataType={MetadataType.AlternativeLink}
      count={lessonAlternativeLinksCount}
    >
      <LessonAlternativeLinksContainer
        params={{
          courseId,
          lessonId,
          sortByUpvotes: true,
          numItemsPerPage: 3,
          topItemId: parsedLessonAlternativeLinkId,
        }}
        shouldSkipFetch={false}
      />
    </InLessonDetailsSection>
  );
};
