import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useGetCourseFetch } from "../../firestore/queries/useGetCourseQuery";
import { useListCourseFavoritesQuery } from "../../firestore/queries/useListCourseFavoritesQuery";
import { ICourse } from "../../types/firestoreMappings";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { Courses } from "../course/course-list/Courses";

interface IFavoriteCoursesContainerProps {
  owner: string;
}

export const FavoriteCoursesContainer: React.FunctionComponent<
  IFavoriteCoursesContainerProps
> = (props) => {
  const { owner } = props;
  const [favoritedCourses, setFavoritedCourses] = useState<ICourse[]>([]);
  const fetchedFavoriteIdsRef = useRef<string[]>([]);
  const {
    data: courseFavorites,
    isLoading: isCourseFavoritesLoading,
    isError: isCourseFavoritesError,
    fetchNextPage: fetchMoreCourseFavorites,
    isNoMoreToFetch: isNoMoreCourseFavoritesToFetch,
  } = useListCourseFavoritesQuery({ owner });
  const getCourse = useGetCourseFetch();

  useEffect(() => {
    const allFavoriteIds = courseFavorites.map((fav) => fav.id);
    if (!_.isEqual(allFavoriteIds, fetchedFavoriteIdsRef.current)) {
      const courseFetchPromises = courseFavorites.map((favorite) => {
        return getCourse({ courseId: favorite.courseId });
      });
      Promise.all(courseFetchPromises).then((courses) => {
        setFavoritedCourses(courses);
      });
      fetchedFavoriteIdsRef.current = allFavoriteIds;
    }
  }, [courseFavorites, getCourse]);

  if (!courseFavorites || isCourseFavoritesLoading) {
    return <GenericLoader />;
  }

  if (isCourseFavoritesError) {
    return <GenericError />;
  }

  return (
    <Courses
      courses={favoritedCourses}
      fetchMoreCourses={fetchMoreCourseFavorites}
      isNoMoreCoursesToFetch={isNoMoreCourseFavoritesToFetch}
      emptyStateText={"No favorites found"}
      loadMoreText={"Load more favorites"}
    />
  );
};
