import { debounce, without } from "lodash";
import { Select, Space, Spin, Tag, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useListUsersBySearchTermQuery } from "../../firestore/queries/useListUsersBySearchTermQuery";
import { locStrings } from "../../localization/locStrings";
import { themeColors } from "../../styling/styleConstants";
import { UserItemContainer } from "./UserItemContainer";
import { useUserAuth } from "../../ui-hooks/useUserAuth";

interface IUserSelectProps {
  value: string[]; // uids
  onChange: (uid: string[]) => void;
  disableRemovalOfMyUser: boolean;
}

export const UserSelect: React.FunctionComponent<IUserSelectProps> = (
  props
) => {
  const { value, onChange, disableRemovalOfMyUser } = props;
  const { user } = useUserAuth();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDebounced, setIsDebounced] = useState<boolean>(false);
  const { data: searchedUsers, isLoading: isSearchLoading } =
    useListUsersBySearchTermQuery(searchTerm, !!searchTerm);

  const onSearchForUsers = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setIsDebounced(false);
  };
  const onSearchForUsersDebounced = debounce(onSearchForUsers, 1000);
  const onSearchForUsersDebouncedWithLoading = (searchTerm: string) => {
    setIsDebounced(true);
    onSearchForUsersDebounced(searchTerm);
  };
  const onRemoveUser = (id: string) => {
    const newUsersValue = without(value, id);
    onChange(newUsersValue);
  };

  const searchedUsersOptions: JSX.Element[] = [];
  if (searchedUsers) {
    searchedUsers.forEach((user) => {
      if (!value.includes(user.id)) {
        searchedUsersOptions.push(
          <Select.Option key={user.id} style={{ cursor: "pointer" }}>
            <UserItemContainer
              userId={user.id}
              preloadedUser={user}
              disableOnClick={true}
            />
          </Select.Option>
        );
      }
    });
  }

  const notFoundContent =
    isSearchLoading || isDebounced ? (
      <Spin />
    ) : searchTerm === "" ? (
      <Typography style={{ color: themeColors.secondaryFontColor }}>
        {locStrings.typeAUsernameCaseSensitive.value()}
      </Typography>
    ) : (
      <Typography style={{ color: themeColors.secondaryFontColor }}>
        {locStrings.noUsersFound.value()}
      </Typography>
    );

  return (
    <Select
      value={value}
      tagRender={(item) => (
        <Tag>
          <Space direction={"horizontal"}>
            <UserItemContainer userId={item.value} disableOnClick={true} />
            {disableRemovalOfMyUser && item.value === user?.uid ? null : (
              <CloseOutlined onClick={() => onRemoveUser(item.value)} />
            )}
          </Space>
        </Tag>
      )}
      onChange={onChange}
      mode={"multiple"}
      showSearch={true}
      filterOption={false}
      loading={isSearchLoading}
      autoClearSearchValue={true}
      onSearch={onSearchForUsersDebouncedWithLoading}
      style={{ width: "100%" }}
      notFoundContent={notFoundContent}
    >
      {searchedUsersOptions}
    </Select>
  );
};
