import React from "react";
import { useGetCourseCommentQuery } from "../../firestore/queries/useGetCourseCommentQuery";
import { ICourseComment } from "../../types/firestoreMappings";
import { CourseComment } from "./CourseComment";

interface ICourseCommentContainerProps {
  courseComment: ICourseComment;
}

export const CourseCommentContainer: React.FunctionComponent<
  ICourseCommentContainerProps
> = (props) => {
  const { courseComment } = props;
  const { data: repliedToComment } = useGetCourseCommentQuery(
    { courseCommentId: courseComment.replyToId || "" },
    !!courseComment.replyToId
  );

  return (
    <CourseComment
      courseComment={courseComment}
      repliedToComment={repliedToComment}
    />
  );
};
