import { Button, Space } from "antd";
import React from "react";
import { ICourse } from "../../types/firestoreMappings";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { calculateAdjacentLessonId } from "../../utils/utils";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { locStrings } from "../../localization/locStrings";

interface IInLessonNavButtonsProps {
  currentLessonId: string;
  course: ICourse;
}

export const InLessonNavButtons: React.FunctionComponent<
  IInLessonNavButtonsProps
> = (props) => {
  const { currentLessonId, course } = props;
  const nav = useNavigation();
  const nextLessonId = calculateAdjacentLessonId(true, currentLessonId, course);
  const prevLessonId = calculateAdjacentLessonId(
    false,
    currentLessonId,
    course
  );
  return (
    <Space direction={"horizontal"}>
      {!!prevLessonId && (
        <Button
          type={"text"}
          icon={<ArrowLeftOutlined />}
          onClick={() => nav.goToPaths.inLesson(course.id, prevLessonId)}
        >
          {locStrings.back.value()}
        </Button>
      )}
      {!!nextLessonId && (
        <Button
          type={"primary"}
          icon={<ArrowRightOutlined />}
          onClick={() => nav.goToPaths.inLesson(course.id, nextLessonId)}
        >
          {locStrings.next.value()}
        </Button>
      )}
    </Space>
  );
};
