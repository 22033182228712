import { Button, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { useNavigation } from "../../ui-hooks/useNavigation";

interface IGenericErrorProps {}

export const GenericError: React.FunctionComponent<IGenericErrorProps> = (
  props
) => {
  const nav = useNavigation();
  return (
    <div style={{ width: "100%", height: "100%", padding: "1rem" }}>
      <Typography>{locStrings.genericErrorMessage.value()}</Typography>
      <Button onClick={nav.goToPaths.home}>
        {locStrings.genericErrorBackToHome.value()}
      </Button>
    </div>
  );
};
