import { Button, Card, Space } from "antd";
import React from "react";
import {
  CourseCategory,
  CourseTopLevelCategory,
} from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { courseCategoryMap } from "../../utils/courseCategoryMap";
import { convertCourseCategoryToStringAndIcon } from "../../utils/iconAndStringUtils";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";

interface IExploreCourseCategoriesProps {}

export const ExploreCourseCategories: React.FunctionComponent<
  IExploreCourseCategoriesProps
> = (props) => {
  const nav = useNavigation();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {Object.keys(courseCategoryMap).map((topLevelCategory) => {
        const topLevelCategoryStringAndIcon =
          convertCourseCategoryToStringAndIcon(
            topLevelCategory as CourseTopLevelCategory
          );
        return (
          <Card
            size={"small"}
            key={`ExploreCourseCategories-TopLevelCard-${topLevelCategory}`}
          >
            <Space direction={"vertical"}>
              <PageTypography
                title={topLevelCategoryStringAndIcon.string}
                level={PageTypographyLevel.PageSection}
              />
              <div>
                {courseCategoryMap[
                  topLevelCategory as keyof typeof courseCategoryMap
                ].map((category: CourseCategory) => {
                  const categoryStringAndIcon =
                    convertCourseCategoryToStringAndIcon(category);
                  return (
                    <Button
                      key={`ExploreCourseCategories-CategoryButton-${category}`}
                      onClick={() => nav.goToPaths.courseCategory(category)}
                      style={{ margin: "0.5rem" }}
                    >
                      <Space direction={"horizontal"}>
                        <img
                          alt={categoryStringAndIcon.string}
                          src={categoryStringAndIcon.iconSrcString}
                          style={{ height: "1.2rem", width: "auto" }}
                        />
                        {categoryStringAndIcon.string}
                      </Space>
                    </Button>
                  );
                })}
              </div>
            </Space>
          </Card>
        );
      })}
    </Space>
  );
};
