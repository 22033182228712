import React from "react";
import { ICourseReviewRejection } from "../../types/firestoreMappings";
import { CourseReviewRejectionItem } from "./CourseReviewRejectionItem";

interface ICourseReviewRejectionItemContainerProps {
  reviewRejection: ICourseReviewRejection;
}

export const CourseReviewRejectionItemContainer: React.FunctionComponent<
  ICourseReviewRejectionItemContainerProps
> = (props) => {
  const { reviewRejection } = props;

  return <CourseReviewRejectionItem reviewRejection={reviewRejection} />;
};
