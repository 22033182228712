import React from "react";
import { Select } from "antd";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { GenericLoader } from "./GenericLoader";

interface ILessonSelectProps {
  courseId: string;
  placeholder: string;
  onChange: (lessonId: string) => void;
  selectedLessonId: string;
  style?: React.CSSProperties;
}

export const LessonSelect: React.FunctionComponent<ILessonSelectProps> = (
  props
) => {
  const { courseId, placeholder, onChange, selectedLessonId, style } = props;
  const { data: course, isLoading: isCourseLoading } = useGetCourseQuery({
    courseId,
  });

  if (isCourseLoading || !course) {
    return <GenericLoader />;
  }

  return (
    <Select
      value={selectedLessonId || null}
      onChange={onChange}
      placeholder={placeholder}
      style={{ display: "flex", ...style }}
    >
      {course.modules.map((module, moduleIndex) => {
        if (module.lessons.length > 0) {
          return (
            <Select.OptGroup
              key={`LessonSelect-ModuleGroup-${module.id}`}
              label={`${moduleIndex + 1}. ${module.title}`}
            >
              {module.lessons.map((lesson) => {
                return (
                  <Select.Option
                    key={`LessonSelect-LessonOption-${lesson.id}`}
                    value={lesson.id}
                  >
                    {lesson.title}
                  </Select.Option>
                );
              })}
            </Select.OptGroup>
          );
        } else {
          return null;
        }
      })}
    </Select>
  );
};
