import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../../localization/locStrings";
import { ICourse, ICourseSuggestion } from "../../../types/firestoreMappings";
import { CourseSuggestionType } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import {
  convertCourseMetadataTypeToIcon,
  convertCourseSuggestionTypeToString,
} from "../../../utils/iconAndStringUtils";
import { CardWithUserHeader } from "../../common/CardWithUserHeader";
import { CourseSuggestionStateDisplayContainer } from "./CourseSuggestionStateDisplayContainer";
import { CourseSuggestionGeneralFeedback } from "./general-feedback/CourseSuggestionGeneralFeedback";
import { CourseSuggestionNewLesson } from "./new-lesson/CourseSuggestionNewLesson";
import { CourseSuggestionNewModule } from "./new-module/CourseSuggestionNewModule";

interface ICourseSuggestionProps {
  course: ICourse;
  courseSuggestion: ICourseSuggestion;
}

export const CourseSuggestion: React.FunctionComponent<
  ICourseSuggestionProps
> = (props) => {
  const { course, courseSuggestion } = props;
  const nav = useNavigation();
  const parsedCourseSuggestionId = nav.urlSearchParams.courseSuggestionId();
  const wasNavigatedToAndShouldHighlight =
    parsedCourseSuggestionId === courseSuggestion.id;
  const icon = convertCourseMetadataTypeToIcon({
    courseSuggestionType: courseSuggestion.type,
  });
  const titleString = convertCourseSuggestionTypeToString(
    courseSuggestion.type
  );
  let renderSuggestionDetails = null;
  switch (courseSuggestion.type) {
    case CourseSuggestionType.NewLesson:
      renderSuggestionDetails = (
        <CourseSuggestionNewLesson
          course={course}
          suggestion={courseSuggestion}
          lessonTitle={
            courseSuggestion.lessonTitle ||
            locStrings.genericErrorMessage.value()
          }
          lessonWeblink={
            courseSuggestion.lessonWeblink ||
            locStrings.genericErrorMessage.value()
          }
          previewImageUrl={courseSuggestion.lessonPreviewImageUrl}
          moduleId={
            courseSuggestion.moduleId || locStrings.genericErrorMessage.value()
          }
          reason={courseSuggestion.reason}
        />
      );
      break;
    case CourseSuggestionType.NewModule:
      renderSuggestionDetails = (
        <CourseSuggestionNewModule
          suggestion={courseSuggestion}
          moduleTitle={
            courseSuggestion.moduleTitle ||
            locStrings.genericErrorMessage.value()
          }
          reason={courseSuggestion.reason}
        />
      );
      break;
    case CourseSuggestionType.GeneralFeedback:
      renderSuggestionDetails = (
        <CourseSuggestionGeneralFeedback reason={courseSuggestion.reason} />
      );
      break;
  }

  return (
    <CardWithUserHeader
      userId={courseSuggestion.owner}
      courseIdToCheckForOwnership={courseSuggestion.courseId}
      customElements={[
        <Space direction={"vertical"} style={{ alignItems: "flex-end" }}>
          <CourseSuggestionStateDisplayContainer
            courseSuggestion={courseSuggestion}
            buttonContent={"Add to course"}
          />
          <Space direction={"horizontal"}>
            {icon}
            <Typography>{titleString}</Typography>
          </Space>
        </Space>,
      ]}
      className={
        wasNavigatedToAndShouldHighlight
          ? "highlight-light-blue-and-fade"
          : undefined
      }
    >
      <div
        style={{
          width: "100%",
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "5px",
        }}
      ></div>
      {renderSuggestionDetails}
    </CardWithUserHeader>
  );
};
