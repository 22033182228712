import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertLessonAnswerSnapToILessonAnswer,
  ILessonAnswer,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { useGetLessonAnswerFetch } from "./useGetLessonAnswerQuery";

export interface ListLessonAnswersQueryParams {
  lessonQuestionId?: string;
  courseId?: string;
  lessonId?: string;
  sortByUpvotes?: boolean;
  topItemId?: string | null;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 3;

export const listLessonAnswersQueryKey = (
  params: ListLessonAnswersQueryParams
) => {
  return ["listLessonAnswersQueryKey", params];
};

export const useListLessonAnswersQuery = (
  params: ListLessonAnswersQueryParams,
  enabled: boolean = true
) => {
  const fetchLessonAnswer = useGetLessonAnswerFetch();
  const listLessonAnswers = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ILessonAnswer[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const {
      lessonQuestionId,
      courseId,
      lessonId,
      sortByUpvotes,
      topItemId,
      numItemsPerPage,
    } = params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const lessonAnswersCollectionRef = collection(
      db,
      collectionPaths.lessonAnswers
    );
    const queryConstraints = [];
    if (sortByUpvotes) {
      queryConstraints.push(orderBy("upvotes", "desc"));
    }
    // Always sort by createdAt by default
    queryConstraints.push(orderBy("createdAt", "asc"));
    if (lessonQuestionId) {
      queryConstraints.push(where("lessonQuestionId", "==", lessonQuestionId));
    }
    if (courseId) {
      queryConstraints.push(where("courseId", "==", courseId));
    }
    if (lessonId) {
      queryConstraints.push(where("lessonId", "==", lessonId));
    }
    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      lessonAnswersCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    let lessonAnswers = qSnapshot.docs.map((snapshot) =>
      convertLessonAnswerSnapToILessonAnswer(snapshot.id, snapshot.data())
    );

    if (lessonAnswers.length < numItemsPerPageToUse) {
      isLastPage = true;
    }

    // Only do top item logic if questionId is specified
    // Because if multiple lists of answers for multiple questionare on same screen,
    // we only want to insert this specified item in the correct question
    if (topItemId && lessonQuestionId) {
      const topAnswer = await fetchLessonAnswer({
        lessonAnswerId: topItemId,
      });
      if (topAnswer.lessonQuestionId === lessonQuestionId) {
        // Filter out specified top item to avoid duplicates
        lessonAnswers = lessonAnswers.filter(
          (answer) => answer.id !== topItemId
        );
        const isFirstPage = !pageParam;
        if (isFirstPage) {
          // Only add specified top item if first page load, otherwise it duplicates
          lessonAnswers = [topAnswer].concat(lessonAnswers);
        }
      }
    }

    return {
      realData: lessonAnswers,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listLessonAnswersQueryKey(params),
    listLessonAnswers,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListLessonAnswersQuery"),
      enabled,
    }
  );

  let realDataAllPages: ILessonAnswer[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }

  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
