import { Layout } from "antd";
import {
  PAGE_HORIZONTAL_SPACING_LARGER_THAN_MD,
  PAGE_HORIZONTAL_SPACING_SMALLER_THAN_MD,
  PAGE_MAX_WIDTH,
} from "../../styling/styleConstants";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";

interface ILayoutContentProps {
  children?: React.ReactNode;
}

const verticalPadding = "2rem";

export const LayoutContent: React.FunctionComponent<ILayoutContentProps> = (
  props
) => {
  const { children } = props;
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  const horizontalPadding = isScreenLargerThanMd
    ? PAGE_HORIZONTAL_SPACING_LARGER_THAN_MD
    : PAGE_HORIZONTAL_SPACING_SMALLER_THAN_MD;
  return (
    <Layout.Content
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          maxWidth: PAGE_MAX_WIDTH,
          paddingTop: verticalPadding,
          paddingBottom: verticalPadding,
          paddingLeft: horizontalPadding,
          paddingRight: horizontalPadding,
        }}
      >
        {children}
      </div>
    </Layout.Content>
  );
};
