import { EyeOutlined, FilterOutlined } from "@ant-design/icons";
import React from "react";
import { NotificationType } from "../../types/helperTypes";
import { featureFlags } from "../../utils/appConstants";
import {
  convertCourseMetadataTypeToIcon,
  convertNotificationTypeToPrimaryString,
} from "../../utils/iconAndStringUtils";
import { FilterDropdown } from "../common/FilterDropdown";

const typeFilterOptions = [
  NotificationType.CourseSuggestion,
  NotificationType.CourseReviewRejection,
  NotificationType.LessonAlternativeLink,
  NotificationType.CourseComment,
  NotificationType.CourseCommentReply,
  ...(featureFlags.enableLessonExercises
    ? [NotificationType.LessonExercise]
    : []),
  ...(featureFlags.enableLessonQuestionsAndAnswers
    ? [NotificationType.LessonQuestion]
    : []),
  ...(featureFlags.enableLessonQuestionsAndAnswers
    ? [NotificationType.LessonAnswer]
    : []),
];

export interface INotificationsFilterDropdownProps {
  isUnreadOnlyEnabled: boolean;
  toggleUnreadOnly: () => void;
  currentTypeFilter: NotificationType | undefined;
  onTypeFilterChange: (newFilter: NotificationType | undefined) => void;
  clearAllFilters: () => void;
}

export const NotificationsFilterDropdown: React.FunctionComponent<
  INotificationsFilterDropdownProps
> = (props) => {
  const {
    isUnreadOnlyEnabled,
    toggleUnreadOnly,
    currentTypeFilter,
    onTypeFilterChange,
    clearAllFilters,
  } = props;

  const filterSections = [
    {
      title: "Status",
      filterOptions: [
        {
          title: "Unread Only",
          icon: <EyeOutlined />,
          isSelected: isUnreadOnlyEnabled,
          isClearable: true,
          onClick: toggleUnreadOnly,
        },
      ],
    },
    {
      title: "Type",
      filterOptions: typeFilterOptions.map((type) => {
        return {
          title: convertNotificationTypeToPrimaryString(type),
          icon: convertCourseMetadataTypeToIcon({
            notificationType: type,
          }),
          isSelected: currentTypeFilter === type,
          isClearable: true,
          onClick: () => {
            if (currentTypeFilter === type) {
              onTypeFilterChange(undefined);
            } else {
              onTypeFilterChange(type);
            }
          },
        };
      }),
    },
  ];

  return (
    <FilterDropdown
      defaultTitle={"Filter"}
      defaultIcon={<FilterOutlined />}
      filterSections={filterSections}
      clearAllFilters={clearAllFilters}
    />
  );
};
