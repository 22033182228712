import { Button, Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { NotificationItemCourseCommentContainer } from "./NotificationItemCourseCommentContainer";
import { NotificationItemCourseReviewRejectionContainer } from "./NotificationItemCourseReviewRejectionContainer";
import { NotificationItemCourseSuggestionContainer } from "./NotificationItemCourseSuggestionContainer";
import { NotificationItemLessonAlternativeLinkContainer } from "./NotificationItemLessonAlternativeLinkContainer";
import { NotificationItemLessonAnswerContainer } from "./NotificationItemLessonAnswerContainer";
import { NotificationItemLessonExerciseContainer } from "./NotificationItemLessonExerciseContainer";
import { NotificationItemLessonQuestionContainer } from "./NotificationItemLessonQuestionContainer";
import {
  INotificationsFilterDropdownProps,
  NotificationsFilterDropdown,
} from "./NotificationsFilterDropdown";

interface INotificationsProps extends INotificationsFilterDropdownProps {
  notifications: INotification[];
  isNoMoreNotificationsToFetch: boolean;
  fetchMoreNotifications: () => void;
  isUnreadOnlyEnabled: boolean;
  toggleUnreadOnly: () => void;
  isAnyFilterApplied: boolean;
  clearAllFilters: () => void;
}

export const Notifications: React.FunctionComponent<INotificationsProps> = (
  props
) => {
  const {
    notifications,
    isNoMoreNotificationsToFetch,
    fetchMoreNotifications,
    isAnyFilterApplied,
    clearAllFilters,
  } = props;

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ display: "flex", flex: 1 }}>
        <PageTypography
          title={locStrings.yourNotifications.value()}
          level={PageTypographyLevel.PageTitle}
          style={{ marginBottom: PAGE_SECTION_SPACING }}
          isGoBackEnabled={true}
        />
        <Space
          direction={"horizontal"}
          style={{
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <NotificationsFilterDropdown {...props} />
        </Space>
        {notifications.length === 0 &&
          (isAnyFilterApplied ? (
            <Space direction={"vertical"}>
              <Typography.Text italic={true}>
                {"No notifications found with these filters"}
              </Typography.Text>
              <Button onClick={clearAllFilters}>{"Clear filters"}</Button>
            </Space>
          ) : (
            <Typography.Text italic={true}>
              {"You have no notifications"}
            </Typography.Text>
          ))}
        {notifications.map((notification) => {
          if (notification.type === NotificationType.LessonAlternativeLink) {
            return (
              <NotificationItemLessonAlternativeLinkContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (notification.type === NotificationType.LessonExercise) {
            return (
              <NotificationItemLessonExerciseContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (notification.type === NotificationType.LessonQuestion) {
            return (
              <NotificationItemLessonQuestionContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (notification.type === NotificationType.LessonAnswer) {
            return (
              <NotificationItemLessonAnswerContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (
            notification.type === NotificationType.CourseReviewRejection
          ) {
            return (
              <NotificationItemCourseReviewRejectionContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (notification.type === NotificationType.CourseSuggestion) {
            return (
              <NotificationItemCourseSuggestionContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else if (
            notification.type === NotificationType.CourseComment ||
            notification.type === NotificationType.CourseCommentReply
          ) {
            return (
              <NotificationItemCourseCommentContainer
                key={`Notifications-${notification.id}`}
                notification={notification}
              />
            );
          } else {
            return <div key={`Notifications-${notification.id}`}>UNKNOWN</div>;
          }
        })}
        <FetchMoreButton
          text={locStrings.loadMore.value()}
          isNoMoreToFetch={isNoMoreNotificationsToFetch}
          fetchMore={fetchMoreNotifications}
        />
      </Space>
    </LayoutContent>
  );
};
