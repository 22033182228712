import { Button } from "antd";
import React, { useState } from "react";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { HeaderSettingsDrawer } from "./HeaderSettingsDrawer";
import { MenuOutlined } from "@ant-design/icons";
import { UserItemContainer } from "../common/UserItemContainer";
import { UnreadNotificationsBadgeWrapper } from "../common/UnreadNotificationsBadgeWrapper";

interface IHeaderSettingsContainerProps {}

export const HeaderSettingsContainer: React.FunctionComponent<
  IHeaderSettingsContainerProps
> = (props) => {
  const { user, signOut } = useUserAuth();
  const [isSettingsDrawerVisible, setIsSettingsDrawerVisible] =
    useState<boolean>(false);
  const openSettingsDrawer = () => {
    setIsSettingsDrawerVisible(true);
  };
  const closeSettingsDrawer = () => {
    setIsSettingsDrawerVisible(false);
  };

  const userId = user?.uid;

  return (
    <div>
      {userId ? (
        <UnreadNotificationsBadgeWrapper>
          <UserItemContainer
            onClick={openSettingsDrawer}
            userId={userId}
            hideUsername={true}
          />
        </UnreadNotificationsBadgeWrapper>
      ) : (
        <Button
          type={"text"}
          onClick={openSettingsDrawer}
          style={{ padding: 0 }}
        >
          <MenuOutlined style={{ color: "white" }} />
        </Button>
      )}
      <HeaderSettingsDrawer
        visible={isSettingsDrawerVisible}
        closeSettingsDrawer={closeSettingsDrawer}
        signOut={signOut}
      />
    </div>
  );
};
