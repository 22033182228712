import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { ILessonAlternativeLink } from "../../types/firestoreMappings";
import { LessonAlternativeLinkContainer } from "../alternative-links/LessonAlternativeLinkContainer";
import { FetchMoreButton } from "../common/FetchMoreButton";

interface ILessonAlternativeLinksProps {
  alternativeLinks: ILessonAlternativeLink[];
  isNoMoreAlternativeLinksToFetch: boolean;
  fetchMoreAlternativeLinks: () => void;
}

export const LessonAlternativeLinks: React.FunctionComponent<
  ILessonAlternativeLinksProps
> = (props) => {
  const {
    alternativeLinks,
    isNoMoreAlternativeLinksToFetch,
    fetchMoreAlternativeLinks,
  } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {alternativeLinks.length === 0 && (
        <Typography.Text italic={true}>
          {"No alternative links have been added yet"}
        </Typography.Text>
      )}
      {alternativeLinks.map((alternativeLink) => {
        return (
          <LessonAlternativeLinkContainer
            alternativeLink={alternativeLink}
            key={`LessonAlternativeLinks-LinkCard-${alternativeLink.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreAlternativeLinks.value()}
        isNoMoreToFetch={isNoMoreAlternativeLinksToFetch}
        fetchMore={fetchMoreAlternativeLinks}
      />
    </Space>
  );
};
