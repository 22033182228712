import React, { useEffect, useState } from "react";
import { useCreateCourseMutation } from "../../../firestore/mutations/useCreateCourseMutation";
import { locStrings } from "../../../localization/locStrings";
import { CourseCategory } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { useSisuModal } from "../../../ui-hooks/useSisuModal";
import { useUserAuth } from "../../../ui-hooks/useUserAuth";
import { isValidCourseInputWithErrorNotify } from "../../../utils/validInputUtils";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseEdit } from "./CourseEdit";

interface ICourseCreateContainerProps {}

export const CourseCreateContainer: React.FunctionComponent<
  ICourseCreateContainerProps
> = (props) => {
  const nav = useNavigation();
  const parsedCategory = nav.urlSearchParams.getCategory() as
    | CourseCategory
    | undefined;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { user } = useUserAuth();
  const {
    mutate: createCourse,
    data: createdCourseData,
    isSuccess: isCourseCreationSuccessful,
    isLoading: isCourseCreationLoading,
  } = useCreateCourseMutation();
  const [courseTitle, setCourseTitle] = useState<string>("");
  const [courseDescription, setCourseDescription] = useState<string>("");
  const [courseCategories, setCourseCategories] = useState<CourseCategory[]>(
    !!parsedCategory ? [parsedCategory] : []
  );
  const [courseOwners, setCourseOwners] = useState<string[]>(
    user?.uid ? [user?.uid] : []
  );
  const onCourseTitleChange = (s: string) => {
    setCourseTitle(s);
  };
  const onCourseDescriptionChange = (s: string) => {
    setCourseDescription(s);
  };
  const onCourseOwnersChange = (owners: string[]) => {
    if (user?.uid) {
      const ownersWithoutMe = owners.filter((uid) => uid !== user?.uid);
      const ownersWithMeFirst = [user?.uid].concat(ownersWithoutMe);
      setCourseOwners(ownersWithMeFirst);
    }
  };
  const onCourseCategoriesChange = (newCategories: CourseCategory[]) => {
    setCourseCategories(newCategories);
  };

  useEffect(() => {
    if (isCourseCreationSuccessful && createdCourseData?.id) {
      nav.goToPaths.courseInfo(createdCourseData.id);
    }
  }, [isCourseCreationSuccessful, createdCourseData?.id, nav.goToPaths]);

  useEffect(() => {
    if (user) {
      setCourseOwners([user.uid]);
    }
  }, [user]);

  const onCreateNewCourse = async () => {
    const isValid = isValidCourseInputWithErrorNotify().forCreate({
      title: courseTitle,
      description: courseDescription,
      categories: courseCategories,
      owners: courseOwners,
      meUser: user,
    });

    if (isValid) {
      createCourse({
        title: courseTitle,
        description: courseDescription,
        categories: courseCategories,
        owners: courseOwners,
      });
    }
  };

  if (isCourseCreationLoading) {
    return <GenericLoader />;
  }

  return (
    <CourseEdit
      pageTitle={locStrings.createANewCourse.value()}
      courseTitle={courseTitle}
      courseDescription={courseDescription}
      courseCategories={courseCategories}
      courseOwners={courseOwners}
      onCourseTitleChange={onCourseTitleChange}
      onCourseDescriptionChange={onCourseDescriptionChange}
      onCourseCategoriesChange={onCourseCategoriesChange}
      onCourseOwnersChange={onCourseOwnersChange}
      onSubmit={forceSisuModalFxnWrapper(onCreateNewCourse)}
      disableRemovalOfMyUser={true}
    />
  );
};
