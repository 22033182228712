import { Collapse, Menu, Space, Typography } from "antd";
import React, { useState } from "react";
import { themeColors } from "../../styling/styleConstants";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { Principles } from "./Principles";

enum InformationTab {
  WhatIs = "WhatIs",
  HowItWorks = "HowItWorks",
  Principles = "Principles",
}

const textSize = "1rem";

interface IHomeInformationalSectionProps {}

export const HomeInformationalSection: React.FunctionComponent<
  IHomeInformationalSectionProps
> = (props) => {
  const [currentTab, setCurrentTab] = useState<InformationTab>(
    InformationTab.WhatIs
  );
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  if (isScreenLargerThanMd) {
    return (
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <Menu
          mode={"horizontal"}
          selectedKeys={[currentTab]}
          onSelect={(e) => setCurrentTab(e.key as InformationTab)}
          style={{ minWidth: 0, flex: "auto", justifyContent: "center" }}
        >
          <Menu.Item
            key={InformationTab.WhatIs}
            style={{
              fontSize: "1.5rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            <PageTypography
              title={convertInformationTabToTitleString(InformationTab.WhatIs)}
              level={PageTypographyLevel.PageSection}
            />
          </Menu.Item>
          <Menu.Item
            key={InformationTab.HowItWorks}
            style={{
              fontSize: "1.5rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            <PageTypography
              title={convertInformationTabToTitleString(
                InformationTab.HowItWorks
              )}
              level={PageTypographyLevel.PageSection}
            />
          </Menu.Item>
          <Menu.Item
            key={InformationTab.Principles}
            style={{
              fontSize: "1.5rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            <PageTypography
              title={convertInformationTabToTitleString(
                InformationTab.Principles
              )}
              level={PageTypographyLevel.PageSection}
            />
          </Menu.Item>
        </Menu>
        {currentTab === InformationTab.WhatIs && (
          <WhatIsLoaSection
            onOpenHowItWorks={() => setCurrentTab(InformationTab.HowItWorks)}
          />
        )}
        {currentTab === InformationTab.HowItWorks && (
          <HowItWorksSection
            onOpenPrinciples={() => setCurrentTab(InformationTab.Principles)}
          />
        )}
        {currentTab === InformationTab.Principles && <Principles />}
      </Space>
    );
  } else {
    return (
      //@ts-ignore
      <Collapse
        activeKey={currentTab}
        onChange={(key) => setCurrentTab(key as InformationTab)}
        accordion={true}
      >
        {/**@ts-ignore */}
        <Collapse.Panel
          key={InformationTab.WhatIs}
          header={
            <PageTypography
              title={convertInformationTabToTitleString(InformationTab.WhatIs)}
              level={PageTypographyLevel.PageSection}
            />
          }
          style={{
            background: themeColors.bodyBackground,
          }}
        >
          <WhatIsLoaSection
            onOpenHowItWorks={() => setCurrentTab(InformationTab.HowItWorks)}
          />
        </Collapse.Panel>
        {/**@ts-ignore */}
        <Collapse.Panel
          key={InformationTab.HowItWorks}
          header={
            <PageTypography
              title={convertInformationTabToTitleString(
                InformationTab.HowItWorks
              )}
              level={PageTypographyLevel.PageSection}
            />
          }
          style={{
            background: themeColors.bodyBackground,
          }}
        >
          <HowItWorksSection
            onOpenPrinciples={() => setCurrentTab(InformationTab.Principles)}
          />
        </Collapse.Panel>
        {/**@ts-ignore */}
        <Collapse.Panel
          key={InformationTab.Principles}
          header={
            <PageTypography
              title={convertInformationTabToTitleString(
                InformationTab.Principles
              )}
              level={PageTypographyLevel.PageSection}
            />
          }
          style={{
            background: themeColors.bodyBackground,
          }}
        >
          <Principles />
        </Collapse.Panel>
      </Collapse>
    );
  }
};

const WhatIsLoaSection: React.FunctionComponent<{
  onOpenHowItWorks: () => void;
}> = (props) => {
  const { onOpenHowItWorks } = props;
  return (
    <div style={{ fontSize: textSize }}>
      <Typography.Paragraph>
        Knowledge has never been so widely available in all of human history.
        Information on every subject, every topic, every category is hosted
        somewhere on the web. It comes in the form of videos, articles, blogs,
        podcasts, etc. It's safe to say that if you want to learn about it, the
        information is somewhere out there for free.
      </Typography.Paragraph>
      <Typography.Paragraph>
        But learning online is tough. There's{" "}
        <Typography.Text italic={true}>too much</Typography.Text> information,{" "}
        <Typography.Text italic={true}>too many</Typography.Text> links to sift
        through, and knowing how to navigate all that information requires
        expertise, forethought, and lots of searching.
      </Typography.Paragraph>
      <Typography.Paragraph>
        LoA is the platform where people come together to curate and organize
        those links into easily digestible courses. We enable people to share
        their knowledge & expertise without having to create their own videos or
        write their own articles, instead relying on all that free information
        floating around the web and giving people the tools to piece those links
        together in a structured and iterative way.
      </Typography.Paragraph>
      <Typography.Link onClick={onOpenHowItWorks}>
        So how does it work?
      </Typography.Link>
    </div>
  );
};

const HowItWorksSection: React.FunctionComponent<{
  onOpenPrinciples: () => void;
}> = (props) => {
  const { onOpenPrinciples } = props;
  return (
    <div style={{ fontSize: textSize }}>
      <PageTypography
        title={"Links & Modules"}
        level={PageTypographyLevel.PageSection}
        style={{ marginBottom: "0.5rem" }}
      />
      <Typography.Paragraph>
        Links are the primary content in a course. These links can be anything:
        YouTube videos, Medium articles, Reddit posts, Google docs... anything!
        There's so much free, high quality information online that you don't
        need to "reinvent the wheel" and re-write what's been written. Just find
        the best links.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Modules are how you organize these links in a course. You can think of
        them like the sections or chapters in a curriculum. Links go inside
        these modules.
      </Typography.Paragraph>
      <PageTypography
        title={"Contributors & Course Owners"}
        level={PageTypographyLevel.PageSection}
        style={{ marginBottom: "0.5rem" }}
      />
      <Typography.Paragraph>
        LoA aims to be as open as possible, allowing anyone to contribute to any
        course. As a contributor, you can submit a new link or module to any
        course, and it will be displayed as a suggestion. An upvote/downvote
        system allows the best of these suggestions to rise to the top.
      </Typography.Paragraph>
      <Typography.Paragraph>
        To balance that openess and maintain a high level of quality and trust,
        every course has up to 10 owners that act as expert instructors and
        moderators. Links and modules that are submitted by a course owner are
        immediately added to the official structure of the course, and owners
        have the ability to approve contributor suggestions to make them an
        official part of the course.
      </Typography.Paragraph>
      <Typography.Link onClick={onOpenPrinciples}>
        See LoA's guiding principles
      </Typography.Link>
    </div>
  );
};

const convertInformationTabToTitleString = (tab: InformationTab) => {
  switch (tab) {
    case InformationTab.WhatIs:
      return "What is LoA";
    case InformationTab.HowItWorks:
      return "How it Works";
    case InformationTab.Principles:
      return "Guiding Principles";
  }

  return "???";
};
