import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { ILessonExercise } from "../../types/firestoreMappings";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { LessonExerciseContainer } from "../exercises/LessonExerciseContainer";

interface ILessonExercisesProps {
  exercises: ILessonExercise[];
  isNoMoreExercisesToFetch: boolean;
  fetchMoreExercises: () => void;
}

export const LessonExercises: React.FunctionComponent<ILessonExercisesProps> = (
  props
) => {
  const { exercises, isNoMoreExercisesToFetch, fetchMoreExercises } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {exercises.length === 0 && (
        <Typography.Text
          italic={true}
        >{`No exercises have been added yet`}</Typography.Text>
      )}
      {exercises.map((exercise) => {
        return (
          <LessonExerciseContainer
            exercise={exercise}
            key={`LessonExercises-ExerciseCard-${exercise.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreExercises.value()}
        isNoMoreToFetch={isNoMoreExercisesToFetch}
        fetchMore={fetchMoreExercises}
      />
    </Space>
  );
};
