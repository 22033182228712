import { Button, Divider, Drawer, Space } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { HeaderBarPath } from "../../types/helperTypes";
import { useIsMeUserReviewer } from "../../ui-hooks/useIsMeUserReviewer";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { convertHeaderBarPathToIconAndString } from "../../utils/iconAndStringUtils";
import { ConfirmButton } from "../common/ConfirmButton";
import { UnreadNotificationsBadgeWrapper } from "../common/UnreadNotificationsBadgeWrapper";
import { UserItemContainer } from "../common/UserItemContainer";

export interface IHeaderSettingsDrawerProps {
  visible: boolean;
  closeSettingsDrawer: () => void;
  signOut: () => void;
}

export const HeaderSettingsDrawer: React.FunctionComponent<
  IHeaderSettingsDrawerProps
> = (props) => {
  const { visible, closeSettingsDrawer, signOut } = props;
  const nav = useNavigation();
  const { openSisuModal } = useSisuModal();
  const { user } = useUserAuth();
  const isMeUserReviewer = useIsMeUserReviewer();

  const onSignIn = () => {
    openSisuModal();
    closeSettingsDrawer();
  };
  const onSignOut = () => {
    signOut();
    closeSettingsDrawer();
  };
  const onProfile = () => {
    if (user) {
      nav.goToPaths.userProfile(user.uid);
      closeSettingsDrawer();
    }
  };
  const onMyCourses = () => {
    if (user) {
      nav.goToPaths.userProfile(user.uid, { myCourses: "true" });
      closeSettingsDrawer();
    }
  };
  const onMyFavorites = () => {
    if (user) {
      nav.goToPaths.userProfile(user.uid, { myFavorites: "true" });
      closeSettingsDrawer();
    }
  };
  const onExplore = () => {
    nav.goToPaths.explore();
    closeSettingsDrawer();
  };
  const onNewCourse = () => {
    nav.goToPaths.newCourse();
    closeSettingsDrawer();
  };
  const onNotifications = () => {
    nav.goToPaths.notifications();
    closeSettingsDrawer();
  };
  const onReviewQueue = () => {
    nav.goToPaths.reviewQueue();
    closeSettingsDrawer();
  };

  return (
    <Drawer
      visible={visible}
      placement={"right"}
      onClose={closeSettingsDrawer}
      width={"15rem"}
    >
      <Space direction={"vertical"} style={{ width: "100%" }}>
        {!!user ? (
          <UserItemContainer userId={user.uid} disableOnClick={true} />
        ) : (
          <Button onClick={onSignIn} type={"primary"} style={{ width: "100%" }}>
            {locStrings.sisuSignIn.value()}
          </Button>
        )}
        <SectionDivider />
        {!!user && (
          <Button
            onClick={onProfile}
            type={"text"}
            icon={
              convertHeaderBarPathToIconAndString(HeaderBarPath.Profile).icon
            }
          >
            {convertHeaderBarPathToIconAndString(HeaderBarPath.Profile).string}
          </Button>
        )}
        {!!user && (
          <UnreadNotificationsBadgeWrapper>
            <Button
              onClick={onNotifications}
              type={"text"}
              icon={
                convertHeaderBarPathToIconAndString(HeaderBarPath.Notifications)
                  .icon
              }
            >
              {
                convertHeaderBarPathToIconAndString(HeaderBarPath.Notifications)
                  .string
              }
            </Button>
          </UnreadNotificationsBadgeWrapper>
        )}
        {!!user && (
          <Button
            onClick={onMyCourses}
            type={"text"}
            icon={
              convertHeaderBarPathToIconAndString(HeaderBarPath.MyCourses).icon
            }
          >
            {
              convertHeaderBarPathToIconAndString(HeaderBarPath.MyCourses)
                .string
            }
          </Button>
        )}
        {!!user && (
          <Button
            onClick={onMyFavorites}
            type={"text"}
            icon={
              convertHeaderBarPathToIconAndString(HeaderBarPath.MyFavorites)
                .icon
            }
          >
            {
              convertHeaderBarPathToIconAndString(HeaderBarPath.MyFavorites)
                .string
            }
          </Button>
        )}
        {isMeUserReviewer && (
          <Button
            onClick={onReviewQueue}
            type={"text"}
            icon={
              convertHeaderBarPathToIconAndString(HeaderBarPath.ReviewQueue)
                .icon
            }
          >
            {
              convertHeaderBarPathToIconAndString(HeaderBarPath.ReviewQueue)
                .string
            }
          </Button>
        )}
        {!!user && <SectionDivider />}
        <Button
          onClick={onNewCourse}
          type={"text"}
          icon={
            convertHeaderBarPathToIconAndString(HeaderBarPath.NewCourse).icon
          }
        >
          {convertHeaderBarPathToIconAndString(HeaderBarPath.NewCourse).string}
        </Button>
        <Button
          onClick={onExplore}
          type={"text"}
          icon={convertHeaderBarPathToIconAndString(HeaderBarPath.Explore).icon}
        >
          {convertHeaderBarPathToIconAndString(HeaderBarPath.Explore).string}
        </Button>
        {!!user && <SectionDivider />}
        {!!user && (
          <ConfirmButton
            onConfirm={onSignOut}
            text={locStrings.sisuSignOutMessage.value()}
            type={"text"}
          />
        )}
      </Space>
    </Drawer>
  );
};

const SectionDivider = () => {
  return <Divider style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }} />;
};
