import React from "react";
import { CoursePublishState } from "../../types/helperTypes";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { CoursesContainer } from "../course/course-list/CoursesContainer";

interface IHomeContributeCoursesProps {}

export const HomeContributeCourses: React.FunctionComponent<
  IHomeContributeCoursesProps
> = (props) => {
  return (
    <>
      <PageTypography
        title={"Contribute to courses that need your help"}
        level={PageTypographyLevel.PageSection}
        style={{ marginBottom: "1rem" }}
      />
      <CoursesContainer
        listCoursesQueryParams={{
          publishStates: [CoursePublishState.Draft],
          sortByUpdatedAt: true,
        }}
      />
    </>
  );
};
