import { Typography } from "antd";
import React from "react";
import { convertCourseMetadataTypeToIcon } from "../../../utils/iconAndStringUtils";
import { MetadataType } from "../../../types/helperTypes";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";

interface IInLessonDetailsSectionDividerProps {
  title: string;
  count?: number;
  metadataType?: MetadataType;
}

export const InLessonDetailsSectionDivider: React.FunctionComponent<
  IInLessonDetailsSectionDividerProps
> = (props) => {
  const { title, count, metadataType } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {metadataType &&
        convertCourseMetadataTypeToIcon({ metadataType }, undefined, "large")}
      <PageTypography title={title} level={PageTypographyLevel.PageSection} />
      {count !== undefined && <Typography>{`(${count})`}</Typography>}
    </div>
  );
};
