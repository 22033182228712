import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation } from "react-query";
import {
  CourseSuggestionType,
  NotificationType,
} from "../../types/helperTypes";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const useCreateNotificationMutation = () => {
  const createNotification = (vars: {
    type: NotificationType;
    userId: string;
    notificationData: {
      lessonAlternativeLinkId?: string;
      lessonExerciseId?: string;
      lessonQuestionId?: string;
      lessonAnswerId?: string;
      courseReviewRejectionId?: string;
      courseSuggestionId?: string;
      courseSuggestionType?: CourseSuggestionType;
      courseCommentId?: string;
    };
  }) => {
    const { type, userId, notificationData } = vars;
    const {
      lessonAlternativeLinkId,
      lessonExerciseId,
      lessonQuestionId,
      lessonAnswerId,
      courseReviewRejectionId,
      courseSuggestionId,
      courseSuggestionType,
      courseCommentId,
    } = notificationData;
    const newNotification = {
      type,
      isRead: false,
      userId,
      createdAt: serverTimestamp(),
    };
    if (type === NotificationType.LessonAlternativeLink) {
      if (lessonAlternativeLinkId) {
        (newNotification as any).lessonAlternativeLinkId =
          lessonAlternativeLinkId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, LessonAlternativeLink, lessonAlternativeLinkId required"
        );
      }
    }
    if (type === NotificationType.LessonExercise) {
      if (lessonExerciseId) {
        (newNotification as any).lessonExerciseId = lessonExerciseId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, LessonExercise, lessonExerciseId required"
        );
      }
    }
    if (type === NotificationType.LessonQuestion) {
      if (lessonQuestionId) {
        (newNotification as any).lessonQuestionId = lessonQuestionId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, LessonQuestion, lessonQuestionId required"
        );
      }
    }
    if (type === NotificationType.LessonAnswer) {
      if (lessonAnswerId) {
        (newNotification as any).lessonAnswerId = lessonAnswerId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, LessonAnswer, lessonAnswerId required"
        );
      }
    }
    if (type === NotificationType.CourseReviewRejection) {
      if (courseReviewRejectionId) {
        (newNotification as any).courseReviewRejectionId =
          courseReviewRejectionId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, CourseReviewRejection, courseReviewRejectionId required"
        );
      }
    }
    if (type === NotificationType.CourseSuggestion) {
      if (courseSuggestionId) {
        (newNotification as any).courseSuggestionId = courseSuggestionId;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, CourseSuggestion, courseSuggestionId required"
        );
      }
      if (courseSuggestionType) {
        (newNotification as any).courseSuggestionType = courseSuggestionType;
      } else {
        return Promise.reject(
          "useCreateNotificationMutation, CourseSuggestion, courseSuggestionType required"
        );
      }
    }
    if (
      type === NotificationType.CourseComment ||
      type === NotificationType.CourseCommentReply
    ) {
      if (courseCommentId) {
        (newNotification as any).courseCommentId = courseCommentId;
      } else {
        return Promise.reject(
          `useCreateNotificationMutation, ${type}, courseCommentId required`
        );
      }
    }

    const db = getFirestore();
    const notificationsCollectionRef = collection(
      db,
      collectionPaths.notifications
    );
    return addDoc(notificationsCollectionRef, newNotification);
  };
  return useMutation(createNotification, {
    onSettled: settledLogger("useCreateNotificationMutation"),
  });
};
