import { Typography } from "antd";
import React from "react";

interface IFooterProps {}

export const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Typography.Text>
        © {new Date().getFullYear()} LoA - Library of Alexandria
      </Typography.Text>
      <Typography.Text type={"secondary"}>
        Icons provided by{" "}
        <Typography.Link href={"https://icons8.com"} target={"_blank"}>
          icons8
        </Typography.Link>
      </Typography.Text>
    </div>
  );
};
