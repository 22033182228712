import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listLessonAlternativeLinksQueryKey } from "../queries/useListLessonAlternativeLinksQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteLessonAlternativeLinkMutation = (
  alternativeLinkId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const deleteLessonExercise = () => {
    const db = getFirestore();
    const alternativeLinkDocRef = doc(
      db,
      collectionPaths.lessonAlternativeLinks,
      alternativeLinkId
    );
    return deleteDoc(alternativeLinkDocRef);
  };
  return useMutation(deleteLessonExercise, {
    onSettled: settledLogger("useDeleteLessonAlternativeLinkMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonAlternativeLinksQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
          refetchPage: (page, index) => true,
        }
      );
    },
  });
};
