import React from "react";
import {
  ListCourseCommentsQueryParams,
  useListCourseCommentsQuery,
} from "../../firestore/queries/useListCourseCommentsQuery";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { CourseComments } from "./CourseComments";

interface ICourseCommentsContainerProps {
  params: ListCourseCommentsQueryParams;
  shouldSkipFetch: boolean;
}

export const CourseCommentsContainer: React.FunctionComponent<
  ICourseCommentsContainerProps
> = (props) => {
  const { params, shouldSkipFetch } = props;
  const {
    data: courseComments,
    fetchNextPage: fetchMoreCourseComments,
    isNoMoreToFetch: isNoMoreCourseCommentsToFetch,
    isLoading: isCourseCommentsLoading,
    isError: isCourseCommentsError,
  } = useListCourseCommentsQuery(params, !shouldSkipFetch);

  if (isCourseCommentsError) {
    return <GenericError />;
  }

  if (isCourseCommentsLoading || !courseComments) {
    return <GenericLoader />;
  }

  return (
    <CourseComments
      courseComments={courseComments}
      fetchMoreCourseComments={fetchMoreCourseComments}
      isNoMoreCourseCommentsToFetch={isNoMoreCourseCommentsToFetch}
    />
  );
};
