import { Button, Input, Space } from "antd";
import React, { useState } from "react";
import { locStrings } from "../../../../localization/locStrings";
import { RichTextEditor } from "../../../common/RichTextEditor";

interface ICourseSuggestionCreateNewModuleProps {
  onCreateNewModuleSuggestion: (vars: {
    reason: string;
    moduleTitle: string;
  }) => void;
}

export const CourseSuggestionCreateNewModule: React.FunctionComponent<
  ICourseSuggestionCreateNewModuleProps
> = (props) => {
  const { onCreateNewModuleSuggestion } = props;
  const [reason, setReason] = useState<string>("");
  const [moduleTitle, setModuleTitle] = useState<string>("");

  const onSubmit = () => {
    onCreateNewModuleSuggestion({
      reason,
      moduleTitle,
    });
  };

  return (
    <Space direction={"vertical"} style={{ display: "flex" }}>
      <Input
        id={"CourseSuggestionCreateNewModule-moduleTitle"}
        type={"text"}
        placeholder={locStrings.suggestionNewModuleTitleInputPlaceholder.value()}
        value={moduleTitle}
        onChange={(e) => setModuleTitle(e.target.value)}
      />
      <RichTextEditor
        initialValue={reason}
        onHtmlStringChange={setReason}
        placeholder={locStrings.suggestionNewModuleReasonInputPlaceholder.value()}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button onClick={onSubmit} type={"primary"}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
