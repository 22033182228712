import React from "react";
import { ICourseSuggestion, ILesson } from "./firestoreMappings";

export interface CourseReorderScaffold {
  modules: { moduleId: string; lessonIds: string[] }[];
}

export interface ReorderModeConfig {
  onMoveUpModule: (moduleId: string) => void;
  onMoveDownModule: (moduleId: string) => void;
  onMoveUpLesson: (lessonId: string) => void;
  onMoveDownLesson: (lessonId: string) => void;
}

export interface LessonPlacementInCourse {
  moduleId: string;
  prevLessonId?: string;
  nextLessonId?: string;
}

export interface LessonsAndSuggestionsMap {
  [moduleId: string]: (ILesson | ICourseSuggestion)[];
}

export enum NotificationType {
  LessonAlternativeLink = "LessonAlternativeLink",
  LessonExercise = "LessonExercise",
  LessonQuestion = "LessonQuestion",
  LessonAnswer = "LessonAnswer",
  CourseReviewRejection = "CourseReviewRejection",
  CourseSuggestion = "CourseSuggestion",
  CourseComment = "CourseComment",
  CourseCommentReply = "CourseCommentReply",
}

export enum CourseSuggestionType {
  GeneralFeedback = "GeneralFeedback",
  NewLesson = "NewLesson",
  NewModule = "NewModule",
}

export enum CourseSuggestionState {
  Open = "Open",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum NewCourseCategoryRequestState {
  Open = "Open",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum CoursePublishState {
  Draft = "Draft",
  InReview = "InReview",
  Published = "Published",
}

export enum HeaderBarPath {
  Profile = "Profile",
  MyCourses = "MyCourses",
  MyFavorites = "MyFavorites",
  NewCourse = "NewCourse",
  Notifications = "Notifications",
  ReviewQueue = "ReviewQueue",
  Explore = "Explore",
}

export enum MetadataType {
  Unknown = "Unknown",
  Course = "Course",
  Module = "Module",
  Lesson = "Lesson",
  AlternativeLink = "AlternativeLink",
  LessonQuestion = "LessonQuestion",
  LessonAnswer = "LessonAnswer",
  LessonExercise = "LessonExercise",
  CourseComment = "CourseComment",
  CourseReviewRejection = "CourseReviewRejection",
  CourseSuggestion = "CourseSuggestion",
  CourseSuggestionGeneralFeedback = "CourseSuggestionGeneralFeedback",
  CourseSuggestionNewLesson = "CourseSuggestionNewLesson",
  CourseSuggestionNewModule = "CourseSuggestionNewModule",
}

export enum CourseTopLevelCategory {
  Business = "Business",
  ArtsAndHumanities = "ArtsAndHumanities",
  PhysicalScience = "PhysicalScience",
  ComputerScience = "ComputerScience",
  Culture = "Culture",
}

export enum CourseCategory {
  Unknown = "Unknown",
  // Business
  Finance = "Finance",
  ProjectManagement = "ProjectManagement",
  Marketing = "Marketing",
  RealEstate = "RealEstate",
  // Arts and Humanities
  Music = "Music",
  Painting = "Painting",
  Drawing = "Drawing",
  Writing = "Writing",
  Philosophy = "Philosophy",
  GraphicDesign = "GraphicDesign",
  FilmAndTheatre = "FilmAndTheatre",
  LiteratureAndPoetry = "LiteratureAndPoetry",
  // Physical Science
  Mathematics = "Mathematics",
  Chemistry = "Chemistry",
  Biology = "Biology",
  Physics = "Physics",
  MechanicalEngineering = "MechanicalEngineering",
  // Computer Science
  Coding = "Coding",
  Cybersecurity = "Cybersecurity",
  DataScience = "DataScience",
  // Culture
  PersonalDevelopment = "PersonalDevelopment",
  History = "History",
  Games = "Games",
  Sports = "Sports",
  Religion = "Religion",
  FoodAndDrink = "FoodAndDrink",
}

export type GenericAntTwoToneIcon = React.ComponentType<{
  twoToneColor: string;
  style?: React.CSSProperties;
}>;
