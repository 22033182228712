import React from "react";
import {
  MetadataType,
  CourseSuggestionState,
  CourseSuggestionType,
  HeaderBarPath,
  NotificationType,
  CourseCategory,
  CourseTopLevelCategory,
} from "../types/helperTypes";
import {
  MinusCircleOutlined,
  UserOutlined,
  HeartOutlined,
  PlusOutlined,
  BellOutlined,
  ProfileOutlined,
  FileUnknownFilled,
  BookOutlined,
  AppstoreOutlined,
  FileOutlined,
  PlaySquareOutlined,
  MessageOutlined,
  LinkOutlined,
  FolderAddOutlined,
  CheckOutlined,
  CloseOutlined,
  RocketOutlined,
  QuestionCircleOutlined,
  QuestionCircleFilled,
  BulbOutlined,
  BulbFilled,
  FileProtectOutlined,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";
import BusinessPng from "../assets/course-categories/Business.png";
import ProjectManagementPng from "../assets/course-categories/ProjectManagement.png";
import MarketingPng from "../assets/course-categories/Marketing.png";
import RealEstatePng from "../assets/course-categories/RealEstate.png";
import ArtsAndHumanitiesPng from "../assets/course-categories/ArtsAndHumanities.png";
import MusicPng from "../assets/course-categories/Music.png";
import PaintingPng from "../assets/course-categories/Painting.png";
import DrawingPng from "../assets/course-categories/Drawing.png";
import WritingPng from "../assets/course-categories/Writing.png";
import PhilosophyPng from "../assets/course-categories/Philosophy.png";
import GraphicDesignPng from "../assets/course-categories/GraphicDesign.png";
import FilmAndTheatrePng from "../assets/course-categories/FilmAndTheatre.png";
import PhysicalSciencePng from "../assets/course-categories/PhysicalScience.png";
import MathematicsPng from "../assets/course-categories/Mathematics.png";
import ChemistryPng from "../assets/course-categories/Chemistry.png";
import BiologyPng from "../assets/course-categories/Biology.png";
import PhysicsPng from "../assets/course-categories/Physics.png";
import MechanicalEngineeringPng from "../assets/course-categories/MechanicalEngineering.png";
import ComputerSciencePng from "../assets/course-categories/ComputerScience.png";
import CodingPng from "../assets/course-categories/Coding.png";
import CybersecurityPng from "../assets/course-categories/Cybersecurity.png";
import DataSciencePng from "../assets/course-categories/DataScience.png";
import CulturePng from "../assets/course-categories/Culture.png";
import HistoryPng from "../assets/course-categories/History.png";
import GamesPng from "../assets/course-categories/Games.png";
import ReligionPng from "../assets/course-categories/Religion.png";
import FoodAndDrinkPng from "../assets/course-categories/FoodAndDrink.png";
import PersonalDevelopmentPng from "../assets/course-categories/PersonalDevelopment.png";
import SportsPng from "../assets/course-categories/Sports.png";
import FinancePng from "../assets/course-categories/Finance.png";
import LiteratureAndPoetryPng from "../assets/course-categories/LiteratureAndPoetry.png";
import { locStrings } from "../localization/locStrings";
import { getYoututubeVideoId } from "./utils";
import {
  courseMetadataTypeColors,
  themeColors,
} from "../styling/styleConstants";

const translateTypesIntoMetadataTypes = (vars: {
  metadataType?: MetadataType;
  courseSuggestionType?: CourseSuggestionType;
  notificationType?: NotificationType;
}): MetadataType => {
  const { metadataType, courseSuggestionType, notificationType } = vars;
  let metadataTypeToUse = metadataType || MetadataType.Unknown;
  if (!metadataType) {
    if (courseSuggestionType) {
      switch (courseSuggestionType) {
        case CourseSuggestionType.GeneralFeedback:
          metadataTypeToUse = MetadataType.CourseSuggestionGeneralFeedback;
          break;
        case CourseSuggestionType.NewLesson:
          metadataTypeToUse = MetadataType.CourseSuggestionNewLesson;
          break;
        case CourseSuggestionType.NewModule:
          metadataTypeToUse = MetadataType.CourseSuggestionNewModule;
          break;
      }
    }

    if (notificationType) {
      switch (notificationType) {
        case NotificationType.CourseReviewRejection:
          metadataTypeToUse = MetadataType.CourseReviewRejection;
          break;
        case NotificationType.LessonAlternativeLink:
          metadataTypeToUse = MetadataType.AlternativeLink;
          break;
        case NotificationType.LessonExercise:
          metadataTypeToUse = MetadataType.LessonExercise;
          break;
        case NotificationType.LessonQuestion:
          metadataTypeToUse = MetadataType.LessonQuestion;
          break;
        case NotificationType.LessonAnswer:
          metadataTypeToUse = MetadataType.LessonAnswer;
          break;
        case NotificationType.CourseSuggestion:
          metadataTypeToUse = MetadataType.CourseSuggestion;
          break;
        case NotificationType.CourseComment:
          metadataTypeToUse = MetadataType.CourseComment;
          break;
        case NotificationType.CourseCommentReply:
          metadataTypeToUse = MetadataType.CourseComment;
          break;
      }
    }
  }

  return metadataTypeToUse;
};

export const convertHeaderBarPathToIconAndString = (path: HeaderBarPath) => {
  const iconAndString = {
    icon: <FileUnknownFilled />,
    string: locStrings.genericErrorMessage.value(),
  };
  switch (path) {
    case HeaderBarPath.Profile:
      iconAndString.icon = <UserOutlined />;
      iconAndString.string = locStrings.profile.value();
      break;
    case HeaderBarPath.MyCourses:
      iconAndString.icon = <BookOutlined />;
      iconAndString.string = locStrings.myCourses.value();
      break;
    case HeaderBarPath.MyFavorites:
      iconAndString.icon = <HeartOutlined />;
      iconAndString.string = locStrings.favorites.value();
      break;
    case HeaderBarPath.NewCourse:
      iconAndString.icon = <PlusOutlined />;
      iconAndString.string = locStrings.topNavBarNewCourse.value();
      break;
    case HeaderBarPath.Notifications:
      iconAndString.icon = <BellOutlined />;
      iconAndString.string = locStrings.topNavBarNotifications.value();
      break;
    case HeaderBarPath.ReviewQueue:
      iconAndString.icon = <ProfileOutlined />;
      iconAndString.string = locStrings.topNavBarReviewQueue.value();
      break;
    case HeaderBarPath.Explore:
      iconAndString.icon = <AppstoreOutlined />;
      iconAndString.string = locStrings.topNavBarExploer.value();
      break;
  }

  return iconAndString;
};

export const convertLessonTypeToIcon = (lessonWeblink: string) => {
  let icon = <FileOutlined />;

  const isYoutubeVideo = !!getYoututubeVideoId(lessonWeblink);
  if (isYoutubeVideo) {
    icon = <PlaySquareOutlined />;
  }

  return icon;
};

export const convertCourseMetadataTypeToIcon = (
  vars: {
    metadataType?: MetadataType;
    courseSuggestionType?: CourseSuggestionType;
    notificationType?: NotificationType;
  },
  filled?: boolean,
  size?: "regular" | "large"
) => {
  let icon = <FileUnknownFilled />;
  let metadataTypeToUse = translateTypesIntoMetadataTypes(vars);
  const commonStyles: React.CSSProperties = {
    fontSize: !!size ? size : undefined,
  };

  switch (metadataTypeToUse) {
    case MetadataType.AlternativeLink:
      icon = (
        <LinkOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.alternativeLink,
          }}
        />
      );
      break;
    case MetadataType.LessonQuestion:
      icon = (
        <QuestionCircleOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.lessonQuestion,
          }}
        />
      );
      break;
    case MetadataType.LessonAnswer:
      icon = (
        <QuestionCircleFilled
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.lessonAnswer,
          }}
        />
      );
      break;
    case MetadataType.LessonExercise:
      icon = (
        <RocketOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.lessonExercise,
          }}
        />
      );
      break;
    case MetadataType.CourseReviewRejection:
      icon = (
        <FileProtectOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseReviewRejection,
          }}
        />
      );
      break;
    case MetadataType.CourseSuggestion:
      icon = filled ? (
        <BulbFilled
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseSuggestion,
          }}
        />
      ) : (
        <BulbOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseSuggestion,
          }}
        />
      );
      break;
    case MetadataType.CourseSuggestionGeneralFeedback:
      icon = (
        <MessageOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseSuggestionGeneralFeedback,
          }}
        />
      );
      break;
    case MetadataType.CourseSuggestionNewLesson:
      icon = (
        <LinkOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseSuggestionNewLesson,
          }}
        />
      );
      break;
    case MetadataType.CourseSuggestionNewModule:
      icon = (
        <FolderAddOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseSuggestionNewModule,
          }}
        />
      );
      break;
    case MetadataType.CourseComment:
      icon = (
        <MessageOutlined
          style={{
            ...commonStyles,
            color: courseMetadataTypeColors.courseComment,
          }}
        />
      );
      break;
  }

  if (metadataTypeToUse === MetadataType.Unknown) {
    console.warn(
      `iconAndStringUtils.convertCourseMetadataTypeToIcon, unknown type for icon`
    );
  }
  return icon;
};

export const convertCourseSuggestionTypeToString = (
  suggestionType: CourseSuggestionType
) => {
  let string = locStrings.genericErrorMessage.value();
  switch (suggestionType) {
    case CourseSuggestionType.GeneralFeedback:
      string = locStrings.courseSuggestionGeneralFeedback.value();
      break;
    case CourseSuggestionType.NewLesson:
      string = locStrings.courseSuggestionNewLesson.value();
      break;
    case CourseSuggestionType.NewModule:
      string = locStrings.courseSuggestionNewModule.value();
      break;
  }

  return string;
};

export const convertCourseSuggestionTypeToCreateSelectionItemStrings = (
  suggestionType: CourseSuggestionType
) => {
  const strings = {
    title: locStrings.genericErrorMessage.value(),
    subTitle: locStrings.genericErrorMessage.value(),
  };
  switch (suggestionType) {
    case CourseSuggestionType.GeneralFeedback:
      strings.title = "General Feedback";
      strings.subTitle = "Give constructive feedback about this course";
      break;
    case CourseSuggestionType.NewLesson:
      strings.title = "New Link";
      strings.subTitle = "Suggest a new weblink that would benefit this course";
      break;
    case CourseSuggestionType.NewModule:
      strings.title = "New Module";
      strings.subTitle =
        "Suggest a new module that you think is missing from this course";
      break;
  }

  return strings;
};

export const convertCourseSuggestionStateToIcon = (
  state: CourseSuggestionState,
  withColor?: boolean
) => {
  let icon = <FileUnknownFilled />;
  switch (state) {
    case CourseSuggestionState.Open:
      icon = <MinusCircleOutlined />;
      break;
    case CourseSuggestionState.Accepted:
      icon = (
        <CheckOutlined
          style={{ color: withColor ? themeColors.successGreen : undefined }}
        />
      );
      break;
    case CourseSuggestionState.Rejected:
      icon = (
        <CloseOutlined
          style={{ color: withColor ? themeColors.errorRed : undefined }}
        />
      );
      break;
  }

  return icon;
};

export const convertCourseSuggestionStateToString = (
  state: CourseSuggestionState
) => {
  let string = "Unknown";
  switch (state) {
    case CourseSuggestionState.Open:
      string = "Open";
      break;
    case CourseSuggestionState.Accepted:
      string = "Accepted";
      break;
    case CourseSuggestionState.Rejected:
      string = "Rejected";
      break;
  }

  return string;
};

export const convertNotificationTypeToPrimaryString = (
  type: NotificationType
) => {
  let string = "UNKNOWN";
  switch (type) {
    case NotificationType.CourseReviewRejection:
      string = "Course Review";
      break;
    case NotificationType.CourseSuggestion:
      string = "Course Suggestion";
      break;
    case NotificationType.LessonAlternativeLink:
      string = "Alternative Link";
      break;
    case NotificationType.LessonExercise:
      string = "Exercise";
      break;
    case NotificationType.LessonQuestion:
      string = "Question";
      break;
    case NotificationType.LessonAnswer:
      string = "Answer";
      break;
    case NotificationType.CourseComment:
      string = "Comment";
      break;
    case NotificationType.CourseCommentReply:
      string = "Reply";
      break;
  }
  return string;
};

export const convertSisuInputTypeToIcon = (
  inputType: "username" | "email" | "password"
) => {
  let icon = <FileUnknownFilled />;
  switch (inputType) {
    case "username":
      icon = <UserOutlined />;
      break;
    case "email":
      icon = <MailOutlined />;
      break;
    case "password":
      icon = <LockOutlined />;
      break;
  }
  return icon;
};

export const convertCourseCategoryToStringAndIcon = (
  category: CourseCategory | CourseTopLevelCategory
) => {
  let finalString = "UNKNOWN";
  let finalIconSrcString = "NONSENSE";
  switch (category) {
    case CourseTopLevelCategory.Business:
      finalString = "Business";
      finalIconSrcString = BusinessPng;
      break;
    case CourseCategory.Finance:
      finalString = "Finance";
      finalIconSrcString = FinancePng;
      break;
    case CourseCategory.ProjectManagement:
      finalString = "Project Management";
      finalIconSrcString = ProjectManagementPng;
      break;
    case CourseCategory.Marketing:
      finalString = "Marketing";
      finalIconSrcString = MarketingPng;
      break;
    case CourseCategory.RealEstate:
      finalString = "Real Estate";
      finalIconSrcString = RealEstatePng;
      break;
    case CourseTopLevelCategory.ArtsAndHumanities:
      finalString = "Arts & Humanities";
      finalIconSrcString = ArtsAndHumanitiesPng;
      break;
    case CourseCategory.Music:
      finalString = "Music";
      finalIconSrcString = MusicPng;
      break;
    case CourseCategory.Painting:
      finalString = "Painting";
      finalIconSrcString = PaintingPng;
      break;
    case CourseCategory.Drawing:
      finalString = "Drawing";
      finalIconSrcString = DrawingPng;
      break;
    case CourseCategory.Writing:
      finalString = "Writing";
      finalIconSrcString = WritingPng;
      break;
    case CourseCategory.Philosophy:
      finalString = "Philosophy";
      finalIconSrcString = PhilosophyPng;
      break;
    case CourseCategory.GraphicDesign:
      finalString = "Graphic Design";
      finalIconSrcString = GraphicDesignPng;
      break;
    case CourseCategory.LiteratureAndPoetry:
      finalString = "Literature & Poetry";
      finalIconSrcString = LiteratureAndPoetryPng;
      break;
    case CourseCategory.FilmAndTheatre:
      finalString = "Film & Theatre";
      finalIconSrcString = FilmAndTheatrePng;
      break;
    case CourseTopLevelCategory.PhysicalScience:
      finalString = "Physical Science";
      finalIconSrcString = PhysicalSciencePng;
      break;
    case CourseCategory.Mathematics:
      finalString = "Mathematics";
      finalIconSrcString = MathematicsPng;
      break;
    case CourseCategory.Chemistry:
      finalString = "Chemistry";
      finalIconSrcString = ChemistryPng;
      break;
    case CourseCategory.Biology:
      finalString = "Biology";
      finalIconSrcString = BiologyPng;
      break;
    case CourseCategory.Physics:
      finalString = "Physics";
      finalIconSrcString = PhysicsPng;
      break;
    case CourseCategory.MechanicalEngineering:
      finalString = "Mechnical Engineering";
      finalIconSrcString = MechanicalEngineeringPng;
      break;
    case CourseTopLevelCategory.ComputerScience:
      finalString = "Computer Science";
      finalIconSrcString = ComputerSciencePng;
      break;
    case CourseCategory.Coding:
      finalString = "Coding";
      finalIconSrcString = CodingPng;
      break;
    case CourseCategory.Cybersecurity:
      finalString = "Cybersecurity";
      finalIconSrcString = CybersecurityPng;
      break;
    case CourseCategory.DataScience:
      finalString = "Data Science";
      finalIconSrcString = DataSciencePng;
      break;
    case CourseTopLevelCategory.Culture:
      finalString = "Culture & Society";
      finalIconSrcString = CulturePng;
      break;
    case CourseCategory.PersonalDevelopment:
      finalString = "Personal Development";
      finalIconSrcString = PersonalDevelopmentPng;
      break;
    case CourseCategory.History:
      finalString = "History";
      finalIconSrcString = HistoryPng;
      break;
    case CourseCategory.Games:
      finalString = "Games";
      finalIconSrcString = GamesPng;
      break;
    case CourseCategory.Sports:
      finalString = "Sports";
      finalIconSrcString = SportsPng;
      break;
    case CourseCategory.Religion:
      finalString = "Religion";
      finalIconSrcString = ReligionPng;
      break;
    case CourseCategory.FoodAndDrink:
      finalString = "Food & Drink";
      finalIconSrcString = FoodAndDrinkPng;
      break;
  }
  return { string: finalString, iconSrcString: finalIconSrcString };
};
