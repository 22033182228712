export const userFieldLimitations = {
  usernameMaxLength: 20,
  usernameMinLength: 3,
  passwordMinLength: 6,
};

export const courseFieldLimitations = {
  titleMaxLength: 80,
  descriptionMaxLength: 10000,
  ownersMaxCount: 10, // 10 is the hardlimit for array-in queries, which is important for querying questions/answers/etc by course owners
  categoriesMaxCount: 3,
};

export const moduleFieldLimitations = {
  titleMaxLength: 80,
  descriptionMaxLength: 2000,
};

export const lessonFieldLimitations = {
  titleMaxLength: 80,
  weblinkMaxLength: 1000,
  descriptionMaxLength: 10000,
  previewImageUrlLength: 1000,
};

export const avatarUrlLimitations = {
  imgMaxSize: 2 * 1024 * 1024, // 2mb
};

export const lessonQuestionFieldLimitations = {
  questionMaxLength: 500,
};

export const lessonAnswerFieldLimitations = {
  answerMaxLength: 500,
};

export const lessonExerciseFieldLimitations = {
  exerciseMaxLength: 2000,
};

export const lessonAlternativeLinkFieldLimitations = {
  weblinkMaxLength: lessonFieldLimitations.weblinkMaxLength,
  previewImageUrlLength: lessonFieldLimitations.previewImageUrlLength,
  reasonMaxLength: lessonFieldLimitations.descriptionMaxLength,
};

export const courseSuggestionFieldLimitations = {
  reasonMaxLength: lessonFieldLimitations.descriptionMaxLength,
  lessonTitleMaxLength: lessonFieldLimitations.titleMaxLength,
  lessonWeblinkMaxLength: lessonFieldLimitations.weblinkMaxLength,
  lessonPreviewImageUrlLength: lessonFieldLimitations.previewImageUrlLength,
  moduleTitleMaxLength: moduleFieldLimitations.titleMaxLength,
};

export const courseCommentFieldLimitations = {
  commentMaxLength: 500,
};

export const courseReviewRejectionFieldLimitations = {
  rejectionReasonMinLength: 50,
  rejectionReasonMaxLength: 1000,
};

export const newCourseCategoryRequestFieldLimitations = {
  categoryMaxLength: 50,
};
