import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertCourseSuggestionSnapToICourseSuggestion } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getCourseSuggestionQueryKey = (courseSuggestionId: string) => [
  "getCourseSuggestionQueryKey",
  courseSuggestionId,
];

interface IGetCourseSuggestionArgs {
  courseSuggestionId: string;
}

const getCourseSuggestion = async (vars: IGetCourseSuggestionArgs) => {
  const { courseSuggestionId } = vars;
  const db = getFirestore();
  const courseSuggestionDocRef = doc(
    db,
    collectionPaths.courseSuggestions,
    courseSuggestionId
  );
  const snapshot = await getDoc(courseSuggestionDocRef);
  if (snapshot.exists()) {
    return convertCourseSuggestionSnapToICourseSuggestion(
      courseSuggestionId,
      snapshot.data()
    );
  } else {
    return Promise.reject("getCourseSuggestion, courseSuggestion not found");
  }
};

export const useGetCourseSuggestionQuery = (
  vars: IGetCourseSuggestionArgs,
  enabled: boolean = true
) => {
  const { courseSuggestionId } = vars;
  return useQuery(
    getCourseSuggestionQueryKey(courseSuggestionId),
    () => getCourseSuggestion(vars),
    {
      onSettled: settledLogger("useGetCourseSuggestionQuery"),
      enabled,
    }
  );
};

export const useGetCourseSuggestionFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetCourseSuggestionArgs) => {
    const { courseSuggestionId } = vars;
    return queryClient.fetchQuery(
      getCourseSuggestionQueryKey(courseSuggestionId),
      () => getCourseSuggestion(vars)
    );
  };
};
