import { ICourse } from "../../types/firestoreMappings";
import { getModuleIdViaLessonIdFromCourse } from "../../utils/utils";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useDeleteLessonMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  const deleteLesson = async (vars: { course: ICourse; lessonId: string }) => {
    const { course, lessonId } = vars;
    const moduleId = getModuleIdViaLessonIdFromCourse(course, lessonId);
    const updatedModules = course.modules.map((module) => {
      if (module.id === moduleId) {
        const updatedLessons = module.lessons.filter((lesson) => {
          if (lesson.id === lessonId) {
            return false;
          } else {
            return true;
          }
        });
        return {
          ...module,
          lessons: updatedLessons,
        };
      } else {
        return module;
      }
    });
    await updateCourseMutation.mutate({
      modules: updatedModules,
    });
  };
  return {
    ...updateCourseMutation,
    mutate: deleteLesson,
  };
};
