import { Button, Card, Space, Typography } from "antd";
import React from "react";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { PAGE_SECTION_SPACING } from "../../../styling/styleConstants";
import { CourseCategory, CoursePublishState } from "../../../types/helperTypes";
import { convertCourseCategoryToStringAndIcon } from "../../../utils/iconAndStringUtils";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { CoursesContainer } from "../../course/course-list/CoursesContainer";
import { useNavigation } from "../../../ui-hooks/useNavigation";

interface IExploreCourseCategoryProps {
  category: CourseCategory;
  isCategoryEmpty: boolean;
}

export const ExploreCourseCategory: React.FunctionComponent<
  IExploreCourseCategoryProps
> = (props) => {
  const { category, isCategoryEmpty } = props;
  const categoryStringAndIcon = convertCourseCategoryToStringAndIcon(category);

  if (category === CourseCategory.Unknown) {
    return (
      <PageTypography
        title={"This category does not exist"}
        level={PageTypographyLevel.PageSection}
        isGoBackEnabled={true}
      />
    );
  }

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={`Courses in ${categoryStringAndIcon.string}`}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
          />
        </div>
        {isCategoryEmpty ? (
          <EmptyCategory category={category} />
        ) : (
          <CoursesInCatgeory category={category} />
        )}
      </Space>
    </LayoutContent>
  );
};

const CoursesInCatgeory: React.FunctionComponent<{
  category: CourseCategory;
}> = (props) => {
  const { category } = props;
  return (
    <>
      <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
        <Card
          title={
            <Space direction={"horizontal"}>
              <CheckOutlined />
              <Typography>{"Reviewed"}</Typography>
            </Space>
          }
          size={"small"}
        >
          <CoursesContainer
            listCoursesQueryParams={{
              category,
              publishStates: [CoursePublishState.Published],
              sortByUpdatedAt: true,
            }}
          />
        </Card>
      </div>
      <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
        <Card
          title={
            <Space direction={"horizontal"}>
              <EditOutlined />
              <Typography>{"Under Construction"}</Typography>
            </Space>
          }
          size={"small"}
        >
          <CoursesContainer
            listCoursesQueryParams={{
              category,
              publishStates: [
                CoursePublishState.Draft,
                CoursePublishState.InReview,
              ],
              sortByUpdatedAt: true,
            }}
          />
        </Card>
      </div>
    </>
  );
};

const EmptyCategory: React.FunctionComponent<{
  category: CourseCategory;
}> = (props) => {
  const { category } = props;
  const nav = useNavigation();
  return (
    <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "There's nothing here yet. Be the first to create a course in this category."
        }
      </Typography>
      <Button
        type={"primary"}
        onClick={() => nav.goToPaths.newCourse({ category })}
      >
        {"New Course"}
      </Button>
    </div>
  );
};
