import { notification } from "antd";
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { ICourse } from "../../types/firestoreMappings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { getCourseQueryKey } from "../queries/useGetCourseQuery";
import { listCoursesQueryKeySimple } from "../queries/useListCoursesQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateCourseMutation = (
  courseId: string,
  omitUpdatedAt: boolean = false
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateCourse = async (vars: Partial<ICourse>) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject("useUpdateCourseMutation, error - no user found");
    }
    const db = getFirestore();
    const courseDocRef = doc(db, collectionPaths.courses, courseId);
    const courseUpdates = {
      ...vars,
    };
    if (!omitUpdatedAt) {
      (courseUpdates as any).updatedAt = serverTimestamp();
    }
    await updateDoc(courseDocRef, courseUpdates);
    return vars;
  };
  return useMutation(updateCourse, {
    onSettled: settledLogger("useUpdateCourseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(getCourseQueryKey(courseId), {
        refetchInactive: true,
      });
      queryClient.invalidateQueries([listCoursesQueryKeySimple], {
        refetchInactive: true,
      });
    },
  });
};
