import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { ClickableWrapper } from "./ClickableWrapper";

interface IBackButtonProps {
  style?: React.CSSProperties;
  disableClick?: boolean;
}

export const BackButton: React.FunctionComponent<IBackButtonProps> = (
  props
) => {
  const { style, disableClick } = props;
  const nav = useNavigation();
  return (
    <ClickableWrapper
      onClick={disableClick ? undefined : nav.window.goBack}
      style={{
        fontSize: "1.5rem",
        cursor: "pointer",
        ...style,
      }}
    >
      <ArrowLeftOutlined />
    </ClickableWrapper>
  );
};
