import React, { useState } from "react";
import { useListCourseSuggestionsQuery } from "../../../firestore/queries/useListCourseSuggestionsQuery";
import { ICourse } from "../../../types/firestoreMappings";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { placeLessonSuggestionsAmongstLessons } from "../../../utils/utils";
import { CourseStructure } from "./CourseStructure";

interface ICourseStructureContainerProps {
  course: ICourse;
}

export const CourseStructureContainer: React.FunctionComponent<
  ICourseStructureContainerProps
> = (props) => {
  const { course } = props;
  const [isSuggestionsVisible, setIsSuggestionsVisible] =
    useState<boolean>(true);
  const {
    data: newLessonSuggestions,
    isNoMoreToFetch: isNoMoreNewLessonSuggestionsToFetch,
    fetchNextPage: fetchMoreNewLessonSuggestions,
  } = useListCourseSuggestionsQuery(
    {
      courseId: course.id,
      type: CourseSuggestionType.NewLesson,
      state: CourseSuggestionState.Open,
      sortByUpvotes: true,
      numItemsPerPage: 20,
    },
    isSuggestionsVisible
  );

  const lessonsAndSuggestionsMap = placeLessonSuggestionsAmongstLessons(
    course,
    newLessonSuggestions || [],
    !isSuggestionsVisible
  );

  return (
    <CourseStructure
      course={course}
      lessonsAndSuggestionsMap={lessonsAndSuggestionsMap}
      isNoMoreNewLessonSuggestionsToFetch={isNoMoreNewLessonSuggestionsToFetch}
      fetchMoreNewLessonSuggestions={fetchMoreNewLessonSuggestions}
      isSuggestionsVisible={isSuggestionsVisible}
      toggleSuggestionsVisibility={() =>
        setIsSuggestionsVisible(!isSuggestionsVisible)
      }
    />
  );
};
