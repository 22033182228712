import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertCourseReviewRejectionSnapToICourseReviewRejection,
  ICourseReviewRejection,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

interface ListCourseReviewRejectionsQueryParams {
  courseId: string;
}

const numItemsPerPage = 5;

export const listCourseReviewRejectionsQueryKey = (
  params: ListCourseReviewRejectionsQueryParams
) => {
  return ["listCourseReviewRejectionsQueryKey", params];
};

export const useListCourseReviewRejectionsQuery = (
  params: ListCourseReviewRejectionsQueryParams,
  enabled: boolean = true
) => {
  const listCourseReviewRejections = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ICourseReviewRejection[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const { courseId } = params;
    let isLastPage = false;
    const db = getFirestore();
    const courseReviewRejectionsCollectionRef = collection(
      db,
      collectionPaths.courseReviewRejections
    );
    const queryConstraints = [];
    queryConstraints.push(where("courseId", "==", courseId));

    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      courseReviewRejectionsCollectionRef,
      orderBy("createdAt", "desc"),
      ...queryConstraints,
      limit(numItemsPerPage)
    );
    const qSnapshot = await getDocs(q);
    const courseReviewRejections = qSnapshot.docs.map((snapshot) =>
      convertCourseReviewRejectionSnapToICourseReviewRejection(
        snapshot.id,
        snapshot.data()
      )
    );

    if (courseReviewRejections.length < numItemsPerPage) {
      isLastPage = true;
    }
    return {
      realData: courseReviewRejections,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listCourseReviewRejectionsQueryKey(params),
    listCourseReviewRejections,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListCourseReviewRejectionsQuery"),
      enabled,
    }
  );

  let realDataAllPages: ICourseReviewRejection[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }
  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
