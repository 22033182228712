import { Button, Space, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { locStrings } from "../../localization/locStrings";
import {
  PAGE_SECTION_SPACING,
  zIndexPriorities,
} from "../../styling/styleConstants";
import { ICourse } from "../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { LayoutContent } from "../common/LayoutContent";
import { OwnersOfCourseLinks } from "../common/OwnersOfCourseLinks";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { baseUrlWithPath, useNavigation } from "../../ui-hooks/useNavigation";
import { RichTextRenderer } from "../common/RichTextRenderer";
import { CoursePublishState } from "../../types/helperTypes";
import { CourseInfoReviewContainer } from "./CourseInfoReviewContainer";
import { CourseSuggestionsContainer } from "../course-suggestions/read/CourseSuggestionsContainer";
import { CourseFavoriteButtonContainer } from "../course-favorites/CourseFavoriteButtonContainer";
import { ShareLinkButton } from "../common/ShareLinkButton";
import { PublishStateTag } from "../common/PublishStateTag";
import { CourseCategoryTag } from "../common/CourseCategoryTag";
import { CourseInfoPublishStateRequestModal } from "./publish-state/CourseInfoPublishStateRequestModal";
import { useScrollToElementRef } from "../../ui-hooks/useScrollToElementRef";
import { CourseCollaborateBox } from "./CourseCollaborateBox";
import { calculateModuleAndLessonCountsInCourse } from "../../utils/utils";
import { CourseStructureContainer } from "./course-structure/CourseStructureContainer";

interface ICourseInfoProps {
  course: ICourse;
  hasLessons: boolean;
  startAtFirstLesson: () => void;
  isMeReviewer: boolean;
}

export const CourseInfo: React.FunctionComponent<ICourseInfoProps> = (
  props
) => {
  const { course, hasLessons, startAtFirstLesson, isMeReviewer } = props;
  const nav = useNavigation();
  const parsedCourseSuggestionId = nav.urlSearchParams.courseSuggestionId();
  const { elRef: suggestionsSectionRef } = useScrollToElementRef(
    !!parsedCourseSuggestionId
  );
  const [
    isPublishStateRequestModalVisible,
    setIsPublishStateRequestModalVisible,
  ] = useState<boolean>(false);
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(course.id);
  const isPublishStateReviewerOptionsVisible =
    !isMeUserOwnerOfCourse &&
    isMeReviewer &&
    (course.publishState === CoursePublishState.InReview ||
      course.publishState === CoursePublishState.Published);
  const moduleAndLessonCounts = calculateModuleAndLessonCountsInCourse(course);

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div
          style={{
            marginBottom: PAGE_SECTION_SPACING,
          }}
        >
          <Space direction={"vertical"} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <PageTypography
                title={course.title}
                level={PageTypographyLevel.PageTitle}
                subTitle={<OwnersOfCourseLinks owners={course.owners} />}
              />
              {isMeUserOwnerOfCourse && (
                <Button
                  onClick={() => nav.goToPaths.editCourse(course.id)}
                  icon={<EditOutlined />}
                >
                  {"Edit"}
                </Button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <PublishStateTag
                publishState={course.publishState}
                onClick={
                  isMeUserOwnerOfCourse
                    ? () => setIsPublishStateRequestModalVisible(true)
                    : undefined
                }
              />
              {course.categories.map((category) => (
                <CourseCategoryTag
                  key={`CourseInfo-AboutCategoriesTag-${category}`}
                  category={category}
                />
              ))}
            </div>
          </Space>
        </div>
        {isPublishStateReviewerOptionsVisible && (
          <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
            <CourseInfoReviewContainer
              courseId={course.id}
              publishState={course.publishState}
            />
          </div>
        )}
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <Space direction={"horizontal"}>
            <Tooltip
              title={!hasLessons ? "There is no content yet" : undefined}
              zIndex={zIndexPriorities.tooltipOutsideModal}
            >
              <Button
                type={"primary"}
                onClick={startAtFirstLesson}
                size={"large"}
                disabled={!hasLessons}
              >
                {locStrings.startCourse.value()}
              </Button>
            </Tooltip>
            <CourseFavoriteButtonContainer
              courseId={course.id}
              size={"large"}
            />
            <ShareLinkButton
              modalTitle={"Share this course"}
              link={baseUrlWithPath.courseInfo(course.id)}
              size={"large"}
            />
          </Space>
        </div>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <div style={{ marginBottom: "0.5rem" }}>
            <PageTypography
              title={"About this Course"}
              level={PageTypographyLevel.PageSection}
            />
          </div>
          <RichTextRenderer htmlString={course.description} />
        </div>
        {isMeUserOwnerOfCourse && moduleAndLessonCounts.lessonsCount > 0 && (
          <CourseCollaborateBox courseId={course.id} />
        )}
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <CourseStructureContainer course={course} />
        </div>
        <div
          style={{ marginBottom: PAGE_SECTION_SPACING }}
          ref={suggestionsSectionRef}
        >
          <CourseSuggestionsContainer
            courseId={course.id}
            hideNewButtonOnEmptyState={true}
            queryParams={{ topItemId: parsedCourseSuggestionId }}
          />
        </div>
      </Space>
      {isMeUserOwnerOfCourse && (
        <CourseInfoPublishStateRequestModal
          courseId={course.id}
          visible={isPublishStateRequestModalVisible}
          onClose={() => setIsPublishStateRequestModalVisible(false)}
        />
      )}
    </LayoutContent>
  );
};
