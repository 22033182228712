import React from "react";
import { useParams } from "react-router-dom";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseSuggestionsOverview } from "./CourseSuggestionsOverview";

interface ICourseSuggestionsOverviewContainerProps {}
type ICourseSuggestionsOverviewContainerParams = {
  courseId: string;
};

export const CourseSuggestionsOverviewContainer: React.FunctionComponent<
  ICourseSuggestionsOverviewContainerProps
> = (props) => {
  const { courseId } = useParams<ICourseSuggestionsOverviewContainerParams>();
  const {
    data: course,
    isLoading: isCourseLoading,
    error: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });

  if (isCourseError) {
    return <GenericError />;
  }
  if (isCourseLoading || !course) {
    return <GenericLoader />;
  }

  return <CourseSuggestionsOverview course={course} />;
};
