import { Space, Typography } from "antd";
import React from "react";
import { CourseSuggestionState } from "../../../types/helperTypes";
import { ICourseSuggestion } from "../../../types/firestoreMappings";
import { themeColors } from "../../../styling/styleConstants";
import {
  convertCourseSuggestionStateToIcon,
  convertCourseSuggestionStateToString,
} from "../../../utils/iconAndStringUtils";

interface ICourseSuggestionStateDisplayReadProps {
  courseSuggestion: ICourseSuggestion;
}

export const CourseSuggestionStateDisplayRead: React.FunctionComponent<
  ICourseSuggestionStateDisplayReadProps
> = (props) => {
  const { courseSuggestion } = props;
  const icon = convertCourseSuggestionStateToIcon(courseSuggestion.state, true);

  let renderState = null;
  switch (courseSuggestion.state) {
    case CourseSuggestionState.Open:
      // Show nothing for clean UI
      renderState = (
        <Typography style={{ color: themeColors.secondaryFontColor }}>
          {convertCourseSuggestionStateToString(CourseSuggestionState.Open)}
        </Typography>
      );
      break;
    case CourseSuggestionState.Accepted:
      renderState = (
        <Space direction={"horizontal"}>
          {icon}
          <Typography style={{ color: themeColors.successGreen }}>
            {convertCourseSuggestionStateToString(
              CourseSuggestionState.Accepted
            )}
          </Typography>
        </Space>
      );
      break;
    case CourseSuggestionState.Rejected:
      renderState = (
        <Space direction={"horizontal"}>
          {icon}
          <Typography style={{ color: themeColors.errorRed }}>
            {convertCourseSuggestionStateToString(
              CourseSuggestionState.Rejected
            )}
          </Typography>
        </Space>
      );
      break;
  }
  return renderState;
};
