import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteModuleMutation } from "../../../firestore/mutations/useDeleteModuleMutation";
import { useUpdateModuleMutation } from "../../../firestore/mutations/useUpdateModuleMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { locStrings } from "../../../localization/locStrings";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { getModuleFromCourse, isEmptyHtmlString } from "../../../utils/utils";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { ModuleEdit } from "./ModuleEdit";
import { isValidModuleInputWithErrorNotify } from "../../../utils/validInputUtils";

interface IModuleUpdateContainerProps {}

type IModuleUpdateContainerParams = {
  courseId: string;
  moduleId: string;
};

export const ModuleUpdateContainer: React.FunctionComponent<
  IModuleUpdateContainerProps
> = (props) => {
  const { courseId, moduleId } = useParams<IModuleUpdateContainerParams>();
  const nav = useNavigation();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const {
    mutate: updateModule,
    isLoading: isModuleUpdateLoading,
    isSuccess: isModuleUpdateSuccessful,
  } = useUpdateModuleMutation(courseId || "");
  const {
    mutate: deleteModule,
    isLoading: isModuleDeleteLoading,
    isSuccess: isModuleDeleteSuccessful,
  } = useDeleteModuleMutation(courseId || "");
  const [moduleTitle, setModuleTitle] = useState<string>("");
  const [moduleDescription, setModuleDescription] = useState<string>("");
  const onModuleTitleChange = (s: string) => {
    setModuleTitle(s);
  };

  useEffect(() => {
    if (isModuleUpdateSuccessful || isModuleDeleteSuccessful) {
      nav.window.goBack();
    }
  }, [isModuleUpdateSuccessful, isModuleDeleteSuccessful, nav.window]);

  useEffect(() => {
    if (course && moduleId) {
      const currentModule = getModuleFromCourse(moduleId, course);
      if (currentModule) {
        setModuleTitle(currentModule.title);
        setModuleDescription(currentModule.description || "");
      }
    }
  }, [course, moduleId]);

  const onUpdateModule = async () => {
    const isValid = isValidModuleInputWithErrorNotify().forUpdate({
      title: moduleTitle,
      description: moduleDescription,
    });

    if (isValid) {
      if (course && moduleId) {
        updateModule({
          course,
          moduleId,
          title: moduleTitle,
          description: isEmptyHtmlString(moduleDescription)
            ? undefined
            : moduleDescription,
        });
      } else {
        notification.error({ message: "Course not found" });
      }
    }
  };

  const onDeleteModule = () => {
    if (course && moduleId) {
      deleteModule({ course, moduleId });
    } else {
      notification.error({ message: "Module not found" });
    }
  };

  if (isCourseError) {
    return <GenericError />;
  }

  if (
    isModuleUpdateLoading ||
    isModuleDeleteLoading ||
    isCourseLoading ||
    !course
  ) {
    return <GenericLoader />;
  }

  return (
    <ModuleEdit
      pageTitle={locStrings.editModulePageTitle.value()}
      moduleTitle={moduleTitle}
      moduleDescription={moduleDescription}
      onModuleTitleChange={onModuleTitleChange}
      setModuleDescription={setModuleDescription}
      onSubmit={onUpdateModule}
      onDeleteModule={onDeleteModule}
    />
  );
};
