import { Card, Space, Typography } from "antd";
import React from "react";
import { NotificationType } from "../../types/helperTypes";
import { ClickableWrapper } from "../common/ClickableWrapper";
import { BadgeWrapper } from "../common/BadgeWrapper";
import { locStrings } from "../../localization/locStrings";

interface INotificationItemNotFoundProps {
  type: NotificationType;
  isRead: boolean;
  onMarkAsRead: () => void;
}

export const NotificationItemNotFound: React.FunctionComponent<
  INotificationItemNotFoundProps
> = (props) => {
  const { type, isRead, onMarkAsRead } = props;
  let title = "Notification not found";
  switch (type) {
    case NotificationType.LessonAlternativeLink:
      title = "This alternative or course was deleted";
      break;
    case NotificationType.LessonExercise:
      title = "This exercise or course was deleted";
      break;
    case NotificationType.LessonQuestion:
      title = "This question or course was deleted";
      break;
    case NotificationType.LessonAnswer:
      title = "This answer or course was deleted";
      break;
    case NotificationType.CourseReviewRejection:
      title = "This review or course was deleted";
      break;
    case NotificationType.CourseSuggestion:
      title = "This suggestion or course was deleted";
      break;
    case NotificationType.CourseComment:
      title = "This comment or course was deleted";
      break;
  }
  return (
    <BadgeWrapper
      isBadgeVisible={!isRead}
      ribbonConfig={{ text: locStrings.new.value() }}
    >
      <ClickableWrapper onClick={onMarkAsRead}>
        <Card>
          <Space direction={"horizontal"}>
            <Typography.Text italic={true}>{title}</Typography.Text>
          </Space>
        </Card>
      </ClickableWrapper>
    </BadgeWrapper>
  );
};
