// LoA logo font = Google fonts "Righteous"

export const themeColors = {
  primary: "#4548D4",
  primaryLight: "#9495e7",
  primaryVeryLight: "#dfdff9",
  bodyBackground: "#ffffff",
  headerBackground: "#121442",
  footerBackground: "#F0F2F5",
  lessonWeblinkCardBackground: "#29334a",
  lessonWeblinkFontColor: "#cdd9ff",
  highlightedNavCardBackgroundColor: "#d6e6ff",
  lightGrayBackgroundColor: "#FAFAFA",
  publishStateDraftYellow: "#fffdde",
  publishStateInReviewOrange: "#ffe7d2",
  publishStatePublishedGreen: "#e6ffde",
  secondaryFontColor: "#8C8C8C",
  successGreen: "green",
  errorRed: "red",
  courseFavoriteHeartPink: "#e74848",
  discordButtonBackground: "#353985",
  previewImageUrlBackground: "#ededed",
  previewImageUrlDefaultFont: "#a9a9a9",
  upvoteButton: "#565656",
};

export const courseMetadataTypeColors = {
  alternativeLink: "black",
  lessonQuestion: "black",
  lessonAnswer: "black",
  lessonExercise: "black",
  courseReviewRejection: "black",
  courseSuggestion: "black",
  courseSuggestionGeneralFeedback: "black",
  courseSuggestionNewLesson: "black",
  courseSuggestionNewModule: "black",
  courseComment: "black",
};

export const pageFontWeights = {
  homeSectionTitle: 300,
};

export const fontSizes = {
  small: "0.7rem",
};

export const zIndexPriorities = {
  sisuModal: 99,
  lessonExerciseCreateModal: 98,
  lessonQuestionCreateModal: 97,
  lessonAlternativeLinkCreateModal: 96,
  courseSuggestionNewLessonCreateModal: 95,
  courseSuggestionNewModuleCreateModal: 94,
  courseSuggestionGeneralFeedbackCreateModal: 93,
  newCourseCategoryRequestModal: 92,
  newLinkModal: 91,
  newModuleModal: 90,
  courseStructureLessonSuggestionPopover: 90,
  shareLinkModal: 89,
  inLessonSider: 88,
  tooltipOutsideModal: 87,
};

export const PAGE_MAX_WIDTH = "60rem";
export const PAGE_SECTION_SPACING = "2rem";
export const PAGE_HORIZONTAL_SPACING_LARGER_THAN_MD = "2rem";
export const PAGE_HORIZONTAL_SPACING_SMALLER_THAN_MD = "1rem";

export const BREAKPOINT_MD = "md";

export const SISU_INPUT_WIDTH = "17rem";
