import React from "react";
import {
  ListLessonExercisesQueryParams,
  useListLessonExercisesQuery,
} from "../../firestore/queries/useListLessonExercisesQuery";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { LessonExercises } from "./LessonExercises";

interface ILessonExercisesContainerProps {
  params: ListLessonExercisesQueryParams;
  shouldSkipFetch: boolean;
}

export const LessonExercisesContainer: React.FunctionComponent<
  ILessonExercisesContainerProps
> = (props) => {
  const { params, shouldSkipFetch } = props;
  const {
    data: exercises,
    fetchNextPage: fetchMoreExercises,
    isNoMoreToFetch: isNoMoreExercisesToFetch,
    isLoading: isExercisesLoading,
    isError: isExercisesError,
  } = useListLessonExercisesQuery(params, !shouldSkipFetch);

  if (isExercisesError) {
    return <GenericError />;
  }

  if (isExercisesLoading || !exercises) {
    return <GenericLoader />;
  }

  return (
    <LessonExercises
      exercises={exercises}
      fetchMoreExercises={fetchMoreExercises}
      isNoMoreExercisesToFetch={isNoMoreExercisesToFetch}
    />
  );
};
