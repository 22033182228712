import React, { CSSProperties, ReactNode } from "react";

interface IClickableWrapperProps {
  children: ReactNode;
  href?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const ClickableWrapper: React.FunctionComponent<
  IClickableWrapperProps
> = (props) => {
  const { onClick, href, children, style } = props;
  if (href) {
    return (
      <a href={href} target={"_blank"} rel={"noreferrer"}>
        {children}
      </a>
    );
  }
  return (
    <div
      onClick={onClick}
      style={{ ...style, cursor: !!onClick ? "pointer" : "default" }}
    >
      {children}
    </div>
  );
};
