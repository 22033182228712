import { Button, Card, Space, Typography } from "antd";
import { EnterOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { ICourseComment } from "../../types/firestoreMappings";
import { CardWithUserHeader } from "../common/CardWithUserHeader";
import { fontSizes, themeColors } from "../../styling/styleConstants";
import { CreateCourseCommentInputContainer } from "./write/CreateCourseCommentInputContainer";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { UpvoteButtonContainer } from "../upvotes/UpvoteButtonContainer";
import { MetadataType } from "../../types/helperTypes";

interface ICourseCommentProps {
  courseComment: ICourseComment;
  repliedToComment?: ICourseComment;
}

export const CourseComment: React.FunctionComponent<ICourseCommentProps> = (
  props
) => {
  const { courseComment, repliedToComment } = props;
  const [isReplyInputVisible, setIsReplyInputVisible] =
    useState<boolean>(false);
  const nav = useNavigation();
  const parsedCourseCommentIdForLesson =
    nav.urlSearchParams.getCourseCommentIdForLesson();
  const parsedCourseCommentIdForAlternativeLink =
    nav.urlSearchParams.getCourseCommentIdForAlternativeLink();
  const wasNavigatedToAndShouldHighlight =
    parsedCourseCommentIdForLesson === courseComment.id ||
    parsedCourseCommentIdForAlternativeLink === courseComment.id;

  return (
    <CardWithUserHeader
      userId={courseComment.owner}
      date={courseComment.createdAt}
      courseIdToCheckForOwnership={courseComment.courseId}
      className={
        wasNavigatedToAndShouldHighlight
          ? "highlight-light-blue-and-fade"
          : undefined
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "0.5rem",
          width: "100%",
        }}
      >
        {!!repliedToComment && (
          <Card
            size={"small"}
            style={{ backgroundColor: themeColors.previewImageUrlBackground }}
          >
            <Typography
              style={{
                fontSize: fontSizes.small,
              }}
            >
              {"Replying to:"}
            </Typography>
            <Typography.Text style={{ wordBreak: "break-word" }} italic={true}>
              {repliedToComment.comment}
            </Typography.Text>
          </Card>
        )}
        <Space direction={"horizontal"} style={{ alignItems: "center" }}>
          <UpvoteButtonContainer
            upvoteCount={courseComment.upvotes}
            metadataType={MetadataType.CourseComment}
            metadataId={courseComment.id}
          />
          <Typography style={{ wordBreak: "break-word" }}>
            {courseComment.comment}
          </Typography>
        </Space>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            icon={<EnterOutlined />}
            type={"text"}
            size={"small"}
            style={{
              color: themeColors.secondaryFontColor,
              fontSize: fontSizes.small,
            }}
            onClick={() => setIsReplyInputVisible(!isReplyInputVisible)}
          >
            {"Reply"}
          </Button>
        </div>
        {isReplyInputVisible && (
          <CreateCourseCommentInputContainer
            courseId={courseComment.courseId}
            metadataType={courseComment.metadataType}
            metadataId={courseComment.metadataId}
            replyToId={courseComment.id}
            placeholder={"Leave a response..."}
          />
        )}
      </div>
    </CardWithUserHeader>
  );
};
