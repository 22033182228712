import { Button, Collapse, Space, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import React from "react";
import { ICourse } from "../../types/firestoreMappings";
import { calculateSurroundingLessonIdsOfIndex } from "../../utils/utils";
import { CourseStructureLessonItem } from "../course/course-structure/CourseStructureLessonItem";
import { LessonPlacementInCourse } from "../../types/helperTypes";

interface IPlacementInCourseSelectProps {
  course: ICourse;
  onSelectPlacement: (placement: LessonPlacementInCourse) => void;
}

const generateModuleCollapseKey = (moduleId: string) =>
  `PlacementInCourse-Module-${moduleId}`;

export const PlacementInCourseSelect: React.FunctionComponent<
  IPlacementInCourseSelectProps
> = (props) => {
  const { course, onSelectPlacement } = props;
  const allModuleKeys = course.modules.map((module) =>
    generateModuleCollapseKey(module.id)
  );
  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        maxHeight: "50vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {
        // @ts-ignore
        <Collapse activeKey={allModuleKeys} collapsible={"header"}>
          {course.modules.map((module, moduleIndex) => {
            return (
              // @ts-ignore
              <Collapse.Panel
                key={generateModuleCollapseKey(module.id)}
                header={
                  <Space direction={"horizontal"}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {moduleIndex + 1}
                      {"."}
                    </Typography>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {module.title}
                    </Typography>
                  </Space>
                }
                showArrow={false}
              >
                <Space direction={"vertical"} style={{ display: "flex" }}>
                  {module.lessons.length > 0 ? (
                    module.lessons.map((lesson, lessonIndex) => {
                      const isLastLesson =
                        lessonIndex === module.lessons.length - 1;
                      return (
                        <div key={`PlacementInCourse-LessonItem-${lesson.id}`}>
                          <SelectPlacementButton
                            onClick={() =>
                              onSelectPlacement(
                                calculateSurroundingLessonIdsOfIndex(
                                  course,
                                  module.id,
                                  lessonIndex
                                )
                              )
                            }
                            style={{ marginBottom: "8px" }}
                          />
                          <div style={{ opacity: "50%", cursor: "default" }}>
                            <CourseStructureLessonItem
                              lessonTitle={lesson.title}
                              lessonWeblink={""}
                              previewImageUrl={lesson.previewImageUrl}
                              tabs={[]}
                            />
                          </div>
                          {isLastLesson && (
                            <SelectPlacementButton
                              onClick={() =>
                                onSelectPlacement(
                                  calculateSurroundingLessonIdsOfIndex(
                                    course,
                                    module.id,
                                    lessonIndex + 1
                                  )
                                )
                              }
                              style={{ marginTop: "8px" }}
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SelectPlacementButton
                      key={`PlacementInCourse-EmptyItem-${module.id}`}
                      onClick={() =>
                        onSelectPlacement(
                          calculateSurroundingLessonIdsOfIndex(
                            course,
                            module.id,
                            0
                          )
                        )
                      }
                    />
                  )}
                </Space>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      }
    </Space>
  );
};

const SelectPlacementButton: React.FunctionComponent<{
  onClick: () => void;
  style?: React.CSSProperties;
}> = (props) => {
  const { onClick, style } = props;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        onClick={onClick}
        style={style}
        className={"grow-on-hover"}
        type={"primary"}
        icon={<PlusCircleOutlined />}
      >
        {"Add link here"}
      </Button>
    </div>
  );
};
