import { notification } from "antd";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateCourseFavoriteId } from "../idHelpers";
import { getCourseFavoriteQueryKey } from "../queries/useGetCourseFavoriteQuery";
import { listCourseFavoritesQueryKey } from "../queries/useListCourseFavoritesQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteCourseFavoriteMutation = (courseId: string) => {
  const queryClient = useQueryClient();
  const { user, isSignedIn } = useUserAuth();
  const courseFavoriteId = useGenerateCourseFavoriteId(courseId);
  const deleteCourseFavorite = () => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useDeleteCourseFavoriteMutation, error - no user found"
      );
    }
    const db = getFirestore();
    if (!courseFavoriteId) {
      return Promise.reject(
        "useCreateUpvoteMutation, courseFavoriteId not properly generated"
      );
    }
    const upvoteDocRef = doc(
      db,
      collectionPaths.courseFavorites,
      courseFavoriteId
    );
    return deleteDoc(upvoteDocRef);
  };
  return useMutation(deleteCourseFavorite, {
    onSettled: settledLogger("useDeleteCourseFavoriteMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        getCourseFavoriteQueryKey({ courseId, isSignedIn }),
        {
          refetchInactive: true,
          refetchPage: (page, index) => true,
        }
      );
      queryClient.invalidateQueries(
        listCourseFavoritesQueryKey({ owner: user?.uid || "" }),
        { refetchInactive: true, refetchPage: (page, index) => true }
      );
    },
  });
};
