import { ICourse } from "../../types/firestoreMappings";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useDeleteModuleMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  const deleteModule = async (vars: { course: ICourse; moduleId: string }) => {
    const { course, moduleId } = vars;
    const updatedModules = course.modules.filter((module) => {
      if (module.id !== moduleId) {
        return true;
      } else {
        return false;
      }
    });
    await updateCourseMutation.mutate({
      modules: updatedModules,
    });
  };
  return {
    ...updateCourseMutation,
    mutate: deleteModule,
  };
};
