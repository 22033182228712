import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateCourseMutation } from "../../../firestore/mutations/useUpdateCourseMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { CourseReorderScaffold } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import {
  generateCourseFromReorderScaffold,
  generateReorderScaffoldFromCourse,
  moveLessonDownInScaffold,
  moveLessonUpInScaffold,
  moveModuleDownInScaffold,
  moveModuleUpInScaffold,
} from "../../../utils/utils";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseReorder } from "./CourseReorder";

interface ICourseReorderContainerProps {}
type ICourseReorderContainerParams = {
  courseId: string;
};

export const CourseReorderContainer: React.FunctionComponent<
  ICourseReorderContainerProps
> = (props) => {
  const { courseId } = useParams<ICourseReorderContainerParams>();
  const nav = useNavigation();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const {
    mutate: updateCourse,
    isLoading: isUpdateCourseLoading,
    isSuccess: isUpdateCourseSuccess,
    isError: isUpdateCourseError,
  } = useUpdateCourseMutation(courseId || "");
  const [reorderScaffold, setReorderScaffold] = useState<CourseReorderScaffold>(
    { modules: [] }
  );

  useEffect(() => {
    if (isUpdateCourseSuccess) {
      nav.goToPaths.courseInfo(courseId || "");
    }
  }, [isUpdateCourseSuccess, courseId, nav.goToPaths]);

  useEffect(() => {
    if (course) {
      // Set initial scaffold when course first loads
      setReorderScaffold(generateReorderScaffoldFromCourse(course));
    }
  }, [course, setReorderScaffold]);

  if (isCourseError || isUpdateCourseError) {
    return <GenericError />;
  }

  if (isCourseLoading || isUpdateCourseLoading || !course) {
    return <GenericLoader />;
  }

  const onMoveDownModule = (moduleId: string) => {
    const newScaffold = moveModuleDownInScaffold(reorderScaffold, moduleId);
    if (newScaffold) {
      setReorderScaffold(newScaffold);
    } else {
      notification.warn({ message: "Module cannot go down" });
    }
  };
  const onMoveUpModule = (moduleId: string) => {
    const newScaffold = moveModuleUpInScaffold(reorderScaffold, moduleId);
    if (newScaffold) {
      setReorderScaffold(newScaffold);
    } else {
      notification.warn({ message: "Module cannot go up" });
    }
  };
  const onMoveDownLesson = (lessonId: string) => {
    const newScaffold = moveLessonDownInScaffold(reorderScaffold, lessonId);
    if (newScaffold) {
      setReorderScaffold(newScaffold);
    } else {
      notification.warn({ message: "Lesson cannot go down" });
    }
  };
  const onMoveUpLesson = (lessonId: string) => {
    const newScaffold = moveLessonUpInScaffold(reorderScaffold, lessonId);
    if (newScaffold) {
      setReorderScaffold(newScaffold);
    } else {
      notification.warn({ message: "Lesson cannot go up" });
    }
  };

  const reorderedCourse = generateCourseFromReorderScaffold(
    course,
    reorderScaffold
  );

  const onUpdateOrderOfCourse = () => {
    updateCourse({ modules: reorderedCourse.modules });
  };

  return (
    <CourseReorder
      course={reorderedCourse}
      reorderModeConfig={{
        onMoveDownLesson,
        onMoveUpLesson,
        onMoveDownModule,
        onMoveUpModule,
      }}
      onSubmit={onUpdateOrderOfCourse}
    />
  );
};
