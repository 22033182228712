import { Typography } from "antd";
import React from "react";

interface ITypographyBoldProps {
  children: React.ReactNode;
  boldedPrefix: string;
  style?: React.CSSProperties;
}

export const TypographyBold: React.FunctionComponent<ITypographyBoldProps> = (
  props
) => {
  const { children, boldedPrefix, style } = props;
  return (
    <Typography style={style}>
      <Typography.Text strong={true}>{boldedPrefix}</Typography.Text> {children}
    </Typography>
  );
};
