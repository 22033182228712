import React, { useState } from "react";
import { useDeleteLessonAlternativeLinkMutation } from "../../firestore/mutations/useDeleteLessonAlternativeLinkMutation";
import { useUpdateLessonAlternativeLinkMutation } from "../../firestore/mutations/useUpdateLessonAlternativeLinkMutation";
import { useCountCourseCommentsQuery } from "../../firestore/queries/useCountCourseCommentsQuery";
import { useGetWeblinkPreviewFetch } from "../../firestore/queries/useGetWeblinkPreviewQuery";
import { ILessonAlternativeLink } from "../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useIsMeUserOwnerOfLessonAlternativeLink } from "../../ui-hooks/useIsMeUserOwnerOfLessonAlternativeLink";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { GenericLoader } from "../common/GenericLoader";
import { LessonAlternativeLink } from "./LessonAlternativeLink";

interface ILessonAlternativeLinkContainerProps {
  alternativeLink: ILessonAlternativeLink;
}

export const LessonAlternativeLinkContainer: React.FunctionComponent<
  ILessonAlternativeLinkContainerProps
> = (props) => {
  const { alternativeLink } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const fetchWeblinkPreview = useGetWeblinkPreviewFetch();
  const { data: commentCountForAlternativeLink } = useCountCourseCommentsQuery({
    metadataId: alternativeLink.id,
  });
  const {
    mutate: deleteAlternativeLink,
    isLoading: isDeleteAlternativeLinkLoading,
  } = useDeleteLessonAlternativeLinkMutation(
    alternativeLink.id,
    alternativeLink.courseId,
    alternativeLink.lessonId
  );
  const {
    mutate: updateAlternativeLink,
    isLoading: isUpdateAlternativeLinkLoading,
  } = useUpdateLessonAlternativeLinkMutation(
    alternativeLink.id,
    alternativeLink.courseId,
    alternativeLink.lessonId
  );
  const [isLoadingWeblinkPreview, setIsLoadingWeblinkPreview] =
    useState<boolean>(false);

  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(
    alternativeLink.courseId
  );
  const isMeUserOwnerOfAlternativeLink =
    useIsMeUserOwnerOfLessonAlternativeLink(alternativeLink);
  const isEditable = isMeUserOwnerOfCourse || isMeUserOwnerOfAlternativeLink;

  const updateAlternativeLinkWithPreviewImage = async (vars: {
    weblink: string;
    reason: string;
  }) => {
    setIsLoadingWeblinkPreview(true);
    const previewData = await fetchWeblinkPreview({ weblink: vars.weblink });
    setIsLoadingWeblinkPreview(false);
    updateAlternativeLink({
      ...vars,
      previewImageUrl: previewData.previewImageUrl,
    });
  };

  if (
    isDeleteAlternativeLinkLoading ||
    isUpdateAlternativeLinkLoading ||
    isLoadingWeblinkPreview
  ) {
    return <GenericLoader />;
  }

  return (
    <LessonAlternativeLink
      alternativeLink={alternativeLink}
      commentCountForAlternativeLink={commentCountForAlternativeLink || 0}
      updateAlternativeLink={
        isEditable
          ? forceSisuModalFxnWrapper(updateAlternativeLinkWithPreviewImage)
          : undefined
      }
      deleteAlternativeLink={
        isEditable ? forceSisuModalFxnWrapper(deleteAlternativeLink) : undefined
      }
    />
  );
};
