import React from "react";
import { SendOutlined } from "@ant-design/icons";
import { InputWithButton } from "../../common/InputWithButton";

interface ICreateCourseCommentInputProps {
  onCreateComment: () => void;
  commentInput: string;
  setCommentInput: (newCommentInput: string) => void;
  isCreateCommentLoading: boolean;
  placeholder?: string;
}

export const CreateCourseCommentInput: React.FunctionComponent<
  ICreateCourseCommentInputProps
> = (props) => {
  const {
    onCreateComment,
    commentInput,
    setCommentInput,
    isCreateCommentLoading,
    placeholder,
  } = props;

  return (
    <InputWithButton
      inputValue={commentInput}
      onInputChange={setCommentInput}
      inputPlaceholder={placeholder || "Leave a comment..."}
      buttonChildren={<SendOutlined />}
      buttonWidthInPx={50}
      onSubmit={onCreateComment}
      disabled={!commentInput}
      isLoading={isCreateCommentLoading}
    />
  );
};
