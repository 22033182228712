export const collectionPaths = {
  users: "users",
  admins: "admins",
  reviewers: "reviewers",
  courses: "courses",
  lessonQuestions: "lessonQuestions",
  lessonAnswers: "lessonAnswers",
  lessonExercises: "lessonExercises",
  lessonAlternativeLinks: "lessonAlternativeLinks",
  courseSuggestions: "courseSuggestions",
  notifications: "notifications",
  courseReviewRejections: "courseReviewRejections",
  upvotes: "upvotes",
  courseFavorites: "courseFavorites",
  newCourseCategoryRequests: "newCourseCategoryRequests",
  courseComments: "courseComments",
};
