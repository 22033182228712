import { Tooltip } from "antd";
import React from "react";
import { StarFilled } from "@ant-design/icons";
import { locStrings } from "../../localization/locStrings";
import { themeColors, zIndexPriorities } from "../../styling/styleConstants";

interface ICourseOwnerTagProps {}

export const CourseOwnerTag: React.FunctionComponent<ICourseOwnerTagProps> = (
  props
) => {
  return (
    <Tooltip
      title={locStrings.courseOwner.value()}
      zIndex={zIndexPriorities.tooltipOutsideModal}
    >
      <StarFilled style={{ color: themeColors.primaryLight }} />
    </Tooltip>
  );
};
