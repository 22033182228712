import { notification } from "antd";
import { RcFile } from "antd/lib/upload";
import React from "react";
import { useDeleteAvatarUrlMutation } from "../../firestore/mutations/useDeleteAvatarUrlMutation";
import { useUploadAvatarUrlMutation } from "../../firestore/mutations/useUploadAvatarUrlMutation";
import { locStrings } from "../../localization/locStrings";
import { IUser } from "../../types/firestoreMappings";
import { avatarUrlLimitations } from "../../utils/fieldLimitations";
import { GenericLoader } from "../common/GenericLoader";
import { SettingsAvatarEdit } from "./SettingsAvatarEdit";

interface ISettingsAvatarEditContainerProps {
  meUser: IUser;
}

export const SettingsAvatarEditContainer: React.FunctionComponent<
  ISettingsAvatarEditContainerProps
> = (props) => {
  const { meUser } = props;
  const { mutate: uploadAvatarUrl, isLoading: isAvatarUrlUploadLoading } =
    useUploadAvatarUrlMutation();
  const { mutate: deleteAvatarUrl, isLoading: isAvatarUrlDeleteLoading } =
    useDeleteAvatarUrlMutation();

  const onAvatarUrlAction = (file: RcFile): string => {
    if (file.size > avatarUrlLimitations.imgMaxSize) {
      notification.error({ message: "Image is too large (max 2mb)" });
    } else {
      try {
        uploadAvatarUrl(file);
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
    return file.uid;
  };

  const onDeleteAvatarUrl = () => {
    try {
      deleteAvatarUrl();
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };

  if (isAvatarUrlUploadLoading || isAvatarUrlDeleteLoading) {
    return <GenericLoader />;
  }

  return (
    <SettingsAvatarEdit
      meUser={meUser}
      onAvatarUrlAction={onAvatarUrlAction}
      onDeleteAvatarUrl={onDeleteAvatarUrl}
    />
  );
};
