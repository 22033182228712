import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetCourseReviewRejectionQuery } from "../../firestore/queries/useGetCourseReviewRejectionQuery";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemCourseReviewRejectionContainerProps {
  notification: INotification;
}

export const NotificationItemCourseReviewRejectionContainer: React.FunctionComponent<
  INotificationItemCourseReviewRejectionContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: reviewRejection,
    isLoading: isReviewRejectionLoading,
    isError: isReviewRejectionError,
  } = useGetCourseReviewRejectionQuery({
    courseReviewRejectionId: notification.courseReviewRejectionId || "",
  });
  const courseIdToQuery = reviewRejection?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isReviewRejectionError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.CourseReviewRejection}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (
    isReviewRejectionLoading ||
    isCourseLoading ||
    !reviewRejection ||
    !course
  ) {
    return <GenericLoader />;
  }

  const title = `Course Review Rejected`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  const onNavToContent = () => {
    nav.goToPaths.courseReviewRejections(reviewRejection.courseId, {
      courseReviewRejectionId: notification.courseReviewRejectionId || "",
    });
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };

  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={reviewRejection.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
