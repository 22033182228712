import React from "react";
import { useParams } from "react-router-dom";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useIsMeUserReviewer } from "../../ui-hooks/useIsMeUserReviewer";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { calculateFirstLessonId } from "../../utils/utils";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { CourseInfo } from "./CourseInfo";

interface ICourseInfoContainerProps {}

type ICourseInfoContainerParams = {
  courseId: string;
};

export const CourseInfoContainer: React.FunctionComponent<
  ICourseInfoContainerProps
> = (props) => {
  const { courseId } = useParams<ICourseInfoContainerParams>();
  const nav = useNavigation();
  const {
    data: course,
    isLoading: isCourseLoading,
    error: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const isMeUserReviewer = useIsMeUserReviewer();

  if (isCourseError) {
    return <GenericError />;
  }
  if (isCourseLoading || !course) {
    return <GenericLoader />;
  }

  const firstLessonId = calculateFirstLessonId(course);
  const startAtFirstLesson = () => {
    nav.goToPaths.inLesson(course.id, firstLessonId || "");
  };
  const hasLessons = !!firstLessonId;

  return (
    <CourseInfo
      course={course}
      hasLessons={hasLessons}
      startAtFirstLesson={startAtFirstLesson}
      isMeReviewer={isMeUserReviewer}
    />
  );
};
