import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NewCourseCategoryRequestState } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const useCreateNewCourseCategoryRequestMutation = () => {
  const { user } = useUserAuth();
  const createNewCourseCategoryRequest = async (vars: { category: string }) => {
    const { category } = vars;
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateNewCourseCategoryRequestMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const newCourseCategoryRequestsCollectionRef = collection(
      db,
      collectionPaths.newCourseCategoryRequests
    );
    addDoc(newCourseCategoryRequestsCollectionRef, {
      category,
      state: NewCourseCategoryRequestState.Open,
      owner: user.uid,
      createdAt: serverTimestamp(),
    });
  };
  return useMutation(createNewCourseCategoryRequest, {
    onSettled: settledLogger("useCreateNewCourseCategoryRequestMutation"),
  });
};
