import { Button, Space, Typography, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { fontSizes, themeColors } from "../../styling/styleConstants";

interface IModalWithTitleProps {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  zIndex: number;
  title: string;
  subTitle?: string;
  weblinkSubTitle?: string;
  onBack?: () => void;
}

export const ModalWithTitle: React.FunctionComponent<IModalWithTitleProps> = (
  props
) => {
  const {
    children,
    visible,
    onClose,
    zIndex,
    title,
    subTitle,
    weblinkSubTitle,
    onBack,
  } = props;

  return (
    //@ts-ignore
    <Modal
      visible={visible}
      onCancel={onClose}
      title={
        <div>
          <Space direction={"horizontal"}>
            {onBack && (
              <Button type={"text"} onClick={onBack} style={{ padding: 0 }}>
                <ArrowLeftOutlined />
              </Button>
            )}
            <Typography>{title}</Typography>
          </Space>
          {subTitle && (
            <Typography
              style={{
                fontSize: fontSizes.small,
                color: themeColors.secondaryFontColor,
              }}
            >
              {subTitle}
            </Typography>
          )}
          {weblinkSubTitle && (
            <Typography.Text
              style={{
                fontSize: fontSizes.small,
                color: themeColors.primaryLight,
              }}
              ellipsis={true}
            >
              {weblinkSubTitle}
            </Typography.Text>
          )}
        </div>
      }
      footer={null}
      destroyOnClose={true}
      zIndex={zIndex}
    >
      {children}
    </Modal>
  );
};
