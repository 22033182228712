import React from "react";
import { useParams } from "react-router-dom";
import { useListUsersByIds } from "../../firestore/queries/useListUsersByIds";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { UserProfile } from "./UserProfile";

interface IUserProfileContainerProps {}

type IUserProfileContainerParams = {
  userId: string;
};

export const UserProfileContainer: React.FunctionComponent<
  IUserProfileContainerProps
> = (props) => {
  const { userId } = useParams<IUserProfileContainerParams>();
  const { user: meUser } = useUserAuth();
  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useListUsersByIds([userId || ""]);

  if (isUsersError) {
    return <GenericError />;
  }

  if (isUsersLoading || !users) {
    return <GenericLoader />;
  }

  const user = users[0];
  let isMyself = false;
  if (user.id === meUser?.uid) {
    isMyself = true;
  }
  return <UserProfile user={user} isMyself={isMyself} />;
};
