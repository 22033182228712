import { Affix, Space } from "antd";
import React, { useState } from "react";
import { themeColors } from "../../../styling/styleConstants";
import { ICourse } from "../../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../../ui-hooks/useIsMeUserOwnerOfCourse";
import { CourseContributorToolBarButtons } from "./CourseContributorToolBarButtons";
import { CourseEditorToolBarButtons } from "./CourseEditorToolBarButtons";
import { CourseToolBarSuggestionsButton } from "./CourseToolBarSuggestionsButton";

interface ICourseToolBarProps {
  course: ICourse;
  isNoMoreNewLessonSuggestionsToFetch: boolean;
  fetchMoreNewLessonSuggestions: () => void;
  isSuggestionsVisible: boolean;
  toggleSuggestionsVisibility: () => void;
}

export const CourseToolBar: React.FunctionComponent<ICourseToolBarProps> = (
  props
) => {
  const {
    course,
    isNoMoreNewLessonSuggestionsToFetch,
    fetchMoreNewLessonSuggestions,
    isSuggestionsVisible,
    toggleSuggestionsVisibility,
  } = props;
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(course.id);
  const [isAffixed, setIsAffixed] = useState<boolean>(false);

  return (
    <Affix onChange={(e) => setIsAffixed(!!e)}>
      <Space
        direction={"horizontal"}
        style={{
          width: "100%",
          justifyContent: "space-between",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          backgroundColor: themeColors.bodyBackground,
          borderBottom: isAffixed ? "1px solid #D9D9D9" : undefined,
        }}
      >
        <CourseToolBarSuggestionsButton
          isNoMoreNewLessonSuggestionsToFetch={
            isNoMoreNewLessonSuggestionsToFetch
          }
          fetchMoreNewLessonSuggestions={fetchMoreNewLessonSuggestions}
          isSuggestionsVisible={isSuggestionsVisible}
        toggleSuggestionsVisibility={toggleSuggestionsVisibility}
        />
        {isMeUserOwnerOfCourse ? (
          <CourseEditorToolBarButtons course={course} />
        ) : (
          <CourseContributorToolBarButtons course={course} />
        )}
      </Space>
    </Affix>
  );
};
