import { Space, notification } from "antd";
import React, { useState } from "react";
import { locStrings } from "../../localization/locStrings";
import { ILessonAnswer } from "../../types/firestoreMappings";
import { isValidLessonAnswerInputWithErrorNotify } from "../../utils/validInputUtils";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { InputWithButton } from "../common/InputWithButton";
import { LessonAnswerContainer } from "./LessonAnswerContainer";

interface ILessonAnswersProps {
  answers: ILessonAnswer[];
  createAnswer: (answer: string) => void;
  isNoMoreAnswersToFetch: boolean;
  fetchMoreAnswers: () => void;
}

export const LessonAnswers: React.FunctionComponent<ILessonAnswersProps> = (
  props
) => {
  const { answers, createAnswer, isNoMoreAnswersToFetch, fetchMoreAnswers } =
    props;
  const [answerInput, setAnswerInput] = useState<string>("");
  const onCreateAnswer = () => {
    const isValid = isValidLessonAnswerInputWithErrorNotify().forCreate({
      answer: answerInput,
    });

    if (isValid) {
      try {
        createAnswer(answerInput);
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
  };
  return (
    <Space direction={"vertical"} style={{ display: "flex" }}>
      {answers.map((answer) => {
        return (
          <LessonAnswerContainer
            answer={answer}
            key={`LessonAnswers-Item-${answer.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreAnswers.value()}
        isNoMoreToFetch={isNoMoreAnswersToFetch}
        fetchMore={fetchMoreAnswers}
      />
      <div style={{ marginTop: "1rem" }}>
        <InputWithButton
          inputValue={answerInput}
          onInputChange={setAnswerInput}
          inputPlaceholder={locStrings.writeAnAnswerPlaceholder.value()}
          onSubmit={onCreateAnswer}
          buttonChildren={locStrings.submit.value()}
          buttonWidthInPx={75}
          disabled={!answerInput}
        />
      </div>
    </Space>
  );
};
