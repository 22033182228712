import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertLessonQuestionSnapToILessonQuestion } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getLessonQuestionQueryKey = (lessonQuestionId: string) => [
  "getLessonQuestionQueryKey",
  lessonQuestionId,
];

interface IGetLessonQuestionArgs {
  lessonQuestionId: string;
}

const getLessonQuestion = async (vars: IGetLessonQuestionArgs) => {
  const { lessonQuestionId } = vars;
  const db = getFirestore();
  const lessonQuestionDocRef = doc(
    db,
    collectionPaths.lessonQuestions,
    lessonQuestionId
  );
  const snapshot = await getDoc(lessonQuestionDocRef);
  if (snapshot.exists()) {
    return convertLessonQuestionSnapToILessonQuestion(
      lessonQuestionId,
      snapshot.data()
    );
  } else {
    return Promise.reject("getLessonQuestion, lessonQuestionId not found");
  }
};

export const useGetLessonQuestionQuery = (
  vars: IGetLessonQuestionArgs,
  enabled: boolean = true
) => {
  const { lessonQuestionId } = vars;
  return useQuery(
    getLessonQuestionQueryKey(lessonQuestionId),
    () => getLessonQuestion(vars),
    {
      onSettled: settledLogger("useGetLessonQuestionQuery"),
      enabled,
    }
  );
};

export const useGetLessonQuestionFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetLessonQuestionArgs) => {
    const { lessonQuestionId } = vars;
    return queryClient.fetchQuery(
      getLessonQuestionQueryKey(lessonQuestionId),
      () => getLessonQuestion(vars)
    );
  };
};
