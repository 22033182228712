import { Button, Popconfirm } from "antd";
import React, { CSSProperties } from "react";
import { locStrings } from "../../localization/locStrings";

interface IConfirmButtonProps {
  onConfirm: () => void;
  type: "default" | "primary" | "dashed" | "text";
  text?: string;
  icon?: JSX.Element;
  style?: CSSProperties;
}

export const ConfirmButton: React.FunctionComponent<IConfirmButtonProps> = (
  props
) => {
  const { onConfirm, text, type, icon, style } = props;

  return (
    <Popconfirm
      title={locStrings.areYouSure.value()}
      okText={locStrings.yes.value()}
      cancelText={locStrings.no.value()}
      onConfirm={onConfirm}
    >
      <Button type={type} icon={icon} style={style}>
        {text}
      </Button>
    </Popconfirm>
  );
};
