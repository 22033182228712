import { Typography } from "antd";
import React from "react";

interface ICourseReviewRejectionsEmptyStateProps {}

export const CourseReviewRejectionsEmptyState: React.FunctionComponent<
  ICourseReviewRejectionsEmptyStateProps
> = (props) => {
  return (
    <Typography.Text italic={true}>
      {"This course has no reviewer feedback yet"}
    </Typography.Text>
  );
};
