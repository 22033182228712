import { ILessonExercise } from "../types/firestoreMappings";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserOwnerOfLessonExercise = (exercise: ILessonExercise) => {
  const { user } = useUserAuth();

  if (!user) {
    return false;
  }

  return exercise.owner === user.uid;
};
