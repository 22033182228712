import { Space, Typography } from "antd";
import React from "react";
import { ICourse } from "../../types/firestoreMappings";
import { LessonPlacementInCourse } from "../../types/helperTypes";
import { PlacementInCourseSelect } from "../common/PlacementInCourseSelect";

interface INewLinkBrandNewProps {
  course: ICourse;
  onSubmit: (lessonPlacementInCourse: LessonPlacementInCourse) => void;
}

export const NewLinkBrandNew: React.FunctionComponent<INewLinkBrandNewProps> = (
  props
) => {
  const { course, onSubmit } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {course.modules.length === 0 && (
        <Typography.Text italic={true}>
          {"This course must have modules to add content to."}
        </Typography.Text>
      )}
      <PlacementInCourseSelect course={course} onSelectPlacement={onSubmit} />
    </Space>
  );
};
