import { CoursePublishState } from "../../types/helperTypes";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useUpdateCoursePublishStateMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId, true);
  const updatePublishState = async (vars: {
    publishState: CoursePublishState;
  }) => {
    const { publishState } = vars;
    await updateCourseMutation.mutate({
      publishState,
    });
  };
  return {
    ...updateCourseMutation,
    mutate: updatePublishState,
  };
};
