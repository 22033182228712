import { Button, Dropdown, Menu, Space } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import React from "react";
import { CourseToolBarButton } from "./CourseToolBarButton";

export interface ICourseToolBarItem {
  id: string;
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  tooltipTitle?: string;
  className?: string;
  isHidden?: boolean;
}

interface ICourseToolBarItemsProps {
  items: ICourseToolBarItem[];
}

export const CourseToolBarItems: React.FunctionComponent<
  ICourseToolBarItemsProps
> = (props) => {
  const { items } = props;
  const filteredItems = items.filter((item) => !item.isHidden);
  const numItemsShown = 2;
  const shownItems = filteredItems.slice(0, numItemsShown);
  const overflowItems = filteredItems.slice(
    numItemsShown,
    filteredItems.length
  );
  const shownItemsToRender = shownItems.map((item) => {
    return (
      <CourseToolBarButton item={item} key={`CourseToolBarItems-${item.id}`} />
    );
  });
  const overflowItemsToRender = overflowItems.map((item, index) => {
    return (
      <Menu.Item
        key={`CourseToolBarItems-${item.id}-${index}`}
        onClick={item.onClick}
        icon={item.icon}
      >
        {item.title}
      </Menu.Item>
    );
  });
  return (
    <Space
      direction={"horizontal"}
      style={{
        width: "100%",
      }}
    >
      {shownItemsToRender}
      {overflowItemsToRender.length > 0 && (
        /* @ts-ignore */
        <Dropdown overlay={<Menu>{overflowItemsToRender}</Menu>}>
          <Button>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
    </Space>
  );
};
