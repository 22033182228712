import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreateLessonMutation } from "../../../firestore/mutations/useCreateLessonMutation";
import { useUpdateCourseSuggestionMutation } from "../../../firestore/mutations/useUpdateCourseSuggestionMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { useGetCourseSuggestionQuery } from "../../../firestore/queries/useGetCourseSuggestionQuery";
import { locStrings } from "../../../localization/locStrings";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { isValidLessonInputWithErrorNotify } from "../../../utils/validInputUtils";
import { GenericLoader } from "../../common/GenericLoader";
import { LessonEdit } from "./LessonEdit";

interface ILessonCreateContainerProps {}

type ILessonCreateContainerParams = {
  courseId: string;
};

export const LessonCreateContainer: React.FunctionComponent<
  ILessonCreateContainerProps
> = (props) => {
  const { courseId } = useParams<ILessonCreateContainerParams>();
  const nav = useNavigation();
  const parsedModuleId = nav.urlSearchParams.getModuleId();
  const parsedCourseSuggestionId = nav.urlSearchParams.courseSuggestionId();
  const { data: course, isLoading: isLoadingCourse } = useGetCourseQuery({
    courseId: courseId || "",
  });
  const { data: courseSuggestion, isLoading: isCourseSuggestionLoading } =
    useGetCourseSuggestionQuery(
      {
        courseSuggestionId: parsedCourseSuggestionId || "",
      },
      !!parsedCourseSuggestionId
    );
  const {
    mutate: createLesson,
    isLoading: isLessonCreationLoading,
    isSuccess: isLessonCreationSuccess,
  } = useCreateLessonMutation(courseId || "");
  const { mutate: updateCourseSuggestion } = useUpdateCourseSuggestionMutation(
    parsedCourseSuggestionId || "",
    courseId
  );
  const [lessonTitle, setLessonTitle] = useState<string>("");
  const [lessonWeblink, setLessonWeblink] = useState<string>("");
  const [lessonDescription, setLessonDescription] = useState<string>("");
  const [selectedModuleId, setSelectedModuleId] = useState<string>(
    parsedModuleId || ""
  );
  const onSelectedModuleIdChange = (moduleId: string) => {
    setSelectedModuleId(moduleId);
  };
  const onLessonTitleChange = (s: string) => {
    setLessonTitle(s);
  };
  const onLessonWeblinkChange = (s: string) => {
    setLessonWeblink(s);
  };
  const onLessonDescriptionChange = (s: string) => {
    setLessonDescription(s);
  };

  useEffect(() => {
    if (isLessonCreationSuccess) {
      // If this lesson was created from a course suggestion,
      // then update the course suggestion to Accepted.
      // This will happen async in the background after navigation
      if (!!courseSuggestion) {
        updateCourseSuggestion({ state: CourseSuggestionState.Accepted });
      }
      nav.window.goBack();
    }
  }, [
    isLessonCreationSuccess,
    nav.window,
    courseSuggestion,
    updateCourseSuggestion,
  ]);

  // Load in the NewLesson course suggestion data
  useEffect(() => {
    if (courseSuggestion && !isCourseSuggestionLoading) {
      if (courseSuggestion.type !== CourseSuggestionType.NewLesson) {
        console.warn(
          `LessonCreateContainer, parsed courseSuggestion is incorrect type: ${courseSuggestion.type}`
        );
      }
      setSelectedModuleId(courseSuggestion.moduleId || "");
      setLessonTitle(courseSuggestion.lessonTitle || "");
      setLessonWeblink(courseSuggestion.lessonWeblink || "");
      setLessonDescription(courseSuggestion.reason || "");
    }
  }, [isCourseSuggestionLoading, courseSuggestion]);

  if (
    isLoadingCourse ||
    isLessonCreationLoading ||
    isCourseSuggestionLoading ||
    !course
  ) {
    return <GenericLoader />;
  }

  const onCreateLesson = async () => {
    const isValid = isValidLessonInputWithErrorNotify().forCreate({
      selectedModuleId,
      title: lessonTitle,
      weblink: lessonWeblink,
      description: lessonDescription,
    });

    if (isValid) {
      createLesson({
        course,
        selectedModuleId,
        title: lessonTitle,
        description: lessonDescription,
        weblink: lessonWeblink,
      });
    }
  };

  return (
    <LessonEdit
      pageTitle={locStrings.addANewLesson.value()}
      course={course}
      selectedModuleId={selectedModuleId}
      lessonTitle={lessonTitle}
      lessonWeblink={lessonWeblink}
      lessonDescription={lessonDescription}
      onSelectedModuleIdChange={onSelectedModuleIdChange}
      onLessonTitleChange={onLessonTitleChange}
      onLessonWeblinkChange={onLessonWeblinkChange}
      onLessonDescriptionChange={onLessonDescriptionChange}
      onSubmit={onCreateLesson}
    />
  );
};
