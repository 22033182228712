import { Button, Space, Tooltip } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Upload, { RcFile } from "antd/lib/upload";
import React from "react";
import { IUser } from "../../types/firestoreMappings";
import { UserAvatar } from "../common/UserAvatar";
import { zIndexPriorities } from "../../styling/styleConstants";

interface ISettingsAvatarEditProps {
  meUser: IUser;
  onAvatarUrlAction: (file: RcFile) => string;
  onDeleteAvatarUrl: () => void;
}

export const SettingsAvatarEdit: React.FunctionComponent<
  ISettingsAvatarEditProps
> = (props) => {
  const { meUser, onAvatarUrlAction, onDeleteAvatarUrl } = props;

  return (
    <Space direction={"horizontal"} style={{ alignItems: "center" }}>
      <UserAvatar
        uid={meUser.id}
        username={meUser.username}
        avatarUrl={meUser.avatarUrl}
        overrideSize={120}
      />
      <Space direction={"horizontal"}>
        <Upload
          action={onAvatarUrlAction}
          accept={"image/png, image/jpeg"}
          itemRender={(item) => null}
        >
          <Tooltip
            title={"Upload avatar image"}
            zIndex={zIndexPriorities.tooltipOutsideModal}
          >
            <Button>
              <UploadOutlined />
            </Button>
          </Tooltip>
        </Upload>
        {!!meUser.avatarUrl && (
          <Tooltip
            title={"Remove avatar image"}
            zIndex={zIndexPriorities.tooltipOutsideModal}
          >
            <Button onClick={onDeleteAvatarUrl}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </Space>
    </Space>
  );
};
