import React from "react";
import { zIndexPriorities } from "../../../styling/styleConstants";
import { ModalWithTitle } from "../../common/ModalWithTitle";
import { CourseInfoPublishStateRequestContainer } from "./CourseInfoPublishStateRequestContainer";

interface ICourseInfoPublishStateRequestModalProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
}

export const CourseInfoPublishStateRequestModal: React.FunctionComponent<
  ICourseInfoPublishStateRequestModalProps
> = (props) => {
  const { visible, onClose, courseId } = props;

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onClose}
      title={"Request a Site-Level Review"}
      subTitle={
        "LoA has independent reviewers that can give your course an extra seal of approval and trustworthiness. If approved, this course will be marked as 'Reviewed'."
      }
      zIndex={zIndexPriorities.newModuleModal}
    >
      <CourseInfoPublishStateRequestContainer courseId={courseId} />
    </ModalWithTitle>
  );
};
