import { Button, Dropdown, Menu, Space, Tag, Typography } from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {
  CourseCategory,
  CourseTopLevelCategory,
} from "../../../types/helperTypes";
import { courseCategoryMap } from "../../../utils/courseCategoryMap";
import { InputMimicry } from "../../common/InputMimicry";
import { themeColors } from "../../../styling/styleConstants";
import { convertCourseCategoryToStringAndIcon } from "../../../utils/iconAndStringUtils";
import { NewCourseCategoryRequestModal } from "./NewCourseCategoryRequestModal";
import { locStrings } from "../../../localization/locStrings";

interface ICourseCategorySelectProps {
  categories: CourseCategory[];
  onCategoriesChange: (newCategories: CourseCategory[]) => void;
}

export const CourseCategorySelect: React.FunctionComponent<
  ICourseCategorySelectProps
> = (props) => {
  const { categories, onCategoriesChange } = props;
  const [
    isNewCategoryRequestModalVisible,
    setIsNewCategoryRequestModalVisible,
  ] = useState<boolean>(false);

  const addCategory = (toBeAddedCategory: CourseCategory) => {
    const newCategories = categories.concat([toBeAddedCategory]);
    onCategoriesChange(newCategories);
  };
  const removeCategory = (toBeRemovedCategory: CourseCategory) => {
    const newCategories = categories.filter(
      (category) => category !== toBeRemovedCategory
    );
    onCategoriesChange(newCategories);
  };
  const openNewCategoryRequestModal = () => {
    setIsNewCategoryRequestModalVisible(true);
  };
  const closeNewCategoryRequestModal = () => {
    setIsNewCategoryRequestModalVisible(false);
  };

  // Purposely not using <Space/> so that elements automatically collapse into grid
  return (
    <>
      <InputMimicry>
        <CurrentCourseCategories
          categories={categories}
          removeCategory={removeCategory}
        />
        <div style={{ marginTop: "0.5rem" }}>
          {Object.keys(courseCategoryMap).map((topLevelCategory) => (
            <TopLevelCategoryDropdown
              key={`CourseCategorySelect-TopLevelCategoryDropdown-${topLevelCategory}`}
              categories={categories}
              addCategory={addCategory}
              removeCategory={removeCategory}
              topLevelCategory={topLevelCategory as CourseTopLevelCategory}
            />
          ))}
          <Button
            style={{ margin: "0.2rem" }}
            onClick={openNewCategoryRequestModal}
          >
            {"Other"}
          </Button>
        </div>
      </InputMimicry>
      <NewCourseCategoryRequestModal
        visible={isNewCategoryRequestModalVisible}
        onClose={closeNewCategoryRequestModal}
      />
    </>
  );
};

const TopLevelCategoryDropdown: React.FunctionComponent<{
  categories: CourseCategory[];
  addCategory: (category: CourseCategory) => void;
  removeCategory: (category: CourseCategory) => void;
  topLevelCategory: CourseTopLevelCategory;
}> = (props) => {
  const { categories, addCategory, removeCategory, topLevelCategory } = props;
  const toggleCategory = (
    toBeToggledCategory: CourseCategory,
    isCategoryAlreadySelected: boolean
  ) => {
    if (isCategoryAlreadySelected) {
      removeCategory(toBeToggledCategory);
    } else {
      // Add the category
      addCategory(toBeToggledCategory);
    }
  };

  const menu = (
    <Menu>
      {courseCategoryMap[
        topLevelCategory as keyof typeof courseCategoryMap
      ].map((category: CourseCategory) => {
        const isCategoryAlreadySelected = categories.includes(category);
        const categoryStringAndIcon =
          convertCourseCategoryToStringAndIcon(category);

        return (
          <Menu.Item
            key={`TopLevelCategoryDropdown-MenuItem-${category}`}
            onClick={() => toggleCategory(category, isCategoryAlreadySelected)}
            style={{
              backgroundColor: isCategoryAlreadySelected
                ? themeColors.primaryVeryLight
                : undefined,
            }}
            icon={
              <img
                alt={categoryStringAndIcon.string}
                src={categoryStringAndIcon.iconSrcString}
                style={{ height: "1.3rem", width: "auto" }}
              />
            }
          >
            {categoryStringAndIcon.string}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const topLevelCategoryStringAndIcon =
    convertCourseCategoryToStringAndIcon(topLevelCategory);

  return (
    //@ts-ignore
    <Dropdown overlay={menu}>
      <Button style={{ margin: "0.2rem" }}>
        {topLevelCategoryStringAndIcon.string}
      </Button>
    </Dropdown>
  );
};

const CurrentCourseCategories: React.FunctionComponent<{
  categories: CourseCategory[];
  removeCategory: (category: CourseCategory) => void;
}> = (props) => {
  const { categories, removeCategory } = props;

  if (categories.length === 0) {
    return (
      <Typography.Text
        italic={true}
        style={{ color: themeColors.secondaryFontColor }}
      >
        {locStrings.courseCategoriesPlaceholder.value()}
      </Typography.Text>
    );
  }

  // Purposely not using <Space/> so that elements automatically collapse into grid
  return (
    <div>
      {categories.map((category) => {
        const categoryStringAndIcon =
          convertCourseCategoryToStringAndIcon(category);
        return (
          <Tag
            key={`CurrentCourseCategories-${category}`}
            style={{
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              backgroundColor: themeColors.primaryVeryLight,
              margin: "0.2rem",
            }}
          >
            <Space direction={"horizontal"}>
              <img
                alt={categoryStringAndIcon.string}
                src={categoryStringAndIcon.iconSrcString}
                style={{ height: "1.3rem", width: "auto" }}
              />
              <Typography style={{ fontWeight: 800 }}>
                {categoryStringAndIcon.string}
              </Typography>
              <CloseOutlined onClick={() => removeCategory(category)} />
            </Space>
          </Tag>
        );
      })}
    </div>
  );
};
