import { Space } from "antd";
import React from "react";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { ExploreCourseCategories } from "./ExploreCourseCategories";

interface IExploreProps {}

export const Explore: React.FunctionComponent<IExploreProps> = (props) => {
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={"Explore"}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
          />
        </div>
        <ExploreCourseCategories />
      </Space>
    </LayoutContent>
  );
};
