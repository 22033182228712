import { notification } from "antd";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { getLessonExerciseQueryKey } from "../queries/useGetLessonExerciseQuery";
import { listLessonExercisesQueryKey } from "../queries/useListLessonExercisesQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateLessonExerciseMutation = (
  exerciseId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateLessonExercise = async (exercise: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useUpdateLessonExerciseMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonExerciseDocRef = doc(
      db,
      collectionPaths.lessonExercises,
      exerciseId
    );
    return updateDoc(lessonExerciseDocRef, {
      exercise,
    });
  };
  return useMutation(updateLessonExercise, {
    onSettled: settledLogger("useUpdateLessonExerciseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonExercisesQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
        }
      );
      queryClient.invalidateQueries(getLessonExerciseQueryKey(exerciseId), {
        refetchInactive: true,
      });
    },
  });
};
