import { notification } from "antd";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { getLessonQuestionQueryKey } from "../queries/useGetLessonQuestionQuery";
import { listLessonQuestionsQueryKey } from "../queries/useListLessonQuestionsQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateLessonQuestionMutation = (
  questionId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateLessonQuestion = async (question: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useUpdateLessonQuestionMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonQuestionDocRef = doc(
      db,
      collectionPaths.lessonQuestions,
      questionId
    );
    return updateDoc(lessonQuestionDocRef, {
      question,
    });
  };
  return useMutation(updateLessonQuestion, {
    onSettled: settledLogger("useUpdateLessonQuestionMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonQuestionsQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
        }
      );
      queryClient.invalidateQueries(getLessonQuestionQueryKey(questionId), {
        refetchInactive: true,
      });
    },
  });
};
