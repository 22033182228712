import React, { useMemo, useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { themeColors } from "../../styling/styleConstants";

interface IWeblinkPreviewImageProps {
  imageUrl?: string;
  style?: React.CSSProperties;
}

export const WeblinkPreviewImage: React.FunctionComponent<
  IWeblinkPreviewImageProps
> = (props) => {
  const { imageUrl, style } = props;
  const [hasFailedToLoadImg, setHasFailedToLoadImg] = useState<boolean>(false);
  const commonStyles: React.CSSProperties = useMemo(
    () => ({
      height: "3rem",
      width: "4rem",
      borderRadius: "3px",
    }),
    []
  );
  if (imageUrl && !hasFailedToLoadImg) {
    return (
      <img
        src={imageUrl}
        alt={"Thumbnail preview"}
        style={{
          objectFit: "cover",
          ...commonStyles,
          ...style,
        }}
        onError={() => {
          setHasFailedToLoadImg(true);
        }}
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: themeColors.previewImageUrlBackground,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...commonStyles,
        ...style,
      }}
    >
      <LinkOutlined
        style={{
          fontSize: "2rem",
          color: themeColors.previewImageUrlDefaultFont,
        }}
      />
    </div>
  );
};
