import { ILessonQuestion } from "../types/firestoreMappings";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserOwnerOfLessonQuestion = (question: ILessonQuestion) => {
  const { user } = useUserAuth();

  if (!user) {
    return false;
  }

  return question.owner === user.uid;
};
