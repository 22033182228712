import { LocalStorageKey } from "./localStorageKeys";

class LocalStorageBase {
  public getEmailLastUsed = () => {
    return localStorage.getItem(LocalStorageKey.EmailLastUsed);
  };

  public setEmailLastUsed = (email: string) => {
    localStorage.setItem(LocalStorageKey.EmailLastUsed, email);
  };
}

export default new LocalStorageBase();
