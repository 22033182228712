import { useGetReviewerQuery } from "../firestore/queries/useGetReviewerQuery";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserReviewer = () => {
  const { user } = useUserAuth();
  const { data: meReviewer, isLoading: isMeReviewerLoading } =
    useGetReviewerQuery({ userId: user?.uid || "" }, !!user?.uid);

  if (!user || !meReviewer || isMeReviewerLoading) {
    return false;
  }

  return meReviewer.isReviewer;
};
