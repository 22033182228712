import React from "react";
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CourseCategory } from "../types/helperTypes";
import { BASE_URL } from "../utils/appConstants";
import { useSisuModal } from "./useSisuModal";

export enum UrlSearchParamKey {
  moduleId = "moduleId",
  category = "category",
  myCourses = "myCourses",
  myFavorites = "myFavorites",
  lessonQuestionId = "lessonQuestionId",
  lessonAnswerId = "lessonAnswerId",
  lessonExerciseId = "lessonExerciseId",
  lessonAlternativeLinkId = "lessonAlternativeLinkId",
  isReviewMode = "isReviewMode",
  courseReviewRejectionId = "courseReviewRejectionId",
  courseSuggestionId = "courseSuggestionId",
  highlightNewSuggestion = "highlightNewSuggestion",
  courseCommentIdForLesson = "courseCommentIdForLesson",
  courseCommentIdForAlternativeLink = "courseCommentIdForAlternativeLink",
}

export const useNavigation = () => {
  const navigate = useNavigate();
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { search } = useLocation();
  const urlSearchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  return {
    paths: {
      home: "/",
      explore: "/explore",
      settings: "/settings",
      courseCategory: "/courseCategory/:category",
      userProfile: "/userProfile/:userId",
      notifications: "/notifications",
      reviewQueue: "/reviewQueue",
      courseInfo: "/courseInfo/:courseId",
      courseReviewRejections: "courseReviewRejections/:courseId",
      newCourse: "/newCourse",
      newLesson: "/newLesson/:courseId",
      editCourse: "/editCourse/:courseId",
      editModule: "/editModule/:courseId/:moduleId",
      editLesson: "/editLesson/:courseId/:lessonId",
      reorderCourse: "/reorderCourse/:courseId",
      inLesson: "/inLesson/:courseId/:lessonId",
      courseSuggestionsOverview: "/courseSuggestionsOverview/:courseId",
    },
    goToPaths: {
      home: () => navigate(`/`),
      explore: () => navigate(`/explore`),
      settings: forceSisuModalFxnWrapper(() => navigate(`/settings`)),
      courseCategory: (category: CourseCategory) =>
        navigate(`/courseCategory/${category}`),
      userProfile: (
        userId: string,
        searchParams?: {
          myCourses?: string;
          myFavorites?: string;
        }
      ) => {
        const multiSearchParams: URLSearchParamsInit = [];
        if (searchParams?.myCourses) {
          multiSearchParams.push([
            UrlSearchParamKey.myCourses,
            searchParams.myCourses,
          ]);
        }
        if (searchParams?.myFavorites) {
          multiSearchParams.push([
            UrlSearchParamKey.myFavorites,
            searchParams.myFavorites,
          ]);
        }

        return navigate({
          pathname: `/userProfile/${userId}`,
          search: searchParams && `?${createSearchParams(multiSearchParams)}`,
        });
      },
      notifications: forceSisuModalFxnWrapper(() => navigate(`/notifications`)),
      reviewQueue: forceSisuModalFxnWrapper(() => navigate(`/reviewQueue`)),
      courseInfo: (
        courseId: string,
        searchParams?: {
          isReviewMode?: string;
          highlightNewSuggestion?: string;
          courseSuggestionId?: string;
        }
      ) => {
        const multiSearchParams: URLSearchParamsInit = [];
        if (searchParams?.isReviewMode) {
          multiSearchParams.push([
            UrlSearchParamKey.isReviewMode,
            searchParams.isReviewMode,
          ]);
        }
        if (searchParams?.highlightNewSuggestion) {
          multiSearchParams.push([
            UrlSearchParamKey.highlightNewSuggestion,
            searchParams.highlightNewSuggestion,
          ]);
        }
        if (searchParams?.courseSuggestionId) {
          multiSearchParams.push([
            UrlSearchParamKey.courseSuggestionId,
            searchParams.courseSuggestionId,
          ]);
        }

        return navigate({
          pathname: `/courseInfo/${courseId}`,
          search: searchParams && `?${createSearchParams(multiSearchParams)}`,
        });
      },
      courseReviewRejections: forceSisuModalFxnWrapper(
        (
          courseId: string,
          searchParams?: { courseReviewRejectionId: string }
        ) =>
          navigate({
            pathname: `/courseReviewRejections/${courseId}`,
            search:
              searchParams &&
              `?${createSearchParams([
                [
                  UrlSearchParamKey.courseReviewRejectionId,
                  searchParams.courseReviewRejectionId,
                ],
              ])}`,
          })
      ),
      newCourse: (searchParams?: { category?: CourseCategory }) => {
        const multiSearchParams: URLSearchParamsInit = [];
        if (searchParams?.category) {
          multiSearchParams.push([
            UrlSearchParamKey.category,
            searchParams.category,
          ]);
        }

        return navigate({
          pathname: `/newCourse`,
          search: searchParams && `?${createSearchParams(multiSearchParams)}`,
        });
      },
      newLesson: forceSisuModalFxnWrapper(
        (
          courseId: string,
          searchParams?: { moduleId?: string; courseSuggestionId?: string }
        ) => {
          const multiSearchParams: URLSearchParamsInit = [];
          if (searchParams?.moduleId) {
            multiSearchParams.push([
              UrlSearchParamKey.moduleId,
              searchParams.moduleId,
            ]);
          }
          if (searchParams?.courseSuggestionId) {
            multiSearchParams.push([
              UrlSearchParamKey.courseSuggestionId,
              searchParams.courseSuggestionId,
            ]);
          }

          return navigate({
            pathname: `/newLesson/${courseId}`,
            search: searchParams && `?${createSearchParams(multiSearchParams)}`,
          });
        }
      ),
      editCourse: forceSisuModalFxnWrapper((courseId: string) =>
        navigate(`/editCourse/${courseId}`)
      ),
      editModule: forceSisuModalFxnWrapper(
        (courseId: string, moduleId: string) =>
          navigate(`/editModule/${courseId}/${moduleId}`)
      ),
      editLesson: forceSisuModalFxnWrapper(
        (courseId: string, lessonId: string) =>
          navigate(`/editLesson/${courseId}/${lessonId}`)
      ),
      reorderCourse: forceSisuModalFxnWrapper((courseId: string) =>
        navigate(`/reorderCourse/${courseId}`)
      ),
      inLesson: (
        courseId: string,
        lessonId: string,
        searchParams?: {
          lessonQuestionId?: string;
          lessonAnswerId?: string;
          lessonExerciseId?: string;
          lessonAlternativeLinkId?: string;
          courseCommentIdForLesson?: string;
          courseCommentIdForAlternativeLink?: string;
        }
      ) => {
        const multiSearchParams: URLSearchParamsInit = [];
        if (searchParams?.lessonQuestionId) {
          multiSearchParams.push([
            UrlSearchParamKey.lessonQuestionId,
            searchParams.lessonQuestionId,
          ]);
        }
        if (searchParams?.lessonAnswerId) {
          multiSearchParams.push([
            UrlSearchParamKey.lessonAnswerId,
            searchParams.lessonAnswerId,
          ]);
        }
        if (searchParams?.lessonExerciseId) {
          multiSearchParams.push([
            UrlSearchParamKey.lessonExerciseId,
            searchParams.lessonExerciseId,
          ]);
        }
        if (searchParams?.lessonAlternativeLinkId) {
          multiSearchParams.push([
            UrlSearchParamKey.lessonAlternativeLinkId,
            searchParams.lessonAlternativeLinkId,
          ]);
        }
        if (searchParams?.courseCommentIdForLesson) {
          multiSearchParams.push([
            UrlSearchParamKey.courseCommentIdForLesson,
            searchParams.courseCommentIdForLesson,
          ]);
        }
        if (searchParams?.courseCommentIdForAlternativeLink) {
          multiSearchParams.push([
            UrlSearchParamKey.courseCommentIdForAlternativeLink,
            searchParams.courseCommentIdForAlternativeLink,
          ]);
        }

        return navigate({
          pathname: `/inLesson/${courseId}/${lessonId}`,
          search: searchParams && `?${createSearchParams(multiSearchParams)}`,
        });
      },
      courseSuggestionsOverview: (
        courseId: string,
        searchParams?: { courseSuggestionId?: string }
      ) => {
        const multiSearchParams: URLSearchParamsInit = [];
        if (searchParams?.courseSuggestionId) {
          multiSearchParams.push([
            UrlSearchParamKey.courseSuggestionId,
            searchParams.courseSuggestionId,
          ]);
        }
        navigate({
          pathname: `/courseSuggestionsOverview/${courseId}`,
          search: searchParams && `?${createSearchParams(multiSearchParams)}`,
        });
      },
    },
    urlSearchParams: {
      getModuleId: () => urlSearchParams.get(UrlSearchParamKey.moduleId),
      getMyCourses: () => urlSearchParams.get(UrlSearchParamKey.myCourses),
      getMyFavorites: () => urlSearchParams.get(UrlSearchParamKey.myFavorites),
      getCategory: () => urlSearchParams.get(UrlSearchParamKey.category),
      getLessonQuestionId: () =>
        urlSearchParams.get(UrlSearchParamKey.lessonQuestionId),
      getLessonAnswerId: () =>
        urlSearchParams.get(UrlSearchParamKey.lessonAnswerId),
      getLessonExerciseId: () =>
        urlSearchParams.get(UrlSearchParamKey.lessonExerciseId),
      getLessonAlternativeLinkId: () =>
        urlSearchParams.get(UrlSearchParamKey.lessonAlternativeLinkId),
      isReviewMode: () => urlSearchParams.get(UrlSearchParamKey.isReviewMode),
      highlightNewSuggestion: () =>
        urlSearchParams.get(UrlSearchParamKey.highlightNewSuggestion),
      courseReviewRejectionId: () =>
        urlSearchParams.get(UrlSearchParamKey.courseReviewRejectionId),
      courseSuggestionId: () =>
        urlSearchParams.get(UrlSearchParamKey.courseSuggestionId),
      getCourseCommentIdForLesson: () =>
        urlSearchParams.get(UrlSearchParamKey.courseCommentIdForLesson),
      getCourseCommentIdForAlternativeLink: () =>
        urlSearchParams.get(
          UrlSearchParamKey.courseCommentIdForAlternativeLink
        ),
    },
    window: {
      scrollToTop: () => window.scrollTo(0, 0),
      goBack: () => navigate(-1),
    },
  };
};

export const baseUrlWithPath = {
  home: () => `${BASE_URL}/`,
  explore: () => `${BASE_URL}/explore`,
  settings: () => `${BASE_URL}/settings`,
  courseCategory: (category: CourseCategory) =>
    `${BASE_URL}/courseCategory/${category}`,
  userProfile: (userId: string) => `${BASE_URL}/userProfile/${userId}`,
  notifications: () => `${BASE_URL}/notifications`,
  reviewQueue: () => `${BASE_URL}/reviewQueue`,
  courseInfo: (courseId: string) => `${BASE_URL}/courseInfo/${courseId}`,
  courseReviewRejections: (courseId: string) =>
    `${BASE_URL}/courseReviewRejections/${courseId}`,
  newCourse: () => `${BASE_URL}/newCourse`,
  newLesson: (courseId: string) => `${BASE_URL}/newLesson/${courseId}`,
  editCourse: (courseId: string) => `${BASE_URL}/editCourse/${courseId}`,
  editModule: (courseId: string, moduleId: string) =>
    `${BASE_URL}/editModule/${courseId}/${moduleId}`,
  editLesson: (courseId: string, lessonId: string) =>
    `${BASE_URL}/editLesson/${courseId}/${lessonId}`,
  reorderCourse: (courseId: string) => `${BASE_URL}/reorderCourse/${courseId}`,
  inLesson: (courseId: string, lessonId: string) =>
    `${BASE_URL}/inLesson/${courseId}/${lessonId}`,
  courseSuggestionsOverview: (courseId: string) =>
    `${BASE_URL}/courseSuggestionsOverview/${courseId}`,
};
