import React from "react";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { useCreateCourseFavoriteMutation } from "../../firestore/mutations/useCreateCourseFavoriteMutation";
import { useDeleteCourseFavoriteMutation } from "../../firestore/mutations/useDeleteCourseFavoriteMutation";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { useGetCourseFavoriteQuery } from "../../firestore/queries/useGetCourseFavoriteQuery";
import { CourseFavoriteButton } from "./CourseFavoriteButton";
import { notification } from "antd";
import { ButtonSize } from "antd/lib/button";

interface ICourseFavoriteButtonContainerProps {
  courseId: string;
  size?: ButtonSize;
}

export const CourseFavoriteButtonContainer: React.FunctionComponent<
  ICourseFavoriteButtonContainerProps
> = (props) => {
  const { courseId, size } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const {
    data: courseFavorite,
    isLoading: isCourseFavoriteLoading,
    isError: isCourseFavoriteError,
  } = useGetCourseFavoriteQuery({ courseId });
  const {
    mutate: createCourseFavorite,
    isLoading: isCreateCourseFavoriteLoading,
  } = useCreateCourseFavoriteMutation(courseId);
  const {
    mutate: deleteCourseFavorite,
    isLoading: isDeleteCourseFavoriteLoading,
  } = useDeleteCourseFavoriteMutation(courseId);

  if (
    isCourseFavoriteLoading ||
    !courseFavorite ||
    isCreateCourseFavoriteLoading ||
    isDeleteCourseFavoriteLoading
  ) {
    return <GenericLoader />;
  }

  if (isCourseFavoriteError) {
    return <GenericError />;
  }

  const isCourseFavorited = !courseFavorite.doesNotExist;
  const onCreateCourseFavorite = async () => {
    await createCourseFavorite();
    notification.success({ message: "Course added to favorites" });
  };
  const onDeleteCourseFavorite = () => {
    deleteCourseFavorite();
  };
  const onFavoriteClick = forceSisuModalFxnWrapper(
    isCourseFavorited ? onDeleteCourseFavorite : onCreateCourseFavorite
  );

  return (
    <CourseFavoriteButton
      onFavoriteClick={onFavoriteClick}
      isCourseFavorited={isCourseFavorited}
      size={size}
    />
  );
};
