import React from "react";
import { useParams } from "react-router-dom";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useListCourseReviewRejectionsQuery } from "../../firestore/queries/useListCourseReviewRejectionsQuery";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { CourseReviewRejections } from "./CourseReviewRejections";

interface ICourseReviewRejectionsContainerProps {}

type ICourseReviewRejectionsContainerParams = {
  courseId: string;
};

export const CourseReviewRejectionsContainer: React.FunctionComponent<
  ICourseReviewRejectionsContainerProps
> = (props) => {
  const { courseId } = useParams<ICourseReviewRejectionsContainerParams>();
  const nav = useNavigation();
  const {
    data: course,
    isLoading: isCourseLoading,
    error: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const {
    data: reviewRejections,
    fetchNextPage: fetchMoreReviewRejections,
    isNoMoreToFetch: isNoMoreReviewRejectionsToFetch,
    isLoading: isReviewRejectionsLoading,
    isError: isReviewRejectionsError,
  } = useListCourseReviewRejectionsQuery({ courseId: courseId || "" });
  const navToCourse = () => {
    nav.goToPaths.courseInfo(courseId || "");
  };

  if (isCourseError || isReviewRejectionsError) {
    return <GenericError />;
  }
  if (
    isCourseLoading ||
    isReviewRejectionsLoading ||
    !reviewRejections ||
    !course
  ) {
    return <GenericLoader />;
  }

  return (
    <CourseReviewRejections
      course={course}
      reviewRejections={reviewRejections}
      isNoMoreReviewRejectionsToFetch={isNoMoreReviewRejectionsToFetch}
      fetchMoreReviewRejections={fetchMoreReviewRejections}
      navToCourse={navToCourse}
    />
  );
};
