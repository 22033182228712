import { Col, Row, Space, Typography } from "antd";
import React from "react";
import { ICourse } from "../../../types/firestoreMappings";
import { FetchMoreButton } from "../../common/FetchMoreButton";
import { Course } from "./Course";

interface ICoursesProps {
  courses: ICourse[];
  fetchMoreCourses: () => void;
  isNoMoreCoursesToFetch: boolean;
  emptyStateText?: string;
  loadMoreText?: string;
  shouldNavWithReviewMode?: boolean;
  shouldNavWithHighlightNewSuggestion?: boolean;
}

const gridGutter = 10;

export const Courses: React.FunctionComponent<ICoursesProps> = (props) => {
  const {
    courses,
    fetchMoreCourses,
    isNoMoreCoursesToFetch,
    emptyStateText,
    loadMoreText,
    shouldNavWithReviewMode,
    shouldNavWithHighlightNewSuggestion,
  } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {courses.length === 0 && (
        <Typography.Text italic={true}>
          {emptyStateText || "No courses found"}
        </Typography.Text>
      )}
      <Row gutter={[gridGutter, gridGutter]}>
        {courses.map((course) => {
          return (
            <Col
              style={{ height: "100%" }}
              md={8}
              xs={24}
              key={`CourseList-item-${course.id}`}
            >
              <Course
                course={course}
                shouldNavWithReviewMode={shouldNavWithReviewMode}
                shouldNavWithHighlightNewSuggestion={
                  shouldNavWithHighlightNewSuggestion
                }
              />
            </Col>
          );
        })}
      </Row>
      <FetchMoreButton
        text={loadMoreText || "Load more courses"}
        isNoMoreToFetch={isNoMoreCoursesToFetch}
        fetchMore={fetchMoreCourses}
      />
    </Space>
  );
};
