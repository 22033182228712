import { notification } from "antd";
import React, { useState } from "react";
import { useUpdateUserMutation } from "../../firestore/mutations/useUpdateUserMutation";
import { locStrings } from "../../localization/locStrings";
import { IUser } from "../../types/firestoreMappings";
import { SettingsNotificationsEdit } from "./SettingsNotificationsEdit";

interface ISettingsNotificationsEditContainerProps {
  meUser: IUser;
}

export const SettingsNotificationsEditContainer: React.FunctionComponent<
  ISettingsNotificationsEditContainerProps
> = (props) => {
  const { meUser } = props;
  const [enableNewNotificationEmails, setEnableNewNotificationEmails] =
    useState<boolean>(!!meUser.enableNewNotificationEmails);
  const { mutate: updateUser, isLoading: isUpdateUserLoading } =
    useUpdateUserMutation(meUser.id);

  const areAnySettingsDelta =
    !!enableNewNotificationEmails !== !!meUser.enableNewNotificationEmails;

  const onSave = () => {
    try {
      updateUser(
        { enableNewNotificationEmails },
        {
          onSuccess: () => {
            notification.success({
              message: "Notification settings successfully saved",
            });
          },
        }
      );
    } catch (error) {
      notification.error({ message: locStrings.genericErrorMessage.value() });
    }
  };

  return (
    <SettingsNotificationsEdit
      onSave={onSave}
      isLoadingSave={isUpdateUserLoading}
      areAnySettingsDelta={areAnySettingsDelta}
      enableNewNotificationEmails={enableNewNotificationEmails}
      setEnableNewNotificationEmails={setEnableNewNotificationEmails}
    />
  );
};
