import { Typography } from "antd";
import React from "react";
import { useListUsersByIds } from "../../firestore/queries/useListUsersByIds";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { GenericError } from "./GenericError";
import { GenericLoader } from "./GenericLoader";

interface IOwnersOfCourseLinksProps {
  owners: string[];
  disableLinking?: boolean;
}

export const OwnersOfCourseLinks: React.FunctionComponent<
  IOwnersOfCourseLinksProps
> = (props) => {
  const { owners, disableLinking } = props;
  const nav = useNavigation();
  const {
    data: ownersOfCourse,
    isLoading: isOwnersOfCourseLoading,
    isError: isOwnersOfCourseError,
  } = useListUsersByIds(owners);

  if (isOwnersOfCourseError) {
    return <GenericError />;
  }

  if (isOwnersOfCourseLoading || !ownersOfCourse) {
    return <GenericLoader />;
  }

  const onOwnerClick = (ownerId: string) => {
    if (!disableLinking) {
      nav.goToPaths.userProfile(ownerId);
    }
  };

  return (
    <Typography>
      {"Owned by "}
      {ownersOfCourse.map((owner, index) => {
        const ownerOfCourseKey = `OwnersOfCourseLinks-${owner.id}`;
        const textToShow =
          index === ownersOfCourse.length - 1
            ? owner.username
            : `${owner.username}, `;

        return (
          <Typography.Link
            key={ownerOfCourseKey}
            type={"secondary"}
            style={{ display: "inline" }}
            onClick={() => onOwnerClick(owner.id)}
          >
            {textToShow}
          </Typography.Link>
        );
      })}
    </Typography>
  );
};
