import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertCourseFavoriteSnapToICourseFavorite,
  ICourseFavorite,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

interface ListCourseFavoritesQueryParams {
  owner: string;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 3;

export const listCourseFavoritesQueryKey = (
  params: ListCourseFavoritesQueryParams
) => {
  return ["listCourseFavoritesQueryKey", params];
};

export const useListCourseFavoritesQuery = (
  params: ListCourseFavoritesQueryParams,
  enabled: boolean = true
) => {
  const listCourseFavorites = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ICourseFavorite[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const { owner, numItemsPerPage } = params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const courseFavoritesCollectionRef = collection(
      db,
      collectionPaths.courseFavorites
    );
    const queryConstraints = [];
    // Always sort by createdAt by default
    queryConstraints.push(orderBy("createdAt", "desc"));
    if (owner) {
      queryConstraints.push(where("owner", "==", owner));
    }
    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      courseFavoritesCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    const courseFavorites = qSnapshot.docs.map((snapshot) =>
      convertCourseFavoriteSnapToICourseFavorite(snapshot.id, snapshot.data())
    );

    if (courseFavorites.length < numItemsPerPageToUse) {
      isLastPage = true;
    }
    return {
      realData: courseFavorites,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listCourseFavoritesQueryKey(params),
    listCourseFavorites,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListCourseFavoritesQuery"),
      enabled,
    }
  );

  let realDataAllPages: ICourseFavorite[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }

  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
