import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { listLessonQuestionsQueryKey } from "../queries/useListLessonQuestionsQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateLessonQuestionMutation = (
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const getCourse = useGetCourseFetch();
  const createLessonQuestion = async (question: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateLessonQuestionMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const lessonQuestionsCollectionRef = collection(
      db,
      collectionPaths.lessonQuestions
    );
    const newLessonQuestionDoc = await addDoc(lessonQuestionsCollectionRef, {
      question,
      courseId,
      lessonId,
      upvotes: 0,
      owner: user.uid,
      createdAt: serverTimestamp(),
    });
    const courseData = await getCourse({ courseId });
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid) {
          createNotification({
            type: NotificationType.LessonQuestion,
            userId: owner,
            notificationData: {
              lessonQuestionId: newLessonQuestionDoc.id,
            },
          });
        }
      });
    }
  };
  return useMutation(createLessonQuestion, {
    onSettled: settledLogger("useCreateLessonQuestionMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonQuestionsQueryKey({
          courseId,
          lessonId,
        }),
        { refetchInactive: true }
      );
    },
  });
};
