import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteLessonMutation } from "../../../firestore/mutations/useDeleteLessonMutation";
import { useUpdateLessonMutation } from "../../../firestore/mutations/useUpdateLessonMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { useGetWeblinkPreviewFetch } from "../../../firestore/queries/useGetWeblinkPreviewQuery";
import { locStrings } from "../../../localization/locStrings";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import {
  generateEmptyHtmlString,
  getLessonFromCourse,
  getModuleIdViaLessonIdFromCourse,
} from "../../../utils/utils";
import { isValidLessonInputWithErrorNotify } from "../../../utils/validInputUtils";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { LessonEdit } from "./LessonEdit";

interface ILessonUpdateContainerProps {}

type ILessonUpdateContainerParams = {
  courseId: string;
  lessonId: string;
};

export const LessonUpdateContainer: React.FunctionComponent<
  ILessonUpdateContainerProps
> = (props) => {
  const { courseId, lessonId } = useParams<ILessonUpdateContainerParams>();
  const nav = useNavigation();
  const { data: course, isLoading: isLoadingCourse } = useGetCourseQuery({
    courseId: courseId || "",
  });
  const fetchWeblinkPreview = useGetWeblinkPreviewFetch();
  const {
    mutate: updateLesson,
    isLoading: isLessonUpdateLoading,
    isSuccess: isLessonUpdateSuccess,
  } = useUpdateLessonMutation(courseId || "");
  const {
    mutate: deleteLesson,
    isLoading: isLessonDeleteLoading,
    isSuccess: isLessonDeleteSuccess,
  } = useDeleteLessonMutation(courseId || "");
  const [isLoadingWeblinkPreview, setIsLoadingWeblinkPreview] =
    useState<boolean>(false);
  const [lessonTitle, setLessonTitle] = useState<string>("");
  const [lessonWeblink, setLessonWeblink] = useState<string>("");
  const [lessonDescription, setLessonDescription] = useState<string>("");
  const onlessonTitleChange = (s: string) => {
    setLessonTitle(s);
  };
  const onlessonWeblinkChange = (s: string) => {
    setLessonWeblink(s);
  };
  const onlessonDescriptionChange = (s: string) => {
    setLessonDescription(s);
  };

  useEffect(() => {
    if (isLessonUpdateSuccess) {
      nav.window.goBack();
    }
    if (isLessonDeleteSuccess) {
      nav.goToPaths.courseInfo(courseId || "");
    }
  }, [
    isLessonUpdateSuccess,
    isLessonDeleteSuccess,
    nav.window,
    nav.goToPaths,
    courseId,
  ]);

  useEffect(() => {
    if (course && lessonId) {
      const currentLesson = getLessonFromCourse(lessonId, course);
      if (currentLesson) {
        setLessonTitle(currentLesson.title);
        setLessonWeblink(currentLesson.weblink);
        setLessonDescription(
          currentLesson.description || generateEmptyHtmlString()
        );
      }
    }
  }, [course, lessonId]);

  if (!lessonId) {
    return <GenericError />;
  }

  if (
    isLoadingCourse ||
    isLessonUpdateLoading ||
    isLessonDeleteLoading ||
    !course ||
    !lessonDescription // Must wait for htmlString to load to set initial value
  ) {
    return <GenericLoader />;
  }

  const onUpdateLesson = async () => {
    setIsLoadingWeblinkPreview(true);
    const previewData = await fetchWeblinkPreview({ weblink: lessonWeblink });
    setIsLoadingWeblinkPreview(false);
    const isValid = isValidLessonInputWithErrorNotify().forUpdate({
      title: lessonTitle,
      weblink: lessonWeblink,
      description: lessonDescription,
      previewImageUrl: previewData.previewImageUrl,
    });

    if (isValid) {
      const targetModuleId = getModuleIdViaLessonIdFromCourse(course, lessonId);
      if (targetModuleId) {
        updateLesson({
          course,
          targetModuleId,
          lessonId,
          title: lessonTitle,
          description: lessonDescription,
          weblink: lessonWeblink,
          previewImageUrl: previewData.previewImageUrl,
        });
      } else {
        notification.error({ message: "Lesson not found in any module" });
      }
    }
  };

  const onDeleteLesson = () => {
    deleteLesson({ course, lessonId });
  };

  return (
    <LessonEdit
      pageTitle={locStrings.editLessonPageTitle.value()}
      course={course}
      selectedModuleId={""}
      lessonTitle={lessonTitle}
      lessonWeblink={lessonWeblink}
      lessonDescription={lessonDescription}
      onSelectedModuleIdChange={() => {}}
      onLessonTitleChange={onlessonTitleChange}
      onLessonWeblinkChange={onlessonWeblinkChange}
      onLessonDescriptionChange={onlessonDescriptionChange}
      onSubmit={onUpdateLesson}
      hideModuleSelection={true}
      onDeleteLesson={onDeleteLesson}
      isLoading={isLoadingWeblinkPreview}
    />
  );
};
