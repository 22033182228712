import { Card, Space, Typography } from "antd";
import React from "react";
import { ClickableWrapper } from "../common/ClickableWrapper";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";

interface INewLinkTypeSelectionProps {
  onBrandNewNext: () => void;
  onAlternativeNext: () => void;
}

export const NewLinkTypeSelection: React.FunctionComponent<
  INewLinkTypeSelectionProps
> = (props) => {
  const { onBrandNewNext, onAlternativeNext } = props;
  return (
    <Space direction={"vertical"}>
      <div style={{ marginBottom: "1rem" }}>
        <Typography>
          {
            "Is your link a brand new concept or an alternative to an existing link?"
          }
        </Typography>
      </div>
      <LinkTypeSelectionOption
        onClick={onBrandNewNext}
        title={"A brand new link"}
      />
      <LinkTypeSelectionOption
        onClick={onAlternativeNext}
        title={"An alternative to an existing link"}
      />
    </Space>
  );
};

const LinkTypeSelectionOption: React.FunctionComponent<{
  onClick: () => void;
  title: string;
}> = (props) => {
  const { onClick, title } = props;
  return (
    <ClickableWrapper onClick={onClick}>
      <Card>
        <PageTypography title={title} level={PageTypographyLevel.PageSection} />
      </Card>
    </ClickableWrapper>
  );
};
