import { Button, Space } from "antd";
import React, { useState } from "react";
import { ILessonAlternativeLink } from "../../types/firestoreMappings";
import { DeleteOutlined, EditFilled, EditOutlined } from "@ant-design/icons";
import { ConfirmButton } from "../common/ConfirmButton";
import { locStrings } from "../../localization/locStrings";
import { isValidLessonAlternativeLinkWithErrorNotify } from "../../utils/validInputUtils";
import { UpvoteButtonContainer } from "../upvotes/UpvoteButtonContainer";
import { MetadataType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { RichTextEditor } from "../common/RichTextEditor";
import { WeblinkInput } from "../common/WeblinkInput";
import { CourseStructureLessonItem } from "../course/course-structure/CourseStructureLessonItem";
import { convertCourseMetadataTypeToIcon } from "../../utils/iconAndStringUtils";
import { CourseCommentsContainer } from "../comments/CourseCommentsContainer";
import { CreateCourseCommentInputContainer } from "../comments/write/CreateCourseCommentInputContainer";

interface ILessonAlternativeLinkProps {
  alternativeLink: ILessonAlternativeLink;
  commentCountForAlternativeLink: number;
  deleteAlternativeLink?: () => void;
  updateAlternativeLink?: (vars: { weblink: string; reason: string }) => void;
}

export const LessonAlternativeLink: React.FunctionComponent<
  ILessonAlternativeLinkProps
> = (props) => {
  const {
    alternativeLink,
    commentCountForAlternativeLink,
    updateAlternativeLink,
    deleteAlternativeLink,
  } = props;
  const nav = useNavigation();
  const parsedLessonAlternativeLinkId =
    nav.urlSearchParams.getLessonAlternativeLinkId();
  const parsedCourseCommentIdForAlternativeLink =
    nav.urlSearchParams.getCourseCommentIdForAlternativeLink();
  const wasNavigatedTo = parsedLessonAlternativeLinkId === alternativeLink.id;
  const wasNavigatedToForComment =
    !!parsedCourseCommentIdForAlternativeLink && wasNavigatedTo;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [weblinkInput, setWeblinkInput] = useState<string>(
    alternativeLink.weblink
  );
  const [reasonInput, setReasonInput] = useState<string>(
    alternativeLink.reason
  );

  const onUpdateAlternativeLink = () => {
    if (
      updateAlternativeLink &&
      isValidLessonAlternativeLinkWithErrorNotify().forUpdate({
        lessonId: alternativeLink.lessonId,
        weblink: weblinkInput,
        reason: reasonInput,
      })
    ) {
      updateAlternativeLink({ weblink: weblinkInput, reason: reasonInput });
    } else {
      console.error(
        "LessonAlternativeLink.onUpdateAlternativeLink, attempted to update without edit permission or invalid fields"
      );
    }
  };

  const renderEditAlternativeLink = (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: "0.5rem",
      }}
    >
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <WeblinkInput weblink={weblinkInput} onChange={setWeblinkInput} />
        <RichTextEditor
          initialValue={reasonInput}
          onHtmlStringChange={setReasonInput}
          placeholder={"Why is this a good alternative link?"}
        />
      </Space>
      <Button onClick={onUpdateAlternativeLink} type={"primary"}>
        {locStrings.submit.value()}
      </Button>
    </div>
  );

  if (isEditMode) {
    return <div style={{ width: "100%" }}>{renderEditAlternativeLink}</div>;
  }

  return (
    <CourseStructureLessonItem
      lessonTitle={""}
      lessonWeblink={alternativeLink.weblink}
      lessonDescription={alternativeLink.reason}
      href={alternativeLink.weblink}
      previewImageUrl={alternativeLink.previewImageUrl}
      leftEl={
        <UpvoteButtonContainer
          upvoteCount={alternativeLink.upvotes}
          metadataId={alternativeLink.id}
          metadataType={MetadataType.AlternativeLink}
        />
      }
      tabs={[
        {
          id: `LessonAlternativeLink-Tab-${alternativeLink.id}`,
          tabTitle: {
            icon: convertCourseMetadataTypeToIcon({
              metadataType: MetadataType.CourseComment,
            }),
            title: `${commentCountForAlternativeLink}`,
            tooltip: "Comments",
          },
          tabFunctionality: {
            isDefaultCurrentTab: wasNavigatedTo && wasNavigatedToForComment,
            content: (
              <>
                <CreateCourseCommentInputContainer
                  courseId={alternativeLink.courseId}
                  metadataType={MetadataType.AlternativeLink}
                  metadataId={alternativeLink.id}
                />
                <CourseCommentsContainer
                  params={{
                    courseId: alternativeLink.courseId,
                    metadataType: MetadataType.AlternativeLink,
                    metadataId: alternativeLink.id,
                    numItemsPerPage: 3,
                    topItemId: wasNavigatedToForComment
                      ? parsedCourseCommentIdForAlternativeLink
                      : undefined,
                  }}
                  shouldSkipFetch={false}
                />
              </>
            ),
          },
        },
      ]}
      cardWithUserHeaderProps={{
        userId: alternativeLink.owner,
        courseIdToCheckForOwnership: alternativeLink.courseId,
        date: alternativeLink.createdAt,
        customElements: [
          updateAlternativeLink && (
            <Button
              icon={isEditMode ? <EditFilled /> : <EditOutlined />}
              type={"text"}
              onClick={() => setIsEditMode(!isEditMode)}
            />
          ),
          deleteAlternativeLink && (
            <ConfirmButton
              onConfirm={deleteAlternativeLink}
              icon={<DeleteOutlined />}
              type={"text"}
            />
          ),
        ],
        className:
          wasNavigatedTo && !wasNavigatedToForComment
            ? "highlight-light-blue-and-fade"
            : undefined,
      }}
    />
  );
};
