import { useListNotificationsQuery } from "../firestore/queries/useListNotificationsQuery";
import { useUserAuth } from "./useUserAuth";

export const useUnreadNotificationsCount = () => {
  const { user, isSignedIn } = useUserAuth();
  const { data: unreadNotifications } = useListNotificationsQuery(
    { userId: user?.uid || "", isRead: false },
    isSignedIn && !!user?.uid
  );
  return unreadNotifications?.length || 0;
};
