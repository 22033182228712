import React from "react";
import {
  ListLessonQuestionsQueryParams,
  useListLessonQuestionsQuery,
} from "../../firestore/queries/useListLessonQuestionsQuery";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { LessonQuestions } from "./LessonQuestions";

interface ILessonQuestionsContainerProps {
  params: ListLessonQuestionsQueryParams;
  shouldSkipFetch: boolean;
}

export const LessonQuestionsContainer: React.FunctionComponent<
  ILessonQuestionsContainerProps
> = (props) => {
  const { params, shouldSkipFetch } = props;
  const {
    data: questions,
    fetchNextPage: fetchMoreQuestions,
    isNoMoreToFetch: isNoMoreQuestionsToFetch,
    isLoading: isQuestionsLoading,
    isError: isQuestionsError,
  } = useListLessonQuestionsQuery(params, !shouldSkipFetch);

  if (isQuestionsError) {
    return <GenericError />;
  }

  if (isQuestionsLoading || !questions) {
    return <GenericLoader />;
  }

  return (
    <LessonQuestions
      questions={questions}
      isNoMoreQuestionsToFetch={isNoMoreQuestionsToFetch}
      fetchMoreQuestions={fetchMoreQuestions}
    />
  );
};
