import React from "react";
import { useIsMeUserOwnerOfCourse } from "../../../ui-hooks/useIsMeUserOwnerOfCourse";
import { ICourseSuggestion } from "../../../types/firestoreMappings";
import { useUpdateCourseSuggestionMutation } from "../../../firestore/mutations/useUpdateCourseSuggestionMutation";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseSuggestionStateDisplayEdit } from "./CourseSuggestionStateDisplayEdit";
import { CourseSuggestionStateDisplayRead } from "./CourseSuggestionStateDisplayRead";
import { useSisuModal } from "../../../ui-hooks/useSisuModal";

interface ICourseSuggestionStateDisplayContainerProps {
  courseSuggestion: ICourseSuggestion;
  buttonContent: React.ReactNode;
}

export const CourseSuggestionStateDisplayContainer: React.FunctionComponent<
  ICourseSuggestionStateDisplayContainerProps
> = (props) => {
  const { courseSuggestion, buttonContent } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: updateSuggestion, isLoading: isUpdateSuggestionLoading } =
    useUpdateCourseSuggestionMutation(
      courseSuggestion.id,
      courseSuggestion.courseId
    );
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(
    courseSuggestion.courseId
  );

  if (isUpdateSuggestionLoading) {
    return <GenericLoader />;
  }

  if (isMeUserOwnerOfCourse) {
    return (
      <CourseSuggestionStateDisplayEdit
        buttonContent={buttonContent}
        courseSuggestion={courseSuggestion}
        updateSuggestion={forceSisuModalFxnWrapper(updateSuggestion)}
      />
    );
  } else {
    return (
      <CourseSuggestionStateDisplayRead courseSuggestion={courseSuggestion} />
    );
  }
};
