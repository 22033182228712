import { notification } from "antd";
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateCourseFavoriteId } from "../idHelpers";
import { getCourseFavoriteQueryKey } from "../queries/useGetCourseFavoriteQuery";
import { listCourseFavoritesQueryKey } from "../queries/useListCourseFavoritesQuery";
import { settledLogger } from "../settledLogger";

export const useCreateCourseFavoriteMutation = (courseId: string) => {
  const queryClient = useQueryClient();
  const { user, isSignedIn } = useUserAuth();
  const courseFavoriteId = useGenerateCourseFavoriteId(courseId);
  const createCourseFavorite = async () => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateCourseFavoriteMutation, error - no user found"
      );
    }
    if (!courseFavoriteId) {
      return Promise.reject(
        "useCreateCourseFavoriteMutation, courseFavoriteId not properly generated"
      );
    }

    const db = getFirestore();
    return setDoc(doc(db, collectionPaths.courseFavorites, courseFavoriteId), {
      courseId,
      owner: user.uid,
      createdAt: serverTimestamp(),
    });
  };
  return useMutation(createCourseFavorite, {
    onSettled: settledLogger("useCreateCourseFavoriteMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        getCourseFavoriteQueryKey({ courseId, isSignedIn }),
        { refetchInactive: true }
      );
      queryClient.invalidateQueries(
        listCourseFavoritesQueryKey({ owner: user?.uid || "" }),
        { refetchInactive: true, refetchPage: (page, index) => true }
      );
    },
  });
};
