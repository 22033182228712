import { Button, Space } from "antd";
import { HeartFilled, BookOutlined, SettingFilled } from "@ant-design/icons";
import React from "react";
import {
  PAGE_SECTION_SPACING,
  themeColors,
} from "../../styling/styleConstants";
import { IUser } from "../../types/firestoreMappings";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { BackButton } from "../common/BackButton";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { UserAvatar } from "../common/UserAvatar";
import { FavoriteCoursesContainer } from "../course-favorites/FavoriteCoursesContainer";
import { CoursesContainer } from "../course/course-list/CoursesContainer";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { locStrings } from "../../localization/locStrings";
import { useScrollToElementRef } from "../../ui-hooks/useScrollToElementRef";

interface IUserProfileProps {
  user: IUser;
  isMyself: boolean;
}

export const UserProfile: React.FunctionComponent<IUserProfileProps> = (
  props
) => {
  const { user, isMyself } = props;
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  const nav = useNavigation();
  const parsedMyCoursesScroll = nav.urlSearchParams.getMyCourses();
  const parsedMyFavoritesScroll = nav.urlSearchParams.getMyFavorites();
  const { elRef: myCoursesSectionRef } = useScrollToElementRef(
    parsedMyCoursesScroll === "true",
    500
  );
  const { elRef: myFavoritesSectionRef } = useScrollToElementRef(
    parsedMyFavoritesScroll === "true",
    500
  );
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {isMyself && (
            <Button onClick={nav.goToPaths.settings} icon={<SettingFilled />}>
              {"Settings"}
            </Button>
          )}
        </div>
        <Space
          direction={"horizontal"}
          style={{ marginBottom: PAGE_SECTION_SPACING, alignItems: "center" }}
        >
          <BackButton />
          <UserAvatar
            uid={user.id}
            username={user.username}
            avatarUrl={user.avatarUrl}
            overrideSize={isScreenLargerThanMd ? 150 : 80}
          />
          <PageTypography
            title={user.username}
            level={PageTypographyLevel.PageTitle}
          />
        </Space>
        <div
          style={{ marginBottom: PAGE_SECTION_SPACING }}
          ref={myCoursesSectionRef}
        >
          <ProfileSectionTitle
            title={isMyself ? locStrings.myCourses.value() : "Owned Courses"}
            icon={<BookOutlined />}
          />
          <CoursesContainer
            listCoursesQueryParams={{ owner: user.id, sortByUpdatedAt: true }}
          />
        </div>
        <div
          style={{ marginBottom: PAGE_SECTION_SPACING }}
          ref={myFavoritesSectionRef}
        >
          <ProfileSectionTitle
            title={"Favorites"}
            icon={
              <HeartFilled
                style={{ color: themeColors.courseFavoriteHeartPink }}
              />
            }
          />
          <FavoriteCoursesContainer owner={user.id} />
        </div>
      </Space>
    </LayoutContent>
  );
};

const ProfileSectionTitle: React.FunctionComponent<{
  title: string;
  icon: JSX.Element;
}> = (props) => {
  const { title, icon } = props;
  return (
    <PageTypography
      title={
        <Space direction={"horizontal"}>
          {icon}
          {title}
        </Space>
      }
      level={PageTypographyLevel.PageSection}
      style={{ marginBottom: "1rem" }}
    />
  );
};
