import { useCallback, useEffect, useRef } from "react";

export const useScrollToElementRef = (
  shouldScrollImmediately: boolean,
  immediateScrollDelay?: number
) => {
  const elRef = useRef<HTMLDivElement | null>(null);

  const scrollToEl = useCallback(() => {
    elRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [elRef]);

  useEffect(() => {
    if (shouldScrollImmediately) {
      setTimeout(scrollToEl, 1000 || immediateScrollDelay);
    }
  }, [scrollToEl, shouldScrollImmediately, immediateScrollDelay]);

  return { elRef, scrollToEl };
};
