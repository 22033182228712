import { Space } from "antd";
import React from "react";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";
import { CoursesContainer } from "../course/course-list/CoursesContainer";
import { CoursePublishState } from "../../types/helperTypes";

interface IReviewQueueProps {}

export const ReviewQueue: React.FunctionComponent<IReviewQueueProps> = (
  props
) => {
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={"Review Queue"}
            level={PageTypographyLevel.PageTitle}
          />
        </div>
        <CoursesContainer
          listCoursesQueryParams={{
            publishStates: [CoursePublishState.InReview],
          }}
          shouldNavWithReviewMode={true}
        />
      </Space>
    </LayoutContent>
  );
};
