import { notification } from "antd";
import React from "react";
import { useCreateCourseSuggestionMutation } from "../../../../firestore/mutations/useCreateCourseSuggestionMutation";
import { locStrings } from "../../../../localization/locStrings";
import { zIndexPriorities } from "../../../../styling/styleConstants";
import { CourseSuggestionType } from "../../../../types/helperTypes";
import { useSisuModal } from "../../../../ui-hooks/useSisuModal";
import { convertCourseSuggestionTypeToCreateSelectionItemStrings } from "../../../../utils/iconAndStringUtils";
import { isValidCourseSuggestionWithErrorNotify } from "../../../../utils/validInputUtils";
import { GenericLoader } from "../../../common/GenericLoader";
import { ModalWithTitle } from "../../../common/ModalWithTitle";
import { CourseSuggestionCreateNewModule } from "./CourseSuggestionCreateNewModule";

interface ICourseSuggestionCreateNewModuleContainerProps {
  courseId: string;
  visible: boolean;
  onClose: () => void;
}

export const CourseSuggestionCreateNewModuleContainer: React.FunctionComponent<
  ICourseSuggestionCreateNewModuleContainerProps
> = (props) => {
  const { courseId, visible, onClose } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: createSuggestion, isLoading: isCreateSuggestionLoading } =
    useCreateCourseSuggestionMutation(courseId);

  const onCreateNewModuleSuggestion = async (vars: {
    reason: string;
    moduleTitle: string;
  }) => {
    const type = CourseSuggestionType.NewModule;
    const { reason, moduleTitle } = vars;
    const isValid = isValidCourseSuggestionWithErrorNotify().forCreate({
      type,
      reason,
      moduleTitle,
    });

    if (isValid) {
      await createSuggestion({
        type,
        reason,
        suggestionData: { moduleTitle },
      });
      notification.success({
        message: locStrings.courseSuggestionCreatedSuccessNotification.value(),
      });
      onClose();
    } else {
      console.error(
        "CourseSuggestionCreateNewModuleContainer.onCreateNewModuleSuggestion, not valid"
      );
    }
  };

  if (isCreateSuggestionLoading) {
    return <GenericLoader />;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onClose}
      title={
        convertCourseSuggestionTypeToCreateSelectionItemStrings(
          CourseSuggestionType.NewModule
        ).title
      }
      subTitle={
        convertCourseSuggestionTypeToCreateSelectionItemStrings(
          CourseSuggestionType.NewModule
        ).subTitle
      }
      zIndex={zIndexPriorities.courseSuggestionNewModuleCreateModal}
    >
      <CourseSuggestionCreateNewModule
        onCreateNewModuleSuggestion={forceSisuModalFxnWrapper(
          onCreateNewModuleSuggestion
        )}
      />
    </ModalWithTitle>
  );
};
