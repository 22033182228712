import React from "react";
import {
  ListLessonAlternativeLinksQueryParams,
  useListLessonAlternativeLinksQuery,
} from "../../firestore/queries/useListLessonAlternativeLinksQuery";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { LessonAlternativeLinks } from "./LessonAlternativeLinks";

interface ILessonAlternativeLinksContainerProps {
  params: ListLessonAlternativeLinksQueryParams;
  shouldSkipFetch: boolean;
}

export const LessonAlternativeLinksContainer: React.FunctionComponent<
  ILessonAlternativeLinksContainerProps
> = (props) => {
  const { params, shouldSkipFetch } = props;
  const {
    data: alternativeLinks,
    fetchNextPage: fetchMoreAlternativeLinks,
    isNoMoreToFetch: isNoMoreAlternativeLinksToFetch,
    isLoading: isAlternativeLinksLoading,
    isError: isAlternativeLinksError,
  } = useListLessonAlternativeLinksQuery(params, !shouldSkipFetch);

  if (isAlternativeLinksError) {
    return <GenericError />;
  }

  if (isAlternativeLinksLoading || !alternativeLinks) {
    return <GenericLoader />;
  }

  return (
    <LessonAlternativeLinks
      alternativeLinks={alternativeLinks}
      fetchMoreAlternativeLinks={fetchMoreAlternativeLinks}
      isNoMoreAlternativeLinksToFetch={isNoMoreAlternativeLinksToFetch}
    />
  );
};
