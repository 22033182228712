import { Button, Input, Space } from "antd";
import React from "react";
import { locStrings } from "../../../localization/locStrings";
import {
  PAGE_SECTION_SPACING,
  themeColors,
} from "../../../styling/styleConstants";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { RichTextEditor } from "../../common/RichTextEditor";
import { UserSelect } from "../../common/UserSelect";
import { DeleteOutlined } from "@ant-design/icons";
import { ConfirmButton } from "../../common/ConfirmButton";
import { CourseCategorySelect } from "./CourseCategorySelect";
import { CourseCategory } from "../../../types/helperTypes";

interface ICourseEditProps {
  pageTitle: string;
  courseTitle: string;
  courseDescription: string;
  courseCategories: CourseCategory[];
  onCourseTitleChange: (s: string) => void;
  onCourseDescriptionChange: (s: string) => void;
  onCourseCategoriesChange: (newCategories: CourseCategory[]) => void;
  onSubmit: () => Promise<void>;
  disableRemovalOfMyUser: boolean;
  courseOwners?: string[];
  onCourseOwnersChange?: (owners: string[]) => void;
  onDeleteCourse?: () => void;
}

export const CourseEdit: React.FunctionComponent<ICourseEditProps> = (
  props
) => {
  const {
    pageTitle,
    courseTitle,
    courseDescription,
    courseCategories,
    onCourseTitleChange,
    onCourseDescriptionChange,
    onCourseCategoriesChange,
    onSubmit,
    disableRemovalOfMyUser,
    courseOwners,
    onCourseOwnersChange,
    onDeleteCourse,
  } = props;

  const isCourseOwnerSelectionEnabled =
    !!courseOwners && !!onCourseOwnersChange;

  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <PageTypography
            title={pageTitle}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
            style={{ marginBottom: PAGE_SECTION_SPACING }}
          />
          {!!onDeleteCourse && (
            <ConfirmButton
              onConfirm={onDeleteCourse}
              text={locStrings.delete.value()}
              type={"default"}
              icon={<DeleteOutlined />}
              style={{ color: themeColors.errorRed }}
            />
          )}
        </div>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={locStrings.courseTitle.value()}
            level={PageTypographyLevel.PageSection}
          />
          <Input
            id={"CourseEdit-title"}
            type={"text"}
            placeholder={locStrings.courseTitlePlaceholder.value()}
            value={courseTitle}
            onChange={(e) => onCourseTitleChange(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={locStrings.courseDescription.value()}
            level={PageTypographyLevel.PageSection}
          />
          <RichTextEditor
            initialValue={courseDescription}
            onHtmlStringChange={onCourseDescriptionChange}
            placeholder={locStrings.courseDescriptionPlaceholder.value()}
          />
        </div>
        <div
          style={{
            marginBottom: isCourseOwnerSelectionEnabled
              ? PAGE_SECTION_SPACING
              : 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageTypography
            title={locStrings.courseCategories.value()}
            level={PageTypographyLevel.PageSection}
          />
          <CourseCategorySelect
            categories={courseCategories}
            onCategoriesChange={onCourseCategoriesChange}
          />
        </div>
        {isCourseOwnerSelectionEnabled && (
          <div>
            <PageTypography
              title={locStrings.courseOwners.value()}
              level={PageTypographyLevel.PageSection}
            />
            <UserSelect
              value={courseOwners}
              onChange={onCourseOwnersChange}
              disableRemovalOfMyUser={disableRemovalOfMyUser}
            />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onSubmit} type={"primary"}>
            {locStrings.submit.value()}
          </Button>
        </div>
      </Space>
    </LayoutContent>
  );
};
