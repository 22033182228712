import { ICourse, ILesson } from "../../types/firestoreMappings";
import { placeLessonInModule } from "../../utils/utils";
import { generateUniqueId } from "../idHelpers";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useCreateLessonMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  let newLessonId = "";
  const createLesson = async (vars: {
    course: ICourse;
    selectedModuleId: string;
    title: string;
    description: string;
    weblink: string;
    prevLessonId?: string;
    nextLessonId?: string;
    previewImageUrl?: string;
    createdFromSuggestionId?: string;
    onSuccess?: () => void;
  }) => {
    const {
      course,
      selectedModuleId,
      title,
      description,
      weblink,
      prevLessonId,
      nextLessonId,
      previewImageUrl,
      createdFromSuggestionId,
      onSuccess,
    } = vars;
    const lessonId = generateUniqueId();
    newLessonId = lessonId;
    const newLesson: ILesson = {
      id: lessonId,
      title,
      description,
      weblink,
    };
    if (previewImageUrl) {
      (newLesson as any).previewImageUrl = previewImageUrl;
    }
    if (createdFromSuggestionId) {
      (newLesson as any).createdFromSuggestionId = createdFromSuggestionId;
    }
    const updatedModules = course.modules.map((module) => {
      if (module.id === selectedModuleId) {
        const updatedModule = placeLessonInModule(
          newLesson,
          module,
          prevLessonId,
          nextLessonId
        );
        return updatedModule;
      }
      return module;
    });
    await updateCourseMutation.mutate(
      {
        modules: updatedModules,
      },
      { onSuccess }
    );
  };
  return {
    ...updateCourseMutation,
    mutate: createLesson,
    data: { newLessonId },
  };
};
