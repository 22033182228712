import { Space } from "antd";
import React from "react";
import { LayoutContent } from "../common/LayoutContent";
import { HomeContributeCourses } from "./HomeContributeCourses";
import { HomeDiscordSection } from "./HomeDiscordSection";
import { HomeExploreSection } from "./HomeExploreSection";
import { HomeInformationalSection } from "./HomeInformationalSection";
import { HomeSampleReviewedCourses } from "./HomeSampleReviewedCourses";
import { HomeTitleSection } from "./HomeTitleSection";

interface IHomeProps {}

export const Home: React.FunctionComponent<IHomeProps> = (props) => {
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: "1rem" }}>
          <HomeTitleSection />
        </div>
        <div style={{ marginBottom: "4rem" }}>
          <HomeInformationalSection />
        </div>
        <div
          style={{
            marginBottom: "4rem",
          }}
        >
          <HomeSampleReviewedCourses />
        </div>
        <div
          style={{
            marginBottom: "4rem",
          }}
        >
          <HomeExploreSection />
        </div>
        <div
          style={{
            marginBottom: "4rem",
          }}
        >
          <HomeContributeCourses />
        </div>
        <div
          style={{
            marginBottom: "4rem",
          }}
        >
          <HomeDiscordSection />
        </div>
      </Space>
    </LayoutContent>
  );
};
