import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { themeColors } from "../../styling/styleConstants";

interface DiscordServerWidgetInfo {
  id: string;
  name: string;
  instant_invite: string;
  presence_count: number;
}

interface IDiscordButtonProps {}

export const DiscordButton: React.FunctionComponent<IDiscordButtonProps> = (
  props
) => {
  const [inviteLink, setInviteLink] = useState<string>("");
  useEffect(() => {
    const fetchDiscordServerInfo = async () => {
      try {
        const serverInfoResponse = await fetch(
          "https://discord.com/api/guilds/905264449160634368/widget.json"
        );
        const serverInfo =
          (await serverInfoResponse.json()) as DiscordServerWidgetInfo;
        setInviteLink(serverInfo.instant_invite);
      } catch (error) {
        console.error(
          `DiscordButton.fetchDiscordServerInfo, something went wrong`,
          error
        );
      }
    };
    fetchDiscordServerInfo();
  }, []);

  return (
    <a href={inviteLink} target={"_blank"} rel={"noreferrer"}>
      <Button
        style={{
          backgroundColor: themeColors.discordButtonBackground,
          border: themeColors.headerBackground,
          color: "white",
          fontWeight: 100,
        }}
        icon={
          <img
            src={
              "https://cdn.pixabay.com/photo/2021/03/02/12/02/logo-6062232__340.png"
            }
            alt={"Discord logo"}
            style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}
          />
        }
      >
        {"Join our Discord"}
      </Button>
    </a>
  );
};
