import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery } from "react-query";
import {
  convertUpvoteSnapToIUpvote,
  IUpvote,
} from "../../types/firestoreMappings";
import { MetadataType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateUpvoteId } from "../idHelpers";
import { settledLogger } from "../settledLogger";

interface IGetUpvoteArgs {
  metadataId: string;
}

interface IGetUpvoteKeyArgs extends IGetUpvoteArgs {
  isSignedIn: boolean;
}

export const getUpvoteQueryKey = (params: IGetUpvoteKeyArgs) => [
  "getUpvoteQueryKey",
  params,
];

const generateNonExistentUpvote = (): IUpvote => {
  return {
    id: "id",
    metadataId: "metadataId",
    metadataType: MetadataType.Unknown,
    owner: "owner",
    doesNotExist: true,
  };
};

export const useGetUpvoteQuery = (
  vars: IGetUpvoteArgs,
  enabled: boolean = true
) => {
  const { metadataId } = vars;
  const { isSignedIn } = useUserAuth();
  const upvoteId = useGenerateUpvoteId(metadataId);
  const getUpvote = async () => {
    if (!isSignedIn) {
      return Promise.resolve(generateNonExistentUpvote());
    }
    if (!upvoteId) {
      return Promise.reject(`getUpvote, upvoteId not properly generated`);
    }
    const db = getFirestore();
    const upvoteDocRef = doc(db, collectionPaths.upvotes, upvoteId);
    const snapshot = await getDoc(upvoteDocRef);
    if (snapshot.exists()) {
      return convertUpvoteSnapToIUpvote(upvoteId, snapshot.data());
    } else {
      return Promise.resolve(generateNonExistentUpvote());
    }
  };
  return useQuery(
    getUpvoteQueryKey({ metadataId, isSignedIn }),
    () => getUpvote(),
    {
      onSettled: settledLogger("useGetUpvoteQuery"),
      enabled,
    }
  );
};
