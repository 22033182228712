import { ICourse } from "../../types/firestoreMappings";
import { useUpdateCourseMutation } from "./useUpdateCourseMutation";

export const useUpdateLessonMutation = (courseId: string) => {
  const updateCourseMutation = useUpdateCourseMutation(courseId);
  const createLesson = async (vars: {
    course: ICourse;
    targetModuleId: string;
    lessonId: string;
    title: string;
    description: string;
    weblink: string;
    previewImageUrl?: string;
  }) => {
    const {
      course,
      targetModuleId,
      lessonId,
      title,
      description,
      weblink,
      previewImageUrl,
    } = vars;
    const updatedModules = course.modules.map((module) => {
      if (module.id === targetModuleId) {
        const updatedLessons = module.lessons.map((lesson) => {
          if (lesson.id === lessonId) {
            const updatedLesson = {
              ...lesson,
              title,
              description,
              weblink,
            };
            if (previewImageUrl) {
              updatedLesson.previewImageUrl = previewImageUrl;
            }
            return updatedLesson;
          }
          return lesson;
        });
        return {
          ...module,
          lessons: updatedLessons,
        };
      }
      return module;
    });
    await updateCourseMutation.mutate({
      modules: updatedModules,
    });
  };
  return {
    ...updateCourseMutation,
    mutate: createLesson,
  };
};
