import React from "react";
import { useDeleteLessonQuestionMutation } from "../../firestore/mutations/useDeleteLessonQuestionMutation";
import { useUpdateLessonQuestionMutation } from "../../firestore/mutations/useUpdateLessonQuestionMutation";
import { ILessonQuestion } from "../../types/firestoreMappings";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useIsMeUserOwnerOfLessonQuestion } from "../../ui-hooks/useIsMeUserOwnerOfLessonQuestion";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { GenericLoader } from "../common/GenericLoader";
import { LessonQuestion } from "./LessonQuestion";

interface ILessonQuestionContainerProps {
  question: ILessonQuestion;
}

export const LessonQuestionContainer: React.FunctionComponent<
  ILessonQuestionContainerProps
> = (props) => {
  const { question } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: deleteQuestion, isLoading: isDeleteQuestionLoading } =
    useDeleteLessonQuestionMutation(
      question.id,
      question.courseId,
      question.lessonId
    );
  const { mutate: updateQuestion, isLoading: isUpdateQuestionLoading } =
    useUpdateLessonQuestionMutation(
      question.id,
      question.courseId,
      question.lessonId
    );
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(question.courseId);
  const isMeUserOwnerOfQuestion = useIsMeUserOwnerOfLessonQuestion(question);
  const isEditable = isMeUserOwnerOfCourse || isMeUserOwnerOfQuestion;

  if (isDeleteQuestionLoading || isUpdateQuestionLoading) {
    return <GenericLoader />;
  }

  return (
    <LessonQuestion
      question={question}
      deleteQuestion={
        isEditable ? forceSisuModalFxnWrapper(deleteQuestion) : undefined
      }
      updateQuestion={
        isEditable ? forceSisuModalFxnWrapper(updateQuestion) : undefined
      }
    />
  );
};
