import { Badge } from "antd";
import React, { ReactNode } from "react";

interface IBadgeWrapperProps {
  children: ReactNode;
  isBadgeVisible: boolean;
  ribbonConfig?: {
    text: string;
  };
  defaultConfig?: {
    count: number;
  };
}

export const BadgeWrapper: React.FunctionComponent<IBadgeWrapperProps> = (
  props
) => {
  const { children, isBadgeVisible, ribbonConfig, defaultConfig } = props;

  if (isBadgeVisible) {
    if (defaultConfig) {
      //@ts-ignore
      return <Badge count={defaultConfig.count}>{children}</Badge>;
    }

    if (ribbonConfig) {
      return (
        <Badge.Ribbon text={ribbonConfig.text} color={"red"}>
          {children}
        </Badge.Ribbon>
      );
    }
  }

  return <>{children}</>;
};
