import { Button } from "antd";
import React, { useState } from "react";
import { convertSisuInputTypeToIcon } from "../../utils/iconAndStringUtils";

interface IRevealFormForEmailButtonProps {
  children: React.ReactNode;
}

export const RevealFormForEmailButton: React.FunctionComponent<
  IRevealFormForEmailButtonProps
> = (props) => {
  const { children } = props;
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  return isFormVisible ? (
    <>{children}</>
  ) : (
    <Button
      onClick={() => setIsFormVisible(true)}
      icon={convertSisuInputTypeToIcon("email")}
    >
      {"Sign in with email"}
    </Button>
  );
};
