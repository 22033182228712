import { getFunctions, httpsCallable } from "firebase/functions";
import { useQuery, useQueryClient } from "react-query";
import { getYoututubeVideoId } from "../../utils/utils";
import { settledLogger } from "../settledLogger";

export const getWeblinkPreviewKey = (weblink: string) => [
  "getWeblinkPreviewKey",
  weblink,
];

interface IGetWeblinkPreviewArgs {
  weblink: string;
}

interface WeblinkPreview {
  contentType: string;
  title: string;
  url: string;
  images: string[];
  favicons: string[];
  previewImageUrl?: string;
  description?: string;
  mediaType?: string;
  siteName?: string;
}

const emptyWeblinkPreview = (weblink: string) => ({
  contentType: "",
  title: "",
  url: weblink,
  images: [],
  favicons: [],
  previewImageUrl: undefined,
  description: "",
  mediaType: "",
  siteName: "",
});

const convertYoutubeLinkIntoDigestibleLink = (weblink: string): string => {
  const youtubeVideoId = getYoututubeVideoId(weblink);
  if (youtubeVideoId) {
    return `https://www.youtube.com/watch?v=${youtubeVideoId}`;
  }
  return weblink;
};

const getWeblinkPreview = async (vars: IGetWeblinkPreviewArgs) => {
  const { weblink } = vars;
  const functions = getFunctions();
  const getWeblinkPreviewCallable = httpsCallable(
    functions,
    "getWeblinkPreview"
  );

  try {
    const digestibleWeblink = convertYoutubeLinkIntoDigestibleLink(weblink);
    const result = await getWeblinkPreviewCallable({
      weblink: digestibleWeblink,
    });
    const previewData = result.data as WeblinkPreview;
    if (previewData.images[0]) {
      previewData.previewImageUrl = previewData.images[0];
    } else if (previewData.favicons[0]) {
      previewData.previewImageUrl = previewData.favicons[0];
    }
    return previewData;
  } catch (error) {
    return Promise.resolve(emptyWeblinkPreview(weblink));
  }
};

export const useGetWeblinkPreviewQuery = (
  vars: IGetWeblinkPreviewArgs,
  enabled: boolean = true
) => {
  return useQuery(
    getWeblinkPreviewKey(vars.weblink),
    () => getWeblinkPreview(vars),
    {
      onSettled: settledLogger("useGetWeblinkPreviewQuery"),
      enabled,
    }
  );
};

export const useGetWeblinkPreviewFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetWeblinkPreviewArgs) => {
    return queryClient.fetchQuery(getWeblinkPreviewKey(vars.weblink), () =>
      getWeblinkPreview(vars)
    );
  };
};
