import { Button } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import React from "react";
import { themeColors } from "../../styling/styleConstants";
import { ButtonSize } from "antd/lib/button";

interface ICourseFavoriteButtonProps {
  onFavoriteClick: () => void;
  isCourseFavorited: boolean;
  size?: ButtonSize;
}

export const CourseFavoriteButton: React.FunctionComponent<
  ICourseFavoriteButtonProps
> = (props) => {
  const { onFavoriteClick, isCourseFavorited, size } = props;

  return (
    <Button onClick={onFavoriteClick} size={size || "middle"}>
      {isCourseFavorited ? (
        <HeartFilled style={{ color: themeColors.courseFavoriteHeartPink }} />
      ) : (
        <HeartOutlined />
      )}
    </Button>
  );
};
