import { Menu, Space, Typography } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { HeaderBarPath } from "../../types/helperTypes";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { convertHeaderBarPathToIconAndString } from "../../utils/iconAndStringUtils";
import { ClickableWrapper } from "../common/ClickableWrapper";
import { HeaderSettingsContainer } from "./HeaderSettingsContainer";
import BrandLogoPng from "../../assets/brand-logo/whiteText-darkBackground/logo512.png";
import { DiscordButton } from "./DiscordButton";
import { useUserAuth } from "../../ui-hooks/useUserAuth";

interface IHeaderNavBarProps {}

const commonMenuItemTextStyle = {
  color: "white",
};

export const HeaderNavBar: React.FunctionComponent<IHeaderNavBarProps> = (
  props
) => {
  const nav = useNavigation();
  const { user, isSignedIn } = useUserAuth();
  const location = useLocation();
  const isScreenLargerThanMd = useIsScreenLargerThanMd();

  const handleTabClick = (e: any) => {
    switch (e.key) {
      case nav.paths.home:
        nav.goToPaths.home();
        break;
      case nav.paths.explore:
        nav.goToPaths.explore();
        break;
      case nav.paths.notifications:
        nav.goToPaths.notifications();
        break;
      case nav.paths.reviewQueue:
        nav.goToPaths.reviewQueue();
        break;
      case nav.paths.newCourse:
        nav.goToPaths.newCourse();
        break;
      case HeaderBarPath.MyCourses:
        if (user) {
          nav.goToPaths.userProfile(user.uid, { myCourses: "true" });
        }
        break;
      case HeaderBarPath.MyFavorites:
        if (user) {
          nav.goToPaths.userProfile(user.uid, { myFavorites: "true" });
        }
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <ClickableWrapper
          onClick={() => nav.goToPaths.home()}
          style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
        >
          <img
            src={BrandLogoPng}
            alt={"Library of Alexandria"}
            style={{ height: "3.5rem", width: "auto" }}
          />
        </ClickableWrapper>
        {isScreenLargerThanMd && (
          <div style={{ width: "100%" }}>
            <Menu
              selectedKeys={[location.pathname]}
              mode={"horizontal"}
              theme={"dark"}
              onClick={handleTabClick}
              style={{ width: "100%" }}
            >
              <Menu.Item key={nav.paths.explore}>
                <Typography style={commonMenuItemTextStyle}>
                  {
                    convertHeaderBarPathToIconAndString(HeaderBarPath.Explore)
                      .string
                  }
                </Typography>
              </Menu.Item>
              <Menu.Item key={nav.paths.newCourse}>
                <Typography style={commonMenuItemTextStyle}>
                  {
                    convertHeaderBarPathToIconAndString(HeaderBarPath.NewCourse)
                      .string
                  }
                </Typography>
              </Menu.Item>
              {isSignedIn && (
                <Menu.Item key={HeaderBarPath.MyCourses}>
                  <Typography style={commonMenuItemTextStyle}>
                    {
                      convertHeaderBarPathToIconAndString(
                        HeaderBarPath.MyCourses
                      ).string
                    }
                  </Typography>
                </Menu.Item>
              )}
              {isSignedIn && (
                <Menu.Item key={HeaderBarPath.MyFavorites}>
                  <Typography style={commonMenuItemTextStyle}>
                    {
                      convertHeaderBarPathToIconAndString(
                        HeaderBarPath.MyFavorites
                      ).string
                    }
                  </Typography>
                </Menu.Item>
              )}
            </Menu>
          </div>
        )}
      </div>
      <Space direction={"horizontal"}>
        <div style={{ marginRight: "1rem" }}>
          <DiscordButton />
        </div>
        <HeaderSettingsContainer />
      </Space>
    </div>
  );
};
