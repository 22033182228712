import React from "react";
import { useParams } from "react-router-dom";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { getLessonFromCourse } from "../../utils/utils";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { PageNotFound } from "../nav/PageNotFound";
import { InLesson } from "./InLesson";

interface IInLessonContainerProps {}

type IInLessonContainerParams = {
  courseId: string;
  lessonId: string;
};

export const InLessonContainer: React.FunctionComponent<
  IInLessonContainerProps
> = (props) => {
  const { courseId, lessonId } = useParams<IInLessonContainerParams>();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });

  if (isCourseError) {
    return <GenericError />;
  }

  if (isCourseLoading || !course) {
    return <GenericLoader />;
  }

  const currentLesson = getLessonFromCourse(lessonId || "", course);

  if (!currentLesson) {
    return <PageNotFound />;
  }

  return <InLesson course={course} lesson={currentLesson} />;
};
