import React from "react";
import { useParams } from "react-router-dom";
import { useListCoursesQuery } from "../../../firestore/queries/useListCoursesQuery";
import { CourseCategory } from "../../../types/helperTypes";
import { GenericLoader } from "../../common/GenericLoader";
import { ExploreCourseCategory } from "./ExploreCourseCategory";

interface IExploreCourseCategoryContainerProps {}

type IExploreCourseCategoryContainerParams = {
  category: CourseCategory;
};

export const ExploreCourseCategoryContainer: React.FunctionComponent<
  IExploreCourseCategoryContainerProps
> = (props) => {
  const { category } = useParams<IExploreCourseCategoryContainerParams>();
  const { data: coursesInCategory, isLoading: isCoursesInCategoryLoading } =
    useListCoursesQuery({ category, numItemsPerPage: 1 });

  if (isCoursesInCategoryLoading || !coursesInCategory) {
    return <GenericLoader />;
  }

  const isCategoryEmpty = coursesInCategory.length === 0;

  return (
    <ExploreCourseCategory
      category={category || CourseCategory.Unknown}
      isCategoryEmpty={isCategoryEmpty}
    />
  );
};
