import React from "react";
import { locStrings } from "../../../localization/locStrings";
import { MetadataType } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { LessonQuestionsContainer } from "../../questions-answers/LessonQuestionsContainer";
import { InLessonDetailsSection } from "./InLessonDetailsSection";

interface IInLessonDetailsSectionQuestionsContainerProps {
  courseId: string;
  lessonId: string;
}

export const InLessonDetailsSectionQuestionsContainer: React.FunctionComponent<
  IInLessonDetailsSectionQuestionsContainerProps
> = (props) => {
  const { courseId, lessonId } = props;
  const nav = useNavigation();
  const parsedLessonQuestionId = nav.urlSearchParams.getLessonQuestionId();

  return (
    <InLessonDetailsSection
      title={locStrings.questions.value()}
      metadataType={MetadataType.LessonQuestion}
    >
      <LessonQuestionsContainer
        params={{
          courseId,
          lessonId,
          sortByUpvotes: true,
          topItemId: parsedLessonQuestionId,
        }}
        shouldSkipFetch={false}
      />
    </InLessonDetailsSection>
  );
};
