import { Button, Space, Tooltip } from "antd";
import React, { useId, useMemo, useState } from "react";

interface LessonItemBottomTab {
  id: string;
  tabTitle: {
    title: string;
    icon: JSX.Element;
    tooltip: string;
  };
  tabFunctionality: {
    // Used for displaying a section
    content?: React.ReactNode;
    isDefaultCurrentTab?: boolean;
    // Used for pure onClick functionality
    onClick?: () => void;
  };
}

export interface ICourseStructureLessonItemBottomTabsProps {
  tabs: LessonItemBottomTab[];
}

export const CourseStructureLessonItemBottomTabs: React.FunctionComponent<
  ICourseStructureLessonItemBottomTabsProps
> = (props) => {
  const { tabs } = props;
  const uniqueId = useId();
  const defaultCurrentTab = useMemo(() => {
    let defaultKey = "";
    tabs.forEach((tab) => {
      if (tab.tabFunctionality.isDefaultCurrentTab) {
        defaultKey = tab.id;
      }
    });
    return defaultKey;
  }, [tabs]);
  const [currentTabKey, setCurrentTabKey] = useState<string | undefined>(
    defaultCurrentTab
  );

  const currentContentToRender = useMemo(() => {
    let matchedTabContent: React.ReactNode;
    tabs.forEach((tab) => {
      if (tab.id === currentTabKey && !!tab.tabFunctionality.content) {
        matchedTabContent = tab.tabFunctionality.content;
      }
    });
    return matchedTabContent;
  }, [currentTabKey, tabs]);

  const tabsToRender = tabs.map((tab) => {
    // If onClick exists, that takes precedence
    // Else if content exists, display that content
    // Else no-op
    const onTabClick = !!tab.tabFunctionality.onClick
      ? tab.tabFunctionality.onClick
      : !!tab.tabFunctionality.content
      ? () => setCurrentTabKey(tab.id)
      : () => {};

    return (
      <Tooltip
        key={`CourseStructureLessonItemBottomTabs-Tab-${tab.id}-${uniqueId}`}
        title={tab.tabTitle.tooltip}
      >
        <Button onClick={onTabClick} icon={tab.tabTitle.icon} type={"default"}>
          {tab.tabTitle.title}
        </Button>
      </Tooltip>
    );
  });

  return (
    <>
      <Space direction={"horizontal"}>{tabsToRender}</Space>
      {!!currentContentToRender && (
        <div style={{ marginTop: "1rem" }}>{currentContentToRender}</div>
      )}
    </>
  );
};
