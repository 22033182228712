import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertCourseSnapToICourse,
  ICourse,
} from "../../types/firestoreMappings";
import { CourseCategory, CoursePublishState } from "../../types/helperTypes";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export interface ListCoursesQueryParams {
  owner?: string;
  publishStates?: CoursePublishState[];
  category?: CourseCategory;
  sortByUpdatedAt?: boolean;
  numItemsPerPage?: number;
}

const defaultNumItemsPerPage = 6;

export const listCoursesQueryKeySimple = "listCoursesQueryKey";

export const listCoursesQueryKey = (params: ListCoursesQueryParams) => {
  return [listCoursesQueryKeySimple, params];
};

export const useListCoursesQuery = (
  params: ListCoursesQueryParams,
  enabled: boolean = true
) => {
  const listCourses = async (vars: {
    pageParam?: {
      lastPage: {
        realData: ICourse[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const { owner, publishStates, category, sortByUpdatedAt, numItemsPerPage } =
      params;
    const numItemsPerPageToUse = numItemsPerPage || defaultNumItemsPerPage;
    let isLastPage = false;
    const db = getFirestore();
    const coursesCollectionRef = collection(db, collectionPaths.courses);
    const queryConstraints = [];
    if (sortByUpdatedAt) {
      queryConstraints.push(orderBy("updatedAt", "desc"));
    } else {
      // Sort by createdAt by default
      queryConstraints.push(orderBy("createdAt", "desc"));
    }
    if (owner) {
      queryConstraints.push(where("owners", "array-contains", owner));
    }
    if (publishStates) {
      queryConstraints.push(where("publishState", "in", publishStates));
    }
    if (category) {
      queryConstraints.push(where("categories", "array-contains", category));
    }

    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      coursesCollectionRef,
      ...queryConstraints,
      limit(numItemsPerPageToUse)
    );
    const qSnapshot = await getDocs(q);
    const courses = qSnapshot.docs.map((snapshot) =>
      convertCourseSnapToICourse(snapshot.id, snapshot.data())
    );

    if (courses.length < numItemsPerPageToUse) {
      isLastPage = true;
    }
    return {
      realData: courses,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listCoursesQueryKey(params),
    listCourses,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListCoursesQuery"),
      enabled,
    }
  );

  let realDataAllPages: ICourse[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }
  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
