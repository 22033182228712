import { Button, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import { IUser } from "../../types/firestoreMappings";
import {
  UsernameUniqueCheckState,
  UsernameUniqueInput,
} from "../common/UsernameUniqueInput";

interface ISettingsUsernameEditProps {
  meUser: IUser;
  username: string;
  setUsername: (newUsername: string) => void;
  usernameUniqueCheckState: UsernameUniqueCheckState;
  setUsernameUniqueCheckState: (newState: UsernameUniqueCheckState) => void;
  onSubmit: () => void;
}

export const SettingsUsernameEdit: React.FunctionComponent<
  ISettingsUsernameEditProps
> = (props) => {
  const {
    meUser,
    username,
    setUsername,
    usernameUniqueCheckState,
    setUsernameUniqueCheckState,
    onSubmit,
  } = props;

  return (
    <Space direction={"horizontal"} style={{ alignItems: "flex-start" }}>
      <UsernameUniqueInput
        value={username}
        onValueChange={setUsername}
        onCheckStateChange={setUsernameUniqueCheckState}
        hideCheckStateStatus={meUser.username === username}
      />
      <Button
        onClick={onSubmit}
        disabled={
          !username ||
          usernameUniqueCheckState !== UsernameUniqueCheckState.Available
        }
      >
        <CheckOutlined />
      </Button>
    </Space>
  );
};
