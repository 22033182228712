import { Button, Input, Space } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { isEmptyHtmlString } from "../../utils/utils";
import { InputMimicry } from "../common/InputMimicry";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { RichTextEditor } from "../common/RichTextEditor";
import { WeblinkPreviewImage } from "../common/WeblinkPreviewImage";

interface INewLinkDetailsEditProps {
  title: string;
  setTitle: (newTitle: string) => void;
  description: string;
  setDescription: (newDescription: string) => void;
  onNext: () => void;
  previewImageUrl?: string;
}

export const NewLinkDetailsEdit: React.FunctionComponent<
  INewLinkDetailsEditProps
> = (props) => {
  const {
    title,
    setTitle,
    description,
    setDescription,
    onNext,
    previewImageUrl,
  } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={locStrings.lessonTitle.value()}
          level={PageTypographyLevel.PageSection}
        />
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={locStrings.lessonTitlePlacerholder.value()}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={locStrings.lessonDescription.value()}
          level={PageTypographyLevel.PageSection}
        />
        <RichTextEditor
          initialValue={description}
          onHtmlStringChange={setDescription}
          placeholder={locStrings.lessonDescriptionPlacerholder.value()}
        />
      </div>
      {previewImageUrl && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PageTypography
            title={"Thumbnail"}
            level={PageTypographyLevel.PageSection}
          />
          <InputMimicry>
            <WeblinkPreviewImage imageUrl={previewImageUrl} />
          </InputMimicry>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={onNext}
          type={"primary"}
          disabled={!title || isEmptyHtmlString(description)}
        >
          {"Next"}
        </Button>
      </div>
    </Space>
  );
};
