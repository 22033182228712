import { Input } from "antd";
import React, { useId } from "react";
import { locStrings } from "../../localization/locStrings";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { isValidUrl } from "../../utils/utils";
import { themeColors } from "../../styling/styleConstants";

interface IWeblinkInputProps {
  weblink: string;
  onChange: (weblink: string) => void;
  style?: React.CSSProperties;
}

export const WeblinkInput: React.FunctionComponent<IWeblinkInputProps> = (
  props
) => {
  const { weblink, onChange, style } = props;
  const uniqueId = useId();

  return (
    <Input
      id={`WeblinkInput-${uniqueId}`}
      type={"text"}
      value={weblink}
      onChange={(e) => onChange(e.target.value)}
      placeholder={locStrings.lessonWeblinkPlacerholder.value()}
      style={style}
      addonAfter={
        isValidUrl(weblink) ? (
          <CheckCircleFilled style={{ color: themeColors.successGreen }} />
        ) : (
          <CheckCircleOutlined />
        )
      }
    />
  );
};
