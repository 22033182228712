import { Card, Divider, Space, Typography } from "antd";
import {
  DollarTwoTone,
  EyeTwoTone,
  SecurityScanTwoTone,
} from "@ant-design/icons";
import React from "react";
import { themeColors } from "../../styling/styleConstants";
import { GenericAntTwoToneIcon } from "../../types/helperTypes";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { TypographyBold } from "../common/TypographyBold";

interface IPrinciplesProps {}

export const Principles: React.FunctionComponent<IPrinciplesProps> = (
  props
) => {
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  return (
    <Card size={isScreenLargerThanMd ? "default" : "small"}>
      <PrinciplesBulletPoint
        Icon={DollarTwoTone as GenericAntTwoToneIcon}
        title={"Free"}
        boldedPrefix={"All content on LoA is free."}
        paragraph={
          "This means links in a course will be free and immediately accessible to anyone. No link should be behind a paywall or require sign-up to access."
        }
      />
      <Divider />
      <PrinciplesBulletPoint
        Icon={EyeTwoTone as GenericAntTwoToneIcon}
        title={"Open"}
        boldedPrefix={"All content on LoA is open."}
        paragraph={
          "This means anyone can view and contribute to a course. We believe that by crowdsourcing courses using the limitless resources available on the internet, we can all come together to create the best online learning experience found anywhere. Everything you contribute will be public and for the benefit of all."
        }
      />
      <Divider />
      <PrinciplesBulletPoint
        Icon={SecurityScanTwoTone as GenericAntTwoToneIcon}
        title={"Quality"}
        boldedPrefix={"All content on LoA is quality."}
        paragraph={
          "This means that courses are well thought out and rigorously reviewed. Your expertise, sincerity and best efforts while constructing a course, as well as others who review what you contribute, ensure this quality."
        }
      />
    </Card>
  );
};

const PrinciplesBulletPoint: React.FunctionComponent<{
  Icon: GenericAntTwoToneIcon;
  title: string;
  boldedPrefix: string;
  paragraph: string;
}> = (props) => {
  const { Icon, title, boldedPrefix, paragraph } = props;
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  return (
    <Space
      direction={isScreenLargerThanMd ? "horizontal" : "vertical"}
      style={{
        alignItems: "center",
      }}
    >
      <Space
        direction={"horizontal"}
        style={{
          width: isScreenLargerThanMd ? "10rem" : undefined,
          marginBottom: !isScreenLargerThanMd ? "0.5rem" : undefined,
        }}
      >
        <Icon
          twoToneColor={themeColors.primaryLight}
          style={{ fontSize: "3rem" }}
        />
        <Typography.Title
          level={4}
          style={{ color: themeColors.primaryLight, margin: 0 }}
        >
          {title}
        </Typography.Title>
      </Space>
      <TypographyBold boldedPrefix={boldedPrefix} children={paragraph} />
    </Space>
  );
};
