import { DocumentData, Timestamp } from "firebase/firestore";
import {
  CourseCategory,
  CoursePublishState,
  CourseSuggestionState,
  CourseSuggestionType,
  MetadataType,
  NewCourseCategoryRequestState,
  NotificationType,
} from "./helperTypes";

interface InternalCourse {
  id: string;
  title: string;
  description: string;
  publishState: CoursePublishState;
  categories: CourseCategory[];
  owners: string[]; // uids
  modules: IModule[];
  createdAt: Date;
  updatedAt: Date;
}

interface InternalModule {
  id: string;
  title: string;
  lessons: ILesson[];
  description?: string;
}

interface InternalLesson {
  id: string;
  title: string;
  weblink: string;
  description: string; // Optional in practice
  previewImageUrl?: string;
  createdFromSuggestionId?: string;
}

interface InternalUser {
  id: string;
  username: string;
  avatarUrl?: string;
  enableNewNotificationEmails?: boolean;
}

interface InternalLessonQuestion {
  id: string;
  question: string;
  courseId: string;
  lessonId: string;
  upvotes: number;
  owner: string; // uid
  createdAt: Date;
}

interface InternalLessonAnswer {
  id: string;
  answer: string;
  lessonQuestionId: string;
  courseId: string;
  lessonId: string;
  upvotes: number;
  owner: string; // uid
  createdAt: Date;
}

interface InternalLessonExercise {
  id: string;
  exercise: string;
  courseId: string;
  lessonId: string;
  upvotes: number;
  owner: string;
  createdAt: Date;
}

interface InternalLessonAlternativeLink {
  id: string;
  weblink: string;
  previewImageUrl?: string;
  reason: string;
  upvotes: number;
  courseId: string;
  lessonId: string;
  owner: string;
  createdAt: Date;
  createdFromSuggestionId?: string;
}

interface InternalCourseSuggestion {
  id: string;
  typeGuard: "ICourseSuggestion"; // Used to distinguish from ILesson when an array has both
  type: CourseSuggestionType;
  courseId: string;
  state: CourseSuggestionState;
  reason: string;
  upvotes: number;
  owner: string;
  createdAt: Date;
  lessonTitle?: string; // required for CourseSuggestionType.NewLesson
  lessonWeblink?: string; // required for CourseSuggestionType.NewLesson
  lessonPreviewImageUrl?: string; // optional for CourseSuggestionType.NewLesson
  prevLessonId?: string; // optional for CourseSuggestionType.NewLesson
  nextLessonId?: string; // optional for CourseSuggestionType.NewLesson
  moduleId?: string; // required for CourseSuggestionType.NewLesson
  moduleTitle?: string; // required for CourseSuggestionType.NewModule
}

interface InternalNotification {
  id: string;
  type: NotificationType;
  isRead: boolean;
  userId: string;
  createdAt: Date;
  lessonAlternativeLinkId?: string; // required for NotificationType.LessonAlternativeLink
  lessonExerciseId?: string; // required for NotificationType.LessonExercise
  lessonQuestionId?: string; // required for NotificationType.LessonQuestion
  lessonAnswerId?: string; // required for NotificationType.LessonAnswer
  courseReviewRejectionId?: string; // required for NotificationType.CourseReviewRejection
  courseSuggestionId?: string; // required for NotificationType.CourseSuggestion
  courseSuggestionType?: CourseSuggestionType; // required for NotificationType.CourseSuggestion
  courseCommentId?: string; // required for NotificationType.CourseComment & CourseCommentReply
}

interface InternalCourseComment {
  id: string;
  comment: string;
  courseId: string;
  metadataType: MetadataType;
  metadataId: string;
  upvotes: number;
  owner: string;
  createdAt: Date;
  replyToId?: string;
}

interface InternalReviewer {
  id: string;
  isReviewer: boolean;
}

interface InternalCourseReviewRejection {
  id: string;
  rejectionReason: string;
  courseId: string;
  owner: string;
  createdAt: Date;
}

interface InternalUpvote {
  id: string;
  metadataId: string;
  metadataType: MetadataType;
  owner: string;
  isDownvote?: boolean;
  doesNotExist?: boolean;
}

interface InternalCourseFavorite {
  id: string;
  courseId: string;
  owner: string;
  createdAt: Date;
  doesNotExist?: boolean;
}

interface InternalNewCourseCategoryRequest {
  id: string;
  category: string;
  state: NewCourseCategoryRequestState;
  owner: string;
  createdAt: Date;
}

export type ICourse = Readonly<InternalCourse>;
export type IModule = Readonly<InternalModule>;
export type ILesson = Readonly<InternalLesson>;
export type IUser = Readonly<InternalUser>;
export type ILessonQuestion = Readonly<InternalLessonQuestion>;
export type ILessonAnswer = Readonly<InternalLessonAnswer>;
export type ILessonExercise = Readonly<InternalLessonExercise>;
export type ILessonAlternativeLink = Readonly<InternalLessonAlternativeLink>;
export type ICourseSuggestion = Readonly<InternalCourseSuggestion>;
export type INotification = Readonly<InternalNotification>;
export type ICourseComment = Readonly<InternalCourseComment>;
export type IReviewer = Readonly<InternalReviewer>;
export type ICourseReviewRejection = Readonly<InternalCourseReviewRejection>;
export type IUpvote = Readonly<InternalUpvote>;
export type ICourseFavorite = Readonly<InternalCourseFavorite>;
export type INewCourseCategoryRequest =
  Readonly<InternalNewCourseCategoryRequest>;

export const convertCourseSnapToICourse = (
  id: string,
  docData: DocumentData
): ICourse => {
  const {
    title,
    description,
    owners,
    modules,
    createdAt,
    updatedAt,
    publishState,
    categories,
  } = docData;

  return {
    id,
    title,
    description,
    publishState: publishState,
    categories,
    owners,
    modules,
    createdAt: (createdAt as Timestamp).toDate(),
    updatedAt: (updatedAt as Timestamp).toDate(),
  };
};

export const convertUserSnapToIUser = (
  id: string,
  docData: DocumentData
): IUser => {
  const { username, avatarUrl, enableNewNotificationEmails } = docData;

  return {
    id,
    username,
    avatarUrl,
    enableNewNotificationEmails,
  };
};

export const convertLessonQuestionSnapToILessonQuestion = (
  id: string,
  docData: DocumentData
): ILessonQuestion => {
  const { question, courseId, lessonId, upvotes, owner, createdAt } = docData;

  return {
    id,
    question,
    courseId,
    lessonId,
    upvotes,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertLessonAnswerSnapToILessonAnswer = (
  id: string,
  docData: DocumentData
): ILessonAnswer => {
  const {
    answer,
    lessonQuestionId,
    courseId,
    lessonId,
    upvotes,
    owner,
    createdAt,
  } = docData;

  return {
    id,
    answer,
    lessonQuestionId,
    courseId,
    lessonId,
    upvotes,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertLessonExerciseSnapToILessonExercise = (
  id: string,
  docData: DocumentData
): ILessonExercise => {
  const { exercise, courseId, lessonId, upvotes, owner, createdAt } = docData;

  return {
    id,
    exercise,
    courseId,
    lessonId,
    upvotes,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertLessonAlternativeLinkSnapToILessonAlternativeLink = (
  id: string,
  docData: DocumentData
): ILessonAlternativeLink => {
  const {
    weblink,
    reason,
    previewImageUrl,
    upvotes,
    courseId,
    lessonId,
    owner,
    createdAt,
    createdFromSuggestionId,
  } = docData;

  return {
    id,
    weblink,
    reason,
    previewImageUrl,
    upvotes,
    courseId,
    lessonId,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
    createdFromSuggestionId,
  };
};

export const convertCourseSuggestionSnapToICourseSuggestion = (
  id: string,
  docData: DocumentData
): ICourseSuggestion => {
  const {
    type,
    courseId,
    state,
    reason,
    upvotes,
    owner,
    createdAt,
    lessonTitle,
    lessonWeblink,
    lessonPreviewImageUrl,
    prevLessonId,
    nextLessonId,
    moduleId,
    moduleTitle,
  } = docData;

  return {
    id,
    typeGuard: "ICourseSuggestion",
    type,
    courseId,
    state,
    reason,
    upvotes,
    owner,
    lessonTitle,
    lessonWeblink,
    lessonPreviewImageUrl,
    prevLessonId,
    nextLessonId,
    moduleId,
    moduleTitle,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertNotificationSnapToINotification = (
  id: string,
  docData: DocumentData
): INotification => {
  const {
    type,
    isRead,
    userId,
    createdAt,
    lessonAlternativeLinkId,
    lessonExerciseId,
    lessonQuestionId,
    lessonAnswerId,
    courseReviewRejectionId,
    courseSuggestionId,
    courseSuggestionType,
    courseCommentId,
  } = docData;

  return {
    id,
    type,
    isRead,
    userId,
    createdAt: (createdAt as Timestamp).toDate(),
    lessonAlternativeLinkId,
    lessonExerciseId,
    lessonQuestionId,
    lessonAnswerId,
    courseReviewRejectionId,
    courseSuggestionId,
    courseSuggestionType,
    courseCommentId,
  };
};

export const convertCourseCommentSnapToICourseComment = (
  id: string,
  docData: DocumentData
): ICourseComment => {
  const {
    comment,
    courseId,
    metadataType,
    metadataId,
    upvotes,
    owner,
    createdAt,
    replyToId,
  } = docData;

  return {
    id,
    comment,
    courseId,
    metadataType,
    metadataId,
    upvotes,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
    replyToId,
  };
};

export const convertReviewerSnapToIReviewer = (
  id: string,
  docData: DocumentData
): IReviewer => {
  const { isReviewer } = docData;

  return {
    id,
    isReviewer: !!isReviewer,
  };
};

export const convertCourseReviewRejectionSnapToICourseReviewRejection = (
  id: string,
  docData: DocumentData
): ICourseReviewRejection => {
  const { rejectionReason, courseId, owner, createdAt } = docData;

  return {
    id,
    rejectionReason,
    courseId,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertUpvoteSnapToIUpvote = (
  id: string,
  docData: DocumentData
): IUpvote => {
  const { metadataId, metadataType, owner, isDownvote } = docData;

  return {
    id,
    metadataId,
    metadataType,
    owner,
    isDownvote,
  };
};

export const convertCourseFavoriteSnapToICourseFavorite = (
  id: string,
  docData: DocumentData
): ICourseFavorite => {
  const { courseId, owner, createdAt } = docData;

  return {
    id,
    courseId,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};

export const convertNewCourseCategoryRequestSnapToINewCourseCategoryRequest = (
  id: string,
  docData: DocumentData
): INewCourseCategoryRequest => {
  const { category, state, owner, createdAt } = docData;

  return {
    id,
    category,
    state,
    owner,
    createdAt: (createdAt as Timestamp).toDate(),
  };
};
