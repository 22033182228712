import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetLessonAnswerQuery } from "../../firestore/queries/useGetLessonAnswerQuery";
import { INotification } from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { getLessonFromCourse } from "../../utils/utils";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemLessonAnswerContainerProps {
  notification: INotification;
}

export const NotificationItemLessonAnswerContainer: React.FunctionComponent<
  INotificationItemLessonAnswerContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const {
    data: lessonAnswer,
    isLoading: isLessonAnswerLoading,
    isError: isLessonAnswerError,
  } = useGetLessonAnswerQuery({
    lessonAnswerId: notification.lessonAnswerId || "",
  });
  const courseIdToQuery = lessonAnswer?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isLessonAnswerError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.LessonAnswer}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (isLessonAnswerLoading || isCourseLoading || !lessonAnswer || !course) {
    return <GenericLoader />;
  }

  const title = `Answer`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  const lessonWithAnswer = getLessonFromCourse(lessonAnswer.lessonId, course);
  infoPoints.push({
    boldedPrefix: "For this Link:",
    text: lessonWithAnswer?.title || "Unknown",
  });
  infoPoints.push({
    boldedPrefix: "Answer:",
    text: lessonAnswer.answer,
  });
  const onNavToContent = () => {
    nav.goToPaths.inLesson(lessonAnswer.courseId, lessonAnswer.lessonId, {
      lessonAnswerId: lessonAnswer.id,
    });
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };

  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={lessonAnswer.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
