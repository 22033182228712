import { notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useCreateLessonQuestionMutation } from "../../firestore/mutations/useCreateLessonQuestionMutation";
import { locStrings } from "../../localization/locStrings";
import { zIndexPriorities } from "../../styling/styleConstants";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { isValidLessonQuestionInputWithErrorNotify } from "../../utils/validInputUtils";
import { GenericLoader } from "../common/GenericLoader";
import { ModalWithTitle } from "../common/ModalWithTitle";
import { LessonQuestionEdit } from "./LessonQuestionEdit";

interface ILessonQuestionCreateModalContainerProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
  defaultLessonId?: string;
}

export const LessonQuestionCreateModalContainer: React.FunctionComponent<
  ILessonQuestionCreateModalContainerProps
> = (props) => {
  const { visible, onClose, courseId, defaultLessonId } = props;
  const defaultLessonIdRef = useRef(defaultLessonId);
  const [lessonId, setLessonId] = useState<string>(defaultLessonId || "");
  const [question, setQuestion] = useState<string>("");
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: createQuestion, isLoading: isCreateQuestionLoading } =
    useCreateLessonQuestionMutation(courseId, lessonId);

  useEffect(() => {
    if (defaultLessonIdRef.current !== defaultLessonId) {
      defaultLessonIdRef.current = defaultLessonId;
      setLessonId(defaultLessonId || "");
    }
  }, [defaultLessonId]);

  const onCreateQuestion = async () => {
    const isValid = isValidLessonQuestionInputWithErrorNotify().forCreate({
      question,
    });

    if (isValid) {
      try {
        await createQuestion(question);
        notification.success({ message: "Question created" });
        setQuestion("");
        onClose();
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
  };

  if (isCreateQuestionLoading) {
    return <GenericLoader />;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onClose}
      title={"New Question"}
      subTitle={"Ask something about this link"}
      zIndex={zIndexPriorities.lessonQuestionCreateModal}
    >
      <LessonQuestionEdit
        courseId={courseId}
        onSubmit={forceSisuModalFxnWrapper(onCreateQuestion)}
        lessonId={lessonId}
        question={question}
        onLessonIdChange={setLessonId}
        onQuestionChange={setQuestion}
      />
    </ModalWithTitle>
  );
};
