import React from "react";
import { useCreateLessonAnswerMutation } from "../../firestore/mutations/useCreateLessonAnswerMutation";
import { useListLessonAnswersQuery } from "../../firestore/queries/useListLessonAnswersQuery";
import { ILessonQuestion } from "../../types/firestoreMappings";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { LessonAnswers } from "./LessonAnswers";

interface ILessonAnswersContainerProps {
  question: ILessonQuestion;
}

export const LessonAnswersContainer: React.FunctionComponent<
  ILessonAnswersContainerProps
> = (props) => {
  const { question } = props;
  const nav = useNavigation();
  const parsedLessonAnswerId = nav.urlSearchParams.getLessonAnswerId();
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const {
    data: answers,
    fetchNextPage: fetchMoreAnswers,
    isNoMoreToFetch: isNoMoreAnswersToFetch,
    isLoading: isAnswersLoading,
    isError: isAnswersError,
  } = useListLessonAnswersQuery({
    lessonQuestionId: question.id,
    lessonId: question.lessonId,
    courseId: question.courseId,
    sortByUpvotes: true,
    topItemId: parsedLessonAnswerId,
  });
  const { mutate: createAnswer, isLoading: isCreateAnswerLoading } =
    useCreateLessonAnswerMutation(
      question.id,
      question.courseId,
      question.lessonId
    );

  if (isAnswersError) {
    return <GenericError />;
  }

  if (isAnswersLoading || !answers || isCreateAnswerLoading) {
    return <GenericLoader />;
  }

  const createAnswerForceSisu = forceSisuModalFxnWrapper(createAnswer);

  return (
    <LessonAnswers
      answers={answers}
      createAnswer={createAnswerForceSisu}
      isNoMoreAnswersToFetch={isNoMoreAnswersToFetch}
      fetchMoreAnswers={fetchMoreAnswers}
    />
  );
};
