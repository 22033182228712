import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import {
  convertReviewerSnapToIReviewer,
  IReviewer,
} from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getReviewerQueryKey = (userId: string) => [
  "getReviewerQueryKey",
  userId,
];

interface IGetReviewerArgs {
  userId: string;
}

const getReviewer = async (vars: IGetReviewerArgs): Promise<IReviewer> => {
  const { userId } = vars;
  const db = getFirestore();
  const reviewerDocRef = doc(db, collectionPaths.reviewers, userId);
  const snapshot = await getDoc(reviewerDocRef);
  if (snapshot.exists()) {
    return convertReviewerSnapToIReviewer(userId, snapshot.data());
  } else {
    return Promise.resolve({ id: userId, isReviewer: false });
  }
};

export const useGetReviewerQuery = (
  vars: IGetReviewerArgs,
  enabled: boolean = true
) => {
  const { userId } = vars;
  return useQuery(getReviewerQueryKey(userId), () => getReviewer(vars), {
    onSettled: settledLogger("useGetReviewerQuery"),
    enabled,
  });
};

export const useGetReviewerFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetReviewerArgs) => {
    const { userId } = vars;
    return queryClient.fetchQuery(getReviewerQueryKey(userId), () =>
      getReviewer(vars)
    );
  };
};
