import { Button, Space } from "antd";
import React, { useState } from "react";
import { ILessonExercise } from "../../types/firestoreMappings";
import { ConfirmButton } from "../common/ConfirmButton";
import { RichTextRenderer } from "../common/RichTextRenderer";
import { DeleteOutlined, EditFilled, EditOutlined } from "@ant-design/icons";
import { RichTextEditor } from "../common/RichTextEditor";
import { locStrings } from "../../localization/locStrings";
import { isValidLessonExerciseInputWithErrorNotify } from "../../utils/validInputUtils";
import { CardWithUserHeader } from "../common/CardWithUserHeader";
import { UpvoteButtonContainer } from "../upvotes/UpvoteButtonContainer";
import { MetadataType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";

interface ILessonExerciseProps {
  exercise: ILessonExercise;
  deleteExercise?: () => void;
  updateExercise?: (updatedExercise: string) => void;
}

export const LessonExercise: React.FunctionComponent<ILessonExerciseProps> = (
  props
) => {
  const { exercise, updateExercise, deleteExercise } = props;
  const nav = useNavigation();
  const parsedLessonExerciseId = nav.urlSearchParams.getLessonExerciseId();
  const wasNavigatedToAndShouldHighlight =
    parsedLessonExerciseId === exercise.id;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updatedExerciseInput, setUpdatedExerciseInput] = useState<string>(
    exercise.exercise
  );

  const onUpdateExercise = () => {
    if (updateExercise) {
      const isValid = isValidLessonExerciseInputWithErrorNotify().forUpdate({
        exercise: updatedExerciseInput,
      });

      if (isValid) {
        updateExercise(updatedExerciseInput);
      }
    } else {
      console.error(
        "LessonExercise.onUpdateExercise, attempted to update without edit permission"
      );
    }
  };

  return (
    <CardWithUserHeader
      userId={exercise.owner}
      courseIdToCheckForOwnership={exercise.courseId}
      customElements={[
        updateExercise && (
          <Button
            icon={isEditMode ? <EditFilled /> : <EditOutlined />}
            type={"text"}
            onClick={() => setIsEditMode(!isEditMode)}
          />
        ),
        deleteExercise && (
          <ConfirmButton
            onConfirm={deleteExercise}
            icon={<DeleteOutlined />}
            type={"text"}
          />
        ),
        <UpvoteButtonContainer
          upvoteCount={exercise.upvotes}
          metadataId={exercise.id}
          metadataType={MetadataType.LessonExercise}
        />,
      ]}
      className={
        wasNavigatedToAndShouldHighlight
          ? "highlight-light-blue-and-fade"
          : undefined
      }
    >
      {isEditMode ? (
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <RichTextEditor
            initialValue={updatedExerciseInput}
            onHtmlStringChange={setUpdatedExerciseInput}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button type={"primary"} onClick={onUpdateExercise}>
              {locStrings.submit.value()}
            </Button>
          </div>
        </Space>
      ) : (
        <RichTextRenderer htmlString={exercise.exercise} />
      )}
    </CardWithUserHeader>
  );
};
