import {
  collection,
  getFirestore,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { ListLessonAlternativeLinksQueryParams } from "./useListLessonAlternativeLinksQuery";

export const countLessonAlternativeLinksQueryKey = (
  args: ICountLessonAlternativeLinksArgs
) => ["countLessonAlternativeLinksQueryKey", args];

interface ICountLessonAlternativeLinksArgs
  extends Omit<
    ListLessonAlternativeLinksQueryParams,
    "sortByUpvotes" | "topItemId" | "numItemsPerPage"
  > {}

const countLessonAlternativeLinks = async (
  vars: ICountLessonAlternativeLinksArgs
) => {
  const { courseId, lessonId } = vars;
  const db = getFirestore();
  const lessonAlternativeLinksCollection = collection(
    db,
    collectionPaths.lessonAlternativeLinks
  );
  const queryConstraints = [];
  if (courseId) {
    queryConstraints.push(where("courseId", "==", courseId));
  }
  if (lessonId) {
    queryConstraints.push(where("lessonId", "==", lessonId));
  }
  const q = query(lessonAlternativeLinksCollection, ...queryConstraints);
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const useCountLessonAlternativeLinksQuery = (
  vars: ICountLessonAlternativeLinksArgs,
  enabled: boolean = true
) => {
  return useQuery(
    countLessonAlternativeLinksQueryKey(vars),
    () => countLessonAlternativeLinks(vars),
    {
      onSettled: settledLogger("useCountLessonAlternativeLinksQuery"),
      enabled,
    }
  );
};
