import React from "react";
import { useUpdateNotificationMutation } from "../../firestore/mutations/useUpdateNotificationMutation";
import { useGetCourseCommentQuery } from "../../firestore/queries/useGetCourseCommentQuery";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { useGetLessonAlternativeLinkFetch } from "../../firestore/queries/useGetLessonAlternativeLinkQuery";
import { INotification } from "../../types/firestoreMappings";
import { MetadataType, NotificationType } from "../../types/helperTypes";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericLoader } from "../common/GenericLoader";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemNotFound } from "./NotificationItemNotFound";

interface INotificationItemCourseCommentContainerProps {
  notification: INotification;
}

export const NotificationItemCourseCommentContainer: React.FunctionComponent<
  INotificationItemCourseCommentContainerProps
> = (props) => {
  const { notification } = props;
  const nav = useNavigation();
  const { user } = useUserAuth();
  const fetchAlternativeLink = useGetLessonAlternativeLinkFetch();
  const {
    data: courseComment,
    isLoading: isCourseCommentLoading,
    isError: isCourseCommentError,
  } = useGetCourseCommentQuery({
    courseCommentId: notification.courseCommentId || "",
  });
  const courseIdToQuery = courseComment?.courseId || "";
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseIdToQuery }, !!courseIdToQuery);
  const { mutate: updateNotification } = useUpdateNotificationMutation(
    notification.id,
    user?.uid || ""
  );
  const onMarkAsRead = async () => {
    if (!notification.isRead) {
      await updateNotification({ isRead: true });
    }
  };

  if (isCourseError || isCourseCommentError) {
    return (
      <NotificationItemNotFound
        type={NotificationType.CourseComment}
        onMarkAsRead={onMarkAsRead}
        isRead={notification.isRead}
      />
    );
  }

  if (isCourseCommentLoading || isCourseLoading || !courseComment || !course) {
    return <GenericLoader />;
  }

  const title =
    notification.type === NotificationType.CourseCommentReply
      ? "New Reply"
      : `New Comment`;
  const infoPoints = [{ boldedPrefix: "In Course:", text: course.title }];
  infoPoints.push({
    boldedPrefix:
      notification.type === NotificationType.CourseCommentReply
        ? "Reply:"
        : "Comment:",
    text: courseComment.comment,
  });
  const onNavToContent = async () => {
    switch (courseComment.metadataType) {
      case MetadataType.Lesson:
        nav.goToPaths.inLesson(
          courseComment.courseId,
          courseComment.metadataId,
          { courseCommentIdForLesson: courseComment.id }
        );
        break;
      case MetadataType.AlternativeLink:
        const alternativeLink = await fetchAlternativeLink({
          lessonAlternativeLinkId: courseComment.metadataId,
        });
        nav.goToPaths.inLesson(
          courseComment.courseId,
          alternativeLink.lessonId,
          {
            courseCommentIdForAlternativeLink: courseComment.id,
            lessonAlternativeLinkId: alternativeLink.id,
          }
        );
        break;
    }
  };
  const onNotificationClick = async () => {
    await onMarkAsRead();
    onNavToContent();
  };
  return (
    <NotificationItem
      notification={notification}
      title={title}
      owner={courseComment.owner}
      infoPoints={infoPoints}
      type={notification.type}
      isRead={notification.isRead}
      onClick={onNotificationClick}
    />
  );
};
