import { notification } from "antd";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGenerateUpvoteId } from "../idHelpers";
import { getUpvoteQueryKey } from "../queries/useGetUpvoteQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteUpvoteMutation = (metadataId: string) => {
  const queryClient = useQueryClient();
  const { user, isSignedIn } = useUserAuth();
  const upvoteId = useGenerateUpvoteId(metadataId);
  const deleteUpvote = () => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject("useDeleteUpvoteMutation, error - no user found");
    }
    const db = getFirestore();
    if (!upvoteId) {
      return Promise.reject(
        "useCreateUpvoteMutation, upvoteId not properly generated"
      );
    }
    const upvoteDocRef = doc(db, collectionPaths.upvotes, upvoteId);
    return deleteDoc(upvoteDocRef);
  };
  return useMutation(deleteUpvote, {
    onSettled: settledLogger("useDeleteUpvoteMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        getUpvoteQueryKey({ metadataId, isSignedIn }),
        {
          refetchInactive: true,
          refetchPage: (page, index) => true,
        }
      );
    },
  });
};
