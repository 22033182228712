import { Space, Typography } from "antd";
import React from "react";
import { LayoutContent } from "../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { PAGE_SECTION_SPACING } from "../../styling/styleConstants";

interface IReviewQueueForbiddenProps {}

export const ReviewQueueForbidden: React.FunctionComponent<
  IReviewQueueForbiddenProps
> = (props) => {
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
          <PageTypography
            title={"Review Queue"}
            level={PageTypographyLevel.PageTitle}
          />
        </div>
        <Typography.Text italic={true}>
          {
            "You are not a reviewer. To become a reviewer, keep contributing to LoA and then reach out to an admin."
          }
        </Typography.Text>
      </Space>
    </LayoutContent>
  );
};
