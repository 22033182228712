import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listLessonQuestionsQueryKey } from "../queries/useListLessonQuestionsQuery";
import { settledLogger } from "../settledLogger";

export const useDeleteLessonQuestionMutation = (
  questionId: string,
  courseId: string,
  lessonId: string
) => {
  const queryClient = useQueryClient();
  const deleteLessonQuestion = () => {
    const db = getFirestore();
    const questionDocRef = doc(db, collectionPaths.lessonQuestions, questionId);
    return deleteDoc(questionDocRef);
  };
  return useMutation(deleteLessonQuestion, {
    onSettled: settledLogger("useDeleteLessonQuestionMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listLessonQuestionsQueryKey({ courseId, lessonId }),
        {
          refetchInactive: true,
          refetchPage: (page, index) => true,
        }
      );
    },
  });
};
