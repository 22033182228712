import { notification } from "antd";
import { User } from "firebase/auth";
import { UsernameUniqueCheckState } from "../components/common/UsernameUniqueInput";
import { CourseCategory, CourseSuggestionType } from "../types/helperTypes";
import {
  courseCommentFieldLimitations,
  courseFieldLimitations,
  courseReviewRejectionFieldLimitations,
  courseSuggestionFieldLimitations,
  lessonAlternativeLinkFieldLimitations,
  lessonAnswerFieldLimitations,
  lessonExerciseFieldLimitations,
  lessonFieldLimitations,
  lessonQuestionFieldLimitations,
  moduleFieldLimitations,
  newCourseCategoryRequestFieldLimitations,
  userFieldLimitations,
} from "./fieldLimitations";
import { isAlphaNumeric, isEmptyHtmlString, isValidUrl } from "./utils";

export const firebaseAuthErrorWithNotify = (error: any) => {
  if (error.code === "auth/invalid-email") {
    notification.error({
      message: "That's not a valid email",
    });
  } else if (error.code === "auth/user-not-found") {
    notification.error({
      message: "We didn't find any user with that email",
    });
  } else if (error.code === "auth/wrong-password") {
    notification.error({
      message: "Incorrect password",
    });
  } else if (error.code === "auth/too-many-requests") {
    notification.error({
      message: "Too many requests",
    });
  } else if (error.code === "auth/popup-closed-by-user") {
    // Do nothing, this is just user closing popup
  } else {
    notification.error({
      message: "Something went wrong",
    });
  }
};

interface SignInInput {
  email: string;
  password: string;
}
export const isValidSignInInputWithErrorNotify = (input: SignInInput) => {
  const { email, password } = input;
  if (!email) {
    notification.error({ message: "Please enter an email" });
    return false;
  }
  if (!password) {
    notification.error({
      message: "Please enter a password",
    });
    return false;
  }
  return true;
};

interface UserInputCommon {
  username: string;
}
interface UserInputCreate extends UserInputCommon {
  email: string;
  password: string;
  confirmPassword: string;
  usernameUniqueCheckState: UsernameUniqueCheckState;
}
interface UserInputUpdate extends UserInputCommon {}
export const isValidUserInputWithErrorNotify = () => {
  const commonChecks = (input: UserInputCommon) => {
    const { username } = input;
    if (!username) {
      notification.error({
        message: "Username required",
      });
      return false;
    }
    if (!isAlphaNumeric(username)) {
      notification.error({
        message: "Username may not have special characters",
      });
      return false;
    }
    if (username.length > userFieldLimitations.usernameMaxLength) {
      notification.error({
        message: `Username is too long (max ${userFieldLimitations.usernameMaxLength} characters)`,
      });
      return false;
    }
    if (username.length < userFieldLimitations.usernameMinLength) {
      notification.error({
        message: `Username is too short (min ${userFieldLimitations.usernameMinLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: UserInputCreate) => {
      const { email, password, confirmPassword, usernameUniqueCheckState } =
        input;
      let isValid = commonChecks(input);
      if (!password) {
        notification.error({
          message: "Password required",
        });
        isValid = false;
      }
      if (password.length < userFieldLimitations.passwordMinLength) {
        notification.error({
          message: "Password must be at least 6 characters",
        });
        isValid = false;
      }
      if (password !== confirmPassword) {
        notification.error({
          message: "Passwords do not match",
        });
        isValid = false;
      }
      if (usernameUniqueCheckState === UsernameUniqueCheckState.Taken) {
        notification.error({
          message: "Username is already taken",
        });
        isValid = false;
      }
      if (usernameUniqueCheckState === UsernameUniqueCheckState.Loading) {
        notification.warn({
          message: "Wait for username check",
        });
        isValid = false;
      }
      if (!email) {
        notification.error({
          message: "Email is required",
        });
        isValid = false;
      }
      return isValid;
    },
    forUpdate: (input: UserInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface CourseInputCommon {
  title: string;
  description: string;
  categories: CourseCategory[];
  owners: string[];
}
interface CourseInputCreate extends CourseInputCommon {
  meUser: User | null;
}
interface CourseInputUpdate extends CourseInputCommon {}
export const isValidCourseInputWithErrorNotify = () => {
  const commonChecks = (input: CourseInputCommon) => {
    const { title, description, categories, owners } = input;
    if (!title) {
      notification.error({ message: "Title required" });
      return false;
    }
    if (title.length > courseFieldLimitations.titleMaxLength) {
      notification.error({
        message: `Title is too long (max ${courseFieldLimitations.titleMaxLength} characters)`,
      });
      return false;
    }
    if (!description) {
      notification.error({ message: "Description required" });
      return false;
    }
    if (description.length > courseFieldLimitations.descriptionMaxLength) {
      notification.error({
        message: `Description is too long (max ${courseFieldLimitations.descriptionMaxLength} characters)`,
      });
      return false;
    }
    if (owners.length < 1) {
      notification.error({
        message: "Course must have at least 1 owner",
      });
      return false;
    }
    if (owners.length > courseFieldLimitations.ownersMaxCount) {
      notification.error({
        message: `Too many course owners (max ${courseFieldLimitations.ownersMaxCount})`,
      });
      return false;
    }
    if (categories.length < 1) {
      notification.error({
        message: "Course must belong to at least 1 category",
      });
      return false;
    }
    if (categories.length > courseFieldLimitations.categoriesMaxCount) {
      notification.error({
        message: `Course cannot belong to more than ${courseFieldLimitations.categoriesMaxCount} categories`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: CourseInputCreate) => {
      const { owners, meUser } = input;
      let isValid = commonChecks(input);
      if (owners[0] !== meUser?.uid) {
        notification.error({
          message: "You must be an owner of your own course",
        });
        isValid = false;
      }
      return isValid;
    },
    forUpdate: (input: CourseInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface ModuleInputCommon {
  title: string;
  description?: string;
}
interface ModuleInputCreate extends ModuleInputCommon {}
interface ModuleInputUpdate extends ModuleInputCommon {}
export const isValidModuleInputWithErrorNotify = () => {
  const commonChecks = (input: ModuleInputCommon) => {
    const { title, description } = input;
    if (!title) {
      notification.error({ message: "Name required" });
      return false;
    }
    if (title.length > moduleFieldLimitations.titleMaxLength) {
      notification.error({
        message: `Name is too long (max ${moduleFieldLimitations.titleMaxLength} characters)`,
      });
      return false;
    }
    if (
      !!description &&
      description.length > moduleFieldLimitations.descriptionMaxLength
    ) {
      notification.error({
        message: `Description is too long (max ${moduleFieldLimitations.descriptionMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: ModuleInputCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: ModuleInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface LessonInputCommon {
  title: string;
  weblink: string;
  description: string;
  previewImageUrl?: string;
}
interface LessonInputCreate extends LessonInputCommon {
  selectedModuleId: string;
}
interface LessonInputUpdate extends LessonInputCommon {}
export const isValidLessonInputWithErrorNotify = () => {
  const commonChecks = (input: LessonInputCommon) => {
    const { title, weblink, description, previewImageUrl } = input;
    if (!title) {
      notification.error({ message: "Title required" });
      return false;
    }
    if (title.length > lessonFieldLimitations.titleMaxLength) {
      notification.error({
        message: `Title is too long (max ${lessonFieldLimitations.titleMaxLength} characters)`,
      });
      return false;
    }
    if (!weblink) {
      notification.error({ message: "Weblink required" });
      return false;
    }
    if (weblink.length > lessonFieldLimitations.weblinkMaxLength) {
      notification.error({
        message: `Weblink is too long (max ${lessonFieldLimitations.weblinkMaxLength} characters)`,
      });
      return false;
    }
    if (!isValidUrl(weblink)) {
      notification.error({ message: "Weblink invalid" });
      return false;
    }
    if (isEmptyHtmlString(description)) {
      notification.error({
        message: `Description required`,
      });
      return false;
    }
    if (description.length > lessonFieldLimitations.descriptionMaxLength) {
      notification.error({
        message: `Description is too long (max ${lessonFieldLimitations.descriptionMaxLength} characters)`,
      });
      return false;
    }
    if (
      previewImageUrl &&
      previewImageUrl.length > lessonFieldLimitations.previewImageUrlLength
    ) {
      notification.error({ message: `Preview image for link is too large` });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: LessonInputCreate) => {
      const { selectedModuleId } = input;
      let isValid = commonChecks(input);
      if (!selectedModuleId) {
        notification.error({
          message: "Please select a module to add this lesson to",
        });
        isValid = false;
      }
      return isValid;
    },
    forUpdate: (input: LessonInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface LessonQuestionInputCommon {
  question: string;
}
interface LessonQuestionInputCreate extends LessonQuestionInputCommon {}
interface LessonQuestionInputUpdate extends LessonQuestionInputCommon {}
export const isValidLessonQuestionInputWithErrorNotify = () => {
  const commonChecks = (input: LessonQuestionInputCommon) => {
    const { question } = input;
    if (!question) {
      notification.error({
        message: "Question cannot be blank",
      });
      return false;
    }
    if (question.length > lessonQuestionFieldLimitations.questionMaxLength) {
      notification.error({
        message: `Question is too long (max ${lessonQuestionFieldLimitations.questionMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: LessonQuestionInputCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: LessonQuestionInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface LessonAnswerInputCommon {
  answer: string;
}
interface LessonAnswerInputCreate extends LessonAnswerInputCommon {}
interface LessonAnswerInputUpdate extends LessonAnswerInputCommon {}
export const isValidLessonAnswerInputWithErrorNotify = () => {
  const commonChecks = (input: LessonAnswerInputCommon) => {
    const { answer } = input;
    if (!answer) {
      notification.error({
        message: "Answer cannot be blank",
      });
      return false;
    }
    if (answer.length > lessonAnswerFieldLimitations.answerMaxLength) {
      notification.error({
        message: `Answer is too long (max ${lessonAnswerFieldLimitations.answerMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: LessonAnswerInputCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: LessonAnswerInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface LessonExerciseInputCommon {
  exercise: string;
}
interface LessonExerciseInputCreate extends LessonExerciseInputCommon {}
interface LessonExerciseInputUpdate extends LessonExerciseInputCommon {}
export const isValidLessonExerciseInputWithErrorNotify = () => {
  const commonChecks = (input: LessonExerciseInputCommon) => {
    const { exercise } = input;
    if (!exercise) {
      notification.error({ message: `Exercise cannot be blank` });
      return false;
    }
    if (exercise.length > lessonExerciseFieldLimitations.exerciseMaxLength) {
      notification.error({
        message: `Exercise is too long (max ${lessonExerciseFieldLimitations.exerciseMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: LessonExerciseInputCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: LessonExerciseInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface LessonAlternativeLinkCommon {
  lessonId: string;
  weblink: string;
  reason: string;
  previewImageUrl?: string;
}
interface LessonAlternativeLinkCreate extends LessonAlternativeLinkCommon {}
interface LessonAlternativeLinkUpdate extends LessonAlternativeLinkCommon {}
export const isValidLessonAlternativeLinkWithErrorNotify = () => {
  const commonChecks = (input: LessonAlternativeLinkCommon) => {
    const { lessonId, weblink, reason, previewImageUrl } = input;
    if (!lessonId) {
      notification.error({
        message: "Select an existing link for this alternative",
      });
      return false;
    }
    if (!weblink) {
      notification.error({ message: "Weblink required" });
      return false;
    }
    if (
      weblink.length > lessonAlternativeLinkFieldLimitations.weblinkMaxLength
    ) {
      notification.error({
        message: `Weblink is too long (max ${lessonAlternativeLinkFieldLimitations.weblinkMaxLength} characters)`,
      });
      return false;
    }
    if (!isValidUrl(weblink)) {
      notification.error({ message: "Weblink invalid" });
      return false;
    }
    if (isEmptyHtmlString(reason)) {
      notification.error({ message: "Reason for alternative link required" });
      return false;
    }
    if (reason.length > lessonAlternativeLinkFieldLimitations.reasonMaxLength) {
      notification.error({
        message: `Reason is too long (max ${lessonAlternativeLinkFieldLimitations.reasonMaxLength} characters)`,
      });
      return false;
    }
    if (
      previewImageUrl &&
      previewImageUrl.length >
        lessonAlternativeLinkFieldLimitations.previewImageUrlLength
    ) {
      notification.error({ message: `Preview image for link is too large` });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: LessonAlternativeLinkCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: LessonAlternativeLinkUpdate) => {
      return commonChecks(input);
    },
  };
};

interface CourseCommentInputCommon {
  comment: string;
}
interface CourseCommentInputCreate extends CourseCommentInputCommon {}
interface CourseCommentInputUpdate extends CourseCommentInputCommon {}
export const isValidCourseCommentInputWithErrorNotify = () => {
  const commonChecks = (input: CourseCommentInputCommon) => {
    const { comment } = input;
    if (!comment) {
      notification.error({
        message: "Comment cannot be blank",
      });
      return false;
    }
    if (comment.length > courseCommentFieldLimitations.commentMaxLength) {
      notification.error({
        message: `Comment is too long (max ${courseCommentFieldLimitations.commentMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: CourseCommentInputCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: CourseCommentInputUpdate) => {
      return commonChecks(input);
    },
  };
};

interface CourseSuggestionCommon {
  type: CourseSuggestionType;
  reason: string;
  lessonTitle?: string;
  lessonWeblink?: string;
  lessonPreviewImageUrl?: string;
  moduleId?: string;
  moduleTitle?: string;
}
interface CourseSuggestionCreate extends CourseSuggestionCommon {}
interface CourseSuggestionUpdate extends CourseSuggestionCommon {}
export const isValidCourseSuggestionWithErrorNotify = () => {
  const commonChecks = (input: CourseSuggestionCommon) => {
    const {
      type,
      reason,
      lessonTitle,
      lessonWeblink,
      lessonPreviewImageUrl,
      moduleId,
      moduleTitle,
    } = input;
    if (isEmptyHtmlString(reason)) {
      notification.error({ message: "Reason required" });
      return false;
    }
    if (reason.length > courseSuggestionFieldLimitations.reasonMaxLength) {
      notification.error({
        message: `Reason is too long (max ${courseSuggestionFieldLimitations.reasonMaxLength} characters)`,
      });
      return false;
    }
    if (type === CourseSuggestionType.NewLesson) {
      if (lessonTitle) {
        if (
          lessonTitle.length >
          courseSuggestionFieldLimitations.lessonTitleMaxLength
        ) {
          notification.error({
            message: `Lesson title is too long (max ${courseSuggestionFieldLimitations.lessonTitleMaxLength} characters)`,
          });
          return false;
        }
      } else {
        notification.error({ message: "Lesson title required" });
        return false;
      }

      if (lessonWeblink) {
        if (!isValidUrl(lessonWeblink)) {
          notification.error({ message: "Lesson weblink invalid" });
          return false;
        }
        if (
          lessonWeblink.length >
          courseSuggestionFieldLimitations.lessonWeblinkMaxLength
        ) {
          notification.error({
            message: `Lesson weblink is too long (max ${courseSuggestionFieldLimitations.lessonWeblinkMaxLength} characters)`,
          });
          return false;
        }
      } else {
        notification.error({ message: "Lesson weblink required" });
        return false;
      }

      if (lessonPreviewImageUrl) {
        if (
          lessonPreviewImageUrl.length >
          courseSuggestionFieldLimitations.lessonPreviewImageUrlLength
        ) {
          notification.error({
            message: `Preview image for link is too large`,
          });
          return false;
        }
      }
      if (!moduleId) {
        notification.error({ message: "Module selection required" });
        return false;
      }
    }
    if (type === CourseSuggestionType.NewModule) {
      if (moduleTitle) {
        if (
          moduleTitle.length >
          courseSuggestionFieldLimitations.moduleTitleMaxLength
        ) {
          notification.error({
            message: `Module title is too long (max ${courseSuggestionFieldLimitations.moduleTitleMaxLength} characters)`,
          });
          return false;
        }
      } else {
        notification.error({ message: "Module title required" });
        return false;
      }
    }
    return true;
  };
  return {
    forCreate: (input: CourseSuggestionCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: CourseSuggestionUpdate) => {
      return commonChecks(input);
    },
  };
};

interface CourseReviewRejectionInputCommon {
  rejectionReason: string;
}
interface CourseReviewRejectionCreate
  extends CourseReviewRejectionInputCommon {}
interface CourseReviewRejectionUpdate
  extends CourseReviewRejectionInputCommon {}
export const isValidCourseReviewRejectionInputWithErrorNotify = () => {
  const commonChecks = (input: CourseReviewRejectionInputCommon) => {
    const { rejectionReason } = input;
    if (!rejectionReason) {
      notification.error({
        message: `You must provide a reason for this rejection`,
      });
      return false;
    }
    if (
      rejectionReason.length <
      courseReviewRejectionFieldLimitations.rejectionReasonMinLength
    ) {
      notification.error({
        message: `Reason is too short. Please provide meaningful feedback so the course owners can improve their course.`,
      });
      return false;
    }
    if (
      rejectionReason.length >
      courseReviewRejectionFieldLimitations.rejectionReasonMaxLength
    ) {
      notification.error({
        message: `Reason is too long (max ${courseReviewRejectionFieldLimitations.rejectionReasonMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: CourseReviewRejectionCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: CourseReviewRejectionUpdate) => {
      return commonChecks(input);
    },
  };
};

interface NewCourseCategoryRequestInputCommon {
  category: string;
}
interface NewCourseCategoryRequestCreate
  extends NewCourseCategoryRequestInputCommon {}
interface NewCourseCategoryRequestUpdate
  extends NewCourseCategoryRequestInputCommon {}
export const isValidNewCourseCategoryRequestInputWithErrorNotify = () => {
  const commonChecks = (input: NewCourseCategoryRequestInputCommon) => {
    const { category } = input;
    if (!category) {
      notification.error({
        message: `Please enter a new category`,
      });
      return false;
    }
    if (
      category.length >
      newCourseCategoryRequestFieldLimitations.categoryMaxLength
    ) {
      notification.error({
        message: `Category name is too long (max ${newCourseCategoryRequestFieldLimitations.categoryMaxLength} characters)`,
      });
      return false;
    }
    return true;
  };
  return {
    forCreate: (input: NewCourseCategoryRequestCreate) => {
      return commonChecks(input);
    },
    forUpdate: (input: NewCourseCategoryRequestUpdate) => {
      return commonChecks(input);
    },
  };
};
