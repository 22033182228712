import { Button, Input, Space } from "antd";
import React from "react";
import { locStrings } from "../../../localization/locStrings";
import {
  PAGE_SECTION_SPACING,
  themeColors,
} from "../../../styling/styleConstants";
import { ConfirmButton } from "../../common/ConfirmButton";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { DeleteOutlined } from "@ant-design/icons";
import { RichTextEditor } from "../../common/RichTextEditor";

interface IModuleEditProps {
  pageTitle: string;
  moduleTitle: string;
  moduleDescription: string;
  onModuleTitleChange: (s: string) => void;
  setModuleDescription: (s: string) => void;
  onSubmit: () => Promise<void>;
  onDeleteModule?: () => void;
}

export const ModuleEdit: React.FunctionComponent<IModuleEditProps> = (
  props
) => {
  const {
    pageTitle,
    moduleTitle,
    moduleDescription,
    onModuleTitleChange,
    setModuleDescription,
    onSubmit,
    onDeleteModule,
  } = props;
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <PageTypography
            title={pageTitle}
            level={PageTypographyLevel.PageTitle}
            isGoBackEnabled={true}
            style={{ marginBottom: PAGE_SECTION_SPACING }}
          />
          {!!onDeleteModule && (
            <ConfirmButton
              onConfirm={onDeleteModule}
              text={locStrings.delete.value()}
              type={"default"}
              icon={<DeleteOutlined />}
              style={{ color: themeColors.errorRed }}
            />
          )}
        </div>
        <div>
          <div style={{ marginBottom: PAGE_SECTION_SPACING }}>
            <PageTypography
              title={locStrings.moduleName.value()}
              level={PageTypographyLevel.PageSection}
            />
            <Input
              id={"ModuleEdit-title"}
              type={"text"}
              placeholder={locStrings.moduleNamePlaceholder.value()}
              value={moduleTitle}
              onChange={(e) => onModuleTitleChange(e.target.value)}
            />
          </div>
          <PageTypography
            title={"Description (Optional)"}
            level={PageTypographyLevel.PageSection}
          />
          <RichTextEditor
            initialValue={moduleDescription}
            onHtmlStringChange={setModuleDescription}
            placeholder={"Add an optional description for this module..."}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onSubmit} type={"primary"}>
            {locStrings.submit.value()}
          </Button>
        </div>
      </Space>
    </LayoutContent>
  );
};
