import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useCreateModuleMutation } from "../../../firestore/mutations/useCreateModuleMutation";
import { useUpdateCourseSuggestionMutation } from "../../../firestore/mutations/useUpdateCourseSuggestionMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { useGetCourseSuggestionQuery } from "../../../firestore/queries/useGetCourseSuggestionQuery";
import { zIndexPriorities } from "../../../styling/styleConstants";
import { CourseSuggestionState } from "../../../types/helperTypes";
import { isEmptyHtmlString } from "../../../utils/utils";
import { isValidModuleInputWithErrorNotify } from "../../../utils/validInputUtils";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { ModalWithTitle } from "../../common/ModalWithTitle";
import { ModuleCreateModal } from "./ModuleCreateModal";

interface IModuleCreateModalContainerProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
  courseSuggestionId?: string;
}

export const ModuleCreateModalContainer: React.FunctionComponent<
  IModuleCreateModalContainerProps
> = (props) => {
  const { visible, onClose, courseId, courseSuggestionId } = props;
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const { mutate: createModule, isLoading: isModuleCreationLoading } =
    useCreateModuleMutation(courseId || "");
  const { data: courseSuggestion, isLoading: isCourseSuggestionLoading } =
    useGetCourseSuggestionQuery(
      {
        courseSuggestionId: courseSuggestionId || "",
      },
      !!courseSuggestionId && visible
    );
  const { mutate: updateCourseSuggestion } = useUpdateCourseSuggestionMutation(
    courseSuggestionId || "",
    courseId
  );
  const [moduleTitle, setModuleTitle] = useState<string>("");
  const [moduleDescription, setModuleDescription] = useState<string>("");

  // Load up suggestion data
  useEffect(() => {
    if (courseSuggestion && visible) {
      if (courseSuggestion.moduleTitle) {
        setModuleTitle(courseSuggestion.moduleTitle);
      }
      if (courseSuggestion.reason) {
        setModuleDescription(courseSuggestion.reason);
      }
    }
  }, [courseSuggestion, visible]);

  const onCloseAndResetFields = () => {
    setModuleTitle("");
    setModuleDescription("");
    onClose();
  };

  const onCreateNewModule = async () => {
    const isValid = isValidModuleInputWithErrorNotify().forCreate({
      title: moduleTitle,
      description: moduleDescription,
    });

    if (isValid) {
      await createModule({
        title: moduleTitle,
        description: isEmptyHtmlString(moduleDescription)
          ? undefined
          : moduleDescription,
        createdFromSuggestionId: !!courseSuggestion
          ? courseSuggestion.id
          : undefined,
        onSuccess: () => {
          if (!!courseSuggestion) {
            updateCourseSuggestion({ state: CourseSuggestionState.Accepted });
          }
          notification.success({ message: "Module successfully created" });
          onCloseAndResetFields();
        },
      });
    }
  };

  if (isCourseError) {
    return <GenericError />;
  }

  if (
    isModuleCreationLoading ||
    isCourseLoading ||
    isCourseSuggestionLoading ||
    !course
  ) {
    return <GenericLoader />;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onCloseAndResetFields}
      title={"New Module"}
      subTitle={"Add a new module where links will go"}
      zIndex={zIndexPriorities.newModuleModal}
    >
      <ModuleCreateModal
        moduleTitle={moduleTitle}
        moduleDescription={moduleDescription}
        setModuleTitle={setModuleTitle}
        setModuleDescription={setModuleDescription}
        onSubmit={onCreateNewModule}
      />
    </ModalWithTitle>
  );
};
