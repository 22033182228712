import React, { ReactNode } from "react";
import { useUnreadNotificationsCount } from "../../ui-hooks/useUnreadNotificationsCount";
import { BadgeWrapper } from "./BadgeWrapper";

interface IUnreadNotificationsBadgeWrapperProps {
  children: ReactNode;
}

export const UnreadNotificationsBadgeWrapper: React.FunctionComponent<
  IUnreadNotificationsBadgeWrapperProps
> = (props) => {
  const { children } = props;
  const unreadNotificationsCount = useUnreadNotificationsCount();

  return (
    <BadgeWrapper
      isBadgeVisible={unreadNotificationsCount > 0}
      defaultConfig={{ count: unreadNotificationsCount }}
    >
      {children}
    </BadgeWrapper>
  );
};
