import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { collectionPaths } from "../collectionPaths";
import { listNotificationsQueryKey } from "../queries/useListNotificationsQuery";
import { settledLogger } from "../settledLogger";

export const useUpdateNotificationMutation = (
  notificationId: string,
  userId: string
) => {
  const queryClient = useQueryClient();
  const updateNotification = async (vars: { isRead: boolean }) => {
    const { isRead } = vars;
    const db = getFirestore();
    const notificationDocRef = doc(
      db,
      collectionPaths.notifications,
      notificationId
    );
    return updateDoc(notificationDocRef, {
      isRead,
    });
  };
  return useMutation(updateNotification, {
    onSettled: settledLogger("useUpdateNotificationMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(listNotificationsQueryKey({ userId }), {
        refetchInactive: true,
      });
    },
  });
};
