import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

class FirebaseSdk {
  private _app: FirebaseApp | undefined;
  constructor() {
    this._app = undefined;
  }

  public initApp = (app: FirebaseApp) => {
    this._app = app;
  };

  public getAuth = () => {
    return getAuth(this._app);
  };

  public getFirestore = () => {
    if (this._app) {
      return getFirestore(this._app);
    }
  };

  public getStorage = () => {
    return getStorage(this._app);
  };

  public getFunctions = () => {
    return getFunctions(this._app);
  };
}

export default new FirebaseSdk();
