import { Button, Input, Space } from "antd";
import React, { useState } from "react";
import { locStrings } from "../../../../localization/locStrings";

interface ICourseSuggestionCreateGeneralFeedbackProps {
  onCreateGeneralFeedbackSuggestion: (vars: { reason: string }) => void;
}

export const CourseSuggestionCreateGeneralFeedback: React.FunctionComponent<
  ICourseSuggestionCreateGeneralFeedbackProps
> = (props) => {
  const { onCreateGeneralFeedbackSuggestion } = props;
  const [reason, setReason] = useState<string>("");

  const onSubmit = () => {
    onCreateGeneralFeedbackSuggestion({
      reason,
    });
  };

  return (
    <Space direction={"vertical"} style={{ display: "flex" }}>
      <Input.TextArea
        id={"CourseSuggestionCreateGeneralFeedback-reason"}
        autoSize={{ minRows: 2 }}
        placeholder={locStrings.suggestionGeneralFeedbackReasonInputPlaceholder.value()}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button onClick={onSubmit} type={"primary"}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
