import { Card, Space } from "antd";
import React from "react";
import { themeColors } from "../../styling/styleConstants";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { DiscordButton } from "../header-nav-bar/DiscordButton";

interface IHomeDiscordSectionProps {}

export const HomeDiscordSection: React.FunctionComponent<
  IHomeDiscordSectionProps
> = (props) => {
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  return (
    <Card
      style={{
        display: "flex",
        backgroundColor: themeColors.headerBackground,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space
        direction={isScreenLargerThanMd ? "horizontal" : "vertical"}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <PageTypography
          title={"We have a community of people who are psyched about LoA"}
          level={PageTypographyLevel.PageSection}
          style={{
            color: "white",
            marginBottom: isScreenLargerThanMd ? 0 : "0.8rem",
            textAlign: "center",
            marginRight: "0.5rem",
          }}
        />
        <DiscordButton />
      </Space>
    </Card>
  );
};
