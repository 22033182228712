import { notification } from "antd";
import React, { useState } from "react";
import { useCreateCourseCommentMutation } from "../../../firestore/mutations/useCreateCourseCommentMutation";
import { locStrings } from "../../../localization/locStrings";
import { MetadataType } from "../../../types/helperTypes";
import { useSisuModal } from "../../../ui-hooks/useSisuModal";
import { isValidCourseCommentInputWithErrorNotify } from "../../../utils/validInputUtils";
import { CreateCourseCommentInput } from "./CreateCourseCommentInput";

interface ICreateCourseCommentInputContainerProps {
  courseId: string;
  metadataType: MetadataType;
  metadataId: string;
  replyToId?: string;
  placeholder?: string;
}

export const CreateCourseCommentInputContainer: React.FunctionComponent<
  ICreateCourseCommentInputContainerProps
> = (props) => {
  const { courseId, metadataType, metadataId, replyToId, placeholder } = props;
  const [commentInput, setCommentInput] = useState<string>("");
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: createComment, isLoading: isCreateCommentLoading } =
    useCreateCourseCommentMutation(courseId);

  const onCreateComment = async () => {
    const isValid = isValidCourseCommentInputWithErrorNotify().forCreate({
      comment: commentInput,
    });

    if (isValid) {
      try {
        await createComment(
          {
            comment: commentInput,
            metadataType,
            metadataId,
            replyToId,
          },
          {
            onSuccess: () => {
              notification.success({ message: "Comment created" });
              setCommentInput("");
            },
          }
        );
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
  };

  return (
    <CreateCourseCommentInput
      onCreateComment={forceSisuModalFxnWrapper(onCreateComment)}
      commentInput={commentInput}
      setCommentInput={setCommentInput}
      isCreateCommentLoading={isCreateCommentLoading}
      placeholder={placeholder}
    />
  );
};
