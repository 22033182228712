import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteCourseMutation } from "../../../firestore/mutations/useDeleteCourseMutation";
import { useUpdateCourseMutation } from "../../../firestore/mutations/useUpdateCourseMutation";
import { useGetCourseQuery } from "../../../firestore/queries/useGetCourseQuery";
import { locStrings } from "../../../localization/locStrings";
import { CourseCategory } from "../../../types/helperTypes";
import { useNavigation } from "../../../ui-hooks/useNavigation";
import { isValidCourseInputWithErrorNotify } from "../../../utils/validInputUtils";
import { GenericError } from "../../common/GenericError";
import { GenericLoader } from "../../common/GenericLoader";
import { CourseEdit } from "./CourseEdit";

interface ICourseUpdateContainerProps {}
type ICourseUpdateContainerParams = {
  courseId: string;
};

export const CourseUpdateContainer: React.FunctionComponent<
  ICourseUpdateContainerProps
> = (props) => {
  const { courseId } = useParams<ICourseUpdateContainerParams>();
  const nav = useNavigation();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId: courseId || "" });
  const {
    mutate: updateCourse,
    isSuccess: isCourseUpdateSuccessful,
    isLoading: isCourseUpdateLoading,
  } = useUpdateCourseMutation(courseId || "");
  const {
    mutate: deleteCourse,
    isSuccess: isCourseDeleteSuccessful,
    isLoading: isCourseDeleteLoading,
  } = useDeleteCourseMutation(courseId || "");
  const [courseTitle, setCourseTitle] = useState<string>("");
  const [courseDescription, setCourseDescription] = useState<string>("");
  const [courseCategories, setCourseCategories] = useState<CourseCategory[]>(
    []
  );
  const [courseOwners, setCourseOwners] = useState<string[]>([]);
  const onCourseTitleChange = (s: string) => {
    setCourseTitle(s);
  };
  const onCourseDescriptionChange = (s: string) => {
    setCourseDescription(s);
  };
  const onCourseOwnersChange = (owners: string[]) => {
    setCourseOwners(owners);
  };
  const onCourseCategoriesChange = (newCategories: CourseCategory[]) => {
    setCourseCategories(newCategories);
  };

  useEffect(() => {
    if (isCourseUpdateSuccessful) {
      nav.window.goBack();
    }

    if (isCourseDeleteSuccessful) {
      nav.goToPaths.home();
    }
  }, [
    isCourseUpdateSuccessful,
    isCourseDeleteSuccessful,
    nav.goToPaths,
    nav.window,
  ]);

  useEffect(() => {
    if (course) {
      setCourseTitle(course.title);
      setCourseDescription(course.description);
      setCourseOwners(course.owners);
      setCourseCategories(course.categories);
    }
  }, [course, setCourseTitle, setCourseDescription]);

  const onUpdateCourse = async () => {
    const isValid = isValidCourseInputWithErrorNotify().forUpdate({
      title: courseTitle,
      description: courseDescription,
      categories: courseCategories,
      owners: courseOwners,
    });

    if (isValid) {
      updateCourse({
        title: courseTitle,
        description: courseDescription,
        categories: courseCategories,
        owners: courseOwners,
      });
    }
  };

  const onDeleteCourse = () => {
    deleteCourse();
  };

  if (isCourseError) {
    return <GenericError />;
  }

  // Must wait for courseDescription to load because RichTextEditor requires initalValue
  if (
    isCourseLoading ||
    isCourseUpdateLoading ||
    isCourseDeleteLoading ||
    !course ||
    !courseDescription
  ) {
    return <GenericLoader />;
  }

  return (
    <CourseEdit
      pageTitle={locStrings.editCoursePageTitle.value()}
      courseTitle={courseTitle}
      courseDescription={courseDescription}
      courseCategories={courseCategories}
      onCourseTitleChange={onCourseTitleChange}
      onCourseDescriptionChange={onCourseDescriptionChange}
      onCourseCategoriesChange={onCourseCategoriesChange}
      onSubmit={onUpdateCourse}
      disableRemovalOfMyUser={false}
      courseOwners={courseOwners}
      onCourseOwnersChange={onCourseOwnersChange}
      onDeleteCourse={onDeleteCourse}
    />
  );
};
