import React, { useState } from "react";
import { ICourse } from "../../../types/firestoreMappings";
import { calculateModuleAndLessonCountsInCourse } from "../../../utils/utils";
import { NewLinkModalContainer } from "../../new-link-modal/NewLinkModalContainer";
import { convertCourseMetadataTypeToIcon } from "../../../utils/iconAndStringUtils";
import { CourseSuggestionType, MetadataType } from "../../../types/helperTypes";
import { ModuleCreateModalContainer } from "../module-write/ModuleCreateModalContainer";
import { CourseToolBarItems, ICourseToolBarItem } from "./CourseToolBarItems";

interface ICourseEditorToolBarButtonsProps {
  course: ICourse;
}

export const CourseEditorToolBarButtons: React.FunctionComponent<
  ICourseEditorToolBarButtonsProps
> = (props) => {
  const { course } = props;
  const [isNewLinkModalVisible, setIsNewLinkModalVisible] =
    useState<boolean>(false);
  const [isNewModuleModalVisible, setIsNewModuleModalVisible] =
    useState<boolean>(false);
  const moduleAndLessonCounts = calculateModuleAndLessonCountsInCourse(course);

  const onCloseNewLinkModal = () => {
    setIsNewLinkModalVisible(false);
  };
  const onCloseNewModuleModal = () => {
    setIsNewModuleModalVisible(false);
  };

  const items: ICourseToolBarItem[] = [
    {
      id: "NewLink",
      title: "Add Link",
      icon: convertCourseMetadataTypeToIcon({
        metadataType: MetadataType.AlternativeLink,
      }),
      onClick: () => setIsNewLinkModalVisible(true),
      tooltipTitle:
        moduleAndLessonCounts.lessonsCount === 0
          ? "Add your first link"
          : undefined,
      className:
        moduleAndLessonCounts.lessonsCount === 0
          ? "pulsing-animation"
          : undefined,
      isHidden: moduleAndLessonCounts.modulesCount === 0,
    },
    {
      id: "NewModule",
      title: "Add Module",
      icon: convertCourseMetadataTypeToIcon({
        courseSuggestionType: CourseSuggestionType.NewModule,
      }),
      onClick: () => setIsNewModuleModalVisible(true),
      tooltipTitle:
        moduleAndLessonCounts.modulesCount === 0
          ? "Add your first module"
          : undefined,
      className:
        moduleAndLessonCounts.modulesCount === 0
          ? "pulsing-animation"
          : undefined,
    },
  ];

  return (
    <>
      <CourseToolBarItems items={items} />
      <NewLinkModalContainer
        courseId={course.id}
        visible={isNewLinkModalVisible}
        onClose={onCloseNewLinkModal}
      />
      <ModuleCreateModalContainer
        courseId={course.id}
        visible={isNewModuleModalVisible}
        onClose={onCloseNewModuleModal}
      />
    </>
  );
};
