import React from "react";

export const InputMimicry: React.FunctionComponent<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = (props) => {
  const { style } = props;
  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        padding: "4px 11px",
        width: "100%",
        fontSize: "14px",
        borderRadius: "5px",
        ...style,
      }}
    >
      {props.children}
    </div>
  );
};
