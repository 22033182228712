import React from "react";
import { MetadataType } from "../../../types/helperTypes";
import { InLessonDetailsSectionDivider } from "./InLessonDetailsSectionDivider";

interface IInLessonDetailsSectionProps {
  children: React.ReactNode;
  title: string;
  metadataType?: MetadataType;
  count?: number;
}

export const InLessonDetailsSection: React.FunctionComponent<
  IInLessonDetailsSectionProps
> = (props) => {
  const { children, title, metadataType, count } = props;

  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <InLessonDetailsSectionDivider
        title={title}
        metadataType={metadataType}
        count={count}
      />
      {children}
    </div>
  );
};
