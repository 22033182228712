import React from "react";
import { useListUsersByIds } from "../../firestore/queries/useListUsersByIds";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { Settings } from "./Settings";

interface ISettingsContainerProps {}

export const SettingsContainer: React.FunctionComponent<
  ISettingsContainerProps
> = (props) => {
  const { user: meUserAuth } = useUserAuth();
  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useListUsersByIds([meUserAuth?.uid || "$$$$$$$$$$$$$$$$$$"]);

  if (isUsersError) {
    return <GenericError />;
  }

  if (isUsersLoading || !users || !users[0]) {
    return <GenericLoader />;
  }

  const meUser = users[0];
  return <Settings meUser={meUser} />;
};
