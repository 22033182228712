import { Button, Dropdown, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";

export interface FilterOption {
  title: string;
  icon: JSX.Element;
  isSelected: boolean;
  isClearable: boolean;
  onClick: () => void;
}

export interface FilterSection {
  title: string;
  filterOptions: FilterOption[];
}

interface IFilterDropdownProps {
  defaultTitle: string;
  defaultIcon: JSX.Element;
  filterSections: FilterSection[];
  clearAllFilters: () => void;
}

export const FilterDropdown: React.FunctionComponent<IFilterDropdownProps> = (
  props
) => {
  const { defaultTitle, defaultIcon, filterSections, clearAllFilters } = props;

  let numOfFiltersSelected = calculateNumOfFiltersSelected(filterSections);

  let buttonDefaultTitle = defaultTitle;
  if (numOfFiltersSelected > 0) {
    buttonDefaultTitle = `${buttonDefaultTitle} (${numOfFiltersSelected})`;
  }

  const menu = (
    <Menu>
      {numOfFiltersSelected > 0 && (
        <Menu.Item
          onClick={clearAllFilters}
          icon={<CloseOutlined />}
          style={{
            backgroundColor: "#ebebeb",
          }}
        >
          {"Clear all"}
        </Menu.Item>
      )}
      {filterSections.map((section) => {
        return (
          <Menu.ItemGroup
            title={section.title}
            key={`FilterDropdown-Section-${section.title}`}
          >
            {section.filterOptions.map((option) => {
              return (
                <Menu.Item
                  icon={option.icon}
                  onClick={option.onClick}
                  key={`FilterDropdown-Option-${option.title}`}
                  style={{ fontWeight: option.isSelected ? "bold" : undefined }}
                >
                  {option.title}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
        );
      })}
    </Menu>
  );

  return (
    //@ts-ignore
    <Dropdown overlay={menu}>
      <Button icon={defaultIcon}>{buttonDefaultTitle}</Button>
    </Dropdown>
  );
};

export const calculateNumOfFiltersSelected = (
  filterSections: FilterSection[]
) => {
  let numOfFiltersSelected = 0;
  filterSections.forEach((section) => {
    section.filterOptions.forEach((option) => {
      if (option.isSelected && option.isClearable) {
        numOfFiltersSelected += 1;
      }
    });
  });
  return numOfFiltersSelected;
};
