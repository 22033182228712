import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import {
  CourseSuggestionState,
  CourseSuggestionType,
  NotificationType,
} from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { listCourseSuggestionsQueryKey } from "../queries/useListCourseSuggestionsQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateCourseSuggestionMutation = (courseId: string) => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const getCourse = useGetCourseFetch();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const createCourseSuggestion = async (vars: {
    type: CourseSuggestionType;
    reason: string;
    suggestionData: {
      lessonTitle?: string;
      lessonWeblink?: string;
      lessonPreviewImageUrl?: string;
      prevLessonId?: string;
      nextLessonId?: string;
      moduleId?: string;
      moduleTitle?: string;
    };
  }) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateCourseSuggestionMutation, error - no user found"
      );
    }
    const { type, reason, suggestionData } = vars;
    const {
      lessonTitle,
      lessonWeblink,
      lessonPreviewImageUrl,
      prevLessonId,
      nextLessonId,
      moduleId,
      moduleTitle,
    } = suggestionData;
    const newSuggestion = {
      type,
      courseId,
      state: CourseSuggestionState.Open,
      reason,
      upvotes: 0,
      owner: user.uid,
      createdAt: serverTimestamp(),
    };
    if (type === CourseSuggestionType.NewLesson) {
      if (lessonTitle) {
        (newSuggestion as any).lessonTitle = lessonTitle;
      } else {
        return Promise.reject(
          "useCreateCourseSuggestionMutation, NewLesson, lessonQuestionId required"
        );
      }
      if (lessonWeblink) {
        (newSuggestion as any).lessonWeblink = lessonWeblink;
      } else {
        return Promise.reject(
          "useCreateCourseSuggestionMutation, NewLesson, lessonQuestionId required"
        );
      }
      if (moduleId) {
        (newSuggestion as any).moduleId = moduleId;
      } else {
        return Promise.reject(
          "useCreateCourseSuggestionMutation, NewLesson, moduleId required"
        );
      }
      if (lessonPreviewImageUrl) {
        (newSuggestion as any).lessonPreviewImageUrl = lessonPreviewImageUrl;
      }
      if (prevLessonId) {
        (newSuggestion as any).prevLessonId = prevLessonId;
      }
      if (nextLessonId) {
        (newSuggestion as any).nextLessonId = nextLessonId;
      }
    }
    if (type === CourseSuggestionType.NewModule) {
      if (moduleTitle) {
        (newSuggestion as any).moduleTitle = moduleTitle;
      } else {
        return Promise.reject(
          "useCreateCourseSuggestionMutation, NewLesson, lessonQuestionId required"
        );
      }
    }

    const db = getFirestore();
    const courseSuggestionsCollectionRef = collection(
      db,
      collectionPaths.courseSuggestions
    );
    const newCourseSuggestionDoc = await addDoc(
      courseSuggestionsCollectionRef,
      newSuggestion
    );
    const courseData = await getCourse({ courseId });
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid) {
          createNotification({
            type: NotificationType.CourseSuggestion,
            userId: owner,
            notificationData: {
              courseSuggestionId: newCourseSuggestionDoc.id,
              courseSuggestionType: type,
            },
          });
        }
      });
    }
  };
  return useMutation(createCourseSuggestion, {
    onSettled: settledLogger("useCreateCourseSuggestionMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listCourseSuggestionsQueryKey({ courseId }),
        { refetchInactive: true }
      );
    },
  });
};
