import React, { useState } from "react";
import { SisuState, useSisuModal } from "../../ui-hooks/useSisuModal";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import {
  firebaseAuthErrorWithNotify,
  isValidUserInputWithErrorNotify,
} from "../../utils/validInputUtils";
import { UsernameUniqueCheckState } from "../common/UsernameUniqueInput";
import { SignUp } from "./SignUp";

interface ISignUpContainerProps {}

export const SignUpContainer: React.FunctionComponent<ISignUpContainerProps> = (
  props
) => {
  const { setSisuState } = useSisuModal();
  const { signUp } = useUserAuth();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [usernameUniqueCheckState, setUsernameUniqueCheckState] =
    useState<UsernameUniqueCheckState>(UsernameUniqueCheckState.Available);

  const onSignUp = async () => {
    const isCreateUserValid = isValidUserInputWithErrorNotify().forCreate({
      username,
      email,
      password,
      confirmPassword,
      usernameUniqueCheckState,
    });
    if (!isCreateUserValid) {
      return;
    }

    try {
      await signUp(username, email, password);
      setSisuState(SisuState.VerifyEmail);
    } catch (error: any) {
      firebaseAuthErrorWithNotify(error);
    }
  };

  return (
    <SignUp
      username={username}
      email={email}
      password={password}
      confirmPassword={confirmPassword}
      usernameUniqueCheckState={usernameUniqueCheckState}
      setUsername={setUsername}
      setEmail={setEmail}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      setUsernameUniqueCheckState={setUsernameUniqueCheckState}
      onSignUp={onSignUp}
    />
  );
};
