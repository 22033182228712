import { Button, Space } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { LessonSelect } from "../common/LessonSelect";
import { RichTextEditor } from "../common/RichTextEditor";

interface ILessonExerciseEditProps {
  courseId: string;
  onSubmit: () => void;
  lessonId: string;
  exercise: string;
  onLessonIdChange: (newLessonId: string) => void;
  onExerciseChange: (newExercise: string) => void;
}

export const LessonExerciseEdit: React.FunctionComponent<
  ILessonExerciseEditProps
> = (props) => {
  const {
    courseId,
    onSubmit,
    lessonId,
    exercise,
    onLessonIdChange,
    onExerciseChange,
  } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <LessonSelect
        courseId={courseId}
        placeholder={"Select an existing link for this exercise"}
        selectedLessonId={lessonId}
        onChange={onLessonIdChange}
      />
      <RichTextEditor
        initialValue={exercise}
        onHtmlStringChange={onExerciseChange}
        placeholder={locStrings.createAnExercisePlaceholder.value()}
      />
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onSubmit} type={"primary"}>
          {locStrings.submit.value()}
        </Button>
      </div>
    </Space>
  );
};
