import { Layout, Menu, Space, Typography } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { ICourse, ILesson } from "../../types/firestoreMappings";
import { useNavigation } from "../../ui-hooks/useNavigation";
import {
  BREAKPOINT_MD,
  themeColors,
  zIndexPriorities,
} from "../../styling/styleConstants";
import { locStrings } from "../../localization/locStrings";
import { convertLessonTypeToIcon } from "../../utils/iconAndStringUtils";
import { useIsScreenLargerThanMd } from "../../ui-hooks/useIsScreenLargerThanMd";
import { BackButton } from "../common/BackButton";

const SIDEBAR_WIDTH = 300;

interface IInLessonSidebarProps {
  course: ICourse;
  currentLesson: ILesson;
}

export const InLessonSidebar: React.FunctionComponent<IInLessonSidebarProps> = (
  props
) => {
  const { course, currentLesson } = props;
  const nav = useNavigation();
  const isScreenLargerThanMd = useIsScreenLargerThanMd();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isScreenLargerThanMd);
  const SiderTriggerElement = isCollapsed ? ArrowLeftOutlined : CloseOutlined;

  // All modules are open by default
  const moduleItemKeys = course.modules.map((module) => {
    return generateModuleItemKey(module.id);
  });

  const toggleSidebarCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onSidebarSelect = (item: any) => {
    if (item.key === generateCourseTitleItemKey(course.id)) {
      nav.goToPaths.courseInfo(course.id);
    } else if ((item.key as string).includes(addLessonSiderBarKeyPrefix)) {
      const selectedModuleId = extractModuleIdFromAddLessonItemKey(item.key);
      if (!isScreenLargerThanMd) {
        toggleSidebarCollapse();
      }
      nav.goToPaths.newLesson(course.id, { moduleId: selectedModuleId });
    } else {
      const selectedLessonId = extractLessonIdFromItemKey(item.key);
      if (!isScreenLargerThanMd) {
        toggleSidebarCollapse();
      }
      nav.goToPaths.inLesson(course.id, selectedLessonId);
    }
  };

  return (
    <Layout.Sider
      breakpoint={BREAKPOINT_MD}
      collapsedWidth={"0"}
      width={SIDEBAR_WIDTH}
      style={{
        zIndex: zIndexPriorities.inLessonSider,
        backgroundColor: themeColors.lightGrayBackgroundColor,
      }}
      collapsible={isCollapsed}
      collapsed={isCollapsed}
      onCollapse={(collapsed) => setIsCollapsed(collapsed)}
      zeroWidthTriggerStyle={{ top: "0px", opacity: "30%" }}
      trigger={<SiderTriggerElement onClick={toggleSidebarCollapse} />}
    >
      <Menu
        mode="inline"
        defaultOpenKeys={moduleItemKeys}
        selectedKeys={[generateLessonItemKey(currentLesson.id)]}
        onSelect={onSidebarSelect}
      >
        <Menu.Item key={generateCourseTitleItemKey(course.id)}>
          <Space direction={"horizontal"}>
            <BackButton style={{ marginBottom: "7px" }} disableClick={true} />
            <Typography.Title
              level={5}
              style={{ marginTop: "9px", maxWidth: "247px" }}
              ellipsis={true}
            >
              {course.title}
            </Typography.Title>
          </Space>
        </Menu.Item>
        {course.modules.map((module, moduleIndex) => {
          return (
            <Menu.SubMenu
              key={generateModuleItemKey(module.id)}
              title={`${moduleIndex + 1}. ${module.title}`}
            >
              {module.lessons.map((lesson) => {
                return (
                  <Menu.Item
                    key={generateLessonItemKey(lesson.id)}
                    icon={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {convertLessonTypeToIcon(lesson.weblink)}
                      </div>
                    }
                  >
                    {lesson.title}
                  </Menu.Item>
                );
              })}
              {module.lessons.length === 0 && (
                <Menu.Item
                  key={generateAddLessonToModuleItemKey(module.id)}
                  disabled
                >
                  <Typography style={{ color: "gray" }}>
                    {locStrings.noLessonsAddedYet.value()}
                  </Typography>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          );
        })}
      </Menu>
    </Layout.Sider>
  );
};

const lessonSiderBarKeyPrefix = "InLessonSidebar-";
const addLessonSiderBarKeyPrefix = "InLessonSidebar-AddLesson-";

const generateCourseTitleItemKey = (courseId: string): string => {
  return `${lessonSiderBarKeyPrefix}${courseId}`;
};

const generateModuleItemKey = (moduleId: string): string => {
  return `${lessonSiderBarKeyPrefix}${moduleId}`;
};

const generateLessonItemKey = (lessonId: string): string => {
  return `${lessonSiderBarKeyPrefix}${lessonId}`;
};

const generateAddLessonToModuleItemKey = (moduleId: string): string => {
  return `${lessonSiderBarKeyPrefix}${addLessonSiderBarKeyPrefix}${moduleId}`;
};

const extractLessonIdFromItemKey = (lessonItemKey: string): string => {
  return lessonItemKey.replace(lessonSiderBarKeyPrefix, "");
};

const extractModuleIdFromAddLessonItemKey = (itemKey: string): string => {
  return itemKey
    .replace(lessonSiderBarKeyPrefix, "")
    .replace(addLessonSiderBarKeyPrefix, "");
};
