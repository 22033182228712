import { notification } from "antd";
import React from "react";
import { useCreateCourseSuggestionMutation } from "../../../../firestore/mutations/useCreateCourseSuggestionMutation";
import { locStrings } from "../../../../localization/locStrings";
import { zIndexPriorities } from "../../../../styling/styleConstants";
import { CourseSuggestionType } from "../../../../types/helperTypes";
import { useSisuModal } from "../../../../ui-hooks/useSisuModal";
import { convertCourseSuggestionTypeToCreateSelectionItemStrings } from "../../../../utils/iconAndStringUtils";
import { isValidCourseSuggestionWithErrorNotify } from "../../../../utils/validInputUtils";
import { GenericLoader } from "../../../common/GenericLoader";
import { ModalWithTitle } from "../../../common/ModalWithTitle";
import { CourseSuggestionCreateGeneralFeedback } from "./CourseSuggestionCreateGeneralFeedback";

interface ICourseSuggestionCreateGeneralFeedbackContainerProps {
  courseId: string;
  visible: boolean;
  onClose: () => void;
}

export const CourseSuggestionCreateGeneralFeedbackContainer: React.FunctionComponent<
  ICourseSuggestionCreateGeneralFeedbackContainerProps
> = (props) => {
  const { courseId, visible, onClose } = props;
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: createSuggestion, isLoading: isCreateSuggestionLoading } =
    useCreateCourseSuggestionMutation(courseId);

  const onCreateGeneralFeedbackSuggestion = async (vars: {
    reason: string;
  }) => {
    const type = CourseSuggestionType.GeneralFeedback;
    const { reason } = vars;
    const isValid = isValidCourseSuggestionWithErrorNotify().forCreate({
      type,
      reason,
    });

    if (isValid) {
      await createSuggestion({
        type,
        reason,
        suggestionData: {},
      });
      notification.success({
        message: locStrings.courseSuggestionCreatedSuccessNotification.value(),
      });
      onClose();
    } else {
      console.error(
        "CourseSuggestionCreateGeneralFeedbackContainer.onCreateGeneralFeedbackSuggestion, not valid"
      );
    }
  };

  if (isCreateSuggestionLoading) {
    return <GenericLoader />;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onClose}
      title={
        convertCourseSuggestionTypeToCreateSelectionItemStrings(
          CourseSuggestionType.GeneralFeedback
        ).title
      }
      subTitle={
        convertCourseSuggestionTypeToCreateSelectionItemStrings(
          CourseSuggestionType.GeneralFeedback
        ).subTitle
      }
      zIndex={zIndexPriorities.courseSuggestionGeneralFeedbackCreateModal}
    >
      <CourseSuggestionCreateGeneralFeedback
        onCreateGeneralFeedbackSuggestion={forceSisuModalFxnWrapper(
          onCreateGeneralFeedbackSuggestion
        )}
      />
    </ModalWithTitle>
  );
};
