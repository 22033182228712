import { Space, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";
import { themeColors } from "../../styling/styleConstants";

interface IVerifyEmailSuccessProps {
  userEmail: string;
}

export const VerifyEmailSuccess: React.FunctionComponent<
  IVerifyEmailSuccessProps
> = (props) => {
  const { userEmail } = props;

  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", alignItems: "center" }}
    >
      <Space style={{ marginBottom: "1rem" }} direction={"horizontal"}>
        <CheckCircleOutlined
          style={{ fontSize: "2rem", color: themeColors.successGreen }}
        />
        <PageTypography
          title={"Email sent!"}
          level={PageTypographyLevel.PageSection}
        />
      </Space>
      <Typography>
        {`An email has been sent to: ${userEmail}. Check your email for the verification link.`}
      </Typography>
    </Space>
  );
};
