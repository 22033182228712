import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { NotificationType } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { useGetCourseFetch } from "../queries/useGetCourseQuery";
import { settledLogger } from "../settledLogger";
import { useCreateNotificationMutation } from "./useCreateNotificationMutation";

export const useCreateCourseReviewRejectionMutation = (courseId: string) => {
  const { user } = useUserAuth();
  const { mutate: createNotification } = useCreateNotificationMutation();
  const getCourse = useGetCourseFetch();
  const createCourseReviewRejection = async (rejectionReason: string) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject(
        "useCreateCourseReviewRejectionMutation, error - no user found"
      );
    }
    const db = getFirestore();
    const courseReviewRejectionsCollectionRef = collection(
      db,
      collectionPaths.courseReviewRejections
    );
    const newCourseReviewRejection = await addDoc(
      courseReviewRejectionsCollectionRef,
      {
        rejectionReason,
        courseId,
        owner: user.uid,
        createdAt: serverTimestamp(),
      }
    );
    const courseData = await getCourse({ courseId });
    if (courseData) {
      courseData.owners.forEach((owner) => {
        if (owner !== user.uid) {
          createNotification({
            type: NotificationType.CourseReviewRejection,
            userId: owner,
            notificationData: {
              courseReviewRejectionId: newCourseReviewRejection.id,
            },
          });
        }
      });
    }
  };
  return useMutation(createCourseReviewRejection, {
    onSettled: settledLogger("useCreateCourseReviewRejectionMutation"),
  });
};
