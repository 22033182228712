import { useGetCourseQuery } from "../firestore/queries/useGetCourseQuery";
import { useUserAuth } from "./useUserAuth";

export const useIsMeUserOwnerOfCourse = (courseId: string) => {
  const { user } = useUserAuth();
  const { data: course } = useGetCourseQuery({ courseId });

  if (!user || !course) {
    return false;
  }

  return course.owners.includes(user.uid);
};
