import { notification } from "antd";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { locStrings } from "../../localization/locStrings";
import { IModule } from "../../types/firestoreMappings";
import { CourseCategory, CoursePublishState } from "../../types/helperTypes";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { generateUniqueId } from "../idHelpers";
import { listCoursesQueryKey } from "../queries/useListCoursesQuery";
import { settledLogger } from "../settledLogger";

export const useCreateCourseMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const createCourse = (vars: {
    title: string;
    description: string;
    categories: CourseCategory[];
    owners: string[];
  }) => {
    if (!user) {
      notification.error({
        message: locStrings.notSignedInRestriction.value(),
      });
      return Promise.reject("useCreateCourseMutation, error - no user found");
    }
    const { title, description, categories, owners } = vars;
    const db = getFirestore();
    const coursesCollectionRef = collection(db, collectionPaths.courses);
    const moduleId = generateUniqueId();
    const defaultFirstModule: IModule = {
      id: moduleId,
      title: "Untitled Module",
      lessons: [],
    };
    return addDoc(coursesCollectionRef, {
      title,
      description,
      publishState: CoursePublishState.Draft,
      owners,
      categories,
      modules: [defaultFirstModule],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };
  return useMutation(createCourse, {
    onSettled: settledLogger("useCreateCourseMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(
        listCoursesQueryKey({
          owner: user?.uid,
        }),
        { refetchInactive: true }
      );
    },
  });
};
