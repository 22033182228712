import {
  collection,
  documentId,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { convertUserSnapToIUser } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const listUsersByIdsQueryKey = (uids: string[]) => {
  const uidObj: { [uid: string]: boolean } = {};
  uids.forEach((uid) => {
    uidObj[uid] = true;
  });
  return ["listUsersByIdsQueryKey", uidObj];
};

export const useListUsersByIds = (uids: string[], enabled: boolean = true) => {
  const listUsersByIds = async () => {
    const db = getFirestore();
    const coursesCollectionRef = collection(db, collectionPaths.users);
    const q = query(coursesCollectionRef, where(documentId(), "in", uids));
    const qSnapshot = await getDocs(q);
    return qSnapshot.docs.map((snapshot) =>
      convertUserSnapToIUser(snapshot.id, snapshot.data())
    );
  };
  return useQuery(listUsersByIdsQueryKey(uids), listUsersByIds, {
    onSettled: settledLogger("useListUsersByIds"),
    enabled,
  });
};
