import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertCourseReviewRejectionSnapToICourseReviewRejection } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getCourseReviewRejectionQueryKey = (
  courseReviewRejectionId: string
) => ["getCourseReviewRejectionQueryKey", courseReviewRejectionId];

interface IGetCourseReviewRejectionArgs {
  courseReviewRejectionId: string;
}

const getCourseReviewRejection = async (
  vars: IGetCourseReviewRejectionArgs
) => {
  const { courseReviewRejectionId } = vars;
  const db = getFirestore();
  const courseReviewRejectionDocRef = doc(
    db,
    collectionPaths.courseReviewRejections,
    courseReviewRejectionId
  );
  const snapshot = await getDoc(courseReviewRejectionDocRef);
  if (snapshot.exists()) {
    return convertCourseReviewRejectionSnapToICourseReviewRejection(
      courseReviewRejectionId,
      snapshot.data()
    );
  } else {
    return Promise.reject(
      "getCourseReviewRejection, courseReviewRejectionId not found"
    );
  }
};

export const useGetCourseReviewRejectionQuery = (
  vars: IGetCourseReviewRejectionArgs,
  enabled: boolean = true
) => {
  const { courseReviewRejectionId } = vars;
  return useQuery(
    getCourseReviewRejectionQueryKey(courseReviewRejectionId),
    () => getCourseReviewRejection(vars),
    {
      onSettled: settledLogger("useGetCourseReviewRejectionQuery"),
      enabled,
    }
  );
};

export const useGetCourseReviewRejectionFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetCourseReviewRejectionArgs) => {
    const { courseReviewRejectionId } = vars;
    return queryClient.fetchQuery(
      getCourseReviewRejectionQueryKey(courseReviewRejectionId),
      () => getCourseReviewRejection(vars)
    );
  };
};
