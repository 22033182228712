import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertCourseCommentSnapToICourseComment } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getCourseCommentQueryKey = (courseCommentId: string) => [
  "getCourseCommentQueryKey",
  courseCommentId,
];

interface IGetCourseCommentArgs {
  courseCommentId: string;
}

const getCourseComment = async (vars: IGetCourseCommentArgs) => {
  const { courseCommentId } = vars;
  const db = getFirestore();
  const courseCommentDocRef = doc(
    db,
    collectionPaths.courseComments,
    courseCommentId
  );
  const snapshot = await getDoc(courseCommentDocRef);
  if (snapshot.exists()) {
    return convertCourseCommentSnapToICourseComment(
      courseCommentId,
      snapshot.data()
    );
  } else {
    return Promise.reject("getCourseComment, courseCommentId not found");
  }
};

export const useGetCourseCommentQuery = (
  vars: IGetCourseCommentArgs,
  enabled: boolean = true
) => {
  const { courseCommentId } = vars;
  return useQuery(
    getCourseCommentQueryKey(courseCommentId),
    () => getCourseComment(vars),
    {
      onSettled: settledLogger("useGetCourseCommentQuery"),
      enabled,
    }
  );
};

export const useGetCourseCommentFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetCourseCommentArgs) => {
    const { courseCommentId } = vars;
    return queryClient.fetchQuery(
      getCourseCommentQueryKey(courseCommentId),
      () => getCourseComment(vars)
    );
  };
};
