import React from "react";
import { HeaderNavBar } from "./HeaderNavBar";

interface IHeaderNavBarContainerProps {}

export const HeaderNavBarContainer: React.FunctionComponent<
  IHeaderNavBarContainerProps
> = (props) => {
  return <HeaderNavBar />;
};
