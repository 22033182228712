import { useListCourseReviewRejectionsQuery } from "../firestore/queries/useListCourseReviewRejectionsQuery";

export const useHasAnyCourseReviewRejections = (courseId: string) => {
  const { data: courseReviewRejections } = useListCourseReviewRejectionsQuery({
    courseId,
  });

  if (courseReviewRejections) {
    return courseReviewRejections.length > 0;
  }

  return false;
};
