import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styling/antd-theme-overrides.css";
// import "antd/dist/antd.min.css";
import App from "./App";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import FirebaseSdk from "./FirebaseSdk";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAC0PXvkI5d-cKJEKq4k3_iPzdNEaec94M",
  authDomain: "loa2-c2092.firebaseapp.com",
  projectId: "loa2-c2092",
  storageBucket: "loa2-c2092.appspot.com",
  messagingSenderId: "661260395855",
  appId: "1:661260395855:web:7f3c8051ea2f3853c6113a",
  measurementId: "G-1LP2VSXH75",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
FirebaseSdk.initApp(app);

// Initialize react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {
      //@ts-ignore
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    }
  </React.StrictMode>
);
