import { Button, Space, Typography } from "antd";
import React from "react";
import { ICourse, ICourseSuggestion } from "../../../types/firestoreMappings";
import { locStrings } from "../../../localization/locStrings";
import { NewCourseSuggestionButton } from "../../common/NewCourseSuggestionButton";
import { CourseSuggestion } from "./CourseSuggestion";
import {
  CourseSuggestionState,
  CourseSuggestionType,
} from "../../../types/helperTypes";
import { CourseSuggestionsFilterDropdown } from "./CourseSuggestionsFilterDropdown";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { FetchMoreButton } from "../../common/FetchMoreButton";

interface ICourseSuggestionsProps {
  course: ICourse;
  courseSuggestions: ICourseSuggestion[];
  fetchMoreCourseSuggestions: () => void;
  isNoMoreCourseSuggestionsToFetch: boolean;
  isSortingByUpvotes: boolean;
  stateFilter: CourseSuggestionState | undefined;
  typeFilter: CourseSuggestionType | undefined;
  toggleIsSortingByUpvotes: () => void;
  setStateFilter: (state: CourseSuggestionState | undefined) => void;
  setTypeFilter: (type: CourseSuggestionType | undefined) => void;
  clearAllFilters: () => void;
  hideFilters?: boolean;
  hideNewButtonOnEmptyState?: boolean;
}

export const CourseSuggestions: React.FunctionComponent<
  ICourseSuggestionsProps
> = (props) => {
  const {
    course,
    courseSuggestions,
    fetchMoreCourseSuggestions,
    isNoMoreCourseSuggestionsToFetch,
    isSortingByUpvotes,
    stateFilter,
    typeFilter,
    toggleIsSortingByUpvotes,
    setStateFilter,
    setTypeFilter,
    clearAllFilters,
    hideFilters,
    hideNewButtonOnEmptyState,
  } = props;

  const areAnyFiltersApplied =
    stateFilter !== undefined || typeFilter !== undefined;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <PageTypography
          title={"Suggestions"}
          level={PageTypographyLevel.PageSection}
        />
        {!hideFilters && courseSuggestions.length > 0 && (
          <CourseSuggestionsFilterDropdown
            isSortingByUpvotes={isSortingByUpvotes}
            stateFilter={stateFilter}
            typeFilter={typeFilter}
            toggleIsSortingByUpvotes={toggleIsSortingByUpvotes}
            setStateFilter={setStateFilter}
            setTypeFilter={setTypeFilter}
            clearAllFilters={clearAllFilters}
          />
        )}
      </div>
      {courseSuggestions.length === 0 &&
        (areAnyFiltersApplied ? (
          <Space direction={"vertical"}>
            <Typography.Text italic={true}>
              {"No suggestions match your filters"}
            </Typography.Text>
            <Button onClick={clearAllFilters}>{"Clear filters"}</Button>
          </Space>
        ) : (
          <Space direction={"vertical"}>
            <Typography.Text italic={true}>
              {"No suggestions have been added to this course yet"}
            </Typography.Text>
            {!hideNewButtonOnEmptyState && (
              <NewCourseSuggestionButton course={course} />
            )}
          </Space>
        ))}
      {courseSuggestions.map((courseSuggestion) => {
        return (
          <CourseSuggestion
            course={course}
            courseSuggestion={courseSuggestion}
            key={`CourseSuggestions-${courseSuggestion.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreSuggestions.value()}
        isNoMoreToFetch={isNoMoreCourseSuggestionsToFetch}
        fetchMore={fetchMoreCourseSuggestions}
      />
    </Space>
  );
};
