import { Button, Dropdown, Menu, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { locStrings } from "../../localization/locStrings";
import { convertCourseMetadataTypeToIcon } from "../../utils/iconAndStringUtils";
import { CourseSuggestionType, MetadataType } from "../../types/helperTypes";
import { LessonExerciseCreateModalContainer } from "../exercises/LessonExerciseCreateModalContainer";
import { LessonQuestionCreateModalContainer } from "../questions-answers/LessonQuestionCreateModalContainer";
import { ButtonType } from "antd/lib/button";
import { CourseSuggestionCreateNewModuleContainer } from "../course-suggestions/create/new-module/CourseSuggestionCreateNewModuleContainer";
import { CourseSuggestionCreateGeneralFeedbackContainer } from "../course-suggestions/create/general-feedback/CourseSuggestionCreateGeneralFeedbackContainer";
import { NewLinkModalContainer } from "../new-link-modal/NewLinkModalContainer";
import { ICourse } from "../../types/firestoreMappings";
import {
  calculateModuleAndLessonCountsInCourse,
  getModuleIdViaLessonIdFromCourse,
} from "../../utils/utils";
import { featureFlags } from "../../utils/appConstants";

interface INewCourseSuggestionButtonProps {
  course: ICourse;
  currentLessonId?: string;
  className?: string;
  buttonType?: ButtonType;
}

export const NewCourseSuggestionButton: React.FunctionComponent<
  INewCourseSuggestionButtonProps
> = (props) => {
  const { course, currentLessonId, className, buttonType } = props;
  const [isModalVisibleType, setIsModalVisibleType] = useState<
    MetadataType | undefined
  >(undefined);
  const closeModal = () => {
    setIsModalVisibleType(undefined);
  };
  const openModal = (metadataType: MetadataType) => {
    setIsModalVisibleType(metadataType);
  };

  const currentModuleId = getModuleIdViaLessonIdFromCourse(
    course,
    currentLessonId || ""
  );

  const moduleAndLessonCounts = calculateModuleAndLessonCountsInCourse(course);

  const menu = (
    <Menu>
      {moduleAndLessonCounts.modulesCount > 0 && (
        <Menu.Item
          // MetadataType.AlternativeLink is being used for New Link
          icon={convertCourseMetadataTypeToIcon({
            metadataType: MetadataType.AlternativeLink,
          })}
          onClick={() => openModal(MetadataType.AlternativeLink)}
        >
          <Typography>{"New Link"}</Typography>
        </Menu.Item>
      )}
      <Menu.Item
        icon={convertCourseMetadataTypeToIcon({
          courseSuggestionType: CourseSuggestionType.NewModule,
        })}
        onClick={() => openModal(MetadataType.CourseSuggestionNewModule)}
      >
        <Typography>{"New Module"}</Typography>
      </Menu.Item>
      {featureFlags.enableGeneralFeedbackSuggestion && (
        <Menu.Item
          icon={convertCourseMetadataTypeToIcon({
            courseSuggestionType: CourseSuggestionType.GeneralFeedback,
          })}
          onClick={() =>
            openModal(MetadataType.CourseSuggestionGeneralFeedback)
          }
        >
          <Typography>{"Give Feedback"}</Typography>
        </Menu.Item>
      )}
      {!!currentLessonId &&
        (featureFlags.enableLessonExercises ||
          featureFlags.enableLessonQuestionsAndAnswers) && (
          <Menu.SubMenu title={"For this link"}>
            {featureFlags.enableLessonExercises &&
              moduleAndLessonCounts.lessonsCount > 0 && (
                <Menu.Item
                  icon={convertCourseMetadataTypeToIcon({
                    metadataType: MetadataType.LessonExercise,
                  })}
                  onClick={() => openModal(MetadataType.LessonExercise)}
                >
                  <Typography>{"New Exercise"}</Typography>
                </Menu.Item>
              )}
            {featureFlags.enableLessonQuestionsAndAnswers &&
              moduleAndLessonCounts.lessonsCount > 0 && (
                <Menu.Item
                  icon={convertCourseMetadataTypeToIcon({
                    metadataType: MetadataType.LessonQuestion,
                  })}
                  onClick={() => openModal(MetadataType.LessonQuestion)}
                >
                  <Typography>{"New Question"}</Typography>
                </Menu.Item>
              )}
          </Menu.SubMenu>
        )}
    </Menu>
  );

  return (
    <>
      {/* @ts-ignore */}
      <Dropdown overlay={menu}>
        <Button
          icon={<PlusOutlined />}
          className={className}
          type={buttonType || "default"}
        >
          {locStrings.newCourseSuggestionButtonText.value()}
        </Button>
      </Dropdown>
      <NewLinkModalContainer
        visible={isModalVisibleType === MetadataType.AlternativeLink}
        onClose={closeModal}
        courseId={course.id}
        currentLessonId={currentLessonId}
        currentModuleId={currentModuleId}
      />
      <CourseSuggestionCreateNewModuleContainer
        visible={isModalVisibleType === MetadataType.CourseSuggestionNewModule}
        onClose={closeModal}
        courseId={course.id}
      />
      {featureFlags.enableGeneralFeedbackSuggestion && (
        <CourseSuggestionCreateGeneralFeedbackContainer
          visible={
            isModalVisibleType === MetadataType.CourseSuggestionGeneralFeedback
          }
          onClose={closeModal}
          courseId={course.id}
        />
      )}
      {featureFlags.enableLessonExercises && (
        <LessonExerciseCreateModalContainer
          visible={isModalVisibleType === MetadataType.LessonExercise}
          onClose={closeModal}
          courseId={course.id}
          defaultLessonId={currentLessonId}
        />
      )}
      {featureFlags.enableLessonQuestionsAndAnswers && (
        <LessonQuestionCreateModalContainer
          visible={isModalVisibleType === MetadataType.LessonQuestion}
          onClose={closeModal}
          courseId={course.id}
          defaultLessonId={currentLessonId}
        />
      )}
    </>
  );
};
