import React, { useState } from "react";
import { MetadataType } from "../../types/helperTypes";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { useGetUpvoteQuery } from "../../firestore/queries/useGetUpvoteQuery";
import { useCreateUpvoteMutation } from "../../firestore/mutations/useCreateUpvoteMutation";
import { useDeleteUpvoteMutation } from "../../firestore/mutations/useDeleteUpvoteMutation";
import { UpvoteButton } from "./UpvoteButton";
import { useSisuModal } from "../../ui-hooks/useSisuModal";

interface IUpvoteButtonContainerProps {
  upvoteCount: number;
  metadataId: string;
  metadataType: MetadataType;
}

export const UpvoteButtonContainer: React.FunctionComponent<
  IUpvoteButtonContainerProps
> = (props) => {
  const { upvoteCount, metadataId, metadataType } = props;
  const [optimisticUpvoteCount, setOptimisticUpvoteCount] =
    useState<number>(upvoteCount);
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const {
    data: upvote,
    isLoading: isUpvoteLoading,
    isError: isUpvoteError,
  } = useGetUpvoteQuery({ metadataId });
  const { mutate: createUpvote, isLoading: isCreateUpvoteLoading } =
    useCreateUpvoteMutation(metadataId, metadataType);
  const { mutate: deleteUpvote, isLoading: isDeleteUpvoteLoading } =
    useDeleteUpvoteMutation(metadataId);

  if (
    isUpvoteLoading ||
    !upvote ||
    isCreateUpvoteLoading ||
    isDeleteUpvoteLoading
  ) {
    return <GenericLoader />;
  }

  if (isUpvoteError) {
    return <GenericError />;
  }

  const doesUpvoteDocExist = !upvote.doesNotExist;
  const isExistingVoteDown = !!upvote.isDownvote;

  const onCreateVote = (isDownvote?: boolean) => {
    if (doesUpvoteDocExist) {
      deleteUpvote(undefined, {
        onSuccess: () => {
          createUpvote(
            { isDownvote },
            {
              onSuccess: () => {
                // Double because this accounts for removal of old and addition of new
                setOptimisticUpvoteCount(
                  optimisticUpvoteCount + (isDownvote ? -2 : 2)
                );
              },
            }
          );
        },
      });
    } else {
      createUpvote(
        { isDownvote },
        {
          onSuccess: () => {
            setOptimisticUpvoteCount(
              optimisticUpvoteCount + (isDownvote ? -1 : 1)
            );
          },
        }
      );
    }
  };

  const onRemoveVote = () => {
    deleteUpvote(undefined, {
      onSuccess: () => {
        setOptimisticUpvoteCount(
          optimisticUpvoteCount + (upvote.isDownvote ? 1 : -1)
        );
      },
    });
  };

  const onUpvoteClick = forceSisuModalFxnWrapper(
    !doesUpvoteDocExist || isExistingVoteDown
      ? () => onCreateVote(false)
      : onRemoveVote
  );
  const onDownvoteClick = forceSisuModalFxnWrapper(
    !doesUpvoteDocExist || !isExistingVoteDown
      ? () => onCreateVote(true)
      : onRemoveVote
  );

  return (
    <UpvoteButton
      upvoteCount={optimisticUpvoteCount}
      doesUpvoteDocExist={doesUpvoteDocExist}
      isExistingVoteDown={isExistingVoteDown}
      onUpvoteClick={onUpvoteClick}
      onDownvoteClick={onDownvoteClick}
    />
  );
};
