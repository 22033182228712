import React from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as signUserOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  User,
  onAuthStateChanged,
  getAuth,
  GoogleAuthProvider,
  UserCredential,
  getAdditionalUserInfo,
} from "firebase/auth";
import { useCreateUserMutation } from "../firestore/mutations/useCreateUserMutation";
import { notification } from "antd";
import { locStrings } from "../localization/locStrings";
import { BASE_URL } from "../utils/appConstants";
import { baseUrlWithPath } from "./useNavigation";
import LocalStorageBase from "../local-storage/LocalStorageBase";
import { userFieldLimitations } from "../utils/fieldLimitations";

const googleProvider = new GoogleAuthProvider();

// Only need to do this once globally
// This fixes firestore permission issues after email verification
let hasRefreshedUserStateForEmailVerification = false;

export const useUserAuth = () => {
  const auth = getAuth();
  const { mutate: createUser } = useCreateUserMutation();
  const [user, setUser] = React.useState<User | null>(null);
  const isSignedIn = !!user;
  const isEmailVerified = user?.emailVerified;

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        if (isEmailVerified && !hasRefreshedUserStateForEmailVerification) {
          hasRefreshedUserStateForEmailVerification = true;
          console.log(
            `useUserAuth.onAuthStateChanged, reloading user for email verification`,
            user
          );
          user.reload();
        }
      } else {
        setUser(null);
      }
    });
  }, [auth, isEmailVerified]);

  const signUp = React.useCallback(
    async (username: string, email: string, password: string) => {
      const signUpResult = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (signUpResult.user) {
        await createUser({ userId: signUpResult.user.uid, username });
        LocalStorageBase.setEmailLastUsed(email);
        notification.success({
          message: locStrings.sisuSignUpMessage.value(username),
        });
      }
    },
    [auth, createUser]
  );

  const signIn = React.useCallback(
    async (email: string, password: string) => {
      await signInWithEmailAndPassword(auth, email, password);
      LocalStorageBase.setEmailLastUsed(email);
      notification.success({ message: locStrings.sisuSignInMessage.value() });
    },
    [auth]
  );

  const signInWithGoogle = React.useCallback(async () => {
    const userCredential = await signInWithPopup(auth, googleProvider);
    console.log("signed in with G");
    if (userCredential.user) {
      const additionalUserInfo = getAdditionalUserInfo(userCredential);
      if (additionalUserInfo?.isNewUser) {
        const username = generateDefaultGoogleSignupUsername(userCredential);
        await createUser({
          userId: userCredential.user.uid,
          username,
        });
      }
      if (userCredential.user.email) {
        LocalStorageBase.setEmailLastUsed(userCredential.user.email);
      }
      console.log("notifying for G");
      notification.success({
        message: "You're signed in!",
      });
    }
  }, [auth, createUser]);

  const signOut = React.useCallback(async () => {
    await signUserOut(auth);
    notification.success({ message: locStrings.sisuSignOutMessage.value() });
  }, [auth]);

  const resetPassword = React.useCallback(
    async (email: string) => {
      await sendPasswordResetEmail(auth, email, {
        url: BASE_URL,
      });
    },
    [auth]
  );

  const verifyEmail = React.useCallback(async () => {
    if (user) {
      await sendEmailVerification(user, {
        url: baseUrlWithPath.home(),
      });
    } else {
      notification.error({
        message: "You must sign in to send an account verification email",
      });
    }
  }, [user]);

  return {
    user,
    isSignedIn,
    isEmailVerified,
    signInWithGoogle,
    signIn,
    signUp,
    signOut,
    resetPassword,
    verifyEmail,
  };
};

const generateDefaultGoogleSignupUsername = (
  userCredential: UserCredential
) => {
  let username = "user000";

  // Try to extract something from Google info
  if (userCredential.user.displayName) {
    username = userCredential.user.displayName;
  } else if (userCredential.user.email) {
    username = userCredential.user.email.slice(0, -10);
  }

  // Then cut it down to size
  if (username.length > userFieldLimitations.usernameMaxLength) {
    username = username.slice(0, userFieldLimitations.usernameMaxLength - 1);
  }
  if (username.length < userFieldLimitations.usernameMinLength) {
    username += "000";
  }

  return username;
};
