import React, { useState } from "react";
import LocalStorageBase from "../../local-storage/LocalStorageBase";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import {
  firebaseAuthErrorWithNotify,
  isValidSignInInputWithErrorNotify,
} from "../../utils/validInputUtils";
import { SignIn } from "./SignIn";

interface ISignInContainerProps {}

export const SignInContainer: React.FunctionComponent<ISignInContainerProps> = (
  props
) => {
  const { closeSisuModal } = useSisuModal();
  const { signIn } = useUserAuth();
  const emailLastUsed = LocalStorageBase.getEmailLastUsed();
  const [email, setEmail] = useState<string>(emailLastUsed || "");
  const [password, setPassword] = useState<string>("");

  const resetInputs = () => {
    setEmail("");
    setPassword("");
  };
  const onCloseSisuModal = () => {
    resetInputs();
    closeSisuModal();
  };
  const onSignIn = async () => {
    const isValid = isValidSignInInputWithErrorNotify({ email, password });
    if (isValid) {
      try {
        await signIn(email, password);
        onCloseSisuModal();
      } catch (error: any) {
        firebaseAuthErrorWithNotify(error);
      }
    }
  };

  return (
    <SignIn
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
      onSignIn={onSignIn}
    />
  );
};
