import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useGetCourseQuery } from "../../firestore/queries/useGetCourseQuery";
import { zIndexPriorities } from "../../styling/styleConstants";
import { useIsMeUserOwnerOfCourse } from "../../ui-hooks/useIsMeUserOwnerOfCourse";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { GenericError } from "../common/GenericError";
import { GenericLoader } from "../common/GenericLoader";
import { NewLinkTypeSelection } from "./NewLinkTypeSelection";
import { calculateModuleAndLessonCountsInCourse } from "../../utils/utils";
import { NewLinkWeblinkEntry } from "./NewLinkWeblinkEntry";
import { useGetWeblinkPreviewFetch } from "../../firestore/queries/useGetWeblinkPreviewQuery";
import { NewLinkDetailsEdit } from "./NewLinkDetailsEdit";
import { NewLinkBrandNew } from "./NewLinkBrandNew";
import { NewLinkAlternative } from "./NewLinkAlternative";
import { useCreateCourseSuggestionMutation } from "../../firestore/mutations/useCreateCourseSuggestionMutation";
import {
  isValidCourseSuggestionWithErrorNotify,
  isValidLessonAlternativeLinkWithErrorNotify,
  isValidLessonInputWithErrorNotify,
} from "../../utils/validInputUtils";
import {
  CourseSuggestionState,
  CourseSuggestionType,
  LessonPlacementInCourse,
} from "../../types/helperTypes";
import { locStrings } from "../../localization/locStrings";
import { useCreateLessonMutation } from "../../firestore/mutations/useCreateLessonMutation";
import { useCreateLessonAlternativeLinkMutation } from "../../firestore/mutations/useCreateLessonAlternativeLinkMutation";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { ModalWithTitle } from "../common/ModalWithTitle";
import { useGetCourseSuggestionQuery } from "../../firestore/queries/useGetCourseSuggestionQuery";
import { useUpdateCourseSuggestionMutation } from "../../firestore/mutations/useUpdateCourseSuggestionMutation";

export enum NewLinkCreationPhase {
  WeblinkEntry = "WeblinkEntry",
  DetailsEdit = "DetailsEdit",
  TypeSelection = "TypeSelection",
  BrandNew = "BrandNew",
  Alternative = "Alternative",
}

interface INewLinkModalContainerProps {
  courseId: string;
  visible: boolean;
  onClose: () => void;
  currentModuleId?: string;
  currentLessonId?: string;
  courseSuggestionId?: string;
}

const defaultFirstPhase = NewLinkCreationPhase.WeblinkEntry;

export const NewLinkModalContainer: React.FunctionComponent<
  INewLinkModalContainerProps
> = (props) => {
  const {
    courseId,
    visible,
    onClose,
    currentModuleId,
    currentLessonId,
    courseSuggestionId,
  } = props;
  const isMeUserOwnerOfCourse = useIsMeUserOwnerOfCourse(courseId);
  const nav = useNavigation();
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const [creationPhaseStack, setCreationPhaseStack] = useState<
    NewLinkCreationPhase[]
  >([defaultFirstPhase]);
  const [weblink, setWeblink] = useState<string>("");
  const [isLoadingWeblinkPreview, setIsLoadingWeblinkPreview] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [previewImageUrl, setPreviewImageUrl] = useState<string | undefined>();
  const [lessonId, setLessonId] = useState<string>(currentLessonId || "");
  const { data: courseSuggestion, isLoading: isCourseSuggestionLoading } =
    useGetCourseSuggestionQuery(
      {
        courseSuggestionId: courseSuggestionId || "",
      },
      !!courseSuggestionId && visible
    );
  const { mutate: updateCourseSuggestion } = useUpdateCourseSuggestionMutation(
    courseSuggestionId || "",
    courseId
  );
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
  } = useGetCourseQuery({ courseId });
  const { mutate: createSuggestion, isLoading: isCreateSuggestionLoading } =
    useCreateCourseSuggestionMutation(courseId);
  const { mutate: createLesson, isLoading: isCreateLessonLoading } =
    useCreateLessonMutation(courseId);
  const {
    mutate: createAlternativeLink,
    isLoading: isCreateAlternativeLinkLoading,
  } = useCreateLessonAlternativeLinkMutation(courseId, lessonId);
  const getWeblinkPreview = useGetWeblinkPreviewFetch();

  // Update current ids when switching between links InLesson view
  useEffect(() => {
    if (currentLessonId) {
      setLessonId(currentLessonId);
    }
  }, [currentModuleId, currentLessonId]);

  // Load up suggestion data
  useEffect(() => {
    if (courseSuggestion && visible) {
      if (courseSuggestion.lessonTitle) {
        setTitle(courseSuggestion.lessonTitle);
      }
      if (courseSuggestion.reason) {
        setDescription(courseSuggestion.reason);
      }
      if (courseSuggestion.lessonWeblink) {
        setWeblink(courseSuggestion.lessonWeblink);
      }
      if (courseSuggestion.lessonPreviewImageUrl) {
        setPreviewImageUrl(courseSuggestion.lessonPreviewImageUrl);
      }
    }
  }, [courseSuggestion, visible]);

  const currentCreationPhase =
    creationPhaseStack[creationPhaseStack.length - 1];

  const goBackToPreviousCreationPhase = () => {
    setCreationPhaseStack(
      creationPhaseStack.slice(0, creationPhaseStack.length - 1)
    );
  };

  const goToNextCreationPhase = (phase: NewLinkCreationPhase) => {
    setCreationPhaseStack(creationPhaseStack.concat([phase]));
  };

  const onCloseModalAndResetFields = () => {
    setCreationPhaseStack([defaultFirstPhase]);
    setIsLoadingWeblinkPreview(false);
    setWeblink("");
    setTitle("");
    setDescription("");
    setPreviewImageUrl(undefined);
    setLessonId(currentLessonId || "");
    onClose();
  };

  if (isCourseError) {
    return <GenericError />;
  }

  if (
    isCourseLoading ||
    isCreateSuggestionLoading ||
    isCreateLessonLoading ||
    isCreateAlternativeLinkLoading ||
    isCourseSuggestionLoading ||
    !course
  ) {
    return <GenericLoader />;
  }

  const onCreateNewLessonSuggestion = (
    lessonPlacementInCourse: LessonPlacementInCourse
  ) => {
    const type = CourseSuggestionType.NewLesson;
    const isValid = isValidCourseSuggestionWithErrorNotify().forCreate({
      type,
      reason: description,
      lessonTitle: title,
      lessonWeblink: weblink,
      lessonPreviewImageUrl: previewImageUrl,
      moduleId: lessonPlacementInCourse.moduleId,
    });

    if (isValid) {
      createSuggestion(
        {
          type,
          reason: description,
          suggestionData: {
            lessonTitle: title,
            lessonWeblink: weblink,
            lessonPreviewImageUrl: previewImageUrl,
            moduleId: lessonPlacementInCourse.moduleId,
            prevLessonId: lessonPlacementInCourse.prevLessonId,
            nextLessonId: lessonPlacementInCourse.nextLessonId,
          },
        },
        {
          onSuccess: () => {
            notification.success({
              message:
                locStrings.courseSuggestionCreatedSuccessNotification.value(),
            });
            onCloseModalAndResetFields();
          },
        }
      );
    } else {
      console.error(
        "NewLinkModalContainer.onCreateNewLessonSuggestion, not valid"
      );
    }
  };

  const onCreateLesson = (lessonPlacementInCourse: LessonPlacementInCourse) => {
    const isValid = isValidLessonInputWithErrorNotify().forCreate({
      selectedModuleId: lessonPlacementInCourse.moduleId,
      title,
      weblink,
      description,
      previewImageUrl,
    });

    if (isValid) {
      createLesson({
        course,
        selectedModuleId: lessonPlacementInCourse.moduleId,
        title,
        description,
        weblink,
        prevLessonId: lessonPlacementInCourse.prevLessonId,
        nextLessonId: lessonPlacementInCourse.nextLessonId,
        previewImageUrl,
        createdFromSuggestionId: !!courseSuggestion
          ? courseSuggestion.id
          : undefined,
        onSuccess: () => {
          if (!!courseSuggestion) {
            updateCourseSuggestion({ state: CourseSuggestionState.Accepted });
          }
          onCloseModalAndResetFields();
        },
      });
    } else {
      console.error("NewLinkModalContainer.onCreateLesson, not valid");
    }
  };

  const onCreateAlternativeLink = () => {
    const isValid = isValidLessonAlternativeLinkWithErrorNotify().forCreate({
      lessonId,
      weblink,
      reason: description,
      previewImageUrl,
    });

    if (isValid) {
      createAlternativeLink(
        {
          weblink,
          reason: description,
          previewImageUrl,
          createdFromSuggestionId: !!courseSuggestion
            ? courseSuggestion.id
            : undefined,
        },
        {
          onSuccess: (result) => {
            if (!!courseSuggestion) {
              updateCourseSuggestion({ state: CourseSuggestionState.Accepted });
            }
            nav.goToPaths.inLesson(courseId, lessonId, {
              lessonAlternativeLinkId: result.id,
            });
            notification.success({ message: "Alternative link submitted" });
            onCloseModalAndResetFields();
          },
        }
      );
    } else {
      console.error("NewLinkModalContainer.onCreateAlternativeLink, not valid");
    }
  };

  const onWeblinkEntryNext = async () => {
    setIsLoadingWeblinkPreview(true);
    const weblinkPreview = await getWeblinkPreview({ weblink });
    if (!courseSuggestionId || !title) {
      setTitle(weblinkPreview.title);
    }

    if (weblinkPreview.description && (!courseSuggestionId || !description)) {
      setDescription(weblinkPreview.description);
    }
    if (weblinkPreview.previewImageUrl) {
      setPreviewImageUrl(weblinkPreview.previewImageUrl);
    }
    setIsLoadingWeblinkPreview(false);
    goToNextCreationPhase(NewLinkCreationPhase.DetailsEdit);
  };

  const onDetailsEditNext = () => {
    const moduleAndLessonCounts =
      calculateModuleAndLessonCountsInCourse(course);
    // If there's no existing links, then skip type selection
    if (moduleAndLessonCounts.lessonsCount === 0) {
      onBrandNewNext();
    } else {
      goToNextCreationPhase(NewLinkCreationPhase.TypeSelection);
    }
  };

  const onBrandNewNext = () => {
    goToNextCreationPhase(NewLinkCreationPhase.BrandNew);
  };

  const onAlternativeNext = () => {
    goToNextCreationPhase(NewLinkCreationPhase.Alternative);
  };

  let contentToRender: JSX.Element | null = null;
  let modalSubtitle: string = "";
  switch (currentCreationPhase) {
    case NewLinkCreationPhase.WeblinkEntry:
      contentToRender = (
        <NewLinkWeblinkEntry
          weblink={weblink}
          setWeblink={setWeblink}
          isLoadingWeblinkPreview={isLoadingWeblinkPreview}
          onNext={onWeblinkEntryNext}
        />
      );
      modalSubtitle = "Let's start with a new link";
      break;
    case NewLinkCreationPhase.DetailsEdit:
      contentToRender = (
        <NewLinkDetailsEdit
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          onNext={onDetailsEditNext}
          previewImageUrl={previewImageUrl}
        />
      );
      modalSubtitle = "Edit and review your link's details";
      break;
    case NewLinkCreationPhase.TypeSelection:
      contentToRender = (
        <NewLinkTypeSelection
          onBrandNewNext={onBrandNewNext}
          onAlternativeNext={onAlternativeNext}
        />
      );
      modalSubtitle = "Brand new or an alternative?";
      break;
    case NewLinkCreationPhase.BrandNew:
      contentToRender = (
        <NewLinkBrandNew
          course={course}
          onSubmit={
            isMeUserOwnerOfCourse
              ? forceSisuModalFxnWrapper(onCreateLesson)
              : forceSisuModalFxnWrapper(onCreateNewLessonSuggestion)
          }
        />
      );
      modalSubtitle = "Where should your link go?";
      break;
    case NewLinkCreationPhase.Alternative:
      contentToRender = (
        <NewLinkAlternative
          courseId={courseId}
          lessonId={lessonId}
          setLessonId={setLessonId}
          onSubmit={forceSisuModalFxnWrapper(onCreateAlternativeLink)}
        />
      );
      modalSubtitle = "Select an existing link for your alternative";
      break;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onCloseModalAndResetFields}
      title={"New Link"}
      subTitle={modalSubtitle}
      weblinkSubTitle={
        currentCreationPhase !== NewLinkCreationPhase.WeblinkEntry
          ? weblink
          : undefined
      }
      onBack={
        creationPhaseStack.length > 1
          ? goBackToPreviousCreationPhase
          : undefined
      }
      zIndex={zIndexPriorities.newLinkModal}
    >
      {contentToRender}
    </ModalWithTitle>
  );
};
