import { notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useCreateLessonExerciseMutation } from "../../firestore/mutations/useCreateLessonExerciseMutation";
import { locStrings } from "../../localization/locStrings";
import { zIndexPriorities } from "../../styling/styleConstants";
import { useSisuModal } from "../../ui-hooks/useSisuModal";
import { isValidLessonExerciseInputWithErrorNotify } from "../../utils/validInputUtils";
import { GenericLoader } from "../common/GenericLoader";
import { ModalWithTitle } from "../common/ModalWithTitle";
import { LessonExerciseEdit } from "./LessonExerciseEdit";

interface ILessonExerciseCreateModalContainerProps {
  visible: boolean;
  onClose: () => void;
  courseId: string;
  defaultLessonId?: string;
}

export const LessonExerciseCreateModalContainer: React.FunctionComponent<
  ILessonExerciseCreateModalContainerProps
> = (props) => {
  const { visible, onClose, courseId, defaultLessonId } = props;
  const defaultLessonIdRef = useRef(defaultLessonId);
  const [lessonId, setLessonId] = useState<string>(defaultLessonId || "");
  const [exercise, setExercise] = useState<string>("");
  const { forceSisuModalFxnWrapper } = useSisuModal();
  const { mutate: createExercise, isLoading: isCreateExerciseLoading } =
    useCreateLessonExerciseMutation(courseId, lessonId);

  useEffect(() => {
    if (defaultLessonIdRef.current !== defaultLessonId) {
      defaultLessonIdRef.current = defaultLessonId;
      setLessonId(defaultLessonId || "");
    }
  }, [defaultLessonId]);

  const onCreateExercise = async () => {
    const isValid = isValidLessonExerciseInputWithErrorNotify().forCreate({
      exercise,
    });

    if (isValid) {
      try {
        await createExercise(exercise);
        notification.success({ message: "Exercise created" });
        onClose();
      } catch (error) {
        notification.error({ message: locStrings.genericErrorMessage.value() });
      }
    }
  };

  if (isCreateExerciseLoading) {
    return <GenericLoader />;
  }

  return (
    <ModalWithTitle
      visible={visible}
      onClose={onClose}
      title={"New Exercise"}
      subTitle={"Suggest a new exercise that would benefit a link"}
      zIndex={zIndexPriorities.lessonExerciseCreateModal}
    >
      <LessonExerciseEdit
        courseId={courseId}
        onSubmit={forceSisuModalFxnWrapper(onCreateExercise)}
        lessonId={lessonId}
        exercise={exercise}
        onExerciseChange={setExercise}
        onLessonIdChange={setLessonId}
      />
    </ModalWithTitle>
  );
};
