import { Collapse, Space } from "antd";
import React, { useState } from "react";
import { themeColors } from "../../styling/styleConstants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ConfirmButton } from "../common/ConfirmButton";
import { RichTextEditor } from "../common/RichTextEditor";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { CoursePublishState } from "../../types/helperTypes";

interface ICourseInfoReviewProps {
  publishState: CoursePublishState;
  onReject: (rejectionReason: string) => void;
  onPublish: () => void;
}

const reviewCollapseKey = "reviewCollapseKey";

export const CourseInfoReview: React.FunctionComponent<
  ICourseInfoReviewProps
> = (props) => {
  const { publishState, onReject, onPublish } = props;
  const nav = useNavigation();
  const isReviewMode = nav.urlSearchParams.isReviewMode() === "true";
  const [isVisible, setIsVisible] = useState<boolean>(isReviewMode);
  const [rejectionReason, setRejectionReason] = useState<string>("");

  return (
    // @ts-ignore
    <Collapse
      activeKey={isVisible ? reviewCollapseKey : ""}
      onChange={(panelKey) => setIsVisible(!isVisible)}
      style={{ maxWidth: "50rem" }}
    >
      {
        // @ts-ignore
        <Collapse.Panel
          key={reviewCollapseKey}
          header={"Reviewer Options"}
          style={{ backgroundColor: themeColors.publishStateInReviewOrange }}
        >
          <Space direction={"vertical"} style={{ width: "100%" }}>
            <Space direction={"horizontal"}>
              <ConfirmButton
                onConfirm={() => onReject(rejectionReason)}
                type={"primary"}
                text={
                  publishState === CoursePublishState.InReview
                    ? "Reject"
                    : publishState === CoursePublishState.Published
                    ? "Revert to Draft"
                    : "UNKONWN"
                }
                icon={<CloseOutlined />}
              />
              {publishState === CoursePublishState.InReview && (
                <ConfirmButton
                  onConfirm={onPublish}
                  type={"primary"}
                  text={"Approve and Publish"}
                  icon={<CheckOutlined />}
                />
              )}
            </Space>
            <RichTextEditor
              initialValue={rejectionReason}
              onHtmlStringChange={setRejectionReason}
              placeholder={
                "Provide a reason for rejecting this course so that the course creators can improve it"
              }
            />
          </Space>
        </Collapse.Panel>
      }
    </Collapse>
  );
};
