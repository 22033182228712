import React from "react";
import { Explore } from "./Explore";

interface IExploreContainerProps {}

export const ExploreContainer: React.FunctionComponent<
  IExploreContainerProps
> = (props) => {
  return <Explore />;
};
