import { Button, Space, Collapse, Typography, Divider } from "antd";
import {
  UpSquareFilled,
  DownSquareFilled,
  UpSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons";
import React, { useMemo } from "react";
import { locStrings } from "../../../localization/locStrings";
import { PAGE_SECTION_SPACING } from "../../../styling/styleConstants";
import { ICourse } from "../../../types/firestoreMappings";
import { ReorderModeConfig } from "../../../types/helperTypes";
import { ClickableWrapper } from "../../common/ClickableWrapper";
import { LayoutContent } from "../../common/LayoutContent";
import { PageTypography, PageTypographyLevel } from "../../common/PageTitle";
import { CourseStructureLessonItem } from "../course-structure/CourseStructureLessonItem";

interface ICourseReorderProps {
  course: ICourse;
  reorderModeConfig: ReorderModeConfig;
  onSubmit: () => void;
}

const generateModuleCollapseKey = (moduleId: string) =>
  `CourseReorder-Module-${moduleId}`;

const reorderArrowIconSize = "25px";

export const CourseReorder: React.FunctionComponent<ICourseReorderProps> = (
  props
) => {
  const { course, reorderModeConfig, onSubmit } = props;
  const allModuleKeys = useMemo(() => {
    return course.modules.map((module) => generateModuleCollapseKey(module.id));
  }, [course]);
  return (
    <LayoutContent>
      <Space direction={"vertical"} style={{ flex: 1 }}>
        <PageTypography
          title={locStrings.reorderCoursePageTitle.value()}
          level={PageTypographyLevel.PageTitle}
          style={{ marginBottom: PAGE_SECTION_SPACING }}
          isGoBackEnabled={true}
        />
        {
          // @ts-ignore
          <Collapse activeKey={allModuleKeys} collapsible={"header"}>
            {course.modules.map((module, moduleIndex) => {
              const isFirstModule = moduleIndex === 0;
              const isLastModule = moduleIndex === course.modules.length - 1;
              return (
                // @ts-ignore
                <Collapse.Panel
                  key={generateModuleCollapseKey(module.id)}
                  showArrow={false}
                  header={
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {`${moduleIndex + 1}. ${module.title}`}
                    </Typography>
                  }
                  extra={
                    <div>
                      {!isFirstModule && (
                        <ClickableWrapper
                          onClick={() =>
                            reorderModeConfig.onMoveUpModule(module.id)
                          }
                        >
                          <UpSquareFilled
                            style={{ fontSize: reorderArrowIconSize }}
                          />
                        </ClickableWrapper>
                      )}
                      {!isLastModule && (
                        <ClickableWrapper
                          onClick={() =>
                            reorderModeConfig.onMoveDownModule(module.id)
                          }
                        >
                          <DownSquareFilled
                            style={{ fontSize: reorderArrowIconSize }}
                          />
                        </ClickableWrapper>
                      )}
                    </div>
                  }
                >
                  <Space direction={"vertical"} style={{ display: "flex" }}>
                    {module.lessons.length > 0 ? (
                      module.lessons.map((lesson, lessonIndex) => {
                        const isLastLessonInModule =
                          lessonIndex === module.lessons.length - 1;
                        const isFirstLessonOfFirstModule =
                          moduleIndex === 0 && lessonIndex === 0;
                        const isLastLessonOfLastModule =
                          moduleIndex === course.modules.length - 1 &&
                          lessonIndex === module.lessons.length - 1;
                        return (
                          <div
                            key={`CourseReorder-Lesson-${lesson.id}`}
                            style={{ width: "100%" }}
                          >
                            <CourseStructureLessonItem
                              lessonTitle={lesson.title}
                              lessonWeblink={lesson.weblink}
                              previewImageUrl={lesson.previewImageUrl}
                              rightEl={
                                <div>
                                  {!isFirstLessonOfFirstModule && (
                                    <ClickableWrapper
                                      onClick={() =>
                                        reorderModeConfig.onMoveUpLesson(
                                          lesson.id
                                        )
                                      }
                                    >
                                      <UpSquareOutlined
                                        style={{
                                          fontSize: reorderArrowIconSize,
                                        }}
                                      />
                                    </ClickableWrapper>
                                  )}
                                  {!isLastLessonOfLastModule && (
                                    <ClickableWrapper
                                      onClick={() =>
                                        reorderModeConfig.onMoveDownLesson(
                                          lesson.id
                                        )
                                      }
                                    >
                                      <DownSquareOutlined
                                        style={{
                                          fontSize: reorderArrowIconSize,
                                        }}
                                      />
                                    </ClickableWrapper>
                                  )}
                                </div>
                              }
                              tabs={[]}
                            />
                            {!isLastLessonInModule && <Divider />}
                          </div>
                        );
                      })
                    ) : (
                      <Typography.Link type={"secondary"} disabled={true}>
                        {locStrings.noLessonsAddedYet.value()}
                      </Typography.Link>
                    )}
                  </Space>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        }
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onSubmit} type={"primary"}>
            {locStrings.submit.value()}
          </Button>
        </div>
      </Space>
    </LayoutContent>
  );
};
