import { Space } from "antd";
import React from "react";
import {
  ICourse,
  ICourseSuggestion,
} from "../../../../types/firestoreMappings";
import { MetadataType } from "../../../../types/helperTypes";
import { getModuleFromCourse } from "../../../../utils/utils";
import { TypographyBold } from "../../../common/TypographyBold";
import { CourseStructureLessonItem } from "../../../course/course-structure/CourseStructureLessonItem";
import { UpvoteButtonContainer } from "../../../upvotes/UpvoteButtonContainer";

interface ICourseSuggestionNewLessonProps {
  course: ICourse;
  suggestion: ICourseSuggestion;
  lessonTitle: string;
  lessonWeblink: string;
  previewImageUrl?: string;
  moduleId: string;
  reason: string;
}

export const CourseSuggestionNewLesson: React.FunctionComponent<
  ICourseSuggestionNewLessonProps
> = (props) => {
  const {
    course,
    suggestion,
    lessonTitle,
    lessonWeblink,
    previewImageUrl,
    moduleId,
    reason,
  } = props;
  const module = getModuleFromCourse(moduleId, course);

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <CourseStructureLessonItem
        lessonTitle={lessonTitle}
        lessonWeblink={lessonWeblink}
        lessonDescription={reason}
        previewImageUrl={previewImageUrl}
        href={lessonWeblink}
        leftEl={
          <UpvoteButtonContainer
            upvoteCount={suggestion.upvotes}
            metadataId={suggestion.id}
            metadataType={MetadataType.CourseSuggestion}
          />
        }
        tabs={[]}
      />
      <TypographyBold
        children={module?.title || "Not Found?"}
        boldedPrefix={"In Module:"}
      />
    </Space>
  );
};
