export const settledLogger = (hookName: string) => {
  return <T>(data: T, error: T, variables?: T) => {
    if (data) {
      console.log(`${hookName} success`, data);
    }
    if (error) {
      console.error(`${hookName} error`, error);
      if (variables) {
        console.warn(`${hookName} variables`, variables);
      }
    }
  };
};
