import {
  Space,
  Typography,
  Divider,
  Popover,
  Button,
  notification,
} from "antd";
import React, { useState } from "react";
import { ILessonAnswer } from "../../types/firestoreMappings";
import { useNavigation } from "../../ui-hooks/useNavigation";
import { ConfirmButton } from "../common/ConfirmButton";
import { DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { InputWithButton } from "../common/InputWithButton";
import { isValidLessonAnswerInputWithErrorNotify } from "../../utils/validInputUtils";
import { locStrings } from "../../localization/locStrings";
import { UserItemContainer } from "../common/UserItemContainer";
import { UpvoteButtonContainer } from "../upvotes/UpvoteButtonContainer";
import { MetadataType } from "../../types/helperTypes";

interface ILessonAnswerProps {
  answer: ILessonAnswer;
  updateAnswer?: (answer: string) => void;
  deleteAnswer?: () => void;
}

export const LessonAnswer: React.FunctionComponent<ILessonAnswerProps> = (
  props
) => {
  const { answer, updateAnswer, deleteAnswer } = props;
  const nav = useNavigation();
  const parsedLessonAnswerId = nav.urlSearchParams.getLessonAnswerId();
  const wasNavigatedToAndShouldHighlight = parsedLessonAnswerId === answer.id;
  const [answerInput, setAnswerInput] = useState<string>(answer.answer);
  const [isUpdateAnswerVisible, setIsUpdateAnswerVisible] =
    useState<boolean>(false);

  const onUpdateQuestion = () => {
    if (updateAnswer) {
      const isValid = isValidLessonAnswerInputWithErrorNotify().forUpdate({
        answer: answerInput,
      });

      if (isValid) {
        try {
          updateAnswer(answerInput);
        } catch (error) {
          notification.error({
            message: locStrings.genericErrorMessage.value(),
          });
        }
      }
    }
  };

  const renderEditAnswer = (
    <InputWithButton
      inputValue={answerInput}
      onInputChange={setAnswerInput}
      onSubmit={onUpdateQuestion}
      buttonChildren={<CheckOutlined />}
      buttonWidthInPx={50}
      inputPlaceholder={""}
      disabled={!answerInput}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      className={
        wasNavigatedToAndShouldHighlight
          ? "highlight-light-blue-and-fade"
          : undefined
      }
    >
      <Divider style={{ margin: 0 }} />
      <Space
        direction={"horizontal"}
        style={{ alignItems: "flex-start", justifyContent: "space-between" }}
      >
        <UserItemContainer
          userId={answer.owner}
          hideAvatar={true}
          courseIdToCheckForOwnership={answer.courseId}
        />
        <Space direction={"horizontal"} style={{ alignItems: "flex-start" }}>
          {updateAnswer && (
            <Popover
              content={renderEditAnswer}
              visible={isUpdateAnswerVisible}
              onVisibleChange={setIsUpdateAnswerVisible}
              trigger={"click"}
            >
              <Button icon={<EditOutlined />} type={"text"} />
            </Popover>
          )}
          {deleteAnswer && (
            <ConfirmButton
              onConfirm={deleteAnswer}
              icon={<DeleteOutlined />}
              type={"text"}
            />
          )}
          <UpvoteButtonContainer
            upvoteCount={answer.upvotes}
            metadataId={answer.id}
            metadataType={MetadataType.LessonAnswer}
          />
        </Space>
      </Space>
      <Typography>{answer.answer}</Typography>
    </div>
  );
};
