import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useInfiniteQuery } from "react-query";
import {
  convertNotificationSnapToINotification,
  INotification,
} from "../../types/firestoreMappings";
import { NotificationType } from "../../types/helperTypes";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

interface ListNotificationsQueryParams {
  userId: string;
  isRead?: boolean;
  type?: NotificationType;
}

const numItemsPerPage = 10;

export const listNotificationsQueryKey = (
  params: ListNotificationsQueryParams
) => {
  return ["listNotificationsQueryKey", params];
};

export const useListNotificationsQuery = (
  params: ListNotificationsQueryParams,
  enabled: boolean = true
) => {
  const listNotifications = async (vars: {
    pageParam?: {
      lastPage: {
        realData: INotification[];
        snaps: QuerySnapshot<DocumentData>;
      };
    };
  }) => {
    const { pageParam } = vars;
    const { userId, isRead, type } = params;
    let isLastPage = false;
    const db = getFirestore();
    const notificaionsCollectionRef = collection(
      db,
      collectionPaths.notifications
    );
    const queryConstraints = [];
    queryConstraints.push(where("userId", "==", userId));
    if (isRead !== undefined) {
      queryConstraints.push(where("isRead", "==", isRead));
    }
    if (type !== undefined) {
      queryConstraints.push(where("type", "==", type));
    }

    // Do pagination stuff
    if (pageParam) {
      const lastSnap =
        pageParam.lastPage.snaps.docs[pageParam.lastPage.snaps.docs.length - 1];
      if (lastSnap) {
        queryConstraints.push(startAfter(lastSnap));
      }
    }
    const q = query(
      notificaionsCollectionRef,
      orderBy("createdAt", "desc"),
      ...queryConstraints,
      limit(numItemsPerPage)
    );
    const qSnapshot = await getDocs(q);
    const notifications = qSnapshot.docs.map((snapshot) =>
      convertNotificationSnapToINotification(snapshot.id, snapshot.data())
    );

    if (notifications.length < numItemsPerPage) {
      isLastPage = true;
    }
    return {
      realData: notifications,
      snaps: qSnapshot,
      isLastPage,
    };
  };
  const paginatedResult = useInfiniteQuery(
    listNotificationsQueryKey(params),
    listNotifications,
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          lastPage,
          allPages,
        };
      },
      onSettled: settledLogger("useListNotificationsQuery"),
      enabled,
    }
  );

  let realDataAllPages: INotification[] = [];
  let isNoMoreToFetch = true;
  if (paginatedResult.data) {
    paginatedResult.data.pages.forEach((page) => {
      realDataAllPages = realDataAllPages.concat(page.realData);
    });
    isNoMoreToFetch =
      paginatedResult.data.pages[paginatedResult.data.pages.length - 1]
        .isLastPage;
  }
  return {
    ...paginatedResult,
    data: realDataAllPages,
    isNoMoreToFetch,
  };
};
