const prodUrl = "https://www.loalinks.com";
const devUrl = "http://localhost:3000";

export const isDevMode = process.env.NODE_ENV === "development";

export const BASE_URL = isDevMode ? devUrl : prodUrl;

export const featureFlags = {
  enableNewLinkButtonInCourseStructure: false,
  enableLessonExercises: false,
  enableLessonQuestionsAndAnswers: false,
  enableGeneralFeedbackSuggestion: false,
};

export const homePageSampleCourseId = "tN2Dl4jLJgSvkUk3S7Gk";
