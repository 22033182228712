import { Button, Space } from "antd";
import React from "react";
import { isValidUrl } from "../../utils/utils";
import { WeblinkInput } from "../common/WeblinkInput";

interface INewLinkWeblinkEntryProps {
  weblink: string;
  setWeblink: (newWeblink: string) => void;
  isLoadingWeblinkPreview: boolean;
  onNext: () => void;
}

export const NewLinkWeblinkEntry: React.FunctionComponent<
  INewLinkWeblinkEntryProps
> = (props) => {
  const { weblink, setWeblink, isLoadingWeblinkPreview, onNext } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <WeblinkInput weblink={weblink} onChange={setWeblink} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={onNext}
          disabled={!isValidUrl(weblink)}
          loading={isLoadingWeblinkPreview}
          type={"primary"}
        >
          {"Next"}
        </Button>
      </div>
    </Space>
  );
};
