import { Button, Input, Space } from "antd";
import React from "react";
import { SISU_INPUT_WIDTH } from "../../styling/styleConstants";
import { convertSisuInputTypeToIcon } from "../../utils/iconAndStringUtils";
import { PageTypography, PageTypographyLevel } from "../common/PageTitle";

interface IResetPasswordEmailEntryProps {
  email: string;
  setEmail: (s: string) => void;
  onResetPassword: () => void;
}

export const ResetPasswordEmailEntry: React.FunctionComponent<
  IResetPasswordEmailEntryProps
> = (props) => {
  const { email, setEmail, onResetPassword } = props;
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", alignItems: "center" }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <PageTypography
          title={"Recover your password."}
          level={PageTypographyLevel.PageSection}
        />
      </div>
      <Input
        id={"ResetPasswordEmailEntry-Email"}
        placeholder={"Email"}
        type={"email"}
        value={email}
        prefix={convertSisuInputTypeToIcon("email")}
        style={{ width: SISU_INPUT_WIDTH }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type={"primary"} onClick={onResetPassword} size={"large"}>
        Send Recovery Email
      </Button>
    </Space>
  );
};
