import { Tag, Tooltip } from "antd";
import React from "react";
import { themeColors, zIndexPriorities } from "../../styling/styleConstants";
import { CoursePublishState } from "../../types/helperTypes";
import { ClickableWrapper } from "./ClickableWrapper";

interface IPublishStateTagProps {
  publishState: CoursePublishState;
  onClick?: () => void;
}

export const PublishStateTag: React.FunctionComponent<IPublishStateTagProps> = (
  props
) => {
  const { publishState, onClick } = props;
  let primaryText = "UNKNOWN";
  let tooltipText = "UNKNOWN";
  let color = "transparent";
  switch (publishState) {
    case CoursePublishState.Draft:
      primaryText = "Under Construction";
      tooltipText =
        "This course is still under construction and has not been reviewed yet.";
      color = themeColors.publishStateDraftYellow;
      break;
    case CoursePublishState.InReview:
      primaryText = "In Review";
      tooltipText =
        "This course is in review. Once approved, it will be marked as reviewed.";
      color = themeColors.publishStateInReviewOrange;
      break;
    case CoursePublishState.Published:
      primaryText = "Reviewed";
      tooltipText = "This course has been reviewed by site moderators.";
      color = themeColors.publishStatePublishedGreen;
      break;
  }
  return (
    <ClickableWrapper onClick={onClick}>
      <Tooltip
        title={tooltipText}
        zIndex={zIndexPriorities.tooltipOutsideModal}
      >
        <Tag style={{ backgroundColor: color }}>{primaryText}</Tag>
      </Tooltip>
    </ClickableWrapper>
  );
};
