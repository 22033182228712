import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from "firebase/firestore";
import { useQuery, useQueryClient } from "react-query";
import { convertUserSnapToIUser } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const getUserByUsernameQueryKey = (username: string) => {
  return ["getUserByUsernameQueryKey", username];
};

interface IGetUserByUsernameArgs {
  username: string;
}

const getUserByUsername = async (vars: IGetUserByUsernameArgs) => {
  const { username } = vars;
  const db = getFirestore();
  const coursesCollectionRef = collection(db, collectionPaths.users);
  const q = query(
    coursesCollectionRef,
    where("username", "==", username),
    limit(1)
  );
  const qSnapshot = await getDocs(q);
  return qSnapshot.docs.map((snapshot) =>
    convertUserSnapToIUser(snapshot.id, snapshot.data())
  );
};

export const useGetUserByUsernameQuery = (
  vars: IGetUserByUsernameArgs,
  enabled: boolean = true
) => {
  const { username } = vars;
  return useQuery(
    getUserByUsernameQueryKey(username),
    () => getUserByUsername(vars),
    {
      onSettled: settledLogger("useGetUserByUsernameQuery"),
      enabled,
    }
  );
};

export const useGetUserByUsernameFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetUserByUsernameArgs) => {
    const { username } = vars;
    return queryClient.fetchQuery(getUserByUsernameQueryKey(username), () =>
      getUserByUsername(vars)
    );
  };
};
