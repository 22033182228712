import { notification } from "antd";
import { deleteField } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useMutation, useQueryClient } from "react-query";
import FirebaseSdk from "../../FirebaseSdk";
import { useUserAuth } from "../../ui-hooks/useUserAuth";
import { listUsersByIdsQueryKey } from "../queries/useListUsersByIds";
import { settledLogger } from "../settledLogger";
import { storagePaths } from "../storagePaths";
import { useUpdateUserMutation } from "./useUpdateUserMutation";

export const useDeleteAvatarUrlMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const { mutate: updateUser } = useUpdateUserMutation(user?.uid || "");
  const storage = FirebaseSdk.getStorage();
  const deleteAvatarUrl = async () => {
    if (user) {
      const avatarUrlRef = ref(storage, storagePaths.avatarUrls(user.uid));
      await deleteObject(avatarUrlRef);
      return updateUser({ avatarUrl: deleteField() });
    } else {
      notification.error({ message: "Not signed in" });
    }
  };
  return useMutation(deleteAvatarUrl, {
    onSettled: settledLogger("useDeleteAvatarUrlMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries(listUsersByIdsQueryKey([user?.uid || ""]), {
        refetchInactive: true,
      });
    },
  });
};
