import React, { useState } from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Card, notification, Space, Typography } from "antd";
import { ButtonSize } from "antd/lib/button";
import { zIndexPriorities } from "../../styling/styleConstants";
import { ClickableWrapper } from "./ClickableWrapper";
import { ModalWithTitle } from "./ModalWithTitle";

interface IShareLinkButtonProps {
  modalTitle: string;
  link: string;
  buttonText?: string;
  size?: ButtonSize;
  style?: React.CSSProperties;
}

export const ShareLinkButton: React.FunctionComponent<IShareLinkButtonProps> = (
  props
) => {
  const { modalTitle, link, buttonText, size, style } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onCopyLinkToClipboard = () => {
    navigator.clipboard.writeText(link);
    notification.success({ message: "Link copied to clipboard" });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalVisible(true)}
        size={size || "middle"}
        style={style}
        icon={buttonText && <ShareAltOutlined />}
      >
        {buttonText || <ShareAltOutlined />}
      </Button>
      <ModalWithTitle
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        title={modalTitle}
        zIndex={zIndexPriorities.shareLinkModal}
      >
        <Space
          direction={"vertical"}
          style={{ alignItems: "center", width: "100%" }}
        >
          <ClickableWrapper onClick={onCopyLinkToClipboard}>
            <Card size={"small"}>
              <Typography.Link
                style={{
                  wordBreak: "break-all",
                }}
              >
                {link}
              </Typography.Link>
            </Card>
          </ClickableWrapper>
          <Button
            onClick={onCopyLinkToClipboard}
            type={"primary"}
            style={{ marginTop: "1rem" }}
          >
            {"Copy to clipboard"}
          </Button>
        </Space>
      </ModalWithTitle>
    </>
  );
};
