import { Space, Tag, Tooltip } from "antd";
import React from "react";
import { zIndexPriorities } from "../../styling/styleConstants";
import { CourseCategory } from "../../types/helperTypes";
import { convertCourseCategoryToStringAndIcon } from "../../utils/iconAndStringUtils";

interface ICourseCategoryTagProps {
  category: CourseCategory;
}

export const CourseCategoryTag: React.FunctionComponent<
  ICourseCategoryTagProps
> = (props) => {
  const { category } = props;
  const categoryStringAndIcon = convertCourseCategoryToStringAndIcon(category);
  return (
    <Tooltip
      title={`Belongs to the ${categoryStringAndIcon.string} category`}
      zIndex={zIndexPriorities.tooltipOutsideModal}
    >
      <Tag style={{ backgroundColor: "white" }}>
        <Space direction={"horizontal"}>
          <img
            alt={categoryStringAndIcon.string}
            src={categoryStringAndIcon.iconSrcString}
            style={{ height: "0.8rem", width: "auto" }}
          />
          {categoryStringAndIcon.string}
        </Space>
      </Tag>
    </Tooltip>
  );
};
