import React, { useState } from "react";
import { convertCourseMetadataTypeToIcon } from "../../../utils/iconAndStringUtils";
import { CourseSuggestionType, MetadataType } from "../../../types/helperTypes";
import { LessonExerciseCreateModalContainer } from "../../exercises/LessonExerciseCreateModalContainer";
import { LessonQuestionCreateModalContainer } from "../../questions-answers/LessonQuestionCreateModalContainer";
import { CourseSuggestionCreateNewModuleContainer } from "../../course-suggestions/create/new-module/CourseSuggestionCreateNewModuleContainer";
import { CourseSuggestionCreateGeneralFeedbackContainer } from "../../course-suggestions/create/general-feedback/CourseSuggestionCreateGeneralFeedbackContainer";
import { NewLinkModalContainer } from "../../new-link-modal/NewLinkModalContainer";
import { ICourse } from "../../../types/firestoreMappings";
import { calculateModuleAndLessonCountsInCourse } from "../../../utils/utils";
import { CourseToolBarItems, ICourseToolBarItem } from "./CourseToolBarItems";
import { featureFlags } from "../../../utils/appConstants";

interface ICourseContributorToolBarButtonsProps {
  course: ICourse;
}

export const CourseContributorToolBarButtons: React.FunctionComponent<
  ICourseContributorToolBarButtonsProps
> = (props) => {
  const { course } = props;
  const [isModalVisibleType, setIsModalVisibleType] = useState<
    MetadataType | undefined
  >(undefined);
  const closeModal = () => {
    setIsModalVisibleType(undefined);
  };
  const openModal = (metadataType: MetadataType) => {
    setIsModalVisibleType(metadataType);
  };

  const moduleAndLessonCounts = calculateModuleAndLessonCountsInCourse(course);

  const items: ICourseToolBarItem[] = [
    {
      id: "NewLink",
      title: "Add Link",
      icon: convertCourseMetadataTypeToIcon({
        metadataType: MetadataType.AlternativeLink,
      }),
      onClick: () => openModal(MetadataType.AlternativeLink),
    },
    {
      id: "NewModule",
      title: "Add Module",
      icon: convertCourseMetadataTypeToIcon({
        courseSuggestionType: CourseSuggestionType.NewModule,
      }),
      onClick: () => openModal(MetadataType.CourseSuggestionNewModule),
    },
    {
      id: "NewGeneralFeedback",
      title: "Give Feedback",
      icon: convertCourseMetadataTypeToIcon({
        courseSuggestionType: CourseSuggestionType.GeneralFeedback,
      }),
      onClick: () => openModal(MetadataType.CourseSuggestionGeneralFeedback),
      isHidden: !featureFlags.enableGeneralFeedbackSuggestion,
    },
    {
      id: "NewExercise",
      title: "New Exercise",
      icon: convertCourseMetadataTypeToIcon({
        metadataType: MetadataType.LessonExercise,
      }),
      onClick: () => openModal(MetadataType.LessonExercise),
      isHidden:
        moduleAndLessonCounts.lessonsCount === 0 ||
        !featureFlags.enableLessonExercises,
    },
    {
      id: "NewQuestion",
      title: "New Question",
      icon: convertCourseMetadataTypeToIcon({
        metadataType: MetadataType.LessonQuestion,
      }),
      onClick: () => openModal(MetadataType.LessonQuestion),
      isHidden:
        moduleAndLessonCounts.lessonsCount === 0 ||
        !featureFlags.enableLessonQuestionsAndAnswers,
    },
  ];

  return (
    <>
      <CourseToolBarItems items={items} />
      <NewLinkModalContainer
        visible={isModalVisibleType === MetadataType.AlternativeLink}
        onClose={closeModal}
        courseId={course.id}
      />
      <CourseSuggestionCreateNewModuleContainer
        visible={isModalVisibleType === MetadataType.CourseSuggestionNewModule}
        onClose={closeModal}
        courseId={course.id}
      />
      <CourseSuggestionCreateGeneralFeedbackContainer
        visible={
          isModalVisibleType === MetadataType.CourseSuggestionGeneralFeedback
        }
        onClose={closeModal}
        courseId={course.id}
      />
      <LessonExerciseCreateModalContainer
        visible={isModalVisibleType === MetadataType.LessonExercise}
        onClose={closeModal}
        courseId={course.id}
      />
      <LessonQuestionCreateModalContainer
        visible={isModalVisibleType === MetadataType.LessonQuestion}
        onClose={closeModal}
        courseId={course.id}
      />
    </>
  );
};
