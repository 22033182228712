import { useQuery, useQueryClient } from "react-query";
import { NotificationType } from "../types/helperTypes";

interface NotificationsFilterState {
  isUnreadOnlyEnabled: boolean;
  currentFilterType: NotificationType | undefined;
}

const notificationsFilterStateKey = "notificationsFilterStateKey";
const defaultNotificationsFilterState: NotificationsFilterState = {
  isUnreadOnlyEnabled: false,
  currentFilterType: undefined,
};

export const useNotificationsFilterState = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery<NotificationsFilterState>(
    notificationsFilterStateKey,
    async () =>
      Promise.reject(
        "useNotificationsFilterState.no-op, should never be called"
      ),
    {
      initialData: defaultNotificationsFilterState,
      staleTime: Infinity, // this data/cache will be updated manually
      cacheTime: Infinity, // never expire this data for entirety of local session
    }
  );

  const getNotificationsFilterState = () => {
    return data || defaultNotificationsFilterState;
  };

  const setNotificationsFilterState = (
    newNotificationsFilterState: Partial<NotificationsFilterState>
  ) => {
    queryClient.setQueryData<NotificationsFilterState>(
      notificationsFilterStateKey,
      () => ({
        ...getNotificationsFilterState(),
        ...newNotificationsFilterState,
      })
    );
  };

  return {
    getNotificationsFilterState,
    setNotificationsFilterState,
  };
};
