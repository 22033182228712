import React, { useEffect, useRef, useState } from "react";
import "draft-js/dist/Draft.css";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  DraftEditorCommand,
  DraftInlineStyleType,
  DraftBlockType,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Button } from "antd";
import {
  BoldOutlined,
  UnderlineOutlined,
  ItalicOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { InputMimicry } from "./InputMimicry";

interface IRichTextEditorProps {
  initialValue: string;
  onHtmlStringChange: (htmlString: string) => void;
  placeholder?: string;
}

export const RichTextEditor: React.FunctionComponent<IRichTextEditorProps> = (
  props
) => {
  const { initialValue, onHtmlStringChange, placeholder } = props;
  const hasInitialized = useRef<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(
    initialValue
      ? convertHtmlStringToDraftEditorState(initialValue)
      : EditorState.createEmpty()
  );

  // If initialValue begins udnefined because data is being fetched, this will make sure things initialize properly
  useEffect(() => {
    if (!hasInitialized.current && !!initialValue) {
      setEditorState(convertHtmlStringToDraftEditorState(initialValue));
      hasInitialized.current = true;
    }
  }, [initialValue]);

  const onChange = (es: EditorState) => {
    // Should skip initializing if user writes before content can load
    hasInitialized.current = true;
    setEditorState(es);
    onHtmlStringChange(convertDraftEditorStateToHtmlString(es));
  };
  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };
  const toggleBold = () => {
    const inlineStyle: DraftInlineStyleType = "BOLD";
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    onChange(newState);
  };
  const toggleItalic = () => {
    const inlineStyle: DraftInlineStyleType = "ITALIC";
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    onChange(newState);
  };
  const toggleUnderline = () => {
    const inlineStyle: DraftInlineStyleType = "UNDERLINE";
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    onChange(newState);
  };
  const toggleUnorderedList = () => {
    const blockType: DraftBlockType = "unordered-list-item";
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    onChange(newState);
  };
  const toggleOrderedList = () => {
    const blockType: DraftBlockType = "ordered-list-item";
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    onChange(newState);
  };
  //   const toggleBlockquoteList = () => {
  //     const blockType: DraftBlockType = "blockquote";
  //     const newState = RichUtils.toggleBlockType(editorState, blockType);
  //     onChange(newState);
  //   };
  //   const toggleCodeBlockList = () => {
  //     const blockType: DraftBlockType = "code-block";
  //     const newState = RichUtils.toggleBlockType(editorState, blockType);
  //     onChange(newState);
  //   };

  return (
    <InputMimicry>
      <div style={{ borderBottom: "1px solid #d9d9d9", marginBottom: "14px" }}>
        <Button onClick={toggleBold} icon={<BoldOutlined />} type={"text"} />
        <Button
          onClick={toggleItalic}
          icon={<ItalicOutlined />}
          type={"text"}
        />
        <Button
          onClick={toggleUnderline}
          icon={<UnderlineOutlined />}
          type={"text"}
        />
        <Button
          onClick={toggleUnorderedList}
          icon={<UnorderedListOutlined />}
          type={"text"}
        />
        <Button
          onClick={toggleOrderedList}
          icon={<OrderedListOutlined />}
          type={"text"}
        />
      </div>
      <Editor
        editorState={editorState}
        onChange={onChange}
        handleKeyCommand={handleKeyCommand}
        stripPastedStyles={true}
        placeholder={placeholder}
      />
    </InputMimicry>
  );
};

export const convertDraftEditorStateToHtmlString = (
  editorState: EditorState
): string => {
  return stateToHTML(editorState.getCurrentContent());
};

export const convertHtmlStringToDraftEditorState = (
  htmlString: string
): EditorState => {
  const blocksFromHTML = convertFromHTML(htmlString);
  const parsedContentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(parsedContentState);
};
