import { Space, Typography } from "antd";
import React from "react";
import { locStrings } from "../../localization/locStrings";
import { ICourseComment } from "../../types/firestoreMappings";
import { FetchMoreButton } from "../common/FetchMoreButton";
import { CourseCommentContainer } from "./CourseCommentContainer";

interface ICourseCommentsProps {
  courseComments: ICourseComment[];
  isNoMoreCourseCommentsToFetch: boolean;
  fetchMoreCourseComments: () => void;
}

export const CourseComments: React.FunctionComponent<ICourseCommentsProps> = (
  props
) => {
  const {
    courseComments,
    isNoMoreCourseCommentsToFetch,
    fetchMoreCourseComments,
  } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {courseComments.length === 0 && (
        <Typography.Text italic={true}>
          {"No comments have been left yet"}
        </Typography.Text>
      )}
      {courseComments.map((courseComment) => {
        return (
          <CourseCommentContainer
            courseComment={courseComment}
            key={`CourseComments-Comment-${courseComment.id}`}
          />
        );
      })}
      <FetchMoreButton
        text={locStrings.loadMoreComments.value()}
        isNoMoreToFetch={isNoMoreCourseCommentsToFetch}
        fetchMore={fetchMoreCourseComments}
      />
    </Space>
  );
};
