import React from "react";
import { Button, Divider, Space } from "antd";
import GooglePng from "../../assets/social-brand-logos/Google.png";

interface ISignInOptionsProps {
  onSignInWithGoogle: () => void;
}

export const SignInOptions: React.FunctionComponent<ISignInOptionsProps> = (
  props
) => {
  const { onSignInWithGoogle } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Button
        onClick={onSignInWithGoogle}
        icon={
          <img
            src={GooglePng}
            alt={"Sign in with Google"}
            style={{ height: "1.2rem", width: "auto", marginRight: "1rem" }}
          />
        }
      >
        {"Sign in with Google"}
      </Button>
      <Divider
        orientation={"center"}
        orientationMargin={0}
        style={{ margin: 0 }}
      >
        {"or"}
      </Divider>
    </Space>
  );
};
