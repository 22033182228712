import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useMutation } from "react-query";
import { IUser } from "../../types/firestoreMappings";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";

export const useCreateUserMutation = () => {
  const createUser = (vars: { userId: string; username: string }) => {
    const { userId, username } = vars;
    const db = getFirestore();
    const userDocRef = doc(db, collectionPaths.users, userId);
    const defaultUserObject: Partial<IUser> = {
      username,
      enableNewNotificationEmails: true,
    };
    return setDoc(userDocRef, defaultUserObject);
  };
  return useMutation(createUser, {
    onSettled: settledLogger("useCreateUserMutation"),
  });
};
